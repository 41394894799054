import { Center, Modal, ModalBody, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'

import { Spinner } from '@wanda-space/noelle'
import * as React from 'react'

export const PaymentRedirectionModal = ({
  text,
  isOpen,
}: {
  text: string
  isOpen: boolean
}) => (
  <Modal size="xs" isCentered isOpen={isOpen} onClose={() => -1}>
    <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(3px) hue-rotate(90deg)" />
    <ModalContent>
      <ModalBody py="5">
        <Text color="gray.600" textAlign="center">
          {text}
        </Text>
        <Center>
          <Spinner width={12} />
        </Center>
      </ModalBody>
    </ModalContent>
  </Modal>
)
