export const CroppedSize = 860

export const blobToDataURL = async (blob: Blob): Promise<string> =>
  new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener(
      'load',
      () => {
        resolve(reader.result as string)
      },
      false
    )
    reader.readAsDataURL(blob)
  })

export const createImage = async (url: Blob | string): Promise<HTMLImageElement> => {
  const src = typeof url === 'string' ? url : await blobToDataURL(url)

  return new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', reject)
    image.src = src
  })
}

export const resetImageWithScale = async (imageSrc: Blob): Promise<string> => {
  const image = await createImage(imageSrc)
  let { width, height } = image
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  const scaleRatio = width < height ? CroppedSize / width : CroppedSize / height
  width *= scaleRatio
  height *= scaleRatio

  if (ctx) {
    canvas.width = width
    canvas.height = height

    ctx.drawImage(image, 0, 0, width, height)

    return canvas.toDataURL()
  }
  return ''
}

export const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: any,
  rotate: number
): Promise<string> => {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const rotation = (rotate * Math.PI) / 180
  const scale = 860 / pixelCrop.width
  const maxSize = Math.max(image.width, image.height)
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))
  const safeAreaScale = safeArea * scale

  canvas.width = safeAreaScale
  canvas.height = safeAreaScale

  if (ctx) {
    ctx.translate(safeAreaScale / 2, safeAreaScale / 2)
    ctx.rotate(rotation)
    ctx.translate(-safeAreaScale / 2, -safeAreaScale / 2)

    ctx.drawImage(
      image,
      (safeArea / 2 - image.width * 0.5) * scale,
      (safeArea / 2 - image.height * 0.5) * scale,
      image.width * scale,
      image.height * scale
    )

    const data = ctx.getImageData(0, 0, safeAreaScale, safeAreaScale)

    canvas.width = 860
    canvas.height = 860

    ctx.putImageData(
      data,
      0 - safeAreaScale / 2 + image.width * 0.5 * scale - pixelCrop.x * scale,
      0 - safeAreaScale / 2 + image.height * 0.5 * scale - pixelCrop.y * scale
    )
  }

  return canvas.toDataURL('image/jpeg', 0.8)
}
