import { Container, Heading, Link as ChakraLink } from '@chakra-ui/react'
import { BuildingIcon, MovingTruckIcon, SelectCard } from '@wanda-space/noelle'
import { Routes } from 'consts'
import { Stepable } from 'interfaces'
import React from 'react'
import { useIntl } from 'react-intl'
import { Link, LinkProps, useNavigate } from 'react-router-dom'

export const SellingType = (props: Stepable) => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()

  return (
    <Container pt={0}>
      <Heading fontSize="2xl" mb={6}>
        {formatMessage({ id: 'buy.sell.item.location' })}
      </Heading>
      <SelectCard
        active
        title={formatMessage({ id: 'buy.sell.pickup.item' })}
        description={formatMessage({ id: 'buy.sell.pickup.item.description' })}
        icon={<MovingTruckIcon />}
        data-testid="sell-from-pickup-card"
        mb={6}
        onClick={() => navigate(Routes.SellWithPickup + location.search)}
      />
      <ChakraLink
        to={props.nextPath + location.search || Routes.Home}
        as={(props: LinkProps) => (
          <Link {...props}>
            <SelectCard
              active={false}
              title={formatMessage({ id: 'buy.sell.storage.item' })}
              description={formatMessage({ id: 'buy.sell.storage.item.description' })}
              mb={6}
              icon={<BuildingIcon />}
              data-testid="sell-from-storage-card"
            />
          </Link>
        )}
      />
    </Container>
  )
}
