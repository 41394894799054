import { Box, Button, ButtonProps, Flex, HStack, Text } from '@chakra-ui/react'
import {
  Alert,
  Color,
  DynamicIcon,
  DynamicIconName,
  ItemWrapper,
  Notification,
  Pagination,
  WSkeleton,
} from '@wanda-space/noelle'
import { type ItemResponseDto } from '@wanda-space/types'
import { useProductsAndCategories } from 'hooks/useProductsAndCategories'
import { type ThresholdMap } from 'interfaces'
import React, { useEffect, useState } from 'react'

import { useIntl } from 'react-intl'
import { translateItemType } from 'utils/translateItemType'

import { HelpFooter } from 'components/HelpFooter'
import { Routes } from 'consts'
import { Link } from 'react-router-dom'
import { ItemImage } from '../Image/ItemImage'

export interface ItemListProps {
  paginate?: boolean
  isLoading?: boolean
  itemsPerPage?: number
  items: ItemResponseDto[]
  preselectedItems?: ItemResponseDto[]
  handleSelect: ((selectedItems: ItemResponseDto[]) => void) | (() => void)
  handleSubmit: ((selectedItems: ItemResponseDto[]) => void) | (() => void)
  thresholdMap: ThresholdMap
  shouldDisableNextStep?: { areItemsWithListingSelected?: boolean }
}
export const ItemList = ({
  items,
  handleSelect,
  handleSubmit,
  thresholdMap,
  preselectedItems,
  shouldDisableNextStep,
  paginate,
  itemsPerPage = 10,
  isLoading = false,
}: ItemListProps) => {
  const { formatMessage } = useIntl()
  const [selectedItems, setSelectedItems] = useState<ItemResponseDto[]>([])
  const { productCategoriesById } = useProductsAndCategories()
  const [dismissedNotification, setDismissedNotification] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const numberOfPages = Math.ceil(items.length / itemsPerPage)

  const itemsToDisplay = paginate
    ? items.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    : items

  if (preselectedItems && preselectedItems.length > 0 && selectedItems.length === 0) {
    setSelectedItems(preselectedItems)
  }

  const onPaginationChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleToggle = (item: ItemResponseDto) => {
    const updatedSelectedItems =
      selectedItems.find(({ id }) => item.id === id) || thresholdMap[item.type]
        ? selectedItems.filter(({ id }) => id !== item.id)
        : selectedItems.concat(item)

    setSelectedItems(updatedSelectedItems)
    handleSelect(updatedSelectedItems)
  }

  const CtaButton = ({ ...props }: ButtonProps) => (
    <Button
      colorScheme="ctaBlack"
      width="100%"
      onClick={() => handleSubmit(selectedItems)}
      borderRadius="md"
      data-testid="continue-button"
      {...props}
    >
      {formatMessage({ id: 'word.continue' })}
    </Button>
  )
  const isCtaDisabled = Boolean(shouldDisableNextStep)

  if (isLoading) {
    return (
      <Box>
        {Array.from({ length: itemsPerPage }).map((_, i) => (
          <Flex p="3" alignItems="center" borderRadius="lg" justifyContent="space-between">
            <Flex gap={4} flexGrow={1} flexShrink={0}>
              <WSkeleton height="16" width="16" borderRadius="lg" />
              <Flex justifyContent="center" direction="column" flexGrow={1} flexShrink={0}>
                <WSkeleton height="4" width="40%" mb="2" />
                <WSkeleton height="3" width="50%" />
              </Flex>
            </Flex>
            <WSkeleton height="4" width="4" borderRadius="sm" />
          </Flex>
        ))}
      </Box>
    )
  }

  return (
    <>
      {(thresholdMap.SMALL || thresholdMap.BOX || thresholdMap.LARGE) && !dismissedNotification && (
        <Notification
          id="limit-items-notification"
          dismissible
          itemSelect
          data-testid="limit-items-notification"
          text={formatMessage({ id: 'word.taasLimitAmount' })}
          title={formatMessage({ id: 'word.taasReachedMaximum' })}
          type="alerts"
          onClose={() => setDismissedNotification(true)}
        />
      )}

      {shouldDisableNextStep?.areItemsWithListingSelected && (
        <Box mb={4}>
          <Alert
            id="listing-items-selected-notification"
            data-testid="listing-items-selected-notification"
            title={formatMessage({ id: 'word.activeSalesAdd' })}
            text={formatMessage({ id: 'deactivate.listing.warning' })}
            status="error"
            button={{
              as: ({ children, as, ...rest }) => (
                <Link to={Routes.SalesOrders} {...rest}>
                  {children}
                </Link>
              ),
              justifyContent: 'initial',
              margin: 0,
              variant: 'link',
              color: 'red.700',
              children: formatMessage({ id: 'word.goto.my.salesAdd' }),
            }}
          />
        </Box>
      )}

      {!paginate && items.length > 5 ? (
        <CtaButton isDisabled={isCtaDisabled} mb={6} key={'top'} />
      ) : null}
      {itemsToDisplay.map((item) => {
        const isChecked = selectedItems.some(({ id }) => id === item.id)
        const category = productCategoriesById?.[item.storageProduct?.categoryId ?? '']
        const showIndicator =
          Boolean(item.listingId) &&
          Boolean(shouldDisableNextStep?.areItemsWithListingSelected) &&
          isChecked

        return (
          <ItemWrapper
            dataTestId={item.id}
            key={item.id}
            img={
              <ItemImage
                itemId={item.id}
                imageId={item.image ?? undefined}
                iconName={category?.iconName as DynamicIconName}
              />
            }
            contentProps={{
              name: item.name,
              type: translateItemType(formatMessage, item.type),
              indicator: showIndicator ? (
                <HStack>
                  <DynamicIcon color="red.700" iconName={DynamicIconName.warning} w={4} h={4} />
                  <Text color="red.700">{formatMessage({ id: 'word.forSale' })}</Text>
                </HStack>
              ) : undefined,
            }}
            withCheckbox
            isChecked={isChecked}
            {...(showIndicator
              ? {
                  backgroundColor: 'red.50',
                  borderColor: 'red.600',
                  borderWidth: 1,
                  borderStyle: 'solid',
                }
              : null)}
            onClick={() => handleToggle(item)}
            disabled={thresholdMap[item.type] && !isChecked}
          />
        )
      })}
      {paginate && numberOfPages > 1 && (
        <Flex my="6" justifyContent="center" w="100%">
          <Pagination
            currentPage={currentPage}
            numberOfPages={numberOfPages}
            numberOfPagesToShow={5}
            onChange={onPaginationChange}
          />
        </Flex>
      )}
      <CtaButton isDisabled={isCtaDisabled} mt={6} mb={3} key={'bottom'} />
      <HelpFooter />
    </>
  )
}
