import { Card, Flex, Text } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { MoneyInHandIcon, Spinner } from '@wanda-space/noelle'
import { ProductPriceType, ProductType } from '@wanda-space/types'
import { getOrder } from 'api-client/lib/routes/orders'
import { BookingConfirmFooter } from 'components/booking-confirm-page-components/BookingConfirmFooter'
import { BookingConfirmationHeader } from 'components/booking-confirm-page-components/BookingConfirmHeader'
import { OrderlineAddress } from 'components/booking-confirm-page-components/OrderlineAddress'
import { WorthKnowingWrapper } from 'components/booking-confirm-page-components/WorthKnowingWrapper'
import { YouWillBeContacted } from 'components/booking-confirm-page-components/YouWillBeContacted'
import { Routes } from 'consts'
import { useAppSelector } from 'hooks/useAppSelector'
import React from 'react'
import { useIntl } from 'react-intl'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { orderlineToDiscountedOrderline } from 'utils/orderline'
import { priceToSupportedCurrency, sanitizeStripeAmount } from 'utils/price-utils'
import { add } from 'utils/price-utils'

import { linkFormatter } from 'utils/linkFormatter'
import { WorthKnowingStep } from '../../components/booking-confirm-page-components/WorthKnowingStep'

const BookingConfirmation = () => {
  const { formatMessage } = useIntl()
  const [searchParams] = useSearchParams()
  const orderId = searchParams.get('orderId')
  const navigate = useNavigate()
  const countryCode = useAppSelector((state) => state.user.user?.countryCode)
  const { data, isError, isLoading } = useQuery(['orderId', orderId], () => getOrder(orderId!))
  const orderLines = (data?.orderLines || []).filter((o) => o.quantity > 0)
  const orderLinesOneTime = orderLines.filter(
    (o) => o.product.priceType === ProductPriceType.ONE_TIME
  )
  const orderLinesRecurring = orderLines.filter(
    (o) => o.product.priceType === ProductPriceType.RECURRING
  )

  const recurringPrice = add(...orderLinesRecurring.map(orderlineToDiscountedOrderline))
  const oneTimePrice = add(...orderLinesOneTime.map(orderlineToDiscountedOrderline))
  const storageItems = orderLinesRecurring.filter(
    (o) => o.product.productType === ProductType.STORAGE
  )

  if (isError) {
    return <Navigate to={Routes.Storage} />
  }

  if (isLoading || !data) {
    return <Spinner />
  }

  return (
    <>
      <BookingConfirmationHeader />
      <Flex mt={3} flexDirection="column">
        <Text size="md" mb={5}>
          {formatMessage(
            { id: 'booking-confirm.order.details.storageFlow' },
            {
              itemsCount: (
                <b>
                  {storageItems.length}{' '}
                  {formatMessage(
                    {
                      id: 'word.thing.lowercase',
                    },
                    { count: storageItems.length }
                  )}
                </b>
              ),
              monthlyPrice: (
                <b>
                  {sanitizeStripeAmount(recurringPrice.amount)}{' '}
                  {priceToSupportedCurrency(recurringPrice, countryCode)}
                </b>
              ),
              oneTimePrice: (
                <b>
                  {sanitizeStripeAmount(oneTimePrice.amount)}{' '}
                  {priceToSupportedCurrency(oneTimePrice, countryCode)}
                </b>
              ),
              email: data.orderDetails.contactInfo.email,
            }
          )}
        </Text>
        <WorthKnowingWrapper>
          <WorthKnowingStep
            key="step2"
            iconName="photoCheckMark"
            text={formatMessage({
              id: 'booking.confirmation.order.worthKnowing.storageFlow.updateItemNudge',
            })}
          />
          <OrderlineAddress order={data} />
          <YouWillBeContacted messageId="booking.confirmation.order.worthKnowing.storageFlow.youWillBeContacted" />
        </WorthKnowingWrapper>
        <BookingConfirmFooter
          editOrderBtn={{
            show: true,
            orderId: data.id,
          }}
          addImagesBtn={{
            show: true,
          }}
          detailsMessage={formatMessage(
            {
              id: 'booking.confirmation.order.returnFlow.forgotSomething.details',
            },
            {
              termsOfUseLink: (
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={linkFormatter(formatMessage, { navigation: 'terms' })}
                  style={{ textDecoration: 'underline' }}
                >
                  {formatMessage({ id: 'summary.termsOfUse' })}
                </a>
              ),
            }
          )}
        />

        <Card
          borderRadius={10}
          bgColor={'brown.100'}
          p={4}
          mt={10}
          border={'solid'}
          borderColor={'ctaBlack.200'}
          borderWidth={'thin'}
          boxShadow={'unset'}
          onClick={() => navigate(Routes.ReferFriend)}
        >
          <Flex mb={2}>
            <MoneyInHandIcon />
            <Text ml={4} fontSize={'lg'} fontWeight={'semibold'}>
              {formatMessage({ id: 'referral.refer.friend.title' })}
            </Text>
          </Flex>
          <Text>{formatMessage({ id: 'referral.refer.friend.content' })}</Text>
          <Text textDecoration={'underline'}>{formatMessage({ id: 'word.settIgang' })}</Text>
        </Card>
      </Flex>
    </>
  )
}

export { BookingConfirmation }
