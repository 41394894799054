import { Box, Button } from '@chakra-ui/react'
import { UserIcon } from '@wanda-space/noelle'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Routes } from '../../../consts'
import { getTwoLettersInitials } from './utils'

interface UserBadgeButtonProps {
  firstName: string
  lastName: string
}

export const UserBadgeButton = (props: UserBadgeButtonProps) => {
  const twoLetterInitials = getTwoLettersInitials(props.firstName, props.lastName)

  const navigate = useNavigate()
  return (
    <Box
      data-testid="settings-button"
      as={Button}
      aria-label={Routes.Settings}
      colorScheme="ctaBlack"
      borderRadius="50%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      fontSize="sm"
      height={8}
      width={8}
      p={0}
      m={0}
      minWidth={8}
      minHeight={8}
      onClick={() => navigate(Routes.Settings)}
    >
      {twoLetterInitials.length > 1 ? twoLetterInitials : <UserIcon color={'white'} />}
    </Box>
  )
}
