import { ApiError } from 'api-client/lib/api-client'

export interface WandaSerializedErrorInterface {
  status: string
  localizationKey: string
  fullFieldPath?: string
  fieldName?: string
}

export function serializeError(errorResponse: ApiError | unknown): WandaSerializedErrorInterface {
  if (errorResponse instanceof ApiError) {
    const localizationKey = errorResponse.localizationKey()
    const body = errorResponse.responseBody()
    const status = errorResponse.status().toString()
    if (localizationKey === 'errors.validation' && body) {
      // get the first key for validation issue
      const [firstIssueKey] = Object.keys(body.issues ?? [])
      // adds validation. prefix where the validation failed
      const fieldName = firstIssueKey?.split('.').pop()
      const validationLocalizationKey = `error.validation${(fieldName ?? '').replace(/\w+/, '.$&')}`
      return {
        status: status,
        localizationKey: validationLocalizationKey,
        fieldName: fieldName,
        fullFieldPath: firstIssueKey,
      }
    } else if (localizationKey && typeof localizationKey === 'string') {
      return {
        status,
        localizationKey,
      }
    }
    return {
      status,
      localizationKey: 'error.general',
    }
  }

  return {
    status: 'client-side',
    localizationKey: 'error.general',
  }
}
