import { joinString } from 'utils'

export const simpleId = ':simpleId'

export const sellFromStorage = {
  introduction: 'introduction',
  selectSellType: 'select-sell-type',
  selectItems: 'select-item-to-sell',
  createListing: 'create-listing-page',
  listingPreview: 'listing-preview-page',
  listingPublished: 'listing-published-page',
  register: 'register',
}

export const buyPaths = {
  advertisement: 'listing',
  deliveryStep: 'delivery-step',
  paymentStep: 'payment-step',
  confirm: 'confirm',
  register: 'register',
}

export const sellWithPickup = {
  selectItems: 'select-items',
  createListing: 'create-listing-page',
  listingPreview: 'listing-preview-page',
  register: 'register',
  selectDateAndTime: 'listing-pickup-schedule',
  pickupAddress: 'listing-pickup-address',
  carrying: 'listing-carrying',
  paymentStep: 'payment-step',
  bookingConfirmation: 'listing-booking-confirmation',
  listingPublished: 'listing-published-page',
}

export const bidPaths = {
  bid: 'bid-step',
  register: 'register',
}

export const getBidRegisterRoute = (id = simpleId) => joinString([id, bidPaths.register], '/')
export const getBidRoute = (id = simpleId) => joinString([id, bidPaths.bid], '/')

export const getAdvertisementRoute = (id = simpleId) =>
  joinString([buyPaths.advertisement, id], '/')
export const getStorageDeliveryStepRoute = (id = simpleId) =>
  joinString([id, buyPaths.deliveryStep], '/')
export const getBuyPaymentStepRoute = (id = simpleId) => joinString([id, buyPaths.paymentStep], '/')

export const getBuyConfirmRoute = (id = simpleId) => joinString([id, buyPaths.confirm], '/')

export const getBuyRegisterRoute = (id = simpleId) => joinString([id, buyPaths.register], '/')
