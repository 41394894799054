import { captureException } from '@sentry/react'
import qs from 'query-string'
import { v4 as uuid } from 'uuid'

import { baseUrl } from './config'
import { getOrCreateSessionId } from './session-id'

type Primitives = string | undefined | null | boolean | number

interface ApiFilters {
  filters?: Record<string, Primitives | string[]>
}

export type ApiParams = Record<string, Primitives> | ApiFilters

type Options = {
  baseUrl?: string
  url: string
  params?: ApiParams
  signal?: AbortSignal
  token?: string
  skipAuth?: boolean
} & (
  | {
      method: 'GET'
      body?: undefined
    }
  | {
      method: 'POST'
      body: unknown
    }
  | {
      method: 'PUT'
      body: unknown
    }
  | {
      method: 'DELETE'
      body?: undefined
    }
  | {
      method: 'PATCH'
      body?: unknown
    }
)

export class ApiError extends Error {
  public fingerprint: string[]
  constructor(
    private url: string,
    private options: Options,
    private response: Response,
    private body?: Record<string, unknown>
  ) {
    super(`Request to ${url} failed with ${response.statusText} (${response.status})`)
    Object.setPrototypeOf(this, ApiError.prototype)
    this.name = this.constructor.name
    this.fingerprint = [
      this.url,
      this.options.method,
      this.response.status.toString(),
      typeof this.body?.wandaCode === 'string' ? this.body.wandaCode : JSON.stringify(this.body),
    ]
  }
  responseBody() {
    return this.body
  }
  status(): number {
    return this.response.status
  }
  localizationKey(): string | undefined {
    if (this.body?.localizationKey && typeof this.body.localizationKey === 'string') {
      return this.body.localizationKey
    }
    return undefined
  }
  shouldReportToSentry(): boolean {
    const isImage502 = this.response.url.includes('image') && this.response.status === 502
    return this.response.status >= 500 && !isImage502
  }
}
export async function request<ResponseType>(options: Options): Promise<ResponseType> {
  const jwtToken = options.token ?? localStorage.getItem('WANDA_JWT_TOKEN')
  const { filters, ...rest } = options.params ?? { filters: undefined }
  const params: Record<string, Primitives> | undefined = options.params ? { ...rest } : undefined

  if (filters && params) {
    params.filters = JSON.stringify(filters)
  }

  const url =
    (options.baseUrl ? options.baseUrl : baseUrl) +
    (options.url + (params ? `?${qs.stringify(params)}` : '')).replace(/\/\//g, '/')
  const response = await fetch(url, {
    method: options.method,
    credentials: 'same-origin',
    body: options.body
      ? options.body instanceof FormData
        ? options.body
        : JSON.stringify(options.body)
      : undefined,
    signal: options.signal,
    headers: {
      Accept: 'application/json',
      ...(jwtToken && !options.skipAuth ? { Authorization: `Bearer ${jwtToken}` } : {}),
      Credentials: 'same-origin',
      ...(options.body instanceof FormData ? {} : { 'Content-Type': 'application/json' }),
      'x-wanda-trace-id': uuid(),
      'x-wanda-session-id': getOrCreateSessionId(),
      'x-spaceship-version': VERSION,
    },
  })
  if (!response.ok) {
    if (response.status === 401) {
      location.reload()
    }
    const error = new ApiError(url, options, response, await response.json().catch(() => ({})))
    if (error.shouldReportToSentry()) {
      captureException(error, {
        fingerprint: error.fingerprint,
        extra: {
          url,
          method: options.method,
          status: response.status,
        },
      })
    }
    throw error
  }

  // using this instead of response.json cause we have endpoints not returning anything
  const text = await response.text()
  return text.length ? JSON.parse(text) : null
}

export async function requestBlob(options: Omit<Options, 'body'>): Promise<Blob> {
  const jwtToken = localStorage.getItem('WANDA_JWT_TOKEN')
  const url =
    baseUrl +
    (options.url + (options.params ? `? ${qs.stringify(options.params)}` : '')).replace(
      /\/\//g,
      '/'
    )
  const response = await fetch(url, {
    method: options.method,
    credentials: 'same-origin',
    signal: options.signal,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      Credentials: 'same-origin',
      'x-wanda-trace-id': uuid(),
      'x-wanda-session-id': getOrCreateSessionId(),
    },
  })
  if (!response.ok) {
    if (response.status === 401) {
      location.reload()
    }
    const error = new ApiError(
      url,
      { ...options, body: undefined },
      response,
      await response.json().catch(() => ({}))
    )
    if (error.shouldReportToSentry()) {
      captureException(error, {
        fingerprint: error.fingerprint,
        extra: {
          url,
          method: options.method,
          status: response.status,
        },
      })
    }
    throw error
  }
  return response.blob()
}
