import { Button } from '@chakra-ui/react'
import { OrderType, ServiceLevelType } from '@wanda-space/types'
import { SelectCardSkeleton } from 'components/SelectCardSkeleton'
import { SelectCarryingMethod } from 'components/SelectCarryingMethod/SelectCarryingMethod'
import { useAppSelector } from 'hooks/useAppSelector'
import { Stepable } from 'interfaces'
import React from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch } from 'reduxStore'
import { serviceLevelTypeSelector, setServiceLevelType } from 'reduxStore/ducks/returnFlow/reducers'
import { getItemPayloadsFromItems } from 'utils/item'

import { useTaas } from '../../hooks/useTaas'

export const Carrying = ({ onNextStep }: Stepable) => {
  const dispatch = useAppDispatch()
  const items = useAppSelector((state) => state.returnFlow.items)
  const coupon = useAppSelector((state) => state.returnFlow.coupon)
  const floorNumber = useAppSelector((state) => state.returnFlow.address.floorNumber)
  const elevator = useAppSelector((state) => state.returnFlow.address.elevator)
  const serviceLevelType = useAppSelector(serviceLevelTypeSelector)
  const { formatMessage } = useIntl()

  const { isLoading, carryingTotalCost, firstDoorTotalCost, curbSideTotalCost } = useTaas({
    storageItems: getItemPayloadsFromItems(items),
    orderType: OrderType.DELIVERY,
    floorNumber,
    elevator,
    coupon: coupon,
  })

  if (!serviceLevelType && !isLoading) {
    dispatch(
      setServiceLevelType(
        typeof firstDoorTotalCost !== 'undefined'
          ? ServiceLevelType.FIRST_DOOR
          : ServiceLevelType.CURBSIDE
      )
    )
  }

  const handleClickOnNextStep = () => {
    onNextStep()
  }

  const handleCarryingChange = (serviceLevelType: ServiceLevelType) => {
    dispatch(setServiceLevelType(serviceLevelType))
  }

  if (!serviceLevelType) {
    return (
      <>
        <SelectCardSkeleton />
        <SelectCardSkeleton />
        <SelectCardSkeleton />
      </>
    )
  }

  return (
    <>
      <SelectCarryingMethod
        onChange={handleCarryingChange}
        carryingPrice={carryingTotalCost}
        curbSidePrice={curbSideTotalCost}
        serviceLevel={serviceLevelType!}
        curbsideCardProps={{
          title: formatMessage({ id: 'returnFlow.curbside.title' }),
          description: formatMessage({ id: 'returnFlow.curbside.description' }),
        }}
        carryingCardProps={{
          title: formatMessage({ id: 'returnFlow.carrying.title' }),
          description: formatMessage({ id: 'returnFlow.carrying.description' }),
        }}
        firstDoorPrice={firstDoorTotalCost}
        firstDoorCardProps={{
          title: formatMessage({ id: 'returnFlow.firstDoor.title' }),
          description: formatMessage({ id: 'returnFlow.firstDoor.description' }),
        }}
      />
      <Button
        width="100%"
        onClick={handleClickOnNextStep}
        isLoading={isLoading}
        size="lg"
        variant="solid"
        colorScheme="ctaBlack"
        data-testid="continue-button"
      >
        {formatMessage({ id: 'word.continue' })}
      </Button>
    </>
  )
}
