import { useQuery } from '@tanstack/react-query'
import { fetchSellableItemsForListing } from 'api-client'

import { useAppSelector } from './useAppSelector'

export function useSellableItems(page = 0, itemsPerPage = 1000) {
  const user = useAppSelector((state) => state.user.user)

  const sellableItemsQueryResult = useQuery(
    ['fetchSellableListingItems', user, page, itemsPerPage],
    () => fetchSellableItemsForListing({ page, itemsPerPage })
  )

  return sellableItemsQueryResult
}
