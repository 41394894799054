import { PickupAddressContactInfo } from 'components/contact-info/PickupAddressContactInfo'
import { useAppSelector } from 'hooks/useAppSelector'
import type { Stepable } from 'interfaces'
import React from 'react'
import {
  orderContactPersonSelector,
  setContactPerson,
  submitAddress,
} from 'reduxStore/ducks/storage/storageFlow'

export const Address = ({ onNextStep }: Stepable) => {
  const orderAddress = useAppSelector((state) => state.storageFlow.address)
  const orderContactPerson = useAppSelector(orderContactPersonSelector)

  const onSubmit = () => {
    onNextStep()
  }

  return (
    <PickupAddressContactInfo
      onNextStep={onSubmit}
      orderAddress={orderAddress}
      orderContactPerson={orderContactPerson}
      setAddress={submitAddress}
      setContactPerson={setContactPerson}
    />
  )
}
