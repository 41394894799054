import { type ProductCategoryResponseDto } from '@wanda-space/types'
import { type RootState } from 'reduxStore/index'

import { type OrderLineWithFullProductAndDiscount } from '../../../api-client'

export function selectSelectedCategory(state: RootState): ProductCategoryResponseDto | undefined {
  return state.serviceFromStorageFlow.selectedCategory
}

export function selectServiceOrderLines(state: RootState): OrderLineWithFullProductAndDiscount[] {
  return state.serviceFromStorageFlow.serviceOrderLines
}

export function selectOrderSuccessPayload(state: RootState) {
  return state.serviceFromStorageFlow.orderSuccessPayload
}

export function selectCoupon(state: RootState): string | undefined {
  return state.serviceFromStorageFlow.coupon
}

export function selectStorageItems(state: RootState) {
  return state.serviceFromStorageFlow.storageItems
}
