import { Color } from '@wanda-space/noelle'
import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'

export interface Props {
  placeholder: string
  disabled?: boolean
  name: string
  value?: string
  setFieldValue: any
  error?: string | undefined
  defaultCountry?: string
  defaultValue?: string
}

const PhoneNumber = ({
  value,
  placeholder,
  disabled,
  setFieldValue,
  error,
  defaultCountry,
  defaultValue,
}: Props) => {
  const boxShadow = error ? `0 0 0 1px ${Color.Red}` : 'none'
  const borderColor = error ? Color.Red : 'inherit'
  const opacity = disabled ? 0.4 : 1

  return (
    <>
      <PhoneInput
        containerStyle={{
          fontSize: '1.125rem',
          marginBottom: '12px',
          opacity,
        }}
        masks={{ se: '..........' }}
        country={defaultCountry?.toLocaleLowerCase()}
        countryCodeEditable={false}
        disabled={disabled}
        preferredCountries={['no', 'se']}
        dropdownStyle={{
          boxShadow: 'none',
          borderRadius: '8px',
          border: `1px solid ${Color.Black40}`,
          width: '330px',
        }}
        inputProps={{
          name: 'phoneNumber',
        }}
        inputStyle={{
          borderRadius: '0.375rem',
          width: '-webkit-fill-available',
          height: '40px',
          borderWidth: 1,
          borderColor,
          boxShadow,
        }}
        onFocus={(e) => {
          e.target.style.boxShadow = '0 0 0 1px var(--chakra-colors-blue-500)'
          e.target.style.borderColor = 'var(--chakra-colors-blue-500)'
        }}
        onBlur={(e) => {
          e.target.style.boxShadow = boxShadow
          e.target.style.borderColor = borderColor
        }}
        onChange={(e) => {
          setFieldValue(`+${e}`)
        }}
        placeholder={placeholder}
        value={defaultValue ? defaultValue : value}
      />
    </>
  )
}
export { PhoneNumber }
