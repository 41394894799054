const clickedOnScrollbar = (e: MouseEvent): boolean =>
  document.documentElement.clientWidth <= e.clientX ||
  document.documentElement.clientHeight <= e.clientY

export const onClickOutside = (ref: React.RefObject<HTMLElement>) => {
  const handleClick = (event: MouseEvent | TouchEvent) => {
    if (!(event as TouchEvent).touches && clickedOnScrollbar(event as MouseEvent)) {
      return
    }
    if (ref.current && !ref.current.contains(event.target as Node)) {
      return
    }
  }
  document.addEventListener('mousedown', handleClick)
  document.addEventListener('touchstart', handleClick)
  return () => {
    document.removeEventListener('mousedown', handleClick)
    document.removeEventListener('touchstart', handleClick)
  }
}
