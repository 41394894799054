import { Box, Skeleton } from '@chakra-ui/react'
import React from 'react'

export const SelectCardSkeleton = () => (
  <Box padding="6" border="2px solid" borderColor="gray.200" borderRadius="12" mb="6">
    <Skeleton startColor="gray.50" endColor="gray.200" height="5" mb="2" width="48" />
    <Skeleton startColor="gray.50" endColor="gray.200" height="4" mb="1" />
    <Skeleton startColor="gray.50" endColor="gray.200" height="4" mb="2" width="50%" />
    <Skeleton startColor="gray.50" endColor="gray.200" borderRadius="10" height="6" width="16" />
  </Box>
)
