import { type StripeSubscriptionDto } from '@wanda-space/types'
import { attachPaymentMethod, fetchSubscription, fetchSubscriptionTotal } from 'api-client'
import { LoadingStatus } from 'interfaces/loading'
import { PaymentMethod } from 'interfaces/paymentMethod'
import { Dispatch } from 'redux'
import { RootState } from 'reduxStore'

import { DEFAULT_COUNTRY } from 'i18n'
import { getSupportedCurrencyForCountryCode } from 'utils'
import { PriceWrapper } from '../../interfaces'
import { currencySelector } from './ui'

export enum Actions {
  SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD',
  GET_SUBSCRIPTION = 'GET_SUBSCRIPTION',
  SET_SUBSCRIPTION = 'SET_SUBSCRIPTION',
  SET_SUBSCRIPTION_ERROR = 'SET_SUBSCRIPTION_ERROR',
  SET_SUBSCRIPTION_TOTAL = 'SET_SUBSCRIPTION_TOTAL',
  GET_SUBSCRIPTION_TOTAL = 'GET_SUBSCRIPTION_TOTAL',
  SET_SUBSCRIPTION_TOTAL_ERROR = 'SET_SUBSCRIPTION_TOTAL_ERROR',
}

export type State = {
  total: {
    value: PriceWrapper
    status: LoadingStatus
    error?: string
  }
  status: LoadingStatus
  error?: string
  paymentMethod?: PaymentMethod
  subscription?: StripeSubscriptionDto
}

export type Action =
  | { type: Actions.SET_SUBSCRIPTION_TOTAL; payload: PriceWrapper }
  | { type: Actions.GET_SUBSCRIPTION_TOTAL }
  | { type: Actions.SET_SUBSCRIPTION_TOTAL_ERROR; payload?: string }
  | { type: Actions.GET_SUBSCRIPTION }
  | {
      type: Actions.SET_SUBSCRIPTION
      payload: StripeSubscriptionDto
    }
  | { type: Actions.SET_SUBSCRIPTION_ERROR; payload: string }
  | { type: Actions.SET_PAYMENT_METHOD; payload: PaymentMethod }

const initialState: State = {
  total: {
    value: { amount: 0, currency: getSupportedCurrencyForCountryCode(DEFAULT_COUNTRY) },
    status: 'idle',
  },
  status: 'idle',
}

export function subscription(state = initialState, action: Action): State {
  switch (action.type) {
    case Actions.GET_SUBSCRIPTION:
      return {
        ...state,
        status: 'loading',
      }
    case Actions.SET_SUBSCRIPTION:
      return {
        ...state,
        status: 'succeeded',
        paymentMethod: {
          id: action.payload.paymentMethodId,
          last4: action.payload.lastFourDigitsOfPaymentMethodCard,
        },
        subscription: action.payload,
      }
    case Actions.SET_SUBSCRIPTION_ERROR:
      return {
        ...state,
        error: action.payload?.toString(),
        status: 'failed',
        subscription: undefined,
      }
    case Actions.GET_SUBSCRIPTION_TOTAL:
      return {
        ...state,
        total: { ...state.total, status: 'loading' },
      }
    case Actions.SET_SUBSCRIPTION_TOTAL:
      return {
        ...state,
        total: { value: action.payload, status: 'succeeded' },
      }
    case Actions.SET_SUBSCRIPTION_TOTAL_ERROR:
      return {
        ...state,
        total: {
          value: {
            amount: initialState.total.value.amount,
            currency: initialState.total.value.currency,
          },
          error: action.payload?.toString(),
          status: 'failed',
        },
      }
    case Actions.SET_PAYMENT_METHOD:
      return { ...state, paymentMethod: action.payload }
    default: {
      return state
    }
  }
}

export const getSubscription = () => {
  return async (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const state = getState()
    try {
      if (getState().subscription.status === 'succeeded') {
        dispatch({ type: Actions.GET_SUBSCRIPTION_TOTAL })
        const total = await fetchSubscriptionTotal()
        dispatch({
          type: Actions.SET_SUBSCRIPTION_TOTAL,
          payload: { amount: total, currency: currencySelector(state) },
        })
      } else {
        dispatch({
          type: Actions.SET_SUBSCRIPTION_TOTAL,
          payload: { amount: 0, currency: currencySelector(state) },
        })
      }
    } catch (error: any) {
      dispatch({ type: Actions.SET_SUBSCRIPTION_TOTAL_ERROR, error: error.toString() })
    }
  }
}

export function selectSubscription() {
  return async (dispatch: Dispatch<Action>) => {
    try {
      dispatch({ type: Actions.GET_SUBSCRIPTION })

      const subscription = await fetchSubscription()

      dispatch({
        type: Actions.SET_SUBSCRIPTION,
        payload: subscription,
      })
    } catch (error: any) {
      dispatch({
        type: Actions.SET_SUBSCRIPTION_ERROR,
        payload: error.toString(),
      })
    }
  }
}

export function addPaymentMethod(paymentMethodId: string) {
  return async (dispatch: Dispatch<Action>) => {
    await attachPaymentMethod(paymentMethodId)

    dispatch({
      type: Actions.SET_PAYMENT_METHOD,
      payload: {
        id: paymentMethodId,
      },
    })
  }
}
