import { captureException } from '@sentry/react'

let env: Record<string, string>
try {
  // @ts-ignore _env_ is not a standard attribute on window
  env = JSON.parse(decodeURIComponent(window._env_ || '%7B%7D'))
} catch (error) {
  captureException(error)
  env = {}
}

export type EnvKey =
  | 'STRIPE_PUBLIC_KEY'
  | 'GOOGLE_TAG_MANAGER_ID'
  | 'NODE_ENV'
  | 'AUTH0_DOMAIN'
  | 'AUTH0_CLIENTID'
  | 'AUTH0_NAMESPACE'
  | 'AUTH0_AUDIENCE'
  | 'AUTH0_TENANT'
  | 'OBOS_COUPON'
  | 'OBOS_COUPON_SE'
  | 'USER_REGION'
  | 'SHOP_API_URL'
  | 'MIXPANEL_TOKEN'
  | 'APP_ENVIRONMENT'

/* These defaults can be overriden in the environment variables passed to the server */
const defaults: Record<EnvKey, string> = {
  AUTH0_AUDIENCE: 'https://app.dev.wanda.space/api',
  AUTH0_CLIENTID: 'B9ffnJRG0e7xXtzSWtJQge9J6nhDF6ZB',
  AUTH0_DOMAIN: 'id-dev.wanda.space',
  AUTH0_NAMESPACE: 'http://id.wanda.space',
  AUTH0_TENANT: 'wandaspace-dev',
  OBOS_COUPON: 'OBOS',
  OBOS_COUPON_SE: 'OBOS_2BOX_FREE_SE',
  GOOGLE_TAG_MANAGER_ID: 'not-configured',
  NODE_ENV: process.env.NODE_ENV || 'development',
  STRIPE_PUBLIC_KEY: 'pk_test_DQ0fSzbiADyUlXqCU5FXOPMm00bn1KVIk3',
  USER_REGION: 'no',
  SHOP_API_URL: 'https://local.wanda.space:3000/api/v1',
  MIXPANEL_TOKEN: 'not-configured',
  APP_ENVIRONMENT: process.env.APP_ENVIRONMENT || 'dev',
}

export function getEnv(key: EnvKey): string {
  return env[key] || defaults[key]
}
