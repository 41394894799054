import { Button, Container, Flex } from '@chakra-ui/react'
import { CloseButton } from '@chakra-ui/react'
import React, { useState, useCallback } from 'react'
import CropImage from 'react-easy-crop'
import { useIntl } from 'react-intl'

import { getCroppedImg } from './utils'

interface Props {
  onSaveImage: (src: string) => void
  onClose: () => void
  image: string
}

const Cropper = ({ onSaveImage, image, onClose }: Props) => {
  const [croppedAreaPixels, setCroppedAreaPixels] = useState()
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const onCropComplete = useCallback(async (_croppedArea, areaPixels) => {
    setCroppedAreaPixels(areaPixels)
  }, [])
  const { formatMessage } = useIntl()

  const onShowImage = async () => {
    const img = await getCroppedImg(image, croppedAreaPixels, 0)
    onSaveImage(img)
  }

  return (
    <>
      <Container>
        <Flex width="100%" justifyContent="flex-end">
          <CloseButton onClick={onClose} size="lg" mb={3} />
        </Flex>
        <Flex width="100%" position="relative" pb="100%" minHeight="350px">
          <CropImage
            aspect={1}
            crop={crop}
            image={image}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            zoom={zoom}
            style={{
              containerStyle: {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: 16,
              },
            }}
          />
        </Flex>
        <Flex width="100%" mt={3} justifyContent="center">
          <Button data-testid="cropper-add" mr={2} onClick={onShowImage} colorScheme="ctaBlack">
            {formatMessage({ id: 'cropper.addPhoto' })}
          </Button>
          <Button onClick={onClose}> {formatMessage({ id: 'word.cancel' })}</Button>
        </Flex>
      </Container>
    </>
  )
}

export { Cropper }
