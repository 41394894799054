import { getEnv } from 'env'
import mixpanel from 'mixpanel-browser'
import type { TrackerDTO } from 'tracking/types'

/**
 * Singleton to handle Mixpanel tracker (DRY and SoC / SRP)
 * @class MP_handler
 */
class MP_handler {
  private static _instance: MP_handler
  /** Not to be confused with the Do Not Track standard - will be set to true for logged-in users with wanda email address */
  private static _doNotTrack = false
  private static _isUserIdentified = false

  private constructor() {
    mixpanel.init(getEnv('MIXPANEL_TOKEN') || process.env.MIXPANEL_TOKEN || '', {
      debug: getEnv('APP_ENVIRONMENT') === 'development',

      // Server side tracking on our end
      api_host: '/behaviour-logging/mp',

      // Official advice: We recommend using localStorage instead of cookies for persistence
      // persistence: 'localStorage',
      // ...but that doesn't work for us, because we need to track users across subdomains
      persistence: 'cookie',
      cross_subdomain_cookie: true,

      // We ignore DNT (Do Not Track) because we don't track personal data
      ignore_dnt: true,
    })
    // Super properties
    mixpanel.register({
      _env: getEnv('APP_ENVIRONMENT'),
    })
  }

  /** Get sinleton instance */
  static getInstance() {
    if (MP_handler._instance) return MP_handler._instance
    MP_handler._instance = new MP_handler()
    return MP_handler._instance
  }

  /** To set doNotTrack to true, e.g. if user is a Wanda employee (at work) */
  set_doNotTrack = (doNotTrack = true) => {
    MP_handler._doNotTrack = doNotTrack
  }

  /** To determine whether or not to track */
  get_doNotTrack = () => {
    return MP_handler._doNotTrack
  }

  // Below: Wrappers for mixpanel functions ---------------------------------------------------------------------

  /**
   * Wraps Mixpanel's [identify](https://github.com/mixpanel/mixpanel-js/blob/master/doc/readme.io/javascript-full-api-reference.md#mixpanelidentify) function
   * Only calls mixpanel's native identify function once per session
   */
  identify = (unique_id?: string) => {
    if (!MP_handler._isUserIdentified) mixpanel.identify(unique_id)
    MP_handler._isUserIdentified = true
  }

  /** Generic wrapper for Mixpanel's [track](https://github.com/mixpanel/mixpanel-js/blob/master/doc/readme.io/javascript-full-api-reference.md#mixpaneltrack) function */
  track = (eventName: string, payload: TrackerDTO) => {
    if (MP_handler._doNotTrack) return

    const logThis = {
      ...(payload || {}),
      page: window.location.pathname,
      _user_logged_in: MP_handler._isUserIdentified,
    }

    // Keep for debugging
    //console.log('Mixpanel (SINGLETON) track "%s" with payload: ', eventName, logThis)

    mixpanel.track(eventName, logThis)
  }
}

export const mp_handler = MP_handler.getInstance()
