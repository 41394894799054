import { Spinner } from '@chakra-ui/react'
import { Routes, WANDA_LAST_ROUTE } from 'consts'
import { useAuth } from 'hooks/useAuth'
import React from 'react'
import { Navigate } from 'react-router'
import { RegisterForm } from 'routes/Register/RegisterForm'

export const Register = () => {
  const { isAuthenticating, isAuthenticated } = useAuth()

  const pathFromLocalStorage = localStorage.getItem(WANDA_LAST_ROUTE)
  const redirectPath = pathFromLocalStorage || Routes.Space

  if (isAuthenticating) {
    return <Spinner />
  }
  if (isAuthenticated) {
    return <Navigate to={redirectPath} />
  }
  return <RegisterForm hasHeroImage />
}
