import { DynamicIconName } from '@wanda-space/noelle'
import { Routes } from 'consts'
import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import { Text } from '@chakra-ui/react'
import { ServiceInfoBox } from './ServiceInfoBox'

interface Props {
  itemId: string
}

export const NotSharedInfoBox = ({ itemId }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <Link to={Routes.SharingInfo(itemId)}>
      <ServiceInfoBox
        leftIconName={DynamicIconName.shareItem}
        title={formatMessage({ id: 'sharing.info.shareItem.title' })}
        description={formatMessage({ id: 'sharing.info.shareItem.description' })}
      />
    </Link>
  )
}
