import { Box } from '@chakra-ui/react'
import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { getSellItemLink } from 'routes/BuySellFlow/Sell/Storage/SellingFlowForStorage'

import { ServiceInfoBox, ServiceInfoBoxProps } from './ServiceInfoBox'

interface Props extends Omit<ServiceInfoBoxProps, 'title' | 'description'> {
  itemId: string
}

export const SellInfoBox = ({ itemId, ...rest }: Props) => {
  const { formatMessage } = useIntl()
  const InfoBoxWrapper = rest.disabled ? Box : Link

  return (
    <InfoBoxWrapper to={getSellItemLink(itemId)}>
      <ServiceInfoBox
        title={formatMessage({ id: 'sell.with.link.title' })}
        description={formatMessage({ id: 'sell.with.link.description' })}
        {...rest}
      />
    </InfoBoxWrapper>
  )
}
