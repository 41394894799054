export function getListingImageUrl({ listingId, imageId }: { listingId: string; imageId: string }) {
  return `/api/v1/listing/${listingId}/image/${imageId}`
}

export function getListingItemImageUrl({
  listingId,
  itemId,
  imageId,
}: { listingId: string; itemId: string; imageId: string }) {
  return `/api/v1/listing/${listingId}/item/${itemId}/image/${imageId}`
}
