import { type ItemResponseDto, StorageItemType } from '@wanda-space/types'
import { PriceWrapper } from 'interfaces'
import { User } from 'interfaces/user'
import TagManager from 'react-gtm-module'
import { add } from 'utils/price-utils'

export const handleTagManagerCheckout = (
  items: Pick<ItemResponseDto, 'type' | 'id'>[],
  user: User | undefined,
  prices: Record<StorageItemType, PriceWrapper>
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'checkout',
      userId: user?.id,
      countryCode: user?.countryCode,
      ecommerce: {
        checkout: {
          actionField: { step: 5, option: 'Payment' },
          products: items.map((item) => {
            const product = {
              name: item.type === StorageItemType.LARGE ? 'Large Item' : 'Small Item',
              id: item.id,
              price: prices[item.type],
              type: item.type === StorageItemType.LARGE ? 'Large Item' : 'Small Item',
            }
            return product
          }),
        },
      },
    },
  })
}

export const handleTagManagerPurchase = (
  orderId: string,
  items: Pick<ItemResponseDto, 'type'>[],
  prices: Record<StorageItemType, PriceWrapper>,
  user: User | undefined,
  isStoring: boolean
) => {
  const orderType = isStoring ? 'pickup' : 'delivery'
  const products = Object.keys(StorageItemType).map((p) => {
    const priceLarge = prices?.LARGE ? prices?.LARGE.amount : 0
    const priceSmall = prices?.SMALL ? prices?.SMALL.amount : 0
    const price = p === StorageItemType.LARGE ? priceLarge : priceSmall
    const product = {
      name: `${p} items ${orderType}`,
      price,
      quantity: items.reduce((acc, cur) => {
        if (
          (cur.type === StorageItemType.LARGE && p === StorageItemType.LARGE) ||
          (cur.type !== StorageItemType.LARGE && p === StorageItemType.SMALL)
        )
          return acc + 1
        return acc
      }, 0),
    }
    return product
  })

  const itemPrices = items.map((item) => prices[item.type])
  const revenue = add(...itemPrices).amount

  TagManager.dataLayer({
    dataLayer: {
      event: 'purchase',
      userId: user?.id,
      countryCode: user?.countryCode,
      eventLabel: orderType,
      ecommerce: {
        purchase: {
          actionField: {
            orderType: orderType,
            id: orderId,
            revenue,
            quantity: items.length,
          },
          products,
        },
      },
    },
  })
}
