import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Switch,
  Text,
} from '@chakra-ui/react'
import { useQueries } from '@tanstack/react-query'
import {
  CleaningSprayIcon,
  DynamicIconName,
  IconBulletPoint,
  ItemWrapper,
  MovingTruckIcon,
  ShareLinkIcon,
  StudioPhotoIcon,
  TrashIcon,
  WandaSpinner,
} from '@wanda-space/noelle'
import {
  type ItemResponseDto,
  type PaginatedResponseDto,
  type ProductCategoryResponseDto,
  SupportedCountries,
} from '@wanda-space/types'
import { type ListingServiceFees, fetchListingConfig } from 'api-client'
import { getProductCategoriesByCountry } from 'api-client/lib/routes/product'
import { ImageGallery } from 'components/ImageGallery/ImageGallery'
import { ItemImage } from 'components/Item/Image/ItemImage'
import { CURRENCIES_BY_COUNTRY } from 'consts/Currency'
import { Field, FieldProps, Form, Formik } from 'formik'
import { useAppSelector } from 'hooks/useAppSelector'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { isEmpty, isNil } from 'ramda'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import type { ItemPayloadWithProduct } from 'reduxStore/commonMappers'
import {
  type ListingForPickup,
  type ListingForStorage,
  type ListingFormValues,
} from 'reduxStore/ducks/sell/sellFlow'
import { CreateListingSchema } from 'utils'
import { getItemCategory } from 'utils/item'
import { toSupportedCountry } from 'utils/postalCode'

import {
  ConditionSelector,
  DimensionFields,
  ExtraDescriptiveInfoFields,
  ListingDescription,
} from './CreateListingFields'

interface CreateListingProps {
  selectedItems: ItemResponseDto[] | ItemPayloadWithProduct[]
  listing: ListingForStorage | ListingForPickup
  country: SupportedCountries
  handleSubmit: (data: ListingFormValues) => void
  removeSelectedItem?: (id: string) => void
  handleAddMoreItems?: () => void
}

const MAX_NO_OF_IMAGES = 10

export const CreateListingBase = ({
  selectedItems,
  listing,
  country,
  removeSelectedItem,
  handleSubmit,
  handleAddMoreItems,
}: CreateListingProps) => {
  const { formatMessage } = useIntl()
  const user = useAppSelector((state) => state.user)
  const countryCode = user.user?.countryCode
  const countryFromUi = useAppSelector((state) => state.ui.country)
  const featureFlagsResult = useFeatureFlags()

  const queries = [
    {
      queryKey: ['fetchCategory', countryCode],
      queryFn: () => getProductCategoriesByCountry(countryCode!),
      enabled: Boolean(countryCode),
    },
    {
      queryKey: ['fetchListingConfig', selectedItems],
      queryFn: () => fetchListingConfig({ countryCode: country }),
      enabled: !!country,
    },
  ]

  const queryResults = useQueries({ queries })
  const isSuccess = queryResults.every((result) => result.isSuccess)

  const categories = queryResults[0].data as
    | PaginatedResponseDto<ProductCategoryResponseDto>
    | undefined
  const listingFeeBasedOnCountry = queryResults[1].data as ListingServiceFees | undefined

  const currency =
    CURRENCIES_BY_COUNTRY[toSupportedCountry(user.user?.countryCode) ?? countryFromUi]

  const initialValues: ListingFormValues = useMemo(
    () => ({
      ...listing,
      price: {
        currency,
        amount: listing.price.amount > 0 ? listing.price.amount : undefined,
      },
    }),
    [listing]
  )

  const handleRemoveImage = (images: string[], img: string, index: number) => {
    const updatedImages = [...images]
    updatedImages.splice(index, 1)
    return updatedImages
  }

  const handleChangeMainImg = (images: string[], imageBase64: string, index: number) => {
    if (index > 0) {
      const newMainImg = images[index]
      const newArr = [...images]
      newArr.splice(index, 1)
      newArr.unshift(newMainImg)
      return newArr
    }
    return images
  }

  if (featureFlagsResult.isInitialLoading) {
    return <WandaSpinner />
  }

  return (
    <Container pt={0}>
      <Box textAlign="left">
        <Heading fontSize="2xl" mb={2}>
          {formatMessage({ id: 'selling.sellPage.title' })}
        </Heading>
        <Text size="md" mb={6}>
          {formatMessage({ id: 'selling.sellPage.description' })}
        </Text>
      </Box>

      <Box>
        <Text fontWeight="bold" mb={4}>
          {formatMessage({ id: 'selling.sellPage.bundle.bulletpoint' })}
        </Text>
        <Grid mb={8} gap={4} templateColumns="repeat(2, 2fr)">
          <IconBulletPoint
            text={formatMessage({
              id: 'selling.sellPage.bundle.pickup.included',
            })}
            leftIcon={<MovingTruckIcon />}
          />
          <IconBulletPoint
            text={formatMessage({ id: 'selling.sellPage.bundle.wipeover' })}
            leftIcon={<CleaningSprayIcon />}
          />

          <IconBulletPoint
            text={formatMessage({ id: 'selling.sellPage.bundle.studio.photo' })}
            leftIcon={<StudioPhotoIcon />}
          />
          <IconBulletPoint
            text={formatMessage({
              id: 'selling.sellPage.bundle.advertisement',
            })}
            leftIcon={<ShareLinkIcon />}
          />
        </Grid>
      </Box>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={CreateListingSchema(formatMessage)}
      >
        {({ isValidating, isSubmitting, isValid, values }) => (
          <Form>
            <Field name="images">
              {({ field, form }: FieldProps<string, ListingFormValues>) => (
                <FormControl
                  isInvalid={!isEmpty(form.errors.images) && !isNil(form.errors.images)}
                  mb={2}
                >
                  <ImageGallery
                    imageUrls={form.values.images}
                    maxNrOfImages={MAX_NO_OF_IMAGES}
                    onSetImages={(images) => {
                      const existingImages = form.values.images ?? []
                      const filteredImages = images.filter(
                        (image) => !existingImages.includes(image)
                      )
                      form.setFieldValue(
                        field.name,
                        [...existingImages, ...filteredImages].slice(0, MAX_NO_OF_IMAGES)
                      )
                      form.setFieldTouched(field.name, true, false)

                      if (images.length > filteredImages.length) {
                        form.setFieldError(
                          'images',
                          formatMessage({ id: 'validation.duplicationImage' })
                        )
                      }
                    }}
                    onRemoveImage={(img, index) =>
                      form.setFieldValue(
                        field.name,
                        handleRemoveImage(form.values.images ?? [], img, index)
                      )
                    }
                    onChangeMainImg={(img, index) =>
                      form.setFieldValue(
                        field.name,
                        handleChangeMainImg(form.values.images ?? [], img, index)
                      )
                    }
                    isLoading={false}
                  />
                  <FormErrorMessage>{form.errors.images}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Text size="sm" color="gray.500" mb={6}>
              {formatMessage({ id: 'selling.imageUpload.tooltip' })}
            </Text>
            <Field name="name">
              {({ field, form }: FieldProps<string, ListingFormValues>) => (
                <FormControl
                  isInvalid={
                    isEmpty(form.errors.name) === false &&
                    isNil(form.errors.name) === false &&
                    form.touched.name
                  }
                  mb={8}
                >
                  <FormLabel mb={2}>{formatMessage({ id: 'word.title' })}</FormLabel>
                  <Input
                    {...field}
                    placeholder={formatMessage({ id: 'word.title' })}
                    type="text"
                    size="lg"
                    mb={2}
                  />
                  <FormHelperText mt={0}>
                    {formatMessage({ id: 'selling.form.title.helper' })}
                  </FormHelperText>
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <FormLabel mb={2}>{formatMessage({ id: 'word.condition' })}</FormLabel>
            <ConditionSelector />
            <DimensionFields />
            <Box mb={8}>
              <ListingDescription
                helperText={
                  <FormHelperText mt={0}>
                    {formatMessage({ id: 'selling.form.description.helper' })}
                  </FormHelperText>
                }
                images={values.images}
              />
            </Box>
            <ExtraDescriptiveInfoFields
              formLabel={<FormLabel mb={6}>{formatMessage({ id: 'optional.details' })}</FormLabel>}
            />
            <Field name="showOwnerName">
              {({ field, form }: FieldProps<string, ListingFormValues>) => (
                <FormControl mb={8}>
                  <Flex justifyContent="space-between">
                    <Flex direction="column">
                      <FormLabel mb={2}>
                        {formatMessage({ id: 'selling.form.owner.name.title' })}
                      </FormLabel>

                      <FormHelperText mt={0}>
                        {formatMessage({
                          id: 'selling.form.owner.name.helper',
                        })}
                      </FormHelperText>
                    </Flex>
                    <Switch
                      {...field}
                      onChange={(e) => {
                        form.setFieldValue(field.name, e.target.checked)
                      }}
                      id="showOwnerName"
                      name="showOwnerName"
                      isChecked={form.values.showOwnerName}
                    />
                  </Flex>
                </FormControl>
              )}
            </Field>
            <Flex justifyContent="space-between" alignItems="center">
              <Text fontWeight="bold">{formatMessage({ id: 'word.items' })}</Text>
              {handleAddMoreItems && (
                <Button variant="ghost" onClick={() => handleAddMoreItems()} size="sm">
                  {formatMessage({ id: 'word.addMore' })}
                </Button>
              )}
            </Flex>
            <Box mb={6}>
              {selectedItems.map((item, i) => {
                let image = ''
                if ('image' in item) {
                  image = item.image || ''
                }

                return (
                  <ItemWrapper
                    mx="-3"
                    key={i}
                    img={
                      <ItemImage
                        imageId={image}
                        itemId={item.id}
                        iconName={getItemCategory(item, categories)?.iconName as DynamicIconName}
                      />
                    }
                    moreActionButton={
                      selectedItems.length > 1 ? (
                        <IconButton
                          onClick={() => removeSelectedItem?.(item.id)}
                          icon={<TrashIcon />}
                          variant="ghost"
                          aria-label="Trash can icon"
                        />
                      ) : undefined
                    }
                    contentProps={{
                      name: item.name,
                    }}
                    dataTestId={`item-${i + 1}`}
                  />
                )
              })}
            </Box>
            <Field isValid name="price.amount">
              {({ field, form }: FieldProps<number, ListingFormValues>) => (
                <FormControl
                  isInvalid={!!(form.errors.price?.amount && form.touched.price?.amount)}
                  mb={8}
                >
                  <FormLabel mb={2}>{formatMessage({ id: 'word.price' })}</FormLabel>
                  <InputGroup size="lg" mb={2}>
                    <InputLeftAddon>{currency}</InputLeftAddon>
                    <Input
                      {...field}
                      min={3}
                      value={field.value === undefined ? '' : field.value}
                      placeholder={formatMessage({
                        id: 'phrase.for.example.short',
                      })}
                      type="number"
                    />
                  </InputGroup>
                  {isSuccess ? (
                    <FormHelperText mt={0}>
                      {formatMessage(
                        { id: 'selling.form.price.helper' },
                        { fee: listingFeeBasedOnCountry?.sellSideCut }
                      )}
                    </FormHelperText>
                  ) : null}
                  <FormErrorMessage>{form.errors.price?.amount}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Button
              isLoading={isValidating && isSubmitting}
              type="submit"
              colorScheme="ctaBlack"
              size="lg"
              w="100%"
              data-testid="go-to-listing-preview"
              isDisabled={!isValid}
              disabled={!isValid}
            >
              {formatMessage({ id: 'phrase.advertisement.preview' })}
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}
