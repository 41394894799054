import { OrderType } from '@wanda-space/types'
import { useAppSelector } from 'hooks/useAppSelector'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { useProductsAndCategories } from 'hooks/useProductsAndCategories'
import React from 'react'
import { useAppDispatch } from 'reduxStore'
import { setDateAndTime, setTimeslotOrderlinesReturn } from 'reduxStore/ducks/returnFlow/reducers'

import { DateAndTime } from '../../api-client'
import { SelectDateAndTimeBase } from '../Common/SelectDateAndTimeBase'

const SelectDateAndTime = ({ onNextStep }: { onNextStep: () => void }) => {
  const dispatch = useAppDispatch()
  const { timeslot, date } = useAppSelector((state) => state.returnFlow.dateAndTime)
  const coupon = useAppSelector((state) => state.returnFlow.coupon)
  const items = useAppSelector((state) => state.returnFlow.items)
  const { timeslotsProducts, isLoading } = useProductsAndCategories({ couponCode: coupon })
  const { data: featureFlags } = useFeatureFlags()

  const handleClickOnNextStep = (dateAndTime: DateAndTime) => {
    const { date, timeslot } = dateAndTime

    if (
      featureFlags?.ENABLE_PRICED_TIMESLOTS &&
      !isLoading &&
      timeslot?.productId &&
      timeslotsProducts
    ) {
      dispatch(
        setTimeslotOrderlinesReturn([
          { product: timeslotsProducts[timeslot.productId], quantity: 1 },
        ])
      )
    } else {
      dispatch(setTimeslotOrderlinesReturn([]))
    }

    if (date && timeslot) {
      dispatch(setDateAndTime({ timeslot, date }))
      onNextStep()
    }
  }

  return (
    <SelectDateAndTimeBase
      preSelectedDate={date}
      preSelectedTimeslot={timeslot}
      items={items}
      orderType={OrderType.DELIVERY}
      onNext={handleClickOnNextStep}
    />
  )
}

export { SelectDateAndTime }
