import React from 'react'

import { Link, Spinner } from '@chakra-ui/react'
import { DynamicIconName } from '@wanda-space/noelle'
import { SortOrderOptionsType } from '@wanda-space/types'
import { Routes } from 'consts'
import { useAppSelector } from 'hooks/useAppSelector'
import { useItems } from 'hooks/useItems'
import { useProductsAndCategories } from 'hooks/useProductsAndCategories'
import { useIntl } from 'react-intl'
import { ServiceInfoBox } from 'routes/Space/Details/Stored/ServiceInfoBox'

export const ServiceButton = ({ itemIds }: { itemIds: string[] }) => {
  const { formatMessage } = useIntl()
  const productsAndCategories = useProductsAndCategories({
    sortOption: SortOrderOptionsType.SERVICE_FLOW,
  })

  const user = useAppSelector((state) => state.user.user)

  const items = useItems(user, { enabled: !!user })

  if (items.isLoading || productsAndCategories.isLoading) {
    return <Spinner />
  }

  const currentItems = items.data?.filter((item) => itemIds.includes(item.id)) ?? []

  const currentProductCategoryIds = [
    ...new Set(currentItems.map((item) => item.storageProduct.categoryId)),
  ]

  const productIds = [...new Set(currentItems.map((item) => item.storageProduct.id))]

  const applicableServices =
    productsAndCategories.serviceProducts?.filter(
      (serviceProduct) =>
        currentProductCategoryIds.includes(serviceProduct.categoryId) &&
        (!serviceProduct.applicableToProducts ||
          serviceProduct.applicableToProducts.some((id) => productIds.includes(id)))
    ) ?? []
  const hasApplicableServices = applicableServices.length > 0

  return hasApplicableServices ? (
    <Link
      href={`${Routes.ServiceFromStorage}?itemIds=${itemIds.join(',')}`}
      _hover={{ textDecoration: 'none' }}
    >
      <ServiceInfoBox
        title={formatMessage({ id: 'item.service-from-storage.button.title' })}
        description={formatMessage(
          {
            id: 'item.service-from-storage.button.description',
            defaultMessage: 'Available services: {services}',
          },
          {
            services: applicableServices
              .map((product) => formatMessage({ id: `${product.localizationKey}.name` }))
              .join(', '),
          }
        )}
        leftIconName={DynamicIconName.tools}
      />
    </Link>
  ) : null
}
