import {
  Box,
  Container,
  Flex,
  IconButton,
  Link as ChakraLink,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import {
  ChevronRightIcon,
  DealIcon,
  DynamicIconName,
  EmptyStateBox,
  ExclamationMarkInHexagonIcon,
  HandShakeIcon,
  InfoBox,
  ItemWrapper,
  MovingTruckIcon,
  OrderPopup,
  ReturnBoxIcon,
  Spinner,
  ThreeBoxesIcon,
  ToolsIcon,
} from '@wanda-space/noelle'
import introductionSvg from '@wanda-space/noelle/illustrations/introduction.svg'
import service from '@wanda-space/noelle/illustrations/service-stars.svg'
import { ListingResponseDto, OrderState, OrderType, StorageItemState } from '@wanda-space/types'
import { ListingStatus } from '@wanda-space/types'
import { FeatureFlagKey } from 'api-client/lib/routes/feature-flags'
import { getListings } from 'api-client/lib/routes/listing'
import { fetchServiceOrders } from 'api-client/lib/routes/serviceOrders'
import { ItemImage } from 'components/Item/Image/ItemImage'
import { Routes } from 'consts'
import { useDateLocale } from 'contexts/Intl'
import { differenceInCalendarDays, format } from 'date-fns'
import { useDocumentTitle } from 'hooks'
import { useAppSelector } from 'hooks/useAppSelector'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { useOrders } from 'hooks/useOrders'
import { useStorageFlowRouteSelector } from 'hooks/useStorageFlowRouteSelector'
import { eqProps, uniqWith } from 'ramda'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'
import { getPublishedListingRoute } from 'routes/BuySellFlow/Sell/Storage/SellingFlowForStorage'
import { getServiceOrderString } from 'routes/Settings/Payments/Orders/utils'
import { hasOrderlineSquareMeterOrderline } from 'routes/SquareMeterFlow/helper'
import { getListingImageUrl, pathTo } from 'utils'
import { sanitizeStripeAmount } from 'utils/price-utils'

import { useItems } from 'hooks/useItems'
import { StorageItemList } from './Item/StorageItemList'
import { ReferralNotification } from './ReferralNotification'

const Space = () => {
  const { formatMessage } = useIntl()
  useDocumentTitle(formatMessage({ id: 'word.space' }))
  const user = useAppSelector((state) => state.user.user)
  const locale = useDateLocale()
  const [showAllServices, setShowAllServices] = useState(false)
  const navigate = useNavigate()
  const { data: featureFlags } = useFeatureFlags({ suspense: true })

  function handleClickOrderDetails(id: string) {
    navigate(Routes.EditOrderDetails(id))
  }

  const itemsPerPage = 5
  const padding = 2
  const margin = 4
  const bigMargin = 10

  const { isLoading: itemsLoading, data: items } = useItems(user)

  const { data: orders } = useOrders()
  const { data: services } = useQuery(['fetchServices', user], () =>
    fetchServiceOrders({ page: 0, itemsPerPage: 1000 })
  )
  const { data: listing } = useQuery(['fetchListing', user], () =>
    getListings({ page: 0, itemsPerPage: 100 })
  )

  const linkToStorage = useStorageFlowRouteSelector()

  const titleIconList: {
    title: string
    icon: JSX.Element
    link: string
    featureFlag?: FeatureFlagKey
  }[] = [
    {
      title: formatMessage({ id: 'space.orderPopup.store' }),
      icon: <MovingTruckIcon />,
      link: linkToStorage.flowRoute,
      featureFlag: 'ENABLE_SPACESHIP_FLOW_STORAGE',
    },
    {
      title: formatMessage({ id: 'space.orderPopup.delivery' }),
      icon: <ReturnBoxIcon />,
      link: Routes.ReturnFlowSelectItems,
      featureFlag: 'ENABLE_SPACESHIP_FLOW_RETURN',
    },
    {
      title: formatMessage({ id: 'space.orderPopup.service' }),
      icon: <ToolsIcon />,
      link: Routes.ServicesNext,
      featureFlag: 'ENABLE_SPACESHIP_FLOW_SERVICES',
    },
    {
      title: formatMessage({ id: 'space.orderPopup.sell' }),
      icon: <DealIcon />,
      link: Routes.Sell,
      featureFlag: 'ENABLE_SPACESHIP_FLOW_SELL',
    },
    {
      title: formatMessage({ id: 'space.orderPopup.packaging' }),
      icon: <ThreeBoxesIcon />,
      link: Routes.Shop,
      featureFlag: 'ENABLE_SPACESHIP_FLOW_PACKAGING_IN_MENUS',
    },
  ]

  /* has previously stored items */
  const hasWithCustomersItems = useMemo(() => {
    return (items || []).some(({ state }) => state === StorageItemState.WITH_CUSTOMER)
  }, [items])

  /* stored items */
  const storedItems = useMemo(() => {
    return (items || [])
      .filter(({ state }) => state === StorageItemState.STORED)
      .slice(0, itemsPerPage)
  }, [items])

  /* booked items */
  const bookedItems = useMemo(() => {
    // @ts-ignore fix ItemResponseDto for pendingOrderId
    return (items || []).filter(({ pendingOrderId }) => pendingOrderId)
  }, [items])

  /* items without images */
  const hasItemsWithoutImages = (items || []).some(({ image }) => !image)

  /* Upcoming orders */
  const upcomingOrders = useMemo(() => {
    return (orders || []).filter((order) => order.state === OrderState.PAYMENT_AUTHORISED)
  }, [orders])

  /* service orders */
  const serviceOrders = useMemo(() => {
    if (!showAllServices && (services?.orders?.length ?? 0) > 2) {
      return services!.orders.slice(0, itemsPerPage)
    }
    return services?.orders ?? []
  }, [showAllServices, services])

  /* storage price */
  const amountOfStoredItems =
    items?.filter(({ state }) => state === StorageItemState.STORED).length || 0

  const totalStoragePrice = sanitizeStripeAmount(
    (items?.filter(({ state }) => state === StorageItemState.STORED) || [])
      .map(({ storageProduct }) => storageProduct?.price ?? 0)
      .reduce((a, b) => a + b, 0)
  )

  const uniqueBookedAndStoredItems = uniqWith(eqProps('id'), [...bookedItems, ...storedItems])

  /* Sold and Active listings */
  const { activeListings } = useMemo(() => {
    const listings: {
      activeListings: ListingResponseDto[]
    } = {
      activeListings: [],
    }
    if (listing?.items) {
      return (listing?.items ?? []).reduce((acc, listing) => {
        if (
          listing.status === ListingStatus.SOLD &&
          listing.buyTime &&
          differenceInCalendarDays(new Date(listing.buyTime), new Date()) <= 3
        ) {
        } else if (listing.status === ListingStatus.ACTIVE) {
          acc.activeListings.push(listing)
        }
        return acc
      }, listings)
    }

    return listings
  }, [listing?.items])

  if (itemsLoading) {
    return (
      <Flex width="100%" justifyContent="center">
        <Spinner />
      </Flex>
    )
  }

  return (
    <Container>
      <>
        <Box>
          <Text fontSize="xx-large" mb={margin}>
            {formatMessage({ id: 'space.greeting' }, { name: user?.address?.firstName })}
          </Text>
        </Box>

        <Flex mb={margin}>
          <OrderPopup
            title={formatMessage({ id: 'space.newOrder.popup.title' })}
            titleIconList={titleIconList.filter(
              ({ featureFlag }) => !featureFlag || featureFlags?.[featureFlag]
            )}
            buttonText={formatMessage({ id: 'space.newOrder.buttonText' })}
          >
            {''}
          </OrderPopup>
        </Flex>

        {/* Popup-section */}
        <Flex
          direction="column"
          mb={upcomingOrders.length || hasItemsWithoutImages ? bigMargin : ''}
          gap={4}
        >
          {/* Active order popup */}
          {upcomingOrders.map((order, i) => {
            return (
              <InfoBox
                key={i}
                leftIcon={<MovingTruckIcon />}
                onClick={() => handleClickOrderDetails(order.id)}
                rightButton={
                  <IconButton
                    as="span"
                    aria-label="icon-button"
                    backgroundColor="black"
                    onClick={() => handleClickOrderDetails(order.id)}
                    icon={<ChevronRightIcon width={6} height={6} color="white" />}
                    size="xs"
                  />
                }
                title={formatMessage(
                  { id: 'space.infoBox.upcomingOrder.title' },
                  {
                    date: format(new Date(order.orderDetails.deliveryDate), 'EEEE dd.LLL ', {
                      locale: locale,
                    }),
                    from: order.orderDetails.deliveryTimeSlot.from.substring(0, 2),
                    to: order.orderDetails.deliveryTimeSlot.to.substring(0, 2),
                  }
                )}
                description={
                  <>
                    {order.type === OrderType.DELIVERY ? (
                      <Text mr="1">
                        {formatMessage(
                          { id: 'space.infoBox.upcomingOrder.description.delivery' },
                          { amountOfItems: order.storageItems.length }
                        )}
                      </Text>
                    ) : (
                      <Text mr="1">
                        {formatMessage(
                          {
                            id: hasOrderlineSquareMeterOrderline(order.orderLines)
                              ? 'space.infoBox.upcomingOrder.description.m2.pickup'
                              : 'space.infoBox.upcomingOrder.description.pickup',
                          },
                          { amountOfItems: order.storageItems.length }
                        )}
                      </Text>
                    )}
                    <Link
                      style={{ textDecoration: 'underline' }}
                      to={Routes.EditOrderDetails(order.id)}
                    >
                      {formatMessage({ id: 'word.details' })}
                    </Link>
                  </>
                }
              />
            )
          })}
          <ReferralNotification />
          {/* Active listings */}
          {activeListings.map((listing) => (
            <InfoBox
              key={listing.simpleId}
              onClick={() =>
                navigate(pathTo(Routes.Sell, getPublishedListingRoute(listing.simpleId)))
              }
              leftIcon={<HandShakeIcon />}
              rightButton={
                <IconButton
                  as="span"
                  aria-label="icon-button"
                  colorScheme="ctaBlack"
                  icon={<ChevronRightIcon width={4} height={4} color="white" />}
                  size="xs"
                />
              }
              title={formatMessage({ id: 'space.infoBox.activeListing.title' })}
              description={
                <Text mr="1" textOverflow="ellipsis">
                  {formatMessage(
                    { id: 'space.infoBox.soldListing.description' },
                    {
                      title: listing.name,
                    }
                  )}
                </Text>
              }
            />
          ))}

          {/* No images popup */}
          {hasItemsWithoutImages && (
            <InfoBox
              leftIcon={<ExclamationMarkInHexagonIcon p="0.8" />}
              title={formatMessage({ id: 'space.infoBox.missingImages.title' })}
              onClick={() =>
                navigate(Routes.NoImageItemView, {
                  state: { referrer: location.pathname },
                })
              }
              description={
                <Flex direction="column">
                  <Text>{formatMessage({ id: 'space.infoBox.missingImages.description' })}</Text>
                  <ChakraLink
                    style={{ textDecoration: 'underline' }}
                    onClick={() =>
                      navigate(Routes.NoImageItemView, {
                        state: { referrer: location.pathname },
                      })
                    }
                  >
                    {formatMessage({ id: 'space.infoBox.missingImages.link' })}
                  </ChakraLink>
                </Flex>
              }
            />
          )}
        </Flex>
        {/* Stored and booked active items */}
        <Text mt={margin} mb={padding} fontSize="x-large">
          {formatMessage({ id: 'space.yourThings' }, { amountOfItems: amountOfStoredItems })}
        </Text>
        <Text mb={padding}>
          {formatMessage(
            { id: 'space.yourItemsSummary' },
            {
              amountOfStoredItems,
              priceOfStoredItems: totalStoragePrice,
            }
          )}
        </Text>
        <Box mb={bigMargin}>
          <StorageItemList
            items={uniqueBookedAndStoredItems}
            seeAllLink={{
              pathToSeeAllPage: Routes.MyItemsView,
              showWithEmptyStateToo: hasWithCustomersItems,
            }}
            emptyStateBoxProps={{
              linkTo: Routes.SelectFlow,
            }}
          />
        </Box>

        {/* Services */}
        <Text mt={margin} mb={margin} fontSize="x-large">
          {formatMessage({ id: 'space.yourServices' }, { amount: serviceOrders.length })}
        </Text>
        {services?.count === 0 ? (
          <EmptyStateBox
            icon={<img src={service} alt="" />}
            text={
              <>
                <Text mr="1">{formatMessage({ id: 'serviceOverview.emptyState.title' })}</Text>
                <Link to={Routes.ServicesNext} style={{ textDecoration: 'underline' }}>
                  {formatMessage({ id: 'serviceOverview.emptyState.link' })}
                </Link>
              </>
            }
          />
        ) : (
          <List mb={bigMargin}>
            {serviceOrders.map((service, i) => {
              return (
                <ListItem mx="-3" key={i}>
                  <ItemWrapper
                    img={<ItemImage iconName={DynamicIconName.tools} />}
                    contentProps={{
                      name: service.orderLines.map((line) => line.product.name).join(', '),
                      location: formatMessage(
                        { id: 'space.service.location' },
                        { state: getServiceOrderString(service.state, formatMessage) }
                      ),
                    }}
                    moreActionButton={<ChevronRightIcon w="6" h="6" />}
                    onClick={() =>
                      navigate(Routes.EditServiceOrder(service.id), {
                        state: { referrer: location.pathname },
                      })
                    }
                  />
                </ListItem>
              )
            })}
            {serviceOrders.length > itemsPerPage && (
              <Text
                style={{ textDecoration: 'underline' }}
                onClick={() => setShowAllServices(!showAllServices)}
              >
                {showAllServices
                  ? formatMessage({ id: 'word.showLess' })
                  : formatMessage({ id: 'word.seeAll' })}
              </Text>
            )}
          </List>
        )}

        {/* Active listings */}

        <Text mt={margin} mb={margin} fontSize="x-large">
          {formatMessage({ id: 'space.yourActiveListings' }, { amount: activeListings.length })}
        </Text>
        {activeListings?.length === 0 ? (
          <EmptyStateBox
            icon={<img src={introductionSvg} style={{ opacity: '40%' }} alt="" />}
            text={
              <>
                <Text mr="1">{formatMessage({ id: 'listingOverview.emptyState.title' })}</Text>
                <Link to={Routes.Sell} style={{ textDecoration: 'underline' }}>
                  {formatMessage({ id: 'listingOverview.emptyState.link' })}
                </Link>
              </>
            }
          />
        ) : (
          <List mb={bigMargin}>
            {activeListings.map((listing, i) => {
              const publishedRoute = pathTo(Routes.Sell, getPublishedListingRoute(listing.simpleId))

              return (
                <ListItem mx="-3" key={i}>
                  <ItemWrapper
                    img={
                      listing.image ? (
                        <Flex
                          alignItems="center"
                          width="100%"
                          height="100%"
                          backgroundColor="white"
                          border="1px solid #e7e5ff"
                          borderRadius="lg"
                        >
                          <img
                            style={{
                              borderRadius: '0.5rem',
                              objectFit: 'cover',
                              height: '100%',
                              width: '100%',
                            }}
                            src={getListingImageUrl({
                              listingId: listing.id,
                              imageId: listing.image,
                            })}
                            alt=""
                          />
                        </Flex>
                      ) : (
                        <ItemImage iconName={DynamicIconName.deal} />
                      )
                    }
                    contentProps={{
                      name: listing.name,
                      createdAt: formatMessage(
                        { id: 'space.createdFrom' },
                        { time: format(new Date(listing.createdAt), 'MMM dd yyyy') }
                      ),
                      description: listing.storageItems.some((item) => !item.name)
                        ? undefined
                        : listing.storageItems
                            .map((item) => {
                              return item.name
                            })
                            .join(', '),
                    }}
                    moreActionButton={<ChevronRightIcon w="6" h="6" />}
                    onClick={() =>
                      navigate(publishedRoute, {
                        state: { referrer: location.pathname },
                      })
                    }
                  />
                </ListItem>
              )
            })}
            {serviceOrders.length > itemsPerPage && (
              <Text
                style={{ textDecoration: 'underline' }}
                onClick={() => setShowAllServices(!showAllServices)}
              >
                {showAllServices
                  ? formatMessage({ id: 'word.showLess' })
                  : formatMessage({ id: 'word.seeAll' })}
              </Text>
            )}
          </List>
        )}
      </>
    </Container>
  )
}
export { Space }
