import {
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from '@chakra-ui/react'
import { DynamicIcon, DynamicIconName, EyeCrossIcon, EyeIcon } from '@wanda-space/noelle'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import { Connection, UserSignupDetails, useAuth } from 'hooks/useAuth'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { authSchema } from 'utils'

import { Routes } from '../../consts'
import { GoogleButton } from './GoogleButton'

export interface AuthData {
  email: string
  password: string
}

interface Proptypes {
  submitBtnLokalizeId: string
  isLoading: boolean
  isLoadingSocial: boolean
  socialAuthError?: string
  authError?: string
  onAuthFormSubmit: (authData: AuthData, formikHelpers: FormikHelpers<AuthData>) => void
  onSocialClick: () => void
  forgotPasswordLink?: boolean
  userSignpDetails?: UserSignupDetails
  context: string
}

const AuthForm = ({
  isLoading,
  isLoadingSocial,
  socialAuthError,
  onSocialClick,
  onAuthFormSubmit,
  authError,
  submitBtnLokalizeId,
  forgotPasswordLink,
  context,
  userSignpDetails,
}: Proptypes) => {
  const { formatMessage } = useIntl()
  const initialValues = { email: '', password: '' }
  const [showPassword, setShowPassword] = useState(false)
  const { universalLogin } = useAuth()

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onAuthFormSubmit}
        validateOnMount
        validationSchema={authSchema(formatMessage)}
      >
        {({ isValid, errors, touched }) => (
          <Form>
            <FormControl isInvalid={Boolean(errors.email) && touched.email} mb={4}>
              <FormLabel fontWeight="normal" mb={2}>
                {formatMessage({ id: 'word.email' })}
              </FormLabel>
              <Field
                name="email"
                as={Input}
                placeholder={formatMessage({ id: 'word.email' })}
                type="email"
                size="lg"
                py="7"
                borderRadius="10"
                data-testid="input-email"
              />
              <FormErrorMessage ml="2">{errors.email}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.password) && touched.password}>
              <Flex
                direction="row"
                justifyContent="space-between"
                width="100%"
                alignItems="baseline"
              >
                <FormLabel fontWeight="normal" mb={2}>
                  {formatMessage({ id: 'word.password' })}
                </FormLabel>
                {forgotPasswordLink && (
                  <Button
                    as={Link}
                    textDecoration="underline"
                    size="small"
                    variant="link"
                    color="purple.700"
                    to={Routes.ResetPassword}
                    data-testid="reset-password"
                    fontSize="medium"
                  >
                    {`${formatMessage({ id: 'word.forgotPassword' })}?`}
                  </Button>
                )}
              </Flex>
              <InputGroup size="md">
                <Field
                  as={Input}
                  borderRadius="10"
                  py="7"
                  pr="4.5rem"
                  size="lg"
                  type={showPassword ? 'text' : 'password'}
                  placeholder={formatMessage({ id: 'word.password' })}
                  name="password"
                  data-testid="input-password"
                />
                <InputRightElement width="3rem">
                  <Button
                    h="1.75rem"
                    top="2"
                    borderRadius="5"
                    variant="unstyled"
                    size="sm"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <EyeCrossIcon /> : <EyeIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage ml="2" data-testid="input-password-error">
                {errors.password}
              </FormErrorMessage>
            </FormControl>
            <FormControl mb="4" isInvalid={Boolean(authError)}>
              <FormErrorMessage>{authError}</FormErrorMessage>
            </FormControl>
            <Button
              isLoading={isLoading}
              type="submit"
              size="lg"
              w="100%"
              data-testid={`${context}-with-email`}
              isDisabled={!isValid || isLoading || isLoadingSocial}
              colorScheme="purple"
            >
              {formatMessage({ id: submitBtnLokalizeId })}
            </Button>
          </Form>
        )}
      </Formik>
      <Center my={8}>
        <Divider />
        <Text flexShrink="0" color="gray" mx="2" fontSize="xs">
          {formatMessage({ id: 'word.or' })}
        </Text>
        <Divider />
      </Center>
      <VStack spacing={6}>
        <GoogleButton isDisabled={isLoading} isLoading={isLoadingSocial} onClick={onSocialClick} />
        <Button
          leftIcon={<DynamicIcon iconName={DynamicIconName.mobileSignal} />}
          data-testid="sms-login"
          w="100%"
          size="lg"
          p="7"
          variant="solid"
          colorScheme="purple"
          onClick={() => universalLogin(Connection.SMS, userSignpDetails)}
          isLoading={isLoading}
        >
          {formatMessage({ id: 'login.with.sms' })}
        </Button>
      </VStack>
      <FormControl isInvalid={Boolean(socialAuthError)} mb={4}>
        <FormErrorMessage>{socialAuthError}</FormErrorMessage>
      </FormControl>
    </>
  )
}

export { AuthForm }
