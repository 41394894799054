import { Image, Show } from '@chakra-ui/react'
import rocket from '@wanda-space/noelle/illustrations/png/whiteRocket.png'
import React from 'react'

export function RocketImage() {
  return (
    <Show above="md">
      <Image width="50%" height="50%" alt="rocket" src={rocket} />
    </Show>
  )
}
