import { Box, Button, Flex, Text, VStack } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { CardNumberElement, useElements } from '@stripe/react-stripe-js'
import { StripeCardNumberElement, StripeError } from '@stripe/stripe-js'
import { Alert, CheckMarkIcon, DynamicIcon, DynamicIconName } from '@wanda-space/noelle'
import { CustomerPaymentMethod } from 'api-client'
import {
  MissingStripeWhenSubmittingError,
  PaymentError,
  UnknownPaymentError,
} from 'components/PaymentOptions/PaymentError'
import { CardCVC } from 'components/Stripe/CardCVC'
import { CardExpiry } from 'components/Stripe/CardExpiry'
import { CardNumber } from 'components/Stripe/CardNumber'
import { useStripeContext } from 'contexts/stripe'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { nameToCapitalFirstLetterOnly } from 'utils'

import { PaymentType } from './types'

interface Props {
  selected: PaymentType | undefined
  onSelect: (value: PaymentType) => void
  defaultPaymentMethod?: CustomerPaymentMethod
  roundCorners: boolean
  handleNewCard: (card: StripeCardNumberElement) => Promise<string | undefined>
  selectable?: boolean
}

export function PaymentCardBox({
  selected,
  onSelect,
  defaultPaymentMethod,
  roundCorners,
  handleNewCard,
  selectable = true,
}: Props) {
  const cardNumber = defaultPaymentMethod?.card?.last4
  const cardBrand = defaultPaymentMethod?.card?.brand
  const expirationDate =
    defaultPaymentMethod?.card &&
    `${defaultPaymentMethod.card.exp_month}/${defaultPaymentMethod.card.exp_year}`

  const cardSelected = selected === PaymentType.PAYMENT_METHOD
  const [expanded, setExpanded] = useState(cardSelected && !cardNumber)
  const { stripe } = useStripeContext()
  const elements = useElements()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<StripeError | UnknownPaymentError | undefined>()
  const { formatMessage } = useIntl()

  useEffect(() => {
    if (selected === PaymentType.INVOICE && expanded) {
      setExpanded(false)
    }
  }, [selected])

  const clearStripe = () => {
    const expiryElement = elements?.getElement('cardExpiry')
    const numberElement = elements?.getElement('cardNumber')
    const cvcElement = elements?.getElement('cardCvc')

    expiryElement?.clear()
    numberElement?.clear()
    cvcElement?.clear()
  }

  const handleSelectCard = () => {
    if (!cardNumber) {
      setExpanded(true)
    }
    onSelect(PaymentType.PAYMENT_METHOD)
  }

  const addNewPaymentMethod = async () => {
    try {
      if (!stripe || !elements) {
        captureException(new MissingStripeWhenSubmittingError())
        return
      }
      const card = elements.getElement(CardNumberElement)

      if (!card) {
        return
      }
      setLoading(true)

      await handleNewCard(card)
      clearStripe()
      setExpanded(false)
    } catch (error) {
      setError(error as UnknownPaymentError)
      captureException(error)
    } finally {
      setLoading(false)
    }
  }

  const expandPaymentCardBox = () => {
    setError(undefined)
    setExpanded(true)
  }

  return (
    <Button
      as={Box}
      data-testid="card-payment-option"
      p="6"
      border="1px solid"
      borderColor={`${cardSelected ? 'purple.600' : 'gray.200'}`}
      borderTopRadius="12"
      borderBottomRadius={`${roundCorners ? '12' : '0'}`}
      justifyContent="left"
      onClick={() => handleSelectCard()}
      height="auto"
      width="100%"
      whiteSpace="normal"
      backgroundColor={`${cardSelected ? 'purple.50' : 'white'}`}
      _hover={{
        background: cardSelected ? 'purple.100' : 'gray.50',
      }}
      _selected={{
        background: cardSelected ? 'purple.200' : 'gray.50',
      }}
    >
      <Flex width="100%" justifyItems="flex-start">
        <Flex flexDirection="column" width="100%">
          <Flex justifyContent="space-between" alignItems="center" mb={2}>
            <Text fontSize="xl" fontWeight="500">
              {formatMessage({ id: 'payment.paymentCardBox.title' })}
            </Text>
            {selectable && (
              <CheckMarkIcon
                onClick={() => handleSelectCard()}
                textAlign="end"
                color={cardSelected ? 'purple.700' : 'gray.200'}
                width="6"
                height="6"
              />
            )}
          </Flex>
          {cardNumber && expirationDate && cardBrand && !expanded && (
            <Flex width={'100%'} justifyContent="space-between" padding="0 4">
              <VStack spacing={0} alignItems="start" margin={0}>
                <Text fontWeight={'500'} lineHeight="6">
                  {`${nameToCapitalFirstLetterOnly(cardBrand)} ****${cardNumber}`}
                </Text>
                <Text as="span" marginTop="unset" color="ctaBlack.700">
                  {formatMessage(
                    {
                      id: 'payment.card.expirationdate',
                    },
                    { date: expirationDate }
                  )}
                </Text>
              </VStack>
              <DynamicIcon
                textAlign="end"
                mr={1}
                iconName={(cardBrand as DynamicIconName) || DynamicIconName.cardIcon}
                height={10}
                width={10}
              />
            </Flex>
          )}

          {!expanded && (
            <Text
              data-testid="add-payment-card"
              fontSize="lg"
              fontWeight={400}
              lineHeight="6"
              textDecoration="underline"
              onClick={expandPaymentCardBox}
              tabIndex={0}
              role="button"
              mt={2}
            >
              {cardNumber
                ? formatMessage({ id: 'payment.card.input.change' })
                : formatMessage({ id: 'payment.card.input.add' })}
            </Text>
          )}

          {expanded && (
            <>
              <PaymentError wide error={error} />
              <Flex direction="column">
                {cardNumber && (
                  <Box mb="4">
                    <Alert
                      status="warning"
                      id="change-card-warning"
                      text={formatMessage({ id: 'payment.card.change.warning' })}
                    />
                  </Box>
                )}
                {!cardNumber && (
                  <Text mt="2" mb="4" fontSize={'lg'}>
                    {formatMessage({ id: 'payment.card.attachment.info' })}
                  </Text>
                )}
                <CardNumber disabled={loading} />
                <Flex gap={5}>
                  <CardExpiry disabled={loading} />
                  <CardCVC disabled={loading} />
                </Flex>
              </Flex>

              <>
                <Button
                  width="100%"
                  variant="solid"
                  colorScheme="ctaBlack"
                  data-testid="add-card-button"
                  isLoading={loading}
                  size="lg"
                  mb="4"
                  mt="2"
                  fontSize="md"
                  fontWeight="500"
                  onClick={addNewPaymentMethod}
                >
                  {formatMessage({ id: 'modal.addCard' })}
                </Button>
                <Button
                  width="100%"
                  variant="outline"
                  data-testid="cancel-button"
                  size="lg"
                  fontSize="md"
                  mb="4"
                  fontWeight="500"
                  isDisabled={loading}
                  onClick={(event) => {
                    event.stopPropagation()
                    setExpanded(false)
                  }}
                >
                  {formatMessage({ id: 'word.cancel' })}
                </Button>
              </>
            </>
          )}
        </Flex>
      </Flex>
    </Button>
  )
}
