import { StorageItemState } from '@wanda-space/types'

export type ItemState = keyof typeof StorageItemState

export const ItemStateSortValue: Record<
  | StorageItemState.WITH_CUSTOMER
  | StorageItemState.ON_THE_ROAD
  | StorageItemState.STORED
  | StorageItemState.WITH_PARTNER
  | 'ordered',
  number
> = {
  [StorageItemState.WITH_CUSTOMER]: 1,
  ordered: 2,
  [StorageItemState.ON_THE_ROAD]: 3,
  [StorageItemState.STORED]: 4,
  [StorageItemState.WITH_PARTNER]: 4,
}
