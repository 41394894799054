import {
  type AddOrderlineBodyDto,
  type AddPackingOrderlinesDto,
  type CancelOrderReponseDto,
  type ChangeSquareMeterSizeRequestDto,
  type CreateItemDto,
  type FirstTimeOrderResponseDto,
  type OrderResponseDto,
  type OrderResponseDtoWithItemsAndItemIds,
  type OrderResponseWithItems,
  type PlaceOrderRequestDto,
  type PlacedOrderResponseDto,
  type RemovePackingOrderlineDto,
  type RemoveStorageOrderlinesDto,
  type UpdateAddressBodyDto,
  type UpdateAddressResponseDto,
  type UpdateCarryingBodyDto,
  type UpdateContactPersonDto,
  type UpdateDateTimeBodyDto,
  type UpdateDeliveryStrorageOrderlinesDto,
} from '@wanda-space/types'

import { request } from '../api-client'
import { OrderlineDiscount } from '../types'

export async function getOrders(
  page?: number,
  itemsPerPage?: number
): Promise<OrderResponseWithItems[]> {
  return request<{ orders: Promise<OrderResponseWithItems[]> }>({
    method: 'GET',
    url: 'order',
    params: { page, itemsPerPage },
  }).then(async ({ orders }) => orders)
}

export function cancelOrder(id: string): Promise<CancelOrderReponseDto> {
  return request({
    method: 'POST',
    url: `order/${id}/cancel`,
    body: {},
  })
}

export function removeItemOrderlines(
  itemId: RemoveStorageOrderlinesDto['itemIds'][number],
  orderId: string
): Promise<OrderResponseDto> {
  return request({
    method: 'PUT',
    url: `order/${orderId}/remove-storage-orderlines`,
    body: { itemIds: [itemId] },
  })
}

export function getOrder(id: string): Promise<OrderResponseDtoWithItemsAndItemIds> {
  return request<OrderResponseDtoWithItemsAndItemIds>({
    method: 'GET',
    url: `order/${id}`,
  })
}

export function checkIfFirstOrder(): Promise<FirstTimeOrderResponseDto> {
  return request({
    method: 'GET',
    url: 'order/check-first-order',
  })
}

export async function changeDateTime(id: string, data: UpdateDateTimeBodyDto) {
  return request<Promise<OrderResponseDto>>({
    method: 'PUT',
    url: `order/${id}/date-time`,
    body: data,
  })
}

export async function changeAddress(
  id: string,
  address: UpdateAddressBodyDto
): Promise<UpdateAddressResponseDto> {
  return request({
    method: 'PUT',
    url: `order/${id}/address`,
    body: address,
  })
}

export async function addStorageOrderlinesToOrder(
  id: string,
  body: AddOrderlineBodyDto
): Promise<OrderResponseDto> {
  return request({
    method: 'PUT',
    url: `order/${id}/add-storage-orderlines`,
    body: body,
  })
}

export async function changeCarrying(
  id: string,
  body: UpdateCarryingBodyDto
): Promise<OrderResponseDto> {
  return request({
    method: 'PUT',
    url: `order/${id}/carrying`,
    body,
  })
}
export async function updateDeliveryItems(
  payload: UpdateDeliveryStrorageOrderlinesDto,
  id: string
): Promise<OrderResponseDto> {
  return request({
    method: 'PUT',
    url: `order/${id}/update-delivery-storage-orderlines`,
    body: payload,
  })
}

export async function updateSquareMeterSize(
  data: ChangeSquareMeterSizeRequestDto & { discount?: OrderlineDiscount },
  orderId: string
): Promise<OrderResponseDto> {
  return request({
    method: 'PUT',
    url: `order/${orderId}/change-square-meter-size`,
    body: data,
  })
}

export function createOrderWithItems(order: PlaceOrderRequestDto, items: CreateItemDto[]) {
  return request<PlacedOrderResponseDto>({
    method: 'POST',
    url: 'order/with-items',
    body: { order, itemsToBeCreated: items },
  })
}

export function createDeliveryOrder(payload: PlaceOrderRequestDto) {
  return request<PlacedOrderResponseDto>({
    method: 'POST',
    url: 'order',
    body: payload,
  })
}

export async function addPackingToOrder(
  id: string,
  orderlines: AddPackingOrderlinesDto['orderlines']
): Promise<OrderResponseDto> {
  return request({
    method: 'PUT',
    url: `order/${id}/add-packing-orderlines`,
    body: { orderlines: orderlines },
  })
}

export async function removePackingfromOrder(
  id: string,
  itemids: RemovePackingOrderlineDto['itemids']
): Promise<OrderResponseDto> {
  return request({
    method: 'PUT',
    url: `order/${id}/remove-packing-orderlines`,
    body: { itemids },
  })
}

export async function updateContactPerson(
  id: string,
  contactPerson: UpdateContactPersonDto['contactPerson']
): Promise<OrderResponseDto> {
  return request({
    method: 'PUT',
    url: `order/${id}/contact-person`,
    body: { contactPerson },
  })
}
