import { Box, Heading, Text } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { Alert, DynamicIconName, ItemWrapper, Spinner } from '@wanda-space/noelle'
import { type ProductCategoryResponseDto, SortOrderOptionsType } from '@wanda-space/types'
import { Stepable } from 'interfaces'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch } from 'reduxStore'

import { ItemImage } from 'components/Item/Image/ItemImage'
import { WarningModalContent } from 'components/Modals/WarningModal/WarningModal'
import { useSearchParams } from 'react-router-dom'
import { ServiceProviderSelector } from 'routes/ServiceFlow/components/ServiceProviderSelector'
import { fetchItem } from '../../../api-client'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { useProductsAndCategories } from '../../../hooks/useProductsAndCategories'
import {
  selectSelectedCategory,
  selectServiceOrderLines,
  selectStorageItems,
} from '../../../reduxStore/ducks/serviceFromStorage/selectors'
import {
  setSelectedCategory,
  toggleStorageItems,
} from '../../../reduxStore/ducks/serviceFromStorage/serviceFromStorage'

export const SelectServiceProvider = ({ onNextStep }: Stepable) => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const { formatMessage, messages } = useIntl()
  const [pendingNextCategory, setPendingNextCategory] = useState<ProductCategoryResponseDto | null>(
    null
  )
  const { productCategories, isLoading } = useProductsAndCategories({
    sortOption: SortOrderOptionsType.SERVICE_FLOW,
  })
  const selectedCategory = useAppSelector(selectSelectedCategory)
  const serviceOrderLines = useAppSelector(selectServiceOrderLines)
  const storageItems = useAppSelector(selectStorageItems)
  const currentCity = useAppSelector((state) => state.ui.city)
  const currentCountry = useAppSelector((state) => state.ui.country)
  const currentItemIds =
    searchParams.get('itemIds')?.split(',') ?? storageItems.map((item) => item.id)
  const itemsQuery = useQuery(['item', ...currentItemIds], () =>
    Promise.all(currentItemIds.map((id) => fetchItem(id)))
  )
  const currentStorageProductCategoryIds =
    itemsQuery.data?.flatMap((item) =>
      item.storageProduct.categoryId ? [item.storageProduct.categoryId] : []
    ) ?? []

  const handleOnNext = (nextCategory: ProductCategoryResponseDto) => {
    dispatch(setSelectedCategory(nextCategory))
    const selectedItems =
      itemsQuery.data?.filter((item) => item.storageProduct.categoryId === nextCategory.id) ?? []
    dispatch(toggleStorageItems(selectedItems))
    onNextStep()
  }
  const defaultMessageKey = 'app.alert.message'
  const citySpecMessageKey = `${defaultMessageKey}.${currentCity}`
  const countrySpecMessageKey = `${defaultMessageKey}.${currentCountry}`
  const hasCitySpecMessage = messages[citySpecMessageKey]
  const hasCountrySpecMessage = messages[countrySpecMessageKey]
  const hasDefaultMessage = messages[defaultMessageKey]
  const hasAppAlertMessage = hasCitySpecMessage || hasCountrySpecMessage || hasDefaultMessage

  let alertMessage = formatMessage({ id: defaultMessageKey })

  if (hasCountrySpecMessage) {
    alertMessage = formatMessage({ id: countrySpecMessageKey })
  }

  if (hasCitySpecMessage) {
    alertMessage = formatMessage({ id: citySpecMessageKey })
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <Box px="3">
      {hasAppAlertMessage && <Alert id="alert-message" status="info" text={alertMessage} />}
      <Box marginBottom="10">
        <Heading as="h3" fontSize="lg" fontWeight="500">
          {formatMessage({ id: 'service.from.storage.selected.items.title' })}
        </Heading>
        {itemsQuery.data?.map((item) => (
          <ItemWrapper
            mx="-3"
            disableHover
            key={item.id}
            contentProps={{
              name: item.name,
            }}
            img={
              <ItemImage
                itemId={item.id}
                imageId={item.image ?? undefined}
                iconName={
                  productCategories?.find(
                    (category) => category.id === item.storageProduct.categoryId
                  )?.iconName as DynamicIconName
                }
              />
            }
          />
        ))}
      </Box>
      <Heading as="h3" fontSize="lg" fontWeight="500" mb="3">
        {formatMessage({ id: 'service.from.storage.applicable.services' })}
      </Heading>

      <Box display="flex" gap={6} flexWrap="wrap" p={0}>
        <ServiceProviderSelector
          selectedStorageProductCategoryIds={currentStorageProductCategoryIds}
          handleSelectProviderOption={(option) => {
            const { serviceProvider, startingPrice, ...category } = option
            if (!selectedCategory) {
              handleOnNext(category)
            } else if (selectedCategory.id === option.id) {
              // just change screen
              onNextStep()
            } else if (serviceOrderLines.length) {
              setPendingNextCategory(category)
            } else {
              // set service provider and change screen
              handleOnNext(category)
            }
          }}
          selectedCategoryId={selectedCategory?.id}
          hasServiceOrderlines={Boolean(serviceOrderLines.length)}
        />
      </Box>
      {pendingNextCategory ? (
        <WarningModalContent
          isOpen
          title={formatMessage({ id: 'alert.services.unsavedChanges.title' })}
          description={
            <Text textAlign="center">
              {formatMessage({ id: 'alert.services.unsavedChanges.text' })}
            </Text>
          }
          yesButtonText={formatMessage({
            id: 'alert.services.unsavedChanges.yes',
          })}
          noButtonText={formatMessage({
            id: 'alert.services.unsavedChanges.no',
          })}
          handleClickYes={() => {
            if (pendingNextCategory) {
              handleOnNext(pendingNextCategory)
            }
          }}
          handleClickNo={() => {
            if (selectedCategory) {
              onNextStep()
            }
          }}
        />
      ) : null}
    </Box>
  )
}
