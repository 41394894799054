import { captureMessage } from '@sentry/react'
import { getEnv } from 'env'

const env = getEnv('APP_ENVIRONMENT') as 'prd' | 'dev' | 'local'

// KEEP THIS FOR NOW (for dev), so it is easy to switch back and forth (see readme)
// export const baseUrl = ((env === 'dev' && 'https://dev.wanda.space') || '') + '/api/v1/'
export const baseUrl = '/api/v1/'

const opsUrls = {
  prd: 'https://api.ops.wanda.space',
  dev: 'https://api.ops.dev.wanda.space',
  local: '/ops',
}
export const opsBaseUrl = opsUrls[env]

if (!opsBaseUrl) {
  captureMessage('Missing ops api base url', { level: 'error' })
}

const userApiUrls = {
  prd: 'https://api.users.wanda.space',
  dev: 'https://api.users.dev.wanda.space',
  local: '/users',
}

export const userBaseUrl = userApiUrls[env]

if (!userApiUrls) {
  captureMessage('Missing user api base url', { level: 'error' })
}
