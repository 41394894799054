import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Container,
  Flex,
  Text,
} from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { useQueryClient } from '@tanstack/react-query'
import {
  BackButton,
  EditDescriptionIcon,
  MonthlyRevenueIcon,
  MovingTruckIcon,
  TagIcon,
} from '@wanda-space/noelle'
import { type ItemResponseDto, StorageItemState } from '@wanda-space/types'
import { updateDescription, updateName } from 'api-client'
import { ImageGrid } from 'components/Item/Form/ImageGrid'
import { Cropper } from 'components/UploadImage'
import { Routes } from 'consts'
import { useDateLocale } from 'contexts/Intl'
import { format } from 'date-fns'
import { useAppSelector } from 'hooks/useAppSelector'
import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { DeleteItem } from 'routes/Space/Item/DeleteItem'
import { sanitizeAmount } from 'utils/price-utils'

import { ChangeItemDescriptionModal } from './ChangeItemDescriptionModal'
import { ChangeItemNameModal } from './ChangeNameModal'
import { ItemLabel } from './ItemLabel'

export interface Props {
  item: ItemResponseDto
  isError?: boolean
  isSuccess?: boolean
  addImage?: (img: string, itemId: string, hasPrimary: boolean) => void
  deleteImage?: (itemId: string, imageId: string) => void
}

export const ItemForm = ({
  isError,
  isSuccess,
  addImage,
  deleteImage,
  item: {
    id,
    name,
    description,
    storageProduct,
    // @ts-ignore fix this
    pendingOrderDetails,
    // @ts-ignore fix this
    sharedWith,
    ownerId,
    // @ts-ignore fix this
    pendingOrderType,
    image,
    // @ts-ignore fix this
    pendingOrderId,
    state,
    // @ts-ignore fix this
    paymentCommitment,
    pendingServices,
  },
}: Props) => {
  const { formatMessage } = useIntl()
  const location = useLocation()
  const user = useAppSelector((state) => state.user.user)

  const [isAddPhotoMode, setAddPhotoMode] = useState(false)
  const [uploadedImage, setUploadedImage] = useState<string>('')

  const [showEditName, setShowEditName] = useState<boolean>(false)
  const [showEditDescription, setShowEditDescription] = useState<boolean>(false)

  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const queryClient = useQueryClient()

  const navigate = useNavigate()
  const locale = useDateLocale()
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  const canDelete = state === StorageItemState.WITH_CUSTOMER && !pendingOrderId

  const invalidateItem = () => {
    queryClient.invalidateQueries(['item'])
  }

  const handleRemoveImage = (img: string | undefined) => {
    if (img) {
      deleteImage?.(id, img)
      setTimeout(() => {
        if (mounted.current === true) invalidateItem()
      }, 2000)
    }
  }

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      URL.revokeObjectURL(uploadedImage)
    },
    [uploadedImage]
  )

  if (!id) {
    return <Navigate to={location.state?.referrer || Routes.Space} />
  }
  const formatDeliveryTimeSlots = (x: string) => {
    return `${x.substring(0, 2)}:${x.substring(2)}`
  }
  const isOwner = user?.id === ownerId

  const handleAddImage = (img: string) => {
    const hasPrimaryImage = image ? true : false

    addImage?.(img, id, hasPrimaryImage)
    setTimeout(() => {
      if (mounted.current === true) invalidateItem()
    }, 2000)
  }

  const handleUpdateName = async (itemName: string) => {
    try {
      await updateName(id, itemName)
      setShowSuccess(true)
      setShowEditName(false)
      invalidateItem()

      setTimeout(() => {
        if (mounted.current === true) setShowSuccess(false)
      }, 5000)
    } catch (error) {
      setShowError(true)
      setTimeout(() => {
        if (mounted.current === true) setShowError(false)
      }, 5000)
      captureException(error)
      throw error
    }
  }

  const handleUpdateDescription = async (description: string) => {
    try {
      await updateDescription(id, description)
      setShowEditDescription(false)
      setShowSuccess(true)

      invalidateItem()

      setTimeout(() => {
        if (mounted.current === true) setShowSuccess(false)
      }, 5000)
    } catch (error) {
      setShowError(true)
      setTimeout(() => {
        if (mounted.current === true) setShowSuccess(false)
      }, 5000)
      captureException(error)
      throw error
    }
  }

  const getBookingDate = () => {
    if (!pendingOrderDetails || !user?.locale) return
    return (
      <>
        <Flex>
          <Text mr="1">
            {state === StorageItemState.STORED
              ? formatMessage({ id: 'word.delivery' })
              : formatMessage({ id: 'word.pickup' })}
          </Text>
          <Text fontWeight="bold">
            {`${format(new Date(pendingOrderDetails.deliveryDate), 'EEEE dd.LLL ', {
              locale: locale,
            })} ${formatDeliveryTimeSlots(
              pendingOrderDetails.deliveryTimeSlot.from
            )}-${formatDeliveryTimeSlots(pendingOrderDetails.deliveryTimeSlot.to)}`}
          </Text>
        </Flex>

        {pendingOrderId && (
          <>
            <Text mr="1">{formatMessage({ id: 'Item.go.to.order' })}</Text>
            <Text as="u">
              <Link to={Routes.EditOrderDetails(pendingOrderId)}>
                {formatMessage({ id: 'word.see.details' })}
              </Link>
            </Text>
          </>
        )}
      </>
    )
  }

  const paymentCommitmentEndDate = paymentCommitment?.endDate

  const productType = storageProduct.name

  return (
    <>
      <ChangeItemNameModal
        isOpen={showEditName}
        initialValue={name}
        onSubmit={(value: string) => handleUpdateName(value)}
        onClose={() => setShowEditName(false)}
      />
      <ChangeItemDescriptionModal
        isOpen={showEditDescription}
        initialValue={description || ''}
        onSubmit={(value: string) => handleUpdateDescription(value)}
        onClose={() => setShowEditDescription(false)}
      />

      {isAddPhotoMode && (
        <Cropper
          image={uploadedImage}
          onSaveImage={(img) => {
            handleAddImage(img)
            setAddPhotoMode(false)
          }}
          onClose={() => {
            setAddPhotoMode(false)
          }}
        />
      )}
      {!isAddPhotoMode && (
        <Container maxWidth={'580px'}>
          <Flex justifyContent="space-between" alignItems="center">
            <BackButton
              backTo=""
              linkProps={{
                onClick: () => navigate(location.state?.referrer || -1),
              }}
            />
            <Flex>
              <ItemLabel
                id={id}
                state={state}
                sharedWith={sharedWith ?? []}
                isOwner={isOwner}
                pendingOrderId={pendingOrderId}
                pendingOrderType={pendingOrderType}
                pendingServices={pendingServices}
              />
            </Flex>
          </Flex>
          <Box my={4} mx={0}>
            {(showSuccess || isSuccess) && (
              <Alert
                bgColor="green.50"
                status="success"
                border="1px solid"
                borderColor="green.300"
                borderRadius="md"
              >
                <AlertIcon />
                <AlertTitle>{formatMessage({ id: 'item.update.success.title' })}</AlertTitle>
                <AlertDescription>
                  {formatMessage({ id: 'item.update.success.content' })}
                </AlertDescription>
              </Alert>
            )}
            {(showError || isError) && (
              <Alert
                bgColor="red.50"
                status="error"
                border="1px solid"
                borderColor="red.300"
                borderRadius="md"
              >
                <AlertIcon />
                <AlertTitle>{formatMessage({ id: 'item.update.error.title' })}</AlertTitle>
                <AlertDescription>
                  {formatMessage({ id: 'item.update.error.content' })}
                </AlertDescription>
              </Alert>
            )}
            <Flex justify="space-between" flexWrap="wrap-reverse">
              <Text fontSize="3xl" fontWeight="medium" lineHeight="1.3">
                {name}
              </Text>
            </Flex>
            <>
              {isOwner && (
                <Flex mb="3">
                  <Text
                    textDecoration="underline"
                    transition="color 0.2s"
                    role="button"
                    color="gray.500"
                    onClick={() => setShowEditName(true)}
                    _hover={{ color: 'gray.600' }}
                  >
                    {formatMessage({ id: 'word.manageSpace' })}
                  </Text>
                </Flex>
              )}
              <Flex mt="3" alignItems="start">
                <EditDescriptionIcon mt={1 / 2} color="gray.400" />
                <Box ml="3">
                  {description && <Text>{description}</Text>}
                  {isOwner && (
                    <Text
                      transition="color 0.2s"
                      _hover={{ color: 'gray.600' }}
                      color="gray.500"
                      mt={description ? 1 : 0}
                      role="button"
                      textDecoration="underline"
                      onClick={() => setShowEditDescription(true)}
                    >
                      {description
                        ? formatMessage({ id: 'item.edit.description' })
                        : formatMessage({ id: 'item.add.description' })}
                    </Text>
                  )}
                </Box>
              </Flex>
              {productType && (
                <Flex mt="3">
                  <TagIcon mt={1 / 2} color="gray.400" />
                  <Text ml="3">
                    {formatMessage({
                      id: `${storageProduct.localizationKey}.name`,
                    })}
                  </Text>
                </Flex>
              )}
              {!!storageProduct?.price && (
                <Flex mt="3">
                  <MonthlyRevenueIcon mt={1 / 2} color="gray.400" />
                  <Text role="button" ml="3">
                    {formatMessage(
                      { id: 'item.monthly.cost' },
                      { amount: sanitizeAmount(storageProduct?.price) }
                    )}
                  </Text>
                  {paymentCommitmentEndDate && (
                    <Text role="button" ml="1">
                      {formatMessage(
                        { id: 'item.paymentCommitment.endDate' },
                        { endDate: paymentCommitmentEndDate }
                      )}
                    </Text>
                  )}
                </Flex>
              )}

              {pendingOrderId && (
                <Flex mt="3">
                  <MovingTruckIcon mt={1 / 2} height="5" color="gray.400" />
                  <Text ml="3">{getBookingDate()}</Text>
                </Flex>
              )}
              <Box mt="6">
                <ImageGrid
                  itemId={id}
                  onRemoveImage={(img) => handleRemoveImage(img)}
                  onSetImage={(img) => {
                    setUploadedImage(img)
                    setAddPhotoMode(true)
                  }}
                  createMode={isOwner}
                  canRemove={isOwner && canDelete}
                />
              </Box>
            </>
          </Box>
          {canDelete && (
            <DeleteItem
              onDeleteNavigateTo={-1}
              itemId={id}
              itemName={name}
              deleteWith="button"
              buttonProps={{ width: '100%' }}
            />
          )}
        </Container>
      )}
    </>
  )
}
