import { Container, Spinner } from '@chakra-ui/react'
import { FlowStepRoutes } from 'components/FlowStepRoutes'
import React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router'

import { Routes } from 'consts'
import { isEmpty } from 'ramda'
import { Navigate } from 'react-router-dom'
import { BookingHeader } from '../../../components/BookingHeader'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { useAuth } from '../../../hooks/useAuth'
import { useSteps } from '../../../hooks/useCurrentStep'
import { FlowStep } from '../../../interfaces'
import {
  selectSelectedCategory,
  selectServiceOrderLines,
  selectStorageItems,
} from '../../../reduxStore/ducks/serviceFromStorage/selectors'
import { BookingConfirmation } from './BookingConfirmation'
import { paths, rootPath } from './Paths'
import { PaymentAndSummary } from './PaymentAndSummary'
import { SelectServiceProducts } from './SelectServiceProducts'
import { SelectServiceProvider } from './SelectServiceProvider'

const ServiceFromStorage = (): JSX.Element => {
  const { formatMessage } = useIntl()
  const { isAuthenticating } = useAuth()
  const selectedCategory = useAppSelector(selectSelectedCategory)
  const serviceOrderLines = useAppSelector(selectServiceOrderLines)
  const storageItems = useAppSelector(selectStorageItems)
  const navigate = useNavigate()

  const params = useParams()

  const steps: FlowStep[] = [
    {
      element: SelectServiceProvider,
      path: paths.selectService,
      title: formatMessage({ id: 'service.select.title' }),
      description: formatMessage({ id: 'service.select.description' }),
    },
    {
      element: SelectServiceProducts,
      path: paths.serviceDetails,
      fallback: () => typeof selectedCategory === 'undefined' || isEmpty(storageItems),
    },
    {
      element: PaymentAndSummary,
      path: paths.paymentAndSummary,
      title: formatMessage({ id: 'paymentstep.title' }),
      fallback: () => isEmpty(serviceOrderLines),
      requireLogin: true,
    },
    {
      element: BookingConfirmation,
      path: paths.bookingConfirmation,
      requireLogin: true,
    },
  ]

  const { currentStep, isLastStep, FallBackNavigate, StepGuard, onNextStep, nextStep } = useSteps(
    steps,
    `${rootPath}/`
  )

  if (isAuthenticating) {
    return <Spinner />
  }

  if (FallBackNavigate) {
    return <FallBackNavigate />
  }

  if (isEmpty(params.itemIds) && isEmpty(storageItems)) {
    return <Navigate replace to={Routes.Space} />
  }

  if (StepGuard) {
    return <StepGuard />
  }

  return (
    <>
      <BookingHeader
        containerProps={{
          paddingBottom: currentStep.title ? 8 : 0,
          zIndex: 2,
        }}
        title={currentStep.title}
        description={currentStep.description}
        onBack={!isLastStep ? () => navigate(-1) : undefined}
      />
      <Container p={4} pt={0} zIndex="1">
        <FlowStepRoutes
          steps={steps}
          currentStep={currentStep}
          onNextStep={onNextStep}
          flowRootPath={rootPath}
          nextStep={nextStep}
        />
      </Container>
    </>
  )
}

export { ServiceFromStorage }
