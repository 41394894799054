import {
  ItemImageResponseDto,
  type ItemResponseDto,
  PaginatedResponseDto,
  type PaginationQueryParams,
  StorageItemType,
  WandaId,
} from '@wanda-space/types'

import { request, requestBlob } from '../api-client'

export async function fetchItems(params: PaginationQueryParams) {
  return (
    await request<{ storageItems: ItemResponseDto[]; count: number }>({
      url: 'items',
      method: 'GET',
      params,
    })
  ).storageItems
}

export function fetchItem(id: string) {
  return request<ItemResponseDto>({
    url: `items/${id}`,
    method: 'GET',
  })
}

export async function shareItem(
  id: string,
  email: string,
  ownerPays: boolean
): Promise<{ sharedWith: { displayName: string; accountId: WandaId; ownerPays: boolean }[] }> {
  return request({
    method: 'POST',
    url: `items/${id}/share`,
    body: { email, ownerPays },
  })
}

export async function unshareItem(
  id: string,
  sharedWithAccountId: WandaId
): Promise<{ sharedWith: { displayName: string; accountId: WandaId; ownerPays: boolean }[] }> {
  return request({
    method: 'POST',
    url: `items/${id}/unshare`,
    body: { sharedWithAccountId },
  })
}

export async function deleteItem(id: string): Promise<string> {
  return request({
    method: 'DELETE',
    url: `items/${id}`,
  })
}

export const createItem = async ({
  name,
  type,
  file,
  description,
}: {
  name: string
  file?: string
  type: StorageItemType
  description?: string
}): Promise<ItemResponseDto> => {
  const form = new FormData()

  form.append('name', name)
  form.append('type', type)
  description && form.append('description', description)

  if (file) {
    const img = await fetch(file)
    const imgBlob = await img.blob()
    form.append('file', imgBlob)
  }

  return await request({
    method: 'POST',
    url: '/items',
    body: form,
  })
}

export const updateItem = async ({
  name,
  type,
  file,
  description,
  itemId,
}: {
  name: string
  file?: string
  type: StorageItemType
  description?: string
  itemId: string
}): Promise<ItemResponseDto> => {
  const form = new FormData()

  form.append('name', name)
  form.append('type', type)
  description && form.append('description', description)

  if (file) {
    const img = await fetch(file)
    const imgBlob = await img.blob()
    form.append('file', imgBlob)
  }

  return await request({
    method: 'PUT',
    url: `items/${itemId}`,
    body: form,
  })
}

export const uploadImage = async ({
  itemId,
  file,
}: {
  itemId: string
  file?: string
}): Promise<{ imageId: string }> => {
  const form = new FormData()

  if (file) {
    const img = await fetch(file)
    const imgBlob = await img.blob()
    form.append('file', imgBlob)
  }

  return await request({
    method: 'POST',
    url: `items/${itemId}/image`,
    body: form,
  })
}

export const setNewPrimaryPhoto = async ({
  itemId,
  imageId,
}: {
  itemId: string
  imageId: string
}): Promise<void> => {
  return await request({
    method: 'POST',
    url: `items/${itemId}/image/${imageId}/primary`,
    body: {},
  })
}

export async function deleteItemImage(itemId: string, imageId: string): Promise<string> {
  return request({
    method: 'DELETE',
    url: `items/${itemId}/image/${imageId}`,
  })
}

export async function updateDescription(itemId: string, description: string): Promise<void> {
  return request({
    method: 'PUT',
    url: `items/${itemId}/description`,
    body: { description: description },
  })
}

export async function updateName(itemId: string, name: string): Promise<void> {
  return request({
    method: 'PUT',
    url: `items/${itemId}/name`,
    body: { name: name },
  })
}

export async function getImageDetailByImageId(itemId: string, imageId: string) {
  return request<ItemImageResponseDto>({
    method: 'GET',
    url: `items/${itemId}/image-detail/${imageId}`,
  })
}

export async function getImageDetails(itemId: string) {
  return request<PaginatedResponseDto<ItemImageResponseDto>>({
    method: 'GET',
    url: `items/${itemId}/image-details`,
  })
}
