import { RedirectModal } from 'components/Modals/RedirectModal'
import { StorageFlowIds, useStorageFlowRouteSelector } from 'hooks/useStorageFlowRouteSelector'
import React from 'react'

export const IsSquareMeterCheck = () => {
  const data = useStorageFlowRouteSelector()
  const show = data && data.flowId === StorageFlowIds.M2
  return (
    <RedirectModal
      show={show}
      titleLokalizeKey="schedule.payment.hadActiveSubscription"
      descriptionLokalizeKey="already.subscribed.for.square.meter"
      actionRoute={data.flowRoute}
      actionButtonLokalizeKey="flow.select.premium.title"
    />
  )
}
