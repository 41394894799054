import { Flex, Text } from '@chakra-ui/react'
import { DynamicIcon, DynamicIconName, Spinner } from '@wanda-space/noelle'
import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useIntl } from 'react-intl'

import { resetImageWithScale } from './utils'

const HEIFMime = ['image/heic', 'image/heif']

interface Props {
  onSetImage: (img: string) => void
  maxNumberOfImages?: number
}

const getProperImage = async (blob: Blob) => {
  let image = blob

  if (HEIFMime.find((mime) => mime === blob.type)) {
    const { default: heic2any } = await import('heic2any')
    const convertedImage = await heic2any({
      blob,
      toType: 'image/jpeg',
      quality: 1,
    })
    image = Array.isArray(convertedImage) ? convertedImage[0] : convertedImage
  }

  return resetImageWithScale(image)
}

const Uploader = ({ onSetImage, maxNumberOfImages }: Props) => {
  const { formatMessage } = useIntl()
  const [loading, setLoading] = useState(false)
  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/*', ...HEIFMime],
    onDrop: async (img) => {
      if (Array.isArray(img) && img.length > 0) {
        setLoading(true)
        const properImage = await getProperImage(img[0])
        onSetImage(properImage)
      }
    },
  })

  return (
    <>
      <Flex
        data-testid="upload"
        background="purple.50"
        flexDirection="column"
        borderRadius="2xl"
        align="center"
        justifyContent="center"
        cursor="pointer"
        border="1px solid"
        py={4}
        height="100%"
        borderColor="purple.200"
        _hover={{ backgroundColor: 'purple.100', borderColor: 'purple.200' }}
        _active={{ backgroundColor: 'purple.200' }}
        {...getRootProps()}
      >
        {loading ? (
          <Spinner />
        ) : (
          <>
            <input {...getInputProps()} />

            <DynamicIcon iconName={DynamicIconName.addNewImage} color="purple.700" h={10} w={10} />
            <Text fontWeight="medium" fontSize="md" color="gray.700" mt={2}>
              {formatMessage({ id: 'booking.confirmation.order.addImages' })}
            </Text>
            {maxNumberOfImages && (
              <Flex color="gray.500" gap={1}>
                <Text>{formatMessage({ id: 'word.max' })}</Text>
                <Text>{maxNumberOfImages}</Text>
              </Flex>
            )}
          </>
        )}
      </Flex>
    </>
  )
}

export { Uploader }
