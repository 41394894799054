import { ProductPriceType } from '@wanda-space/types'
import { OrderLineWithFullProductAndDiscount } from 'api-client'
import { CURRENCIES_BY_COUNTRY } from 'consts/Currency'
import { PriceWrapper } from 'interfaces'
import { add } from 'utils/price-utils'
import { productToPrice } from 'utils/product-utils'

import { useAppSelector } from './useAppSelector'

export function useFlowPrices(orderLines: {
  storage?: OrderLineWithFullProductAndDiscount[]
  service?: OrderLineWithFullProductAndDiscount[]
  taas?: OrderLineWithFullProductAndDiscount[]
  packing?: OrderLineWithFullProductAndDiscount[]
  addons?: OrderLineWithFullProductAndDiscount[]
  timeslot?: OrderLineWithFullProductAndDiscount[]
}): {
  taasPrice: PriceWrapper
  packingPrice: PriceWrapper
  addOnPrice: PriceWrapper
  onetimeAddOnPrice: PriceWrapper
  recursiveAddOnPrice: PriceWrapper
  storagePrice: PriceWrapper
  servicePrice: PriceWrapper
  timeslotPrice: PriceWrapper
} {
  const filterByProductPriceType =
    (isRecurring = false) =>
    ({ product }: OrderLineWithFullProductAndDiscount) =>
      product.priceType === (isRecurring ? ProductPriceType.RECURRING : ProductPriceType.ONE_TIME)

  const countryCode = useAppSelector((state) => state.ui.country)
  const fallback = { amount: 0, currency: CURRENCIES_BY_COUNTRY[countryCode], oldPrice: undefined }
  const storagePrice =
    orderLines.storage && orderLines.storage.length > 0
      ? add(...orderLines.storage.map(({ product }) => productToPrice(product)))
      : fallback

  storagePrice.oldPrice = storagePrice.oldPrice! > 0 ? storagePrice.oldPrice : undefined

  const taasPrice =
    orderLines.taas && orderLines.taas.length > 0
      ? add(...orderLines.taas.map(({ product }) => productToPrice(product)))
      : fallback
  taasPrice.oldPrice = taasPrice.oldPrice! > 0 ? taasPrice.oldPrice : undefined

  const packingPrice =
    orderLines.packing && orderLines.packing.length > 0
      ? add(...orderLines.packing.map(({ product }) => productToPrice(product)))
      : fallback

  packingPrice.oldPrice = packingPrice.oldPrice! > 0 ? packingPrice.oldPrice : undefined

  const addOnPrice =
    orderLines.addons && orderLines.addons.length > 0
      ? add(...orderLines.addons.map(({ product }) => productToPrice(product)))
      : fallback
  addOnPrice.oldPrice = addOnPrice.oldPrice! > 0 ? addOnPrice.oldPrice : undefined

  const onetimeAddOnPrice =
    orderLines.addons && orderLines.addons.length > 0
      ? add(
          ...orderLines.addons
            .filter(filterByProductPriceType())
            .map(({ product }) => productToPrice(product))
        )
      : fallback
  onetimeAddOnPrice.oldPrice =
    onetimeAddOnPrice.oldPrice! > 0 ? onetimeAddOnPrice.oldPrice : undefined

  const recursiveAddOnPrice =
    orderLines.addons && orderLines.addons.length > 0
      ? add(
          ...orderLines.addons
            .filter(filterByProductPriceType(true))
            .map(({ product }) => productToPrice(product))
        )
      : fallback
  recursiveAddOnPrice.oldPrice =
    recursiveAddOnPrice.oldPrice! > 0 ? recursiveAddOnPrice.oldPrice : undefined

  const servicePrice =
    orderLines.service && orderLines.service.length > 0
      ? add(...orderLines.service.map(({ product }) => productToPrice(product)))
      : fallback
  servicePrice.oldPrice = servicePrice.oldPrice! > 0 ? servicePrice.oldPrice : undefined

  const timeslotPrice =
    orderLines.timeslot && orderLines.timeslot.length > 0
      ? add(...orderLines.timeslot.map(({ product }) => productToPrice(product)))
      : fallback

  timeslotPrice.oldPrice = timeslotPrice.oldPrice! > 0 ? timeslotPrice.oldPrice : undefined

  return {
    storagePrice,
    taasPrice,
    packingPrice,
    addOnPrice,
    onetimeAddOnPrice,
    recursiveAddOnPrice,
    servicePrice,
    timeslotPrice,
  }
}
