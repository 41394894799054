import { useQueries } from '@tanstack/react-query'
import { StorageItemType } from '@wanda-space/types'
import { ProductResponseDto } from '@wanda-space/types'
import { OrderLinePayload, fetchPackingQuote } from 'api-client'
import { fetchDiscounts } from 'api-client/lib/routes/discount'
import { formatPrice, getProductsWithDiscount, sanitizeAmount } from 'utils'

import { CURRENCIES_BY_COUNTRY } from '../consts/Currency'
import { useAppSelector } from './useAppSelector'
import { useProductsAndCategories } from './useProductsAndCategories'

interface PackingTaas {
  items: { productId: string; name: string; id: string; type: StorageItemType }[]
}

export const usePacking = ({ items }: PackingTaas) => {
  const country = useAppSelector((state) => state.ui.country)
  const city = useAppSelector((state) => state.ui.city)
  const { products, isLoading: isProductsLoading } = useProductsAndCategories()

  const [discountsQuery, packingQuery] = useQueries({
    queries: [
      {
        queryKey: ['products-with-discount', country, city],
        queryFn: async () => {
          const discounts = await fetchDiscounts({ countryCode: country, city: city! })
          return discounts
        },
        enabled: !!city && !!country,
      },

      {
        queryKey: ['packing', country, city],
        queryFn: async () => {
          const packing = await fetchPackingQuote({ items: items, country })
          return packing
        },
        enabled: !!city && !!country,
      },
    ],
  })

  const packingQuote = packingQuery.data

  const packingOrderlinesWithProduct: ProductResponseDto[] = mapPackingToProducts(
    packingQuery.data?.orderlines ?? [],
    products ?? []
  )

  const mappedToDiscountedProducts = getProductsWithDiscount(
    packingOrderlinesWithProduct,
    discountsQuery.data ?? []
  )

  const initial = 0
  const totalPrice = mappedToDiscountedProducts.reduce(
    (accumulator, currentValue) => accumulator + currentValue.price,
    initial
  )

  return {
    packingQuote,
    packingPrice: formatPrice(CURRENCIES_BY_COUNTRY[country], sanitizeAmount(totalPrice)),
    packingLoading:
      packingQuery.isInitialLoading || discountsQuery.isInitialLoading || isProductsLoading,
  }
}

export const mapPackingToProducts = (
  orderline: OrderLinePayload[],
  products: ProductResponseDto[]
) => {
  // A kinda subptimal solution since packing does not return products.
  // Packing should be updated on the backend to include orderlines.

  const productsForPacking = orderline
    .map((line) => {
      const productIdForPacking = line.productId
      const product = products?.find((p) => {
        return p.id === productIdForPacking
      })

      return product
    })

    .filter((product): product is ProductResponseDto => !!product)

  return productsForPacking
}
