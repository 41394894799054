import { Box, Button, Flex } from '@chakra-ui/react'
import { DynamicIconName } from '@wanda-space/noelle'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { useIntl } from 'react-intl'
import { useLockBodyScroll } from 'react-use'

import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { Routes } from '../../consts'
import { useAuth } from '../../hooks/useAuth'
import { Layer } from '../../styles/variables'
import { NavMenuItem, NavMenuItemProps } from './NavMenuItem'

export type Props = {
  show: boolean
  onDismiss: () => void
}

export const AuthenticatedNavMenu = ({ show, onDismiss }: Props) => {
  const { data: featureFlags } = useFeatureFlags()
  const { formatMessage } = useIntl()
  const { logout } = useAuth()
  useLockBodyScroll(show)

  const navMenuItems: (NavMenuItemProps & { id: string; disabled?: boolean })[] = [
    {
      text: formatMessage({ id: 'word.addItems' }),
      iconName: DynamicIconName.plusSmall,
      iconProps: {
        backgroundColor: 'purple.600',
        color: 'white',
        borderRadius: 'md',
        height: 4,
        width: 4,
        p: 1,
      },
      path: Routes.SelectFlow,
      id: 'addItems',
    },
    {
      text: formatMessage({ id: 'navigation.mySpace.title' }),
      iconName: DynamicIconName.threeBoxes,
      path: Routes.Space,
      id: 'space',
      end: true,
    },
    {
      text: formatMessage({ id: 'navigation.myOrders.title' }),
      iconName: DynamicIconName.movingTruck,
      path: Routes.Orders,
      id: 'orders',
    },
  ]

  if (featureFlags?.ENABLE_SPACESHIP_FLOW_PACKAGING_IN_MENUS) {
    navMenuItems.push({
      text: formatMessage({ id: 'navigation.shop.title' }),
      iconName: DynamicIconName.box,
      path: Routes.Shop,
      id: 'shop',
    })
  }

  if (featureFlags?.ENABLE_SPACESHIP_FLOW_BUY) {
    navMenuItems.push({
      text: formatMessage({ id: 'navigation.market.title' }),
      iconName: DynamicIconName.sellWithWanda,
      href: 'https://wanda.space/market',
      id: 'market',
    })
  }

  return (
    <>
      {show && (
        <Box
          id="hamburger-nav-background"
          position="fixed"
          height="100%"
          overflowX="hidden"
          opacity="0.5"
          zIndex={Layer.NavOverlay}
          backgroundColor="black"
          width="100%"
          top="65px"
          left="0"
          aria-hidden
          tabIndex={-1}
          onClick={onDismiss}
        >
          <Box width="100%" height="30px" backgroundColor="white" opacity="1" />
        </Box>
      )}
      <AnimatePresence>
        {show && (
          <motion.div
            animate={{ y: 65 }}
            transition={{
              type: 'spring',
              stiffness: 600,
              damping: 15,
              mass: 1,
            }}
            exit={{
              y: '-100vw',
              zIndex: 1,
              transition: {
                ease: 'easeIn',
                duration: 0.4,
              },
            }}
            initial={{
              position: 'absolute',
              zIndex: Layer.NavOverlay,
              width: '100vw',
              left: 0,
            }}
            key="modal"
          >
            <Box
              id="hamburger-nav-background"
              position="absolute"
              backgroundColor="white"
              px={6}
              py={4}
              width="100%"
              tabIndex={0}
            >
              <Flex direction="column" rowGap={2}>
                {navMenuItems
                  .filter(({ disabled }) => !disabled)
                  .map((props) => (
                    <Box pt={2} pb={2} key={props.id}>
                      <NavMenuItem {...props} />
                    </Box>
                  ))}
              </Flex>
              <Flex justifyContent="center" width="100%" mt={4}>
                <Button
                  data-testid="logout-button"
                  textDecoration="underline"
                  onClick={() => {
                    logout()
                  }}
                  variant="tertiary"
                >
                  {formatMessage({ id: 'word.logout' })}
                </Button>
              </Flex>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
