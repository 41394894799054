import { FormatMessage } from 'utils'
import {
  ContactPersonSchema,
  firstName,
  floorNumber,
  lastName,
  phoneNumber,
} from 'utils/validators'
import * as Yup from 'yup'

import { FieldNames } from './types'
import { isFieldDisabled, isFieldVisible } from './utils'

export const contactInfoFormSchema = (
  formatMessage: FormatMessage,
  hideFields?: FieldNames[],
  disableList?: FieldNames[]
) => {
  const baseShape: { [key: string]: Yup.Schema<unknown, unknown> } = {
    [FieldNames.FIRST_NAME]: firstName(formatMessage),
    [FieldNames.LAST_NAME]: lastName(formatMessage),
    [FieldNames.EMAIL]: Yup.string()
      .email()
      .required(formatMessage({ id: 'validation.required' })),
    [FieldNames.PHONE_NUMBER]: phoneNumber(formatMessage).required(
      formatMessage({ id: 'validation.required' })
    ),
    [FieldNames.ADDRESS_COMMENT]: Yup.string(),
    [FieldNames.FLOOR_NUMBER]: floorNumber(formatMessage),
    [FieldNames.ELEVATOR]: Yup.boolean().required(formatMessage({ id: 'validation.required' })),
    contactPerson: ContactPersonSchema(formatMessage),
  }

  const shape: { [key: string]: Yup.Schema<unknown, unknown> } = {}

  if (hideFields || disableList) {
    for (const key in baseShape) {
      if (Object.prototype.hasOwnProperty.call(baseShape, key)) {
        const value = baseShape[key as FieldNames]
        if (
          isFieldVisible(key as FieldNames, hideFields) &&
          !isFieldDisabled(key as FieldNames, disableList)
        ) {
          shape[key] = value
        }
      }
    }
  }

  return Yup.object().shape(hideFields || disableList ? shape : baseShape)
}
