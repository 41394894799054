import { Button } from '@chakra-ui/react'
import { OrderLineWithFullProductAndDiscount } from 'api-client'
import React from 'react'
import { useIntl } from 'react-intl'
import { sanitizeStripeAmount } from 'utils/price-utils'

type Props = {
  onClick: () => void
  isCheckingFlexAddress: boolean
  changeAddressButtonTextId: string
  flexOneTimeOrderline?: OrderLineWithFullProductAndDiscount
}
export const ChangeAddressButton = ({
  onClick,
  isCheckingFlexAddress,
  changeAddressButtonTextId,
  flexOneTimeOrderline,
}: Props) => {
  const { formatMessage } = useIntl()
  return (
    <Button
      onClick={onClick}
      isLoading={isCheckingFlexAddress}
      width="100%"
      size="lg"
      variant="outline"
      colorScheme="gray"
      my="3"
      fontWeight="medium"
    >
      {formatMessage({ id: changeAddressButtonTextId })}
      {flexOneTimeOrderline ? ` ${sanitizeStripeAmount(flexOneTimeOrderline.product.price)},-` : ''}
    </Button>
  )
}
