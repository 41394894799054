import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  ChevronDownIcon,
  HandPalmIcon,
  MedalIcon,
  QuestionIcon,
  TrashIcon,
  WandaLogoShortIcon,
  useWandaToast,
} from '@wanda-space/noelle'
import { Auth0Id } from '@wanda-space/types'
import { getPossibleDuplicateUsers, mergeUsers } from 'api-client/lib/routes/user'
import { HelpFooter } from 'components/HelpFooter'
import { addMonths } from 'date-fns'
import { motion } from 'framer-motion'
import { useAppSelector } from 'hooks/useAppSelector'
import { Connection, useAuth } from 'hooks/useAuth'
import { User } from 'interfaces/user'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { FormatMessage } from 'utils'
import { AccountMergingLoadingModal } from './AccountMergingLoadingModal'

const WaveHand = () => {
  return (
    <motion.div
      animate={{ rotate: [-10, 0, -5, 5, -5, 0, -10], translateX: [0, 1, -1, 1, -1, 1, 0] }}
      transition={{ duration: 0.8, repeat: 1 }}
      style={{ originX: 'center', originY: '100%', display: 'inline-block' }}
    >
      <HandPalmIcon />
    </motion.div>
  )
}

const getMenuTextByLoginType = (userId: Auth0Id, formatMessage: FormatMessage) => {
  if (userId.startsWith('sms')) {
    return formatMessage({ id: 'phrase.logged.in.with.sms' })
  } else if (userId.startsWith('google-oauth2')) {
    return formatMessage({ id: 'phrase.logged.in.with.google' })
  } else {
    return formatMessage({ id: 'phrase.logged.in.with.username.password' })
  }
}

const getConnectionType = (userId: Auth0Id) => {
  if (userId.startsWith('sms')) {
    return Connection.SMS
  } else if (userId.startsWith('google-oauth2')) {
    return Connection.GOOGLE
  }

  return Connection.USERNAME_PASSWORD
}

export const AccountMergeModal = () => {
  const user = useAppSelector((state) => state.user.user)
  const [isMerging, setIsMerging] = useState(false)
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { authenticateSecondaryUser, renewSession } = useAuth()
  const [isSmallerThan750] = useMediaQuery('(max-width: 750px)')
  const toast = useWandaToast({ durationInMilliSecond: 9000, position: 'bottom' })
  const { formatMessage } = useIntl()

  const getUsersWithSameEmail = useQuery(
    ['usersWithSameEmail', user?.email],
    () => getPossibleDuplicateUsers(user?.email!),
    { enabled: !!user?.email }
  )

  const userFilterPredicate = (u: User) => user?.id && u.id !== user.id
  const usersToBeMerged = getUsersWithSameEmail.data?.filter(userFilterPredicate) ?? []

  if (usersToBeMerged.length > 0 && !isOpen) {
    onOpen()
  } else if (usersToBeMerged.length === 0 && isOpen) {
    onClose()
  }

  const queryClient = useQueryClient()

  const accountMergeStatus = localStorage.getItem('accountMerge')
  let doNothing = true

  try {
    const doNothingExpiryDateTimestamp =
      accountMergeStatus?.startsWith('doNothing') && accountMergeStatus?.split('-')[1]
    doNothing = Boolean(
      doNothingExpiryDateTimestamp && new Date().getTime() < parseInt(doNothingExpiryDateTimestamp)
    )
  } catch (e) {
    doNothing = true
  }

  if (usersToBeMerged.length === 0 || doNothing) {
    return null
  }

  const handleMerge = async (userId: Auth0Id) => {
    const accesToken = await authenticateSecondaryUser(getConnectionType(userId))

    if (!accesToken) {
      toast({
        title: formatMessage({ id: 'message.failed.to.merge.user' }),
        description: formatMessage({ id: 'message.failed.to.merge.user.detail' }),
        status: 'error',
        isClosable: true,
        variant: 'subtle',
      })

      captureException(
        `Failed to authenticate secondary user while trying to merge secondary user: ${userId} into primary user: ${user?.id}`
      )

      return
    }

    try {
      setIsMerging(true)
      await mergeUsers(accesToken)
      await renewSession()
      const newUserList = await getPossibleDuplicateUsers(user?.email!)
      const newUsersToBeMerged = newUserList.filter(userFilterPredicate)

      if (newUsersToBeMerged.length > 0) {
        toast({
          title: formatMessage({ id: 'toast.title.has.more.users.to.merge' }),
          description: formatMessage({ id: 'toast.description.has.more.users.to.merge' }),
          status: 'info',
          isClosable: true,
          variant: 'subtle',
        })
      } else {
        toast({
          title: formatMessage({ id: 'toast.title.success.users.merge' }),
          description: formatMessage({ id: 'toast.description.success.users.merge' }),
          status: 'success',
          isClosable: true,
          variant: 'subtle',
        })
        localStorage.removeItem('accountMerge')
      }

      await queryClient.invalidateQueries(['usersWithSameEmail'])
      await getUsersWithSameEmail.refetch()
    } catch (err) {
      toast({
        title: formatMessage({ id: 'toast.title.failed.users.merge' }),
        description: formatMessage({ id: 'toast.description.failed.users.merge' }),
        status: 'error',
        isClosable: true,
        variant: 'subtle',
      })

      captureException(err)
      localStorage.removeItem('accountMerge')
    } finally {
      setIsMerging(false)
    }
  }

  const doNothingHandler = () => {
    const noNothingForAMonth = addMonths(new Date().getTime(), 1).getTime()
    localStorage.setItem('accountMerge', `doNothing-${noNothingForAMonth}`)
    onClose()
  }

  return (
    <>
      <Modal
        isCentered
        onClose={onClose}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        motionPreset="slideInBottom"
        size={isSmallerThan750 ? 'full' : 'xl'}
        scrollBehavior={isSmallerThan750 ? 'inside' : undefined}
      >
        <ModalOverlay backdropFilter="blur(3px) hue-rotate(90deg)" bg="blackAlpha.500" />
        <ModalContent
          borderRadius={isSmallerThan750 ? undefined : 20}
          width={isSmallerThan750 ? undefined : '90%'}
          maxWidth={isSmallerThan750 ? undefined : '60em'}
        >
          <ModalBody p={[5, 5, 10]}>
            <Flex justifyContent="space-between" fontSize="xl" fontWeight="medium">
              <Box>
                <WaveHand /> {formatMessage({ id: 'word.hi.there' })}{' '}
                {user?.address?.firstName ?? ''}
              </Box>
              <WandaLogoShortIcon textAlign="right" w={6} h={6} color="gray.200" />
            </Flex>
            <Text color="gray.700" my="6">
              {formatMessage({ id: 'phrase.merge.users.info' })}
            </Text>
            <Flex
              direction={isSmallerThan750 ? 'column' : 'row'}
              justifyContent="space-between"
              gap="4"
            >
              <Box
                flex={1}
                bg="gray.50"
                border="1px solid"
                borderColor="gray.100"
                px="4"
                py="8"
                textAlign="center"
                borderRadius={14}
              >
                <MedalIcon w={14} h={14} mt="1" mb="4" color="purple.600" />
                <Text color="gray.700" mb="7">
                  {formatMessage({ id: 'option.merge.users' })}
                </Text>
                <Box>
                  <Menu>
                    <MenuButton
                      as={Button}
                      borderRadius="10"
                      colorScheme="purple"
                      variant="outline"
                      px="4"
                      rightIcon={<ChevronDownIcon color="currentcolor" />}
                    >
                      {formatMessage({ id: 'word.merge.users' })}
                    </MenuButton>
                    <MenuList>
                      {usersToBeMerged.map((u) => (
                        <MenuItem key={u.id} onClick={() => handleMerge(u.userId)}>
                          {getMenuTextByLoginType(u.userId, formatMessage)}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </Box>
              </Box>
              <Box
                flex={1}
                bg="gray.50"
                border="1px solid"
                borderColor="gray.100"
                px="4"
                py="8"
                textAlign="center"
                borderRadius={14}
              >
                <TrashIcon w={14} h={14} mt="1" mb="4" color="purple.600" />
                <Text mb="7" color="gray.700">
                  {formatMessage({ id: 'option.delete.users' })}
                </Text>
                <Box marginTop={-5}>
                  <HelpFooter />
                </Box>
              </Box>
              <Box
                flex={1}
                bg="gray.50"
                border="1px solid"
                borderColor="gray.100"
                p="8"
                textAlign="center"
                borderRadius={14}
              >
                <QuestionIcon w={14} h={14} mt="1" mb="4" color="purple.600" />
                <Text color="gray.700" mb="7">
                  {formatMessage({ id: 'option.do.nothing' })}
                </Text>
                <Button
                  borderRadius="10"
                  colorScheme="purple"
                  variant="outline"
                  onClick={doNothingHandler}
                >
                  {formatMessage({ id: 'word.do.nothing' })}
                </Button>
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      {isMerging && <AccountMergingLoadingModal />}
    </>
  )
}
