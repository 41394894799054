import {
  Alert,
  AlertIcon,
  Box,
  Container,
  Heading,
  Link,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { DynamicIconName } from '@wanda-space/noelle'
import { AccountId, type ItemResponseDto, toWandaId } from '@wanda-space/types'
import { fetchSellableItemsForListing, unshareItem } from 'api-client'
import { ItemForm } from 'components/Item'
import { ShareInfoModal } from 'components/Modals/ShareInfoModal/ShareInfoModal'
import { Routes } from 'consts'
import { useAppSelector } from 'hooks/useAppSelector'
import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router'
import { useAppDispatch } from 'reduxStore'
import { setItems } from 'reduxStore/ducks/returnFlow/reducers'

import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { ServiceButton } from 'routes/ServiceFlow/ServiceFromStorage/ServiceButton'
import { useImageHook } from '../hooks'
import { NotSharedInfoBox } from './NotSharedInfoBox'
import { SellInfoBox } from './SellInfoBox'
import { ServiceInfoBox } from './ServiceInfoBox'
import { SharedInfoBox } from './SharedInfoBox'

interface Props {
  item: ItemResponseDto
}

const Stored = ({ item }: Props) => {
  const [showStopSharingModal, setShowStopSharingModal] = useState<boolean>(false)
  const [showStopSharingSuccess, setShowStopSharingSuccess] = useState<boolean>(false)
  const [showStartSharingSuccess, setShowStartSharingSuccess] = useState<boolean>(false)
  const [shareToRemove, setShareToRemove] = useState<{
    displayName: string
    accountId: AccountId
  } | null>(null)
  const [showError, setShowError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { isLoading: imageLoading, isError, isSuccess, addImage } = useImageHook()

  const queryClient = useQueryClient()
  const { formatMessage } = useIntl()
  const loc = useLocation()
  const accountId = useAppSelector((state) => state.user.user?.id)
  const featureFlags = useFeatureFlags()

  const mounted = useRef(false)

  const { data: sellableItemsResponse, isLoading: isSellableItemResponseLoading } = useQuery(
    ['fetchSellableListingItems', accountId],
    () =>
      fetchSellableItemsForListing({
        page: 0,
        itemsPerPage: 1000,
      })
  )

  const isSellable = Boolean(sellableItemsResponse?.items.find(({ id }) => id === item.id))

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  const handleStartReturnFlow = (selectedItems: ItemResponseDto[]) => {
    dispatch(setItems(selectedItems))

    navigate(Routes.ReturnFlow)
  }

  const handleStopSharing = async () => {
    // @ts-ignore should we remove this check?
    if (shareToRemove) {
      setIsLoading(true)
      try {
        await unshareItem(item.id, toWandaId(shareToRemove.accountId))

        queryClient.invalidateQueries(['item'])
        setShowStopSharingModal(false)
        setShowStopSharingSuccess(true)

        setTimeout(() => {
          if (mounted.current === true) setShowStopSharingSuccess(false)
        }, 3000)
      } catch (err: any) {
        setShowError(true)
        captureException(err)
      }
      setIsLoading(false)
    }
  }

  useEffect(() => {
    //TODO: how to type properly
    // @ts-ignore:next-line
    if (loc?.state?.shareSuccess as boolean) {
      setShowStartSharingSuccess(true)
      setTimeout(() => {
        if (mounted.current === true) setShowStartSharingSuccess(false)
      }, 3000)
    }
  }, [])

  if (isLoading || isSellableItemResponseLoading || imageLoading || featureFlags.isInitialLoading) {
    return <Spinner />
  }

  const isOwner = accountId === item.ownerId

  // @ts-ignore
  return (
    <>
      {isOwner && (
        <>
          {showStopSharingSuccess && (
            <Box width="90%" maxW="560px" m="2">
              <Alert
                bgColor="blue.50"
                status="info"
                border="1px solid"
                borderColor="blue.300"
                borderRadius="md"
              >
                <AlertIcon />
                <Text>{formatMessage({ id: 'sharing.stopped.feedback' })}</Text>
              </Alert>
            </Box>
          )}
          {showStartSharingSuccess && (
            <Box width="90%" maxW="560px" m="2">
              <Alert
                bgColor="green.50"
                status="success"
                border="1px solid"
                borderColor="green.300"
                borderRadius="md"
              >
                <AlertIcon />
                <Text>{formatMessage({ id: 'notification.sharingSuccess.title' })}</Text>
              </Alert>
            </Box>
          )}

          {showError && (
            <Box width="90%" maxW="560px" m="2">
              <Alert
                bgColor="red.50"
                status="error"
                border="1px solid"
                borderColor="red.300"
                borderRadius="md"
              >
                <AlertIcon />
                <Text>{formatMessage({ id: 'error.general' })}</Text>
              </Alert>
            </Box>
          )}

          {showStopSharingModal && (
            <ShareInfoModal
              buttonOneText={formatMessage({ id: 'sharing.stop' })}
              buttonTwoText={formatMessage({ id: 'sharing.continue' })}
              data-testid="stop-sharing-modal"
              description={formatMessage({ id: 'sharing.revokeAccessInfo' })}
              onClose={() => setShowStopSharingModal(false)}
              onExit={() => setShowStopSharingModal(false)}
              onPrimaryClick={handleStopSharing}
              show={showStopSharingModal}
              title={formatMessage({ id: 'sharing.wantToStopSharing' })}
              variant="warningInfo"
              variantTwo="lightestPurple"
            />
          )}
        </>
      )}

      <ItemForm item={item} isError={isError} isSuccess={isSuccess} addImage={addImage} />
      <Container>
        <VStack spacing="4">
          {isOwner && (
            <>
              {isSellable ? <SellInfoBox itemId={item.id} /> : null}
              <NotSharedInfoBox itemId={item.id} />
              {/* @ts-ignore shareWith is under process to be removed */}
              {item.sharedWithAccounts && item.sharedWithAccounts.length > 0 && (
                <SharedInfoBox
                  itemId={item.id}
                  sharedWithAccounts={
                    /* @ts-ignore shareWith is under process to be removed */
                    item.sharedWithAccounts
                  }
                  handleStopSharing={(share) => {
                    setShareToRemove(share)
                    setShowStopSharingModal(true)
                  }}
                />
              )}

              {featureFlags.data?.ENABLE_SPACESHIP_FLOW_SERVICE_FROM_STORAGE && (
                <ServiceButton itemIds={[item.id]} />
              )}
            </>
          )}

          <Link w="100%" onClick={() => handleStartReturnFlow([item])}>
            <ServiceInfoBox
              title={formatMessage({ id: 'item.returnFlow.title' })}
              description={formatMessage({ id: 'item.returnFlow.description' })}
              leftIconName={DynamicIconName.returnBox}
            />
          </Link>
        </VStack>
      </Container>
    </>
  )
}

export { Stored }
