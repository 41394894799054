import { captureException } from '@sentry/react'
import { SupportedCountries } from '@wanda-space/types'
import { useAppSelector } from 'hooks/useAppSelector'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router'

import { Routes } from './consts'

const domain = 'https://beacon-v2.helpscout.net'

const loadScript = () => {
  // @ts-ignore third party code
  !(function (e: any, t: any, n: any) {
    function a() {
      const e = t.getElementsByTagName('script')[0],
        n = t.createElement('script')

      ;(n.async = !0), (n.src = domain), e.parentNode.insertBefore(n, e)
    }
    if (
      ((e.Beacon = n =
        function (t: any, n: any, a: any) {
          e.Beacon.readyQueue.push({ method: t, options: n, data: a })
        }),
      (n.readyQueue = []),
      'complete' === t.readyState)
    )
      return a()
    e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1)

  })(window, document, (window as any).Beacon || function () {})
}

const getBeaconId = (country: SupportedCountries, pathname: string) => {
  try {
    const parentRoute =
      pathname.indexOf('/') === pathname.lastIndexOf('/')
        ? pathname
        : pathname.substring(0, pathname.substring(1).indexOf('/') + 1)
    // @ts-ignore the file
    const beaconIdsMap = {
      [SupportedCountries.SE]: {
        [Routes.Space]: '48338c69-d4ac-4510-9023-845abd8613c0',
        [Routes.Shop]: 'ef269816-7125-4d28-9e35-db60cdd16f6e',
        [Routes.Buy]: '28bf8e6b-dc60-45a9-a724-ada3937549f9',
        [Routes.Sell]: '28bf8e6b-dc60-45a9-a724-ada3937549f9',
        [Routes.ServicesNext]: '1b5c4048-49c2-4ba9-b097-4138d68c3b6b',
        [Routes.Storage]: '44279ce8-b254-4340-a428-c2fc9628b39d',
      },
      [SupportedCountries.NO]: {
        [Routes.Space]: 'c16665dc-71a7-4939-9e5f-92429fdb4e01',
        [Routes.Shop]: 'fa161d53-f709-4b8a-ac30-e210261b9cf1',
        [Routes.Sell]: 'b8020f1d-bfcf-49e6-b719-fdff97f10807',
        [Routes.Buy]: 'b8020f1d-bfcf-49e6-b719-fdff97f10807',
        [Routes.ServicesNext]: '02a1c460-5a41-43d3-a111-02e6a2018e25',
        [Routes.Storage]: 'b9c9b786-a40c-4864-a881-2c022c16d13e',
      },
    }
    return beaconIdsMap[country][parentRoute] || beaconIdsMap[country][Routes.Space]
  } catch (e) {
    return ''
  }
}

export const InitHelpscout = () => {
  const currentBeaconId = useRef<string>('')
  const location = useLocation()
  const country = useAppSelector((state) => state.ui.country)

  useEffect(() => {
    try {
      if (country && location) {
        const beaconId = getBeaconId(country, location.pathname)
        if (beaconId && beaconId !== currentBeaconId?.current) {
          // @ts-ignore unknown window property
          if (!currentBeaconId?.current) {
            loadScript()
            // @ts-ignore unknown window property
            window.Beacon('config', {
              hideFABOnMobile: false,
            })
          }
          // @ts-ignore unknown window property
          window.Beacon('init', beaconId)
        }
      }
    } catch (error) {
      captureException(error)
    }
  }, [country, location])

  return null
}


function loadIntercomScript() {
  // @ts-ignore third party code
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/jb6lv3l5';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
}

export const InitIntercom = () => {
  const user = useAppSelector((state) => state.user.user)

  useEffect(() => {
    try {
      loadIntercomScript()
      // @ts-ignore unknown window property
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "jb6lv3l5",
        name: user?.address?.firstName,
        email: user?.email,
      });
    } catch (error) {
      captureException(error)
    }
  }, [user])

  return null
}


