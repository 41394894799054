export const joinString = (s: string | string[] | null | undefined, separator = ''): string => {
  if (s === null || s === undefined) {
    return ''
  }

  if (Array.isArray(s)) {
    return s.join(separator)
  }

  return s
}
