import { captureException } from '@sentry/react'
import { useElements } from '@stripe/react-stripe-js'
import { PaymentMethodCreateParams, StripeElementsOptions, StripeError } from '@stripe/stripe-js'
import { UseMutationResult, UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'
import { SetupIntentResponseDto } from '@wanda-space/types'
import { type CustomerPaymentMethod, createSetupIntent, getPaymentMethods } from 'api-client'
import {
  AuthenticationError,
  MissingStripeWhenSubmittingError,
  UnknownPaymentError,
  UnsuccessfulSetupIntentError,
} from 'components/PaymentOptions/PaymentError'
import { useStripeContext } from 'contexts/stripe'
import { useState } from 'react'

import { useAppSelector } from './useAppSelector'
import { useStripeRedirectHandler } from './useStripeRedirectHandler'

export interface UsePaymentElementInputs {
  setupPaymentIntent?: () => Promise<SetupIntentResponseDto>
  options: StripeElementsOptions
}

type UsePaymentElement = (input: UsePaymentElementInputs) => {
  paymentError: StripeError | UnknownPaymentError | AuthenticationError | undefined
  paymentLoading: boolean
  defaultPaymentMethod?: CustomerPaymentMethod
  paymentIntentMutation: UseMutationResult<SetupIntentResponseDto, unknown, void, unknown>
  handleConfirmPayment: () => Promise<void>
  paymentMethodsResult: UseQueryResult<CustomerPaymentMethod[], unknown>
  hasPaymentSucceded: boolean
  intentId: string | undefined
  handleConfirmPaymentByPaymentMethod: (paymentMethodId: string) => Promise<void>
}

export const usePaymentElement: UsePaymentElement = ({
  setupPaymentIntent = createSetupIntent,
  options,
}) => {
  const user = useAppSelector((state) => state.user.user)
  const ui = useAppSelector((state) => state.ui)
  const { stripe } = useStripeContext()
  const elements = useElements()
  const { hasPaymentSucceded, intentId } = useStripeRedirectHandler()
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [paymentError, setPaymentError] = useState<
    StripeError | UnknownPaymentError | AuthenticationError | undefined
  >()

  const paymentIntentMutation = useMutation(['setupPaymentIntent', user?.id], async () => {
    return await setupPaymentIntent()
  })

  const paymentMethodsResult = useQuery(['getPaymentMethods', user?.id], () => getPaymentMethods())

  let defaultCustomerPaymentMethod: CustomerPaymentMethod | undefined = undefined

  const { data: paymentMethods = [] } = paymentMethodsResult

  if (paymentMethods.length) {
    const defaultPaymentMethod = paymentMethods.find((paymentMethod) => paymentMethod.default)
    defaultCustomerPaymentMethod = defaultPaymentMethod || paymentMethods[0]
  }

  const handleConfirmPayment = async () => {
    try {
      if (!stripe || !elements) {
        captureException(new MissingStripeWhenSubmittingError())
        return
      }

      setPaymentLoading(true)

      const submitToStripeResult = await elements.submit()

      if (submitToStripeResult.error) {
        throw submitToStripeResult.error
      }

      const { clientSecret } = await paymentIntentMutation.mutateAsync()

      if (!clientSecret) {
        throw new UnsuccessfulSetupIntentError()
      }

      const billingDetails: PaymentMethodCreateParams.BillingDetails = {
        address: {
          country: user?.countryCode ?? ui.country,
          postal_code: user?.address?.postalCode,
          city: user?.city ?? ui.city,
        },
        email: user?.email,
        name: `${user?.address?.firstName} ${user?.address?.lastName}`,
        phone: user?.address?.phoneNumber,
      }

      const confirmParams = {
        return_url: `${window.location.origin}${location.pathname}`,
        payment_method_data: {
          billing_details: billingDetails,
        },
      }

      if (options.mode === 'payment') {
        const { error } = await stripe.confirmPayment({
          elements,
          clientSecret,
          confirmParams,
        })
        if (error) {
          throw error
        }
      } else {
        const { error } = await stripe.confirmSetup({
          elements,
          clientSecret,
          confirmParams,
        })

        if (error) {
          throw error
        }
      }

      setPaymentLoading(false)
    } catch (error: unknown) {
      setPaymentError(error as StripeError)
      captureException(error)
    } finally {
      setPaymentLoading(false)
    }
  }

  const handleConfirmPaymentByPaymentMethod = async (paymentMethodId: string) => {
    try {
      if (!stripe || !elements) {
        captureException(new MissingStripeWhenSubmittingError())
        return
      }

      setPaymentLoading(true)

      const { clientSecret } = await paymentIntentMutation.mutateAsync()

      if (!clientSecret) {
        throw new UnsuccessfulSetupIntentError()
      }

      const confirmParams = {
        return_url: `${window.location.origin}${location.pathname}`,
        payment_method: paymentMethodId,
      }

      if (options.mode === 'payment' && paymentMethodId) {
        const { error } = await stripe.confirmPayment({
          clientSecret,
          confirmParams,
        })
        if (error) {
          throw error
        }
      }
    } catch (error) {
      setPaymentError(error as StripeError)
      captureException(error)
      throw error
    } finally {
      setPaymentLoading(false)
    }
  }

  return {
    paymentError: paymentError,
    paymentLoading: paymentLoading,
    defaultPaymentMethod: defaultCustomerPaymentMethod,
    paymentMethodsResult,
    paymentIntentMutation,
    handleConfirmPayment,
    hasPaymentSucceded,
    intentId,
    handleConfirmPaymentByPaymentMethod,
  }
}
