import { CategoryItemCounter } from '@wanda-space/noelle'
import { CountObject } from '@wanda-space/noelle/dist/components/ItemCounterGroup'
import {
  type ItemResponseDto,
  OrderContext,
  PaginatedResponseDto,
  type ProductCategoryResponseDto,
  StorageItemType,
  type StorageItemsForOrderlinesRequest,
} from '@wanda-space/types'
import { type OrderLinePayload, type Product } from 'api-client/lib/types'
import { type PickerItemsAndOrderLines } from 'interfaces'
import { type ItemPayloadWithProduct } from 'reduxStore/commonMappers'
import { type ItemPayloadWithProductAndListingData } from 'routes/BuySellFlow/common/ListingPreview'
import { v4 as uuid } from 'uuid'

import { FormatMessage } from './format'

export function countByItemType<InputType extends { type: StorageItemType }>(items: InputType[]) {
  const result: Record<StorageItemType, number> = { SMALL: 0, BOX: 0, LARGE: 0 }
  return items.reduce((map, item) => {
    map[item.type] = map[item.type] + 1
    return map
  }, result)
}

export const generateCounterFromItems = (
  items: ItemPayloadWithProduct[],
  formatMessage: FormatMessage
) => {
  const counter: CategoryItemCounter = {}

  items.forEach((item) => {
    const categoryId = item.storageProduct.categoryId
    if (categoryId) {
      const categoryCounter = counter[categoryId]

      if (categoryCounter?.[item.storageProduct.id]) {
        const productCountObject = categoryCounter[item.storageProduct.id]
        productCountObject.count++
      } else {
        counter[categoryId] = {
          ...counter[categoryId],
          [item.storageProduct.id]: {
            count: 1,
            itemType: item.type,
            productName: formatMessage({ id: `${item.storageProduct.localizationKey}.name` }),
          },
        }
      }
    }
  })
  return counter
}

export const generateItemsAndOrderLines = (
  counter: CategoryItemCounter,
  productsById: Record<string, Product>,
  existingItems?: ItemResponseDto[]
): PickerItemsAndOrderLines => {
  const pickerItems: ItemPayloadWithProduct[] = []
  const orderLines: OrderLinePayload[] = []
  for (const categoryId in counter) {
    if (Object.prototype.hasOwnProperty.call(counter, categoryId)) {
      const category = counter[categoryId]
      for (const productId in category) {
        const productForCategory: CountObject = category[productId]
        const existingItemsForProduct = (existingItems || []).filter((item) => {
          return item.storageProduct?.id === productId
        })
        for (let i = 0; i < productForCategory.count; i++) {
          const counterNumber = existingItemsForProduct.length + i + 1
          const item = {
            id: uuid(),
            name: `${productForCategory.productName} #${counterNumber}`,
            type: category[productId].itemType as StorageItemType,
            storageProduct: productsById[productId],
          }

          pickerItems.push(item)
          orderLines.push({
            productId,
            quantity: 1,
            item,
          })
        }
      }
    }
  }

  return {
    orderLines,
    pickerItems,
  }
}

export function updateItemStorageProducts(
  items: ItemResponseDto[],
  productsById: Record<string, Product>,
  baseStorageProductByItemType: Record<StorageItemType, Product>
): ItemResponseDto[] {
  return items.map((item) =>
    item.storageProduct?.id && productsById[item.storageProduct.id]
      ? {
          ...item,
          storageProduct: productsById[item.storageProduct.id],
        }
      : {
          ...item,
          storageProduct: baseStorageProductByItemType[item.type],
        }
  )
}

export const getItemCategory = (
  item: ItemResponseDto | ItemPayloadWithProduct,
  categories: PaginatedResponseDto<ProductCategoryResponseDto> | undefined
) => categories?.items?.find((category) => category.id === item.storageProduct?.categoryId)

export function getItemPayloadsFromItems(
  items: (ItemPayloadWithProduct | ItemResponseDto)[],
  orderContextOverride?: OrderContext
): StorageItemsForOrderlinesRequest[] {
  return items.map(({ id, name, type, storageProduct, orderContext }) => {
    const _orderContext = orderContextOverride || orderContext
    if (!_orderContext) throw new Error('Order context is required')

    return {
      id,
      name,
      type,
      orderContext: _orderContext,
      productId: storageProduct.id as string,
    }
  })
}

export function getListingItemsFromItem(
  item: ItemPayloadWithProductAndListingData | ItemResponseDto
): ItemPayloadWithProduct {
  const { id, name, type, storageProduct, orderContext, image } = item
  return {
    id,
    name,
    type,
    storageProduct: storageProduct as Product,
    image,
    orderContext,
  }
}
