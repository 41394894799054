import { Box, Button, Flex, Heading, Link, Text } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import WandaLogoLongIcon from '@wanda-space/noelle/illustrations/wanda+space.svg'
import { AuthForm } from 'components/auth-page-components/AuthForm'
import { RocketImage } from 'components/auth-page-components/RocketImage'
import { Routes } from 'consts'
import { useDocumentTitle } from 'hooks'
import { useAuth } from 'hooks/useAuth'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Navigate, useNavigate } from 'react-router-dom'

interface Proptypes {
  registerButtonHandler?: () => void
  hasHeroImage?: boolean
  onAuthSuccessHandler?: () => void
}

export const InitialValues = { email: '', password: '' }

export const Login = ({
  registerButtonHandler,
  hasHeroImage = true,
  onAuthSuccessHandler,
}: Proptypes) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { login, loginSocial, isAuthenticated } = useAuth()
  const [authError, setAuthError] = useState<string | undefined>()
  const [socialAuthError, setSocialAuthError] = useState<string | undefined>()
  const { mutate: loginSocialMutate, isLoading: isLoadingSocial } = useMutation(loginSocial)
  const { mutate: loginMutate, isLoading } = useMutation(login)

  const handleLogin = (data: typeof InitialValues) => {
    loginMutate(data, {
      onError: (err) => {
        const error = (err as { error_description: string })?.error_description
        setAuthError(error)
      },
      onSuccess: onAuthSuccessHandler,
    })
  }

  if (isAuthenticated) {
    return <Navigate to={Routes.Space} />
  }

  const googleLogin = () => {
    loginSocialMutate('google-oauth2', {
      onError: (err) => {
        const error = err as { message?: string; description?: string }
        setSocialAuthError(error.message || error.description)
      },
      onSuccess: onAuthSuccessHandler,
    })
  }
  useDocumentTitle(formatMessage({ id: 'word.login' }))
  sessionStorage.removeItem('accountMerge')

  return (
    <Flex width="100%" gap="10" px={hasHeroImage ? '8' : 0} py="0">
      <Box width={hasHeroImage ? ['100%', '100%', '50%'] : '100%'}>
        <Flex alignItems="center" justifyContent="space-between" height="10" mb={6}>
          <Heading as="h2" fontWeight="medium" fontSize="2xl">
            {formatMessage({ id: 'word.login' })}
          </Heading>
          <img alt="wanda logo long" src={WandaLogoLongIcon} width="115px" height="auto" />
        </Flex>
        <AuthForm
          context="login"
          isLoading={isLoading}
          isLoadingSocial={isLoadingSocial}
          socialAuthError={socialAuthError}
          authError={authError}
          submitBtnLokalizeId="word.login"
          onSocialClick={googleLogin}
          onAuthFormSubmit={handleLogin}
          forgotPasswordLink
        />
        <Flex direction="row" alignItems="center" gap={2} my={8} justifyContent="center">
          <Text fontWeight="500" fontSize="medium" color="ctaBlack.800">
            {formatMessage({ id: 'login.noAccount' })}
          </Text>
          <Button
            variant="link"
            as={Link}
            onClick={
              registerButtonHandler ? registerButtonHandler : () => navigate(Routes.Register)
            }
            size="small"
            textDecoration="underline"
            color="purple.700"
            fontWeight="500"
            data-testid="go-to-register"
            p={0}
          >
            {formatMessage({ id: 'register.createAccount' })}
          </Button>
        </Flex>
      </Box>
      {hasHeroImage && <RocketImage />}
    </Flex>
  )
}
