import React, { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { DynamicIconName, WSkeleton } from '@wanda-space/noelle'
import {
  ProductCategoryResponseDto,
  ServiceProviderResponseDto,
  SortOrderOptionsType,
} from '@wanda-space/types'
import { Product } from 'api-client'
import { fetchServiceProviders } from 'api-client/lib/routes/serviceProviders'
import { useProductsAndCategories } from 'hooks/useProductsAndCategories'
import { useIntl } from 'react-intl'
import { ServiceProviderCard } from 'routes/ServiceFlow/components/ServiceProviderCard'
import { sanitizeStripeAmount } from 'utils'

type ProviderOption = ProductCategoryResponseDto & { startingPrice: number } & {
  serviceProvider: ServiceProviderResponseDto
}

interface Props {
  selectedStorageProductCategoryIds?: ProductCategoryResponseDto['id'][]
  handleSelectProviderOption: (option: ProviderOption) => void
  hasServiceOrderlines?: boolean
  selectedCategoryId?: ProductCategoryResponseDto['id']
}

export const ServiceProviderSelector = ({
  selectedStorageProductCategoryIds,
  handleSelectProviderOption,
  hasServiceOrderlines,
  selectedCategoryId,
}: Props) => {
  const { formatMessage } = useIntl()
  const { serviceProducts, productCategories, isSuccess, isLoading } = useProductsAndCategories({
    sortOption: SortOrderOptionsType.SERVICE_FLOW,
  })

  const { data: serviceProvidersData, isInitialLoading } = useQuery(['service-providers'], () => {
    return fetchServiceProviders({ page: 0, itemsPerPage: 1000 })
  })

  const providerOptions: ProviderOption[] = useMemo(() => {
    if (
      isSuccess &&
      productCategories &&
      serviceProducts &&
      serviceProducts.length &&
      productCategories.length &&
      Array.isArray(serviceProvidersData?.items)
    ) {
      const activeCategories = productCategories.filter(
        (category) =>
          category.active && (selectedStorageProductCategoryIds?.includes(category.id) ?? true)
      )
      const usedCategoriesIds = serviceProducts.map((product: Product) => product.categoryId)
      const serviceProviderIds = (serviceProvidersData?.items ?? [])
        .filter((s) => s.active)
        .map((s) => s.id)

      return activeCategories
        .filter((category) => usedCategoriesIds.includes(category.id))
        .filter(
          (category) =>
            category.serviceProviderId && serviceProviderIds.includes(category.serviceProviderId)
        )
        .map((category) => ({
          ...category,
          startingPrice: sanitizeStripeAmount(
            serviceProducts
              .filter((sp) => sp.categoryId === category.id)
              .sort((spA, spB) => spA.price - spB.price)[0]?.price ?? 0
          ),
          serviceProvider: serviceProvidersData!.items.find(
            (s) => s.id === category.serviceProviderId
          )!,
        }))
    }
    return []
  }, [isSuccess, productCategories, serviceProducts, serviceProvidersData])

  if (isLoading || isInitialLoading) {
    return (
      <>
        {Array.from({ length: 2 }).map((_, index) => (
          <WSkeleton key={index} height="170px" width="350px" />
        ))}
      </>
    )
  }

  return (
    <>
      {providerOptions.map((option) => {
        return (
          <ServiceProviderCard
            key={option.id}
            startingPrice={option.startingPrice}
            icon={option.iconName as DynamicIconName}
            onClick={() => handleSelectProviderOption(option)}
            serviceProvider={option.serviceProvider}
            title={formatMessage({
              id: `${option.localizationKey}.service.name`,
            })}
            data-testid={`select-service-${option.name}`}
            isPreviouslySelected={option.id === selectedCategoryId && !!hasServiceOrderlines}
          />
        )
      })}
    </>
  )
}
