import { Flex } from '@chakra-ui/react'
import { Spinner } from '@wanda-space/noelle'
import { Routes } from 'consts'
import { useDocumentTitle } from 'hooks'
import { useAuth } from 'hooks/useAuth'
import React from 'react'
import { Navigate } from 'react-router-dom'

const Home = () => {
  useDocumentTitle()

  const { isAuthenticated, isAuthenticating } = useAuth()

  return (
    <Flex
      flex="1"
      direction="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      py={0}
      px={6}
      overflowY="scroll"
    >
      {isAuthenticating ? (
        <Spinner />
      ) : isAuthenticated ? (
        <Navigate to={Routes.Space} />
      ) : (
        <Navigate to={Routes.SelectFlow} />
      )}
    </Flex>
  )
}

export { Home }
