import { PostalCodeDto } from '@wanda-space/ops-types'
import {
  ConditionType,
  FeatureFlagResponseDto,
  SupportedCities,
  SupportedCountries,
} from '@wanda-space/types'
import { type FlattenedDeliveryInfo } from 'interfaces'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import * as Yup from 'yup'

import { FormatMessage } from './format'

export const validatePostalCode = (
  searchCode: string,
  postalCodes: PostalCodeDto[],
  countryCode: SupportedCountries,
  city: SupportedCities
) =>
  postalCodes.some(
    (pc) => pc.postalCode === searchCode && pc.country === countryCode && pc.city === city
  )

export const validateConditionType = (condition: string, conditionType: ConditionType) =>
  Object.keys(conditionType).some((c) => c === condition)

export const requiredString = (formatMessage: FormatMessage) =>
  Yup.string().required(formatMessage({ id: 'validation.required' }))
export const email = (formatMessage: FormatMessage) =>
  Yup.string()
    .email(formatMessage({ id: 'validation.invalidEmail' }))
    .required(formatMessage({ id: 'validation.required' }))

export const phoneNumber = (formatMessage: FormatMessage) => {
  return Yup.string()
    .matches(
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
      formatMessage({ id: 'validation.phonenumber' })
    )
    .test('is-valid-phoneNumber', formatMessage({ id: 'validation.phonenumber' }), (value) => {
      if (value) {
        // used by isPhoneNumber decorator in howls-castle
        return Boolean(parsePhoneNumberFromString(value)?.isValid())
      }
      return true
    })
}

export const firstName = (formatMessage: FormatMessage) => {
  return requiredString(formatMessage)
    .min(1, formatMessage({ id: 'validation.minLengthLetters' }, { length: 1 }))
    .max(50, formatMessage({ id: 'validation.maxLengthLetters' }, { length: 50 }))
}

export const lastName = (formatMessage: FormatMessage) => {
  return requiredString(formatMessage)
    .min(1, formatMessage({ id: 'validation.minLengthLetters' }, { length: 1 }))
    .max(50, formatMessage({ id: 'validation.maxLengthLetters' }, { length: 50 }))
}
export const floorNumber = (formatMessage: FormatMessage) =>
  Yup.number()
    .min(-10)
    .max(200)
    .required(formatMessage({ id: 'validation.required' }))

export const authSchema = (formatMessage: FormatMessage) =>
  Yup.object().shape({
    email: email(formatMessage),
    password: requiredString(formatMessage).min(
      8,
      formatMessage({ id: 'validation.minLengthLetters' }, { length: 8 })
    ),
  })

export const ResetPasswordSchema = (formatMessage: FormatMessage) =>
  Yup.object().shape({
    email: email(formatMessage),
  })

export const ProfileSchema = (formatMessage: FormatMessage) =>
  Yup.object().shape({
    firstName: requiredString(formatMessage)
      .min(1, formatMessage({ id: 'validation.minLengthLetters' }, { length: 1 }))
      .max(50, formatMessage({ id: 'validation.maxLengthLetters' }, { length: 50 })),
    lastName: requiredString(formatMessage)
      .min(1, formatMessage({ id: 'validation.minLengthLetters' }, { length: 1 }))
      .max(50, formatMessage({ id: 'validation.maxLengthLetters' }, { length: 50 })),
    companyName: Yup.string()
      .min(1, formatMessage({ id: 'validation.minLengthLetters' }, { length: 1 }))
      .max(50, formatMessage({ id: 'validation.maxLengthLetters' }, { length: 50 })),
  })

export const PhoneNumberSchema = (formatMessage: FormatMessage) =>
  Yup.object().shape({
    phoneNumber: phoneNumber(formatMessage),
  })

export const ChangePasswordSchema = (formatMessage: FormatMessage) =>
  Yup.object().shape({
    password: requiredString(formatMessage).min(
      6,
      formatMessage({ id: 'validation.minLengthLetters' }, { length: 6 })
    ),
    confirmNewPassword: requiredString(formatMessage).oneOf(
      [Yup.ref('password')],
      formatMessage({ id: 'validation.passwordDontMatch' })
    ),
  })

export const ListingDimensionFieldsSchema = (formatMessage: FormatMessage) => {
  return Yup.object().shape({
    dimensionLength: Yup.number()
      .min(1, formatMessage({ id: 'validation.dimensionLength' }))
      .optional(),
    dimensionWidth: Yup.number()
      .min(1, formatMessage({ id: 'validation.dimensionWidth' }))
      .optional(),
    dimensionHeight: Yup.number()
      .min(1, formatMessage({ id: 'validation.dimensionHeight' }))
      .optional(),
  })
}

export const ListingExtraDescriptiveInfoFieldsSchema = () => {
  return Yup.object().shape({
    brand: Yup.string().optional(),
    model: Yup.string().optional(),
    material: Yup.string().optional(),
    year: Yup.string().optional(),
    colour: Yup.string().optional(),
    comment: Yup.string().optional(),
  })
}

export const CreateListingSchema = (formatMessage: FormatMessage) => {
  return Yup.object()
    .shape({
      name: Yup.string()
        .required(formatMessage({ id: 'validation.required' }))
        .min(1, formatMessage({ id: 'validation.minLengthLetters' }, { length: 1 }))
        .max(50, formatMessage({ id: 'validation.maxLengthLetters' }, { length: 50 })),

      conditionType: Yup.string().required(formatMessage({ id: 'validation.required' })),

      images: Yup.array()
        .required(formatMessage({ id: 'validation.requiredImage' }))
        .min(1, formatMessage({ id: 'validation.minImages' })),

      price: Yup.object({
        amount: Yup.number()
          .required(formatMessage({ id: 'validation.required' }))
          .min(3, formatMessage({ id: 'validation.minAmount' })),
        currency: Yup.string().required(formatMessage({ id: 'validation.required' })),
      }),

      extraDescriptiveInfo: ListingExtraDescriptiveInfoFieldsSchema().optional(),
    })
    .concat(ListingDimensionFieldsSchema(formatMessage))
}

export const ContactPersonSchema = (formatMessage: FormatMessage) =>
  Yup.object().shape({
    name: Yup.string(),
    phoneNumber: phoneNumber(formatMessage),
  }) as Yup.Schema<unknown, unknown, any, ''>

export const validateDeliveryInfo = (address: FlattenedDeliveryInfo) =>
  Boolean(address.street && address.postalCode && address.city)

export const BidListingSchema = (formatMessage: FormatMessage) =>
  Yup.object().shape({
    amount: Yup.number().required(formatMessage({ id: 'validation.required' })),
  })
