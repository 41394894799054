import { Center, Container, Flex } from '@chakra-ui/react'
import { Spinner } from '@wanda-space/noelle'
import { useAppSelector } from 'hooks/useAppSelector'
import { useAuth } from 'hooks/useAuth'
import React, { Suspense, useState } from 'react'

import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { Layer } from '../styles/variables'
import { AuthenticatedNav } from './AuthenticatedNav/AuthenticatedNav'
import { AuthenticatedNavMenu } from './AuthenticatedNav/AuthenticatedNavMenu'
import { UnAutheticatedNav } from './UnAuthenticatedNav/UnAutheticatedNav'
import { AccountMergeModal } from './account-merge/AccountMergeModal'

type Props = {
  children: JSX.Element
}
const navHeight = '65px'
const footerHeight = '75px'

const contentHeight = `calc(100vh - ${footerHeight} - ${navHeight} - 30px)`

const Layout = ({ children }: Props) => {
  const { isMainNavFixed } = useAppSelector((state) => state.ui)
  const [displayExpandedMenu, setDisplayExpandedMenu] = useState<boolean>(false)
  const { data: featureFlags } = useFeatureFlags()
  const { isAuthenticated, isAuthenticating } = useAuth()
  const currentLocation = window.location.pathname
  const hasFooterAndHeader =
    currentLocation !== '/register' &&
    currentLocation !== '/login' &&
    currentLocation !== '/finish-logging-in'

  return (
    <Flex
      overflowX="hidden"
      minHeight="100%"
      width="100%"
      alignItems="center"
      flexDirection="column"
      backgroundColor="white"
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        width="100vw"
        background="white"
        position={isMainNavFixed ? 'fixed' : 'initial'}
        zIndex={displayExpandedMenu ? Layer.NavContentMenu : 99}
        fontWeight="500"
        height={navHeight}
        p={5}
        m="0"
      >
        <Suspense fallback={null}>
          {!hasFooterAndHeader || isAuthenticating ? null : isAuthenticated ? (
            <AuthenticatedNav
              onToggleShowMenu={() => setDisplayExpandedMenu(!displayExpandedMenu)}
              isMenuOpen={displayExpandedMenu}
            />
          ) : (
            <UnAutheticatedNav />
          )}
        </Suspense>
      </Flex>
      {/* Container here should have maxWidth dynamic based on buy boxes vs other pages and remove NarrowContainer component? */}
      <Container
        className="layout-container"
        padding={0}
        centerContent
        maxWidth="1184px"
        marginTop={isMainNavFixed ? '4.3rem' : 0}
        minHeight={contentHeight}
        paddingBottom={footerHeight} // Required so that floating footer doesn't float over content when scrolling all the way down in page
      >
        {isAuthenticating && currentLocation !== '/finish-logging-in' ? (
          <Center width="100%" height="100%">
            <Spinner />
          </Center>
        ) : (
          children
        )}
      </Container>
      {featureFlags?.ENABLE_USERS_MERGE && isAuthenticated ? <AccountMergeModal /> : null}
      <AuthenticatedNavMenu
        show={displayExpandedMenu}
        onDismiss={() => setDisplayExpandedMenu(false)}
      />
    </Flex>
  )
}

export { Layout }
