import { captureException } from '@sentry/react'
import { SupportedCountries } from '@wanda-space/noelle'
import { PostalCodeDto } from '@wanda-space/ops-types'
import { logoutUser, registerUser } from 'api-client/lib/routes/user'
import { Auth0Callback, Auth0Result, WebAuth } from 'auth0-js'
import {
  AUTH_RESULT,
  IS_LOGGED_IN,
  IS_NOTIFICATION_DISMISSED,
  Routes,
  WANDA_JWT_TOKEN,
  WANDA_LAST_ROUTE,
} from 'consts'
import { getEnv } from 'env'
import { mapStringToLocale } from 'i18n'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { pick } from 'ramda'
import React, { ReactNode, useContext, useMemo, useState } from 'react'
import TagManager from 'react-gtm-module'
import { useNavigate } from 'react-router'
import { useAppDispatch } from 'reduxStore'
import { clearItems } from 'reduxStore/ducks/item'
import { clearUser, fetchUser } from 'reduxStore/ducks/user/user'

import { useAppSelector } from './useAppSelector'
import { useFeatureFlags } from './useFeatureFlags'

export enum Connection {
  SMS = 'sms',
  USERNAME_PASSWORD = 'Username-Password-Authentication',
  GOOGLE = 'google-oauth2',
}
export interface UserSignupDetails {
  postalCode?: string
  email?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  countryCode?: SupportedCountries
}
interface Context {
  handleAuthResult: (options: {
    authResult: Auth0Result
    metadata?: UserSignupDetails
    handleRegistrationLoginSuccess?: () => Promise<void> | void
  }) => Promise<void>
  handleRedirect: () => Promise<void>
  isAuthenticated: boolean
  isAuthenticating: boolean
  login: (options: {
    email: string
    password: string
    postalCode?: PostalCodeDto
    handleRegistrationLoginSuccess?: () => Promise<void>
  }) => Promise<void>
  loginSocial: (provider: string) => Promise<void>
  logout: () => Promise<void>
  register: (options: {
    email: string
    password: string
    postalCode: PostalCodeDto
    handleRegistrationLoginSuccess?: () => Promise<void>
  }) => Promise<void>
  registerSocial: (provider: string, options: { postalCode: PostalCodeDto }) => Promise<void>
  renewSession: () => Promise<void>
  universalLogin: (
    connection: Connection,
    userDetails?: UserSignupDetails,
    handleRegistrationLoginSuccess?: () => Promise<void>
  ) => Promise<void>
  authenticateSecondaryUser: (connection: Connection) => Promise<string | undefined>
  renewAuth: () => Promise<void>
}

interface Props {
  children: ReactNode
}

interface AccessTokenPayload extends JwtPayload {
  [key: string]: string[] | string | number | undefined
}

interface AuthTokens {
  accessToken: string
  idToken: string
  refreshToken?: string
  accessTokenPayload: AccessTokenPayload
  idTokenPayload: JwtPayload
}

export const Auth0Context = React.createContext<Context | undefined>(undefined)
export const useAuth = (): Context => {
  const context = useContext(Auth0Context)
  if (!context) {
    throw new Error('Missing AuthProvider')
  }
  return context
}

const domain = getEnv('AUTH0_DOMAIN')
const clientID = getEnv('AUTH0_CLIENTID')
const audience = getEnv('AUTH0_AUDIENCE')
const namespace = getEnv('AUTH0_NAMESPACE')
const tenant = getEnv('AUTH0_TENANT')

const sendTagManagerEvent = (event: string, userId: string) => {
  TagManager.dataLayer({
    dataLayer: { event, userId },
  })
}

const getLastRouteFromLocalStorage = () => {
  return localStorage.getItem(WANDA_LAST_ROUTE)
}

const AuthProvider = ({ children }: Props) => {
  const navigate = useNavigate()
  const featureFlags = useFeatureFlags({ suspense: true })

  const client = useMemo(
    () =>
      new WebAuth({
        domain,
        clientID,
        audience,
        redirectUri: `${window.location.protocol}//${window.location.host}/login/callback`,
        responseType: 'token id_token',
        scope: 'openid',
      }),
    []
  )
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const dispatch = useAppDispatch()
  const locale = useAppSelector((state) => state.ui.language)

  const handleAuthResult: Context['handleAuthResult'] = async ({
    authResult,
    metadata,
    handleRegistrationLoginSuccess,
  }) => {
    if (authResult.idToken && authResult.accessToken) {
      const lastRoute = getLastRouteFromLocalStorage()
      const tokens: AuthTokens = {
        accessToken: authResult.accessToken,
        accessTokenPayload: jwtDecode(authResult.accessToken),
        idToken: authResult.idToken,
        idTokenPayload: jwtDecode(authResult.idToken),
        refreshToken: authResult.refreshToken,
      }
      // if user signed up in auth0 without providing email, collect it here
      if (!tokens.accessTokenPayload[`${namespace}/email`] && !metadata?.email) {
        localStorage.setItem(AUTH_RESULT, JSON.stringify({ authResult, metadata }))
        navigate(Routes.CollectEmail, { replace: true })
        return
      }
      localStorage.setItem(WANDA_JWT_TOKEN, authResult.accessToken)

      const wandaId = tokens.accessTokenPayload[`${namespace}/wanda_id`] as string
      if (
        !tokens.accessTokenPayload[`${namespace}/email`] ||
        (tokens.accessTokenPayload[`${namespace}/is_signup`] &&
          localStorage.getItem(`${wandaId}/registered`) !== 'true')
      ) {
        await registerUser(
          {
            ...metadata,
            locale: mapStringToLocale(locale),
          },
          tokens.accessToken,
          featureFlags.data
        )

        localStorage.setItem(`${wandaId}/registered`, 'true')
        sendTagManagerEvent('registerSuccess', wandaId)
      } else {
        sendTagManagerEvent('loginSuccess', wandaId)
      }

      handleRegistrationLoginSuccess && (await handleRegistrationLoginSuccess())

      navigate(lastRoute ? lastRoute : Routes.Space, { replace: true })
      localStorage.setItem(WANDA_LAST_ROUTE, '')
      localStorage.setItem(IS_LOGGED_IN, 'true')
      setIsAuthenticated(true)

      await dispatch(fetchUser()).unwrap()
      setIsAuthenticating(false)
    }
  }

  const login: Context['login'] = async ({
    email,
    password,
    postalCode,
    handleRegistrationLoginSuccess,
  }) => {
    const lastRoute = getLastRouteFromLocalStorage()
    const state = lastRoute ? lastRoute : Routes.Space
    const options = {
      email,
      password,
      state,
      popup: true,
      audience: `https://${tenant}.eu.auth0.com/api/v2/`,
      scope: 'openid profile email read:current_user update:current_user_metadata',
    }
    const authResult: Auth0Result = await promisify(client.login.bind(client))(options)

    await handleAuthResult({
      authResult,
      metadata: postalCode ? pick(['postalCode', 'countryCode'], postalCode) : undefined,
      handleRegistrationLoginSuccess,
    })
  }

  const universalLogin: Context['universalLogin'] = async (connection, userData) => {
    const authResult = await promisify(client.popup.authorize.bind(client.popup))({
      redirectUri: `${window.location.protocol}//${window.location.host}/login/callback`,
      connection,
      responseType: 'token id_token',
      domain,
      owp: true,
      audience: `https://${tenant}.eu.auth0.com/api/v2/`,
      scope: 'openid profile email read:current_user update:current_user_metadata',
    })
    await handleAuthResult({ authResult, metadata: userData })
  }

  const authenticateSecondaryUser = async (connection: Connection) => {
    const options = {
      redirectUri: `${window.location.protocol}//${window.location.host}/login/callback`,
      connection,
      domain,
      owp: true,
      responseType: 'token',
      audience: `https://${tenant}.eu.auth0.com/api/v2/`,
      scope: 'openid profile email read:current_user',
      prompt: 'login',
    }

    const newClient = new WebAuth({
      domain,
      clientID,
      audience,
      redirectUri: `${window.location.protocol}//${window.location.host}/login/callback`,
      responseType: 'token',
      scope: 'openid profile email',
    })

    const authResult: Auth0Result = await promisify(
      newClient.popup.authorize.bind(newClient.popup)
    )(options)

    return authResult.accessToken
  }

  const logout: Context['logout'] = async () => {
    try {
      await logoutUser()
    } catch (err) {
      // Intentionally empty
    }

    dispatch(clearUser())
    dispatch(clearItems())

    localStorage.setItem(WANDA_LAST_ROUTE, '')
    localStorage.removeItem(IS_LOGGED_IN)
    localStorage.removeItem(WANDA_JWT_TOKEN)
    localStorage.removeItem(IS_NOTIFICATION_DISMISSED)
    sessionStorage.clear()

    if (client) {
      client.logout({
        returnTo: `${window.location.protocol}//${window.location.host}/login`,
      })
    }
  }

  const register: Context['register'] = async ({
    email,
    password,
    postalCode,
    handleRegistrationLoginSuccess,
  }) => {
    await promisify(client.signup.bind(client))({
      email,
      password,
      connection: 'Username-Password-Authentication',
    })

    await login({
      email,
      password,
      postalCode,
      handleRegistrationLoginSuccess,
    })
  }

  const handleRedirect: Context['handleRedirect'] = async () => {
    try {
      const authResult = await promisify(client.parseHash.bind(client))()
      if (authResult) {
        const appState = localStorage.getItem(authResult.appState)
        if (!appState) {
          throw new Error('No appState')
        }
        const parsedAppState = JSON.parse(appState)
        localStorage.removeItem(authResult.appState)
        await handleAuthResult({ authResult, metadata: parsedAppState })
      } else {
        throw new Error('No authResult')
      }
    } catch (error) {
      captureException(error)
      const errorDescription = (error as { errorDescription: string })?.errorDescription
      navigate(`${Routes.Login}?err=${errorDescription}`, {
        replace: true,
      })
      throw error
    }
  }

  const loginSocial: Context['loginSocial'] = async (connection) => {
    const lastRoute = getLastRouteFromLocalStorage()
    const state = lastRoute ? lastRoute : Routes.Space
    const authResult = await promisify(client.popup.authorize.bind(client.popup))({
      redirectUri: `${window.location.protocol}//${window.location.host}/popup`,
      connection,
      responseType: 'token id_token',
      domain,
      owp: true,
      state,
      audience: `https://${tenant}.eu.auth0.com/api/v2/`,
      scope: 'openid profile email read:current_user update:current_user_metadata',
    })

    await handleAuthResult({ authResult })
  }

  const registerSocial: Context['registerSocial'] = async (connection, { postalCode }) => {
    const lastRoute = getLastRouteFromLocalStorage()
    const state = lastRoute ? lastRoute : Routes.Space
    const authResult = await promisify(client.popup.authorize.bind(client.popup))({
      redirectUri: `${window.location.protocol}//${window.location.host}/popup`,
      connection,
      responseType: 'token id_token',
      domain,
      owp: true,
      state,
    })

    await handleAuthResult({
      authResult,
      metadata: {
        postalCode: postalCode.postalCode,
        countryCode: postalCode.country as SupportedCountries,
      },
    })
  }

  const renewSession: Context['renewSession'] = async () => {
    if (localStorage.getItem(IS_LOGGED_IN) === 'true' && !isAuthenticated) {
      try {
        setIsAuthenticating(true)
        const authResult = await promisify(client.checkSession.bind(client))({})
        if (authResult?.accessToken && authResult.idToken) {
          localStorage.setItem(WANDA_LAST_ROUTE, location.pathname + location.search)
          await handleAuthResult({ authResult })
        }
      } catch (error) {
        logout()
        setIsAuthenticating(false)
        captureException(error)
      }
    } else {
      setIsAuthenticating(false)
    }
  }

  const renewAuth: Context['renewAuth'] = async () => {
    try {
      if (localStorage.getItem(IS_LOGGED_IN) === 'true' && isAuthenticated) {
        const authResult = await promisify(client.renewAuth.bind(client))({})
        await handleAuthResult({ authResult })
      }
    } catch (error) {
      captureException(error)
      console.error(error)
    }
  }

  return (
    <Auth0Context.Provider
      value={{
        handleAuthResult,
        handleRedirect,
        isAuthenticated,
        isAuthenticating,
        login,
        loginSocial,
        logout,
        register,
        registerSocial,
        renewSession,
        universalLogin,
        authenticateSecondaryUser,
        renewAuth,
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}

export { AuthProvider }

function promisify<Input, Output>(
  fn: (arg: Input, cb: Auth0Callback<Output, unknown>) => void
): (arg?: Input) => Promise<Output>
function promisify<Arg1, Arg2, Output>(
  fn: (arg1: Arg1, arg2: Arg2, cb: Auth0Callback<Output, unknown>) => void
): (arg?: Arg1, arg2?: Arg2) => Promise<Output>
function promisify<Output>(fn: (cb: Auth0Callback<Output, unknown>) => void): () => Promise<Output>
function promisify<Arg1, Arg2, Output>(
  fn:
    | ((arg: Arg1, cb: Auth0Callback<Output, unknown>) => void)
    | ((arg1: Arg1, arg2: Arg2, cb: Auth0Callback<Output, unknown>) => void)
    | ((cb: Auth0Callback<Output, unknown>) => void)
): (arg1?: Arg1, arg2?: Arg2) => Promise<Output> {
  return async (...args) =>
    new Promise((resolve, reject) => {
      // @ts-ignore not sure how to type this
      fn(...args, (error, result) => {
        if (error) {
          reject(error)
        } else {
          resolve(result)
        }
      })
    })
}
