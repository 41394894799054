import { Box, Flex, Text } from '@chakra-ui/react'
import { DynamicIcon, DynamicIconName, Spinner } from '@wanda-space/noelle'
import { resetImageWithScale } from 'components/UploadImage/utils'
import { Field, FieldProps, FormikErrors } from 'formik'
import heic2any from 'heic2any'
import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useIntl } from 'react-intl'

const HEIFMime = ['image/heic', 'image/heif']

interface Props {
  onSetImages: (images: string[]) => void
  maxNrOfImages?: number
}

const getProperImages = async (blobs: Blob[]): Promise<string[]> => {
  return Promise.all(
    blobs.map(async (blob) => {
      let image = blob
      if (HEIFMime.includes(blob.type)) {
        const convertedImage = await heic2any({
          blob,
          toType: 'image/jpeg',
          quality: 1,
        })
        image = Array.isArray(convertedImage) ? convertedImage[0] : convertedImage
      }
      return resetImageWithScale(image)
    })
  )
}

const Uploader = ({ onSetImages, maxNrOfImages = 5 }: Props) => {
  const { formatMessage } = useIntl()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<
    string | false | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined
  >(undefined)

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/*', ...HEIFMime],
    onDrop: async (images) => {
      if (Array.isArray(images) && images.length > 0) {
        try {
          setLoading(true)
          const properImages = await getProperImages(images)
          onSetImages(properImages)
        } catch (_e) {
          const errorMsg = formatMessage({ id: 'word.tryAgain' })
          setError(errorMsg)
        } finally {
          setLoading(false)
        }
      }
    },
  })

  return (
    <Box height="100%">
      <Field name="file">
        {({ form }: FieldProps<any>) => {
          const hasError = (form.touched.file && form.errors.file) || error

          return (
            <>
              <Flex
                background="purple.50"
                flexDirection="column"
                borderRadius="2xl"
                align="center"
                justifyContent="center"
                cursor="pointer"
                data-testid="upload"
                border="1px solid"
                py={4}
                height="100%"
                borderColor={hasError ? 'red.500' : 'purple.200'}
                _hover={{ backgroundColor: 'purple.100', borderColor: 'purple.200' }}
                _active={{ backgroundColor: 'purple.200' }}
                {...getRootProps()}
              >
                {loading ? (
                  <Spinner />
                ) : (
                  <>
                    <input {...getInputProps()} />

                    <DynamicIcon
                      iconName={DynamicIconName.addNewImage}
                      color="purple.700"
                      h={10}
                      w={10}
                    />
                    <Text fontWeight="medium" fontSize="md" color="gray.700" mt={2}>
                      {formatMessage({ id: 'booking.confirmation.order.addImages' })}
                    </Text>
                    <Flex color="gray.500" gap={1}>
                      <Text>{formatMessage({ id: 'word.max' })}</Text>
                      <Text>{maxNrOfImages}</Text>
                    </Flex>
                  </>
                )}
              </Flex>

              {hasError && (
                <Text fontSize="sm" color="red.500" mt={4}>
                  {hasError}
                </Text>
              )}
            </>
          )
        }}
      </Field>
    </Box>
  )
}

export { Uploader }
