import { Spinner } from '@wanda-space/noelle'
import { ItemResponseDto } from '@wanda-space/types'
import { ItemForm } from 'components/Item'
import React from 'react'

import { useImageHook } from '../hooks'

interface Props {
  item: ItemResponseDto
}

const OnTheMove = ({ item }: Props) => {
  const { isLoading, isError, isSuccess, addImage, deleteImage } = useImageHook()

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <ItemForm
        isError={isError}
        isSuccess={isSuccess}
        addImage={addImage}
        deleteImage={deleteImage}
        item={item}
      />
    </>
  )
}

export { OnTheMove }
