import { Auth0Result } from 'auth0-js'
import { UserSignupDetails } from 'hooks/useAuth'

export const WANDA_JWT_TOKEN = 'WANDA_JWT_TOKEN'
export const WANDA_LAST_ROUTE = 'WANDA_LAST_ROUTE'
export const IS_LOGGED_IN = 'isLoggedIn'
export const IS_NOTIFICATION_DISMISSED = 'isNotificationDismissed'
export const STORAGE_FLOW_STATE = 'storageFlowState'
export const EDIT_STORAGE_FLOW_STATE = 'editStorageFlowState'
export const SELL_FLOW_STATE = 'SellFromStorageFlowState'
export const RETURN_FLOW_STATE = 'returnFlowState'
export const SELL_WITH_PICKUP_STATE = 'sellWithPickupState'
export const SERVICE_FROM_STORAGE = 'serviceFromStorageFlowState'
export const SERVICE_FLOW_NEXT_STATE = 'serviceFlowNextState'
export const PACKAGING_FLOW_STATE = 'packagingFlowState'
export const SQUARE_METER_FLOW_STATE = 'squareMeterFlowState'
export const AUTH_RESULT = 'authResult'
export const BID_FLOW_STATE = 'bidFlowState'
export const UI_STATE = 'uiState'

export interface LocalStorageState {
  [AUTH_RESULT]: { authResult: Auth0Result; metadata?: UserSignupDetails }
}
