import { Stepable } from 'interfaces'
import React from 'react'
import { useIntl } from 'react-intl'
import { FlowIntroduction } from 'routes/Common/FlowIntroduction'

const emojis = ['🛏️', '📦', '🚲', '🛋️️', '🎿']
const Introduction = ({ onNextStep }: Stepable) => {
  const { formatMessage } = useIntl()
  return (
    <FlowIntroduction
      pageType="storage"
      stepNumber={4}
      onNextStep={onNextStep}
      pageTitleKey="buySellFlow.step.advertisement"
      titleKey="storage.howItWorks"
      subtitleKey="storage.introduction.subtitle"
      emojis={emojis}
      readMoreLink={formatMessage({ id: 'intropage.storage.readMoreLink' })}
    />
  )
}

export { Introduction }
