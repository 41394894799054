import { Box, Flex, Tag, Text } from '@chakra-ui/react'
import { CardNumberElement } from '@stripe/react-stripe-js'
import { DynamicIcon, DynamicIconName } from '@wanda-space/noelle'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

interface Props {
  disabled?: boolean
}

const CardNumber = ({ disabled }: Props) => {
  const { formatMessage } = useIntl()
  const [focused, setFocused] = useState(false)
  const [cardBrand, setCardBrand] = useState('')
  const [hasValue, setHasValue] = useState(false)
  const [error, setError] = useState<string | undefined>()

  const inputStyle = {
    style: {
      base: {
        fontSize: '16px',
        color: 'ctaBlack.400',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: 'gray.400',
        },
      },
    },
  }

  return (
    <>
      <Text fontSize={'xs'} mb={2} textTransform="uppercase" color="ctaBlack.900" opacity={0.65}>
        {formatMessage({ id: 'payment.cardNumber.information' })}
      </Text>
      <Tag
        display="flex"
        alignItems="center"
        width="100%"
        backgroundColor="white"
        px={4}
        borderRadius="6px"
        height="54px"
        transition="0.3s border-color"
        mb={error ? 0 : 7}
        boxShadow="-1px 4px 30px rgba(0, 0, 0, 0.03)"
        textTransform="uppercase"
        borderStyle="solid"
        borderWidth="1px"
        _focus={{ borderColor: 'purple.500' }}
        borderColor={
          error ? 'red.400' : focused ? 'purple.500' : hasValue ? 'black.900' : 'black.400'
        }
        color="ctaBlack.400"
      >
        <Flex width="100%" alignItems="center">
          <DynamicIcon
            viewBox=" 0 0 32 32"
            height={8}
            iconName={(cardBrand as DynamicIconName) || DynamicIconName.cardIcon}
            color="ctaBlack.400"
          />
          <Box width="100%" marginLeft={2}>
            <CardNumberElement
              id="stripe-cardnumber"
              onBlur={() => {
                setFocused(false)
              }}
              onChange={({ error: err, brand, empty }) => {
                setCardBrand(brand)
                setError(err ? err.message : undefined)
                setHasValue(!empty)
              }}
              onFocus={() => {
                setFocused(true)
              }}
              options={{ ...inputStyle, disabled, placeholder: '0000 0000 0000 0000' }}
            />
          </Box>
        </Flex>
      </Tag>
      {error && (
        <Text mb={7} mt={1} color="pink.800">
          {error}
        </Text>
      )}
    </>
  )
}

export { CardNumber }
