import { Button, Container, Heading, Text } from '@chakra-ui/react'
import { AnimatedModal as Modal } from 'components/Modal/Modal'
import React from 'react'

interface Props {
  show: boolean
  onClose: () => void
  onExit: () => void
  title: string
  description: string
  'data-testid': string
  onPrimaryClick?: () => void
  variant?: string
  buttonOneText?: string
  buttonTwoText: string
  variantTwo: string
}

const ShareInfoModal = ({
  show,
  onClose,
  title,
  description,
  onPrimaryClick,
  'data-testid': testId,
  onExit,
  buttonOneText,
  buttonTwoText,
  variantTwo,
  variant,
}: Props) => {
  return (
    <Modal data-testid={testId} onClose={onExit} show={show}>
      <Container bg="white" borderRadius="2xl" p={8}>
        <Heading mb={6} as="h2" fontSize="large" fontWeight="medium">
          {title}
        </Heading>
        <Text mb={10}>{description}</Text>
        {buttonOneText ? (
          <Button
            data-testid="modal-close-button"
            onClick={onPrimaryClick}
            bg={variant ? 'blackAlpha.900' : ''}
            color={variant ? 'white' : ''}
            colorScheme={variant}
            width="100%"
            _hover={{ backgroundColor: 'blackAlpha.600' }}
          >
            {buttonOneText}
          </Button>
        ) : (
          ''
        )}

        <Button data-testid="modal-close-button" onClick={onClose} colorScheme={variantTwo}>
          {buttonTwoText}
        </Button>
      </Container>
    </Modal>
  )
}

export { ShareInfoModal }
