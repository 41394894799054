import { type ItemResponseDto, StorageItemState, StorageItemType } from '@wanda-space/types'
import { ItemStateSortValue } from 'consts'
import { LoadingStatus } from 'interfaces/loading'
import { descend, prop, sortWith } from 'ramda'
import { hasItemLimitExceeded } from 'utils/product-utils'

import { Action, Actions } from './actions'

const ADDONTHRESHOLDS = {
  SMALL: 10,
  BOX: 10,
  LARGE: 0,
} as const

export type ItemState = {
  status: LoadingStatus
  items: Record<string, ItemResponseDto>
  item: ItemResponseDto | undefined
  selectedItems: ItemResponseDto[]
  selectedAddOnItems: ItemResponseDto[]
  sortedItems: ItemResponseDto[]
  limitOfItemsExceeded: boolean
  limitOfAddOnItemsExceeded: boolean
  storedItems: ItemResponseDto[]
  itemsWithCustomer: ItemResponseDto[]
}

const initialState: ItemState = {
  status: 'idle',
  items: {},
  item: undefined,
  selectedItems: [],
  selectedAddOnItems: [],
  sortedItems: [],
  limitOfItemsExceeded: false,
  limitOfAddOnItemsExceeded: false,
  storedItems: [],
  itemsWithCustomer: [],
}

export function item(state = initialState, action: Action): ItemState {
  switch (action.type) {
    case Actions.ADD_ITEM: {
      const items = { ...state.items, [action.payload.id]: action.payload }
      const itemsArray = Object.values(items)
      return {
        ...state,
        items,
        itemsWithCustomer: itemsWithCustomer(itemsArray),
        storedItems: storedItems(itemsArray),
        sortedItems: sortItems(itemsArray),
      }
    }
    case Actions.GET_ITEMS:
      return { ...state, status: 'loading' }
    case Actions.SET_ITEMS:
      return {
        ...state,
        status: 'succeeded',
        items: action.payload.reduce<Record<string, ItemResponseDto>>((r, item) => {
          r[item.id] = item
          return r
        }, {}),
        itemsWithCustomer: itemsWithCustomer(action.payload),
        storedItems: storedItems(action.payload),
        sortedItems: sortItems(action.payload),
      }
    case Actions.SET_ITEMS_ERROR:
      return { ...state, status: 'failed' }
    case Actions.SET_ITEM:
      return {
        ...state,
        item: action.payload,
      }

    case Actions.REMOVE_ITEM: {
      const items = { ...state.items }
      delete items[action.payload]
      const itemsArray = Object.values(items)
      return {
        ...state,
        items,
        itemsWithCustomer: itemsWithCustomer(itemsArray),
        storedItems: storedItems(itemsArray),
        sortedItems: sortItems(itemsArray),
      }
    }

    case Actions.CLEAR_SELECTED_ITEMS:
      return {
        ...state,
        selectedItems: [],
        limitOfItemsExceeded: false,
      }
    case Actions.CLEAR_ITEMS:
      return { ...initialState }

    case Actions.TOGGLE_SELECTED_ITEMS: {
      const selectedIds = state.selectedItems.map(({ id }) => id)
      const selectedItems = selectedIds
        .filter((x) => !action.payload.includes(x))
        .concat(action.payload.filter((x) => !selectedIds.includes(x)))
        .map((id) => state.items[id])
      const sizes = selectedItems.reduce<Record<StorageItemType, number>>(
        (acc, item) => {
          acc[item.type] += 1
          return acc
        },
        {
          SMALL: 0,
          LARGE: 0,
          BOX: 0,
        }
      )

      return {
        ...state,
        selectedItems,
        limitOfItemsExceeded: hasItemLimitExceeded(sizes),
      }
    }
    case Actions.TOGGLE_SELECTED_ADD_ON_ITEMS: {
      const selectedIds = state.selectedAddOnItems.map(({ id }) => id)
      const selectedAddOnItems = selectedIds
        .filter((x) => !action.payload.includes(x))
        .concat(action.payload.filter((x) => !selectedIds.includes(x)))
        .map((id) => state.items[id])
      const sizes = selectedAddOnItems.reduce<Record<StorageItemType, number>>(
        (acc, item) => {
          acc[item.type] += 1
          return acc
        },
        {
          SMALL: 0,
          LARGE: 0,
          BOX: 0,
        }
      )

      return {
        ...state,
        selectedAddOnItems,
        limitOfAddOnItemsExceeded:
          sizes.BOX + sizes.SMALL > ADDONTHRESHOLDS.SMALL || sizes.LARGE > ADDONTHRESHOLDS.LARGE,
      }
    }

    default: {
      return state
    }
  }
}

const sortByState = (a: ItemResponseDto, b: ItemResponseDto) =>
  // @ts-ignore fix this type
  ItemStateSortValue[a.pendingOrderId ? 'ordered' : a.state] -
  // @ts-ignore fix this type
  ItemStateSortValue[b.pendingOrderId ? 'ordered' : b.state]

const sortItems: (items: ItemResponseDto[]) => ItemResponseDto[] = sortWith([
  sortByState,
  descend(prop('createdAt')),
])

const storedItems = (items: ItemResponseDto[]): ItemResponseDto[] => {
  return items.filter(({ state }) =>
    [StorageItemState.ON_THE_ROAD, StorageItemState.STORED].includes(state)
  )
}

const itemsWithCustomer = (items: ItemResponseDto[]): ItemResponseDto[] => {
  return items.filter(({ state }) => [StorageItemState.WITH_CUSTOMER].includes(state))
}
