import { Button, Divider, Flex, IconButton, Text } from '@chakra-ui/react'
import { DynamicIcon, DynamicIconName } from '@wanda-space/noelle'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { ItemPayload } from 'reduxStore/commonMappers'

import { type OrderLineWithFullProductAndDiscount, type Product } from '../../../api-client'
import { sanitizeStripeAmount } from '../../../utils'

export type ProductWithServiceProducts = Product & {
  serviceProducts: Product[]
  items: ItemPayload[]
}

/* The types and hierarchy will be reworked */
interface ProductSelectorProps {
  onAddItem?: (item: Product) => void
  onSubItemAdd: (item: any, product: Product) => void
  list: ProductWithServiceProducts[]
  // This will be replaced something more generic, only used for count
  serviceOrderLines: OrderLineWithFullProductAndDiscount[]
  expandedProducts?: string[]
}

/*
  The UI and API for this component will be reworked and moved to Noelle with time and maturity
 */

const ProductItem = ({
  item,
  serviceProducts,
  serviceOrderLines,
  onSubItemAdd,
}: {
  item: ItemPayload
  serviceProducts: Product[]
  serviceOrderLines: OrderLineWithFullProductAndDiscount[]
  onSubItemAdd: (item: ItemPayload, product: Product) => void
}) => {
  const { formatMessage } = useIntl()
  return (
    <Flex
      key={item.id}
      p={4}
      paddingBottom={0}
      direction="column"
      backgroundColor="paleBlue.300"
      borderRadius={16}
    >
      <Flex my={2} mb={8}>
        <Text size="small" fontWeight="medium">
          {item.name}
        </Text>
      </Flex>
      {serviceProducts.map((sp, index) => {
        const noOfServices = serviceOrderLines
          .filter((sol) => sol.item?.id === item.id && sol.product.id === sp.id)
          .reduce((list, current) => list + current.quantity, 0)
        const maxCapacityReached =
          typeof sp.metadata?.maxQuantity !== 'undefined' &&
          !Number.isNaN(sp.metadata.maxQuantity) &&
          Number(sp.metadata.maxQuantity) <= noOfServices
        const productName = formatMessage({ id: `${sp.localizationKey}.name` })
        return (
          <Flex
            key={sp.id}
            justifyContent="space-between"
            width="100%"
            borderTop="1px solid"
            borderColor="paleBlue.400"
            minHeight="75px"
            alignItems="center"
            _hover={{ backgroundColor: 'transparent' }}
            _active={{ backgroundColor: 'transparent' }}
            backgroundColor="transparent"
            aria-label={`add product ${productName}`}
            data-testid={`add-product-button-${productName}-${index}`}
            onClick={() => {
              onSubItemAdd(item, sp)
            }}
            as={Button}
            height="max"
            py={4}
            px={0}
            borderRadius="none"
            whiteSpace="initial"
            disabled={maxCapacityReached}
          >
            <Flex direction="column" gap={2}>
              <Text fontSize="medium" color="black">
                {productName}
              </Text>
              <Text fontSize="small" color="gray.600" lineHeight={1.5}>
                {formatMessage(
                  { id: `${sp.localizationKey}.description` },
                  {
                    price: sanitizeStripeAmount(sp.price),
                  }
                )}
              </Text>
            </Flex>
            <Flex>
              <Flex
                backgroundColor="white"
                color="black"
                height={8}
                width={8}
                minWidth={8}
                minHeight={8}
                alignItems="center"
                justifyContent="center"
                borderRadius="6px"
                mr={4}
                opacity={noOfServices > 0 ? 1 : 0}
              >
                {noOfServices}
              </Flex>
              <DynamicIcon
                height={8}
                width={8}
                minWidth={7}
                iconName={DynamicIconName.chevronRight}
              />
            </Flex>
          </Flex>
        )
      })}
    </Flex>
  )
}

export const ProductSelector = ({
  list,
  onAddItem,
  serviceOrderLines,
  onSubItemAdd,
  ...rest
}: ProductSelectorProps) => {
  const { formatMessage } = useIntl()
  const [expandedProducts, setExpandedProducts] = useState<string[]>(rest.expandedProducts ?? [])

  const toggleExpanded = (id: string) => {
    if (expandedProducts.includes(id)) {
      setExpandedProducts(expandedProducts.filter((pId) => pId !== id))
    } else {
      setExpandedProducts([...expandedProducts, id])
    }
  }

  return (
    <Flex direction="column" rowGap={8}>
      {list.map((product) => {
        const { localizationKey, id, items, serviceProducts, iconName } = product
        const productName = formatMessage({ id: `${localizationKey}.name` })
        const productIconName = iconName
          ? (iconName as DynamicIconName)
          : DynamicIconName.wandaLogoShort
        const itemIds = items.map((item) => item.id)
        const servicesCount = serviceOrderLines.filter((sol) =>
          itemIds.includes(sol.item!.id)
        ).length
        const isExpanded = expandedProducts.includes(id)

        return (
          <Flex key={id} direction="column">
            <Flex direction="column">
              <Flex
                as={Button}
                alignItems="baseline"
                columnGap={4}
                mb={2}
                justifyContent="space-between"
                _hover={{ backgroundColor: 'transparent' }}
                _active={{ backgroundColor: 'transparent' }}
                backgroundColor="transparent"
                aria-label={`${isExpanded ? 'collapse ' : 'expand'} button-${productName}`}
                data-testid={`${isExpanded ? 'collapse-' : 'expand-'}button-${productName}`}
                onClick={() => {
                  toggleExpanded(id)
                }}
              >
                <Flex direction="column">
                  <Flex columnGap={4} mb={4} alignItems="center">
                    <DynamicIcon iconName={productIconName} h={6} w={6} color="paleBlue.700" />
                    <Text>{productName}</Text>
                    <Flex
                      backgroundColor="paleBlue.400"
                      height={8}
                      width={8}
                      minWidth={8}
                      minHeight={8}
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="2xl"
                      opacity={servicesCount > 0 ? 1 : 0}
                    >
                      <Text size="small" fontWeight="medium">
                        {servicesCount}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
                <DynamicIcon
                  mr="3"
                  height={8}
                  width={8}
                  iconName={isExpanded ? DynamicIconName.chevronDown : DynamicIconName.chevronRight}
                  color="black"
                />
              </Flex>
            </Flex>
            {expandedProducts.includes(id) && (
              <Flex direction="column" gap={4}>
                {items.map((item) => {
                  return (
                    <ProductItem
                      key={item.id}
                      item={item}
                      serviceProducts={serviceProducts}
                      serviceOrderLines={serviceOrderLines}
                      onSubItemAdd={onSubItemAdd}
                    />
                  )
                })}
                {onAddItem ? (
                  <Flex alignItems="center" columnGap={2} py={4} onClick={() => onAddItem(product)}>
                    <IconButton
                      aria-label={'add'}
                      backgroundColor="paleBlue.500"
                      data-testid="add-item-button"
                      height={8}
                      width={8}
                      minWidth={8}
                      icon={
                        <DynamicIcon
                          iconName={DynamicIconName.plusSmall}
                          color="black"
                          h={4}
                          w={4}
                        />
                      }
                    />
                    <Text fontWeight="500">
                      {formatMessage({ id: 'word.addMore' })}{' '}
                      {formatMessage({
                        id: `${product.localizationKey}.name`,
                      }).toLowerCase()}
                    </Text>
                  </Flex>
                ) : null}
              </Flex>
            )}
            <Divider />
          </Flex>
        )
      })}
    </Flex>
  )
}
