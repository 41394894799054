import { Spinner } from '@wanda-space/noelle'
import { ProductType, SortOrderOptionsType } from '@wanda-space/types'
import { ItemSelector } from 'components/Item/Selector/ItemSelector'
import { getProductDescriptionForStorage } from 'components/Item/Selector/helper'
import { useAppSelector } from 'hooks/useAppSelector'
import { useProductsAndCategories } from 'hooks/useProductsAndCategories'
import { Stepable } from 'interfaces'
import React from 'react'
import { useAppDispatch } from 'reduxStore'
import type { ItemPayloadWithProduct } from 'reduxStore/commonMappers'
import { itemPayloadSelectors, setItems } from 'reduxStore/ducks/storage/storageFlow'

import { IsSquareMeterCheck } from './components/IsSquareMeterCheck'

export const ItemSelectorPage = ({ onNextStep }: Stepable) => {
  const dispatch = useAppDispatch()
  const selectedExistingItems = useAppSelector((state) => state.storageFlow.existingItems)
  const selectedItems = useAppSelector(itemPayloadSelectors.selectAll)
  const coupon = useAppSelector((state) => state.storageFlow.coupon)
  const productsAndCategories = useProductsAndCategories({
    metadataByType: { [ProductType.STORAGE]: { serviceOnly: false } },
    sortOption: SortOrderOptionsType.STORAGE_FLOW,
    couponCode: coupon,
  })

  const onSelect = (items: ItemPayloadWithProduct[]) => {
    dispatch(setItems(items))
  }

  if (productsAndCategories.isLoading) {
    return <Spinner />
  }

  return (
    <>
      <IsSquareMeterCheck />
      <ItemSelector
        {...{
          selectedItems,
          onSelect,
          selectedExistingItems,
          productsAndCategories,
        }}
        onSubmit={onNextStep}
        getProductDescription={getProductDescriptionForStorage}
      />
    </>
  )
}
