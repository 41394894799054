import { Text } from '@chakra-ui/react'
import React from 'react'
import { useIntl } from 'react-intl'

import { WorthKnowingStep } from './WorthKnowingStep'

interface Props {
  messageId: string
}

export const YouWillBeContacted = ({ messageId }: Props) => {
  const { formatMessage } = useIntl()
  return (
    <WorthKnowingStep
      key="step3"
      iconName="mobileSignal"
      text={formatMessage(
        { id: messageId },
        {
          underline: (value) => (
            <Text as="span" textDecoration="underline">
              {value}
            </Text>
          ),
        }
      )}
    />
  )
}
