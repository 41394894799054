import { captureException } from '@sentry/react'
import { deleteItemImage, setNewPrimaryPhoto, uploadImage } from 'api-client'
import { useEffect, useRef, useState } from 'react'

export const useImageHook = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  const addImage = async (img: string, itemId: string, hasPrimary: boolean) => {
    setIsLoading(true)
    let imageId: string
    try {
      const response = await uploadImage({ itemId, file: img })
      imageId = response.imageId
      setIsSuccess(true)
      setTimeout(() => {
        if (mounted.current === true) setIsSuccess(false)
      }, 3000)
      if (!hasPrimary && imageId) {
        await setNewPrimaryPhoto({ itemId, imageId: imageId })
      }
    } catch (error) {
      setIsError(true)
      captureException(new Error('Could not upload image'))
    }

    setIsLoading(false)
  }

  const deleteImage = async (itemId: string, imageId: string) => {
    setIsLoading(true)
    await deleteItemImage(itemId, imageId)
      .then(() => {
        setIsSuccess(true)
        setTimeout(() => {
          if (mounted.current === true) setIsSuccess(false)
        }, 3000)
      })
      .catch(() => {
        setIsError(true)
        captureException(new Error('Could not delete image'))
      })
    setIsLoading(false)
  }

  return { isLoading, isError, isSuccess, addImage, deleteImage }
}
