import { Box, Text } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { ChevronRightIcon, InfoBox, ThumbsUpHeartIcon } from '@wanda-space/noelle'
import { CreditTransactionType } from '@wanda-space/types'
import { getCreditBalance, getCreditTransactions } from 'api-client/lib/routes/referral'
import { Routes } from 'consts'
import React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { sanitizeStripeAmount } from 'utils'

export const ReferralNotification = () => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  const { data: creditBalance } = useQuery(['creditBalance'], async () => getCreditBalance())

  const { data: creditTransactions } = useQuery(['creditTransaction'], async () =>
    getCreditTransactions({ itemsPerPage: 100, page: 0 })
  )

  const hasCredit =
    creditBalance?.balance && sanitizeStripeAmount(creditBalance?.balance) > 50 ? true : false

  const lastReferral = creditTransactions?.items.find(
    (i) => i.type === CreditTransactionType.REFERRED || i.type === CreditTransactionType.REFERRER
  )

  const getTitle = hasCredit
    ? formatMessage(
        { id: 'referral.space.infobox.credit.title' },
        { amount: Math.trunc(sanitizeStripeAmount(creditBalance?.balance ?? 0)) }
      )
    : formatMessage({ id: 'referral.space.infobox.title' })

  const getDescription = () => {
    if (hasCredit && lastReferral?.type === CreditTransactionType.REFERRED) {
      return 'referral.space.infobox.credit-referred.description'
    } else if (hasCredit && lastReferral?.type === CreditTransactionType.REFERRER) {
      return 'referral.space.infobox.credit.description'
    } else return 'referral.space.infobox.description'
  }

  return (
    <InfoBox
      mb={2}
      onClick={() => navigate(Routes.ReferFriend)}
      rightButton={<ChevronRightIcon color={'white'} bgColor={'black'} borderRadius={100} />}
      title={getTitle}
      description={
        <Box mr={4}>
          {formatMessage({ id: getDescription() })}
          <Text textDecor={'underline'}>
            {formatMessage({ id: hasCredit ? 'word.details' : 'word.settIgang' })}
          </Text>
        </Box>
      }
      leftIcon={<ThumbsUpHeartIcon width={12} height={7} />}
    />
  )
}
