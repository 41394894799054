export enum Layer {
  Content = 0,
  AboveContent = 20,
  Float = 80,
  Overlay = 90,
  Modal = 100,
  NavOverlay = 9999,
  NavContentMenu = 1000,
}

export enum BorderRadius {
  Upsel = '32px',
  Small = '16px',
  Tag = '4px',
}
