import { Spinner } from '@wanda-space/noelle'
import { useAuth } from 'hooks/useAuth'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

const Auth0Callback = () => {
  const location = useLocation()
  const { handleRedirect } = useAuth()

  useEffect(() => {
    if (/access_token|id_token|error/.test(location.hash)) {
      handleRedirect()
    }
  }, [handleRedirect, location])

  return <Spinner />
}

export { Auth0Callback }
