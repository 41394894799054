import { Flex, Grid, GridItem } from '@chakra-ui/react'
import React from 'react'

import { Image } from './Image'
import { Uploader } from './Uploader'

interface Props {
  maxNrOfImages?: number
  onSetImages: (img: string[]) => void
  imageUrls?: string[]
  onRemoveImage: (img: string, index: number) => void
  onChangeMainImg: (img: string, index: number) => void
  isMainImg?: boolean
  isLoading: boolean
}

const ImageGallery = ({
  maxNrOfImages = 5,
  onSetImages,
  imageUrls,
  onRemoveImage,
  onChangeMainImg,
  isMainImg,
  isLoading,
}: Props) => {
  const showUploader = () =>
    (imageUrls && imageUrls.length < maxNrOfImages) || imageUrls === undefined

  return (
    <Flex>
      <Grid
        templateColumns={imageUrls && imageUrls?.length <= 0 ? '' : '1fr 1fr'}
        templateRows="1fr"
        gap={2}
        width="100%"
      >
        {imageUrls?.map((url, i) => (
          <GridItem
            key={url}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="2xl"
            border="1px solid"
            borderColor="gray.200"
            minH={32}
            position="relative"
          >
            <Image
              index={i}
              onRemoveImage={onRemoveImage}
              onChangeMainImg={onChangeMainImg}
              isMainImg={isMainImg || i === 0}
              isLoading={isLoading}
              imageUrl={url}
            />
          </GridItem>
        ))}

        {showUploader() && (
          <GridItem>
            <Uploader
              maxNrOfImages={maxNrOfImages}
              onSetImages={(images) => {
                onSetImages(images)
              }}
            />
          </GridItem>
        )}
      </Grid>
    </Flex>
  )
}

export { ImageGallery }
