import { Flex, Text } from '@chakra-ui/react'
import { DynamicIcon, DynamicIconName } from '@wanda-space/noelle'
import React from 'react'

export interface WorthKnowingStepProps {
  iconName: string
  text: React.ReactNode
}

export const WorthKnowingStep = ({ iconName, text }: WorthKnowingStepProps): JSX.Element => {
  return (
    <Flex flexDirection="row" alignItems="flex-start">
      <Flex p={2} backgroundColor="purple.200" borderRadius="xl" mr={4}>
        <DynamicIcon iconName={iconName as DynamicIconName} height={6} width={6} />
      </Flex>
      <Flex>
        <Text>{text}</Text>
      </Flex>
    </Flex>
  )
}
