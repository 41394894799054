import {
  type PackingQuoteRequestDto,
  type PackingQuoteResponseDto,
  type PaginatedResponseDto,
  type PaginationQueryParams,
  type ProductCategoryResponseDto,
  type ProductResponseDto,
  SupportedCities,
  SupportedCountries,
} from '@wanda-space/types'

import { ApiParams, request, requestBlob } from '../api-client'

export async function fetchProducts(
  country: SupportedCountries,
  city?: SupportedCities,
  params?: PaginationQueryParams
): Promise<ProductResponseDto[]> {
  return request<ProductResponseDto[]>({
    method: 'GET',
    url: `product/${country}/city/${city}`,
    params,
  })
}

export async function getProductCategoriesByCountry(
  country: SupportedCountries,
  params?: ApiParams
): Promise<PaginatedResponseDto<ProductCategoryResponseDto>> {
  return request<PaginatedResponseDto<ProductCategoryResponseDto>>({
    method: 'GET',
    url: `product/category/${country}`,
    params,
  })
}

export async function fetchPackingQuote({
  country,
  items,
}: PackingQuoteRequestDto): Promise<PackingQuoteResponseDto> {
  return request<PackingQuoteResponseDto>({
    method: 'POST',
    url: 'product/packing/quote',
    body: { country, items },
  })
}

export async function fetchProductImage(id: string, imageId: string) {
  return requestBlob({
    method: 'GET',
    url: `product/${id}/image/${imageId}`,
  })
}

export async function fetchProductImages(id: string): Promise<string[]> {
  return request({
    method: 'GET',
    url: `product/${id}/images`,
  })
}
