import { Box, IconButton, Text, VStack } from '@chakra-ui/react'
import { DynamicIconName, TrashIcon } from '@wanda-space/noelle'
import type { AccountId } from '@wanda-space/types'
import React from 'react'
import { useIntl } from 'react-intl'

import { ServiceInfoBox } from './ServiceInfoBox'

interface Props {
  itemId: string
  sharedWithAccounts: {
    displayName: string
    accountId: AccountId
    ownerPays: boolean
  }[]
  handleStopSharing: (share: { displayName: string; accountId: AccountId }) => void
}

export const SharedInfoBox = ({ sharedWithAccounts, handleStopSharing }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <ServiceInfoBox
      disableHover={true}
      leftIconName={DynamicIconName.shareItem}
      rightButton={<Box />}
      title={formatMessage({ id: 'sharing.info.itemShared.title' })}
      description={
        <VStack>
          {sharedWithAccounts.map((share) => (
            <Box key={share.accountId} padding={1}>
              <IconButton
                marginRight={1}
                display={'inline-block'}
                width={'auto'}
                size={'xs'}
                variant={'ghost'}
                icon={<TrashIcon />}
                colorScheme="red"
                onClick={() => handleStopSharing(share)}
                aria-label={formatMessage({ id: 'sharing.stop' })}
              />
              <Text display={'inline-block'}>{share.displayName}</Text>
            </Box>
          ))}
        </VStack>
      }
    />
  )
}
