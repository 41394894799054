import { Box, Button, Flex, HStack, Heading, Text } from '@chakra-ui/react'
import {
  ArrowRightIcon,
  CheckMarkIcon,
  DynamicIcon,
  DynamicIconName,
  EditDescriptionIcon,
  NoteIcon,
  ShareLinkIcon,
} from '@wanda-space/noelle'
import React from 'react'
import { useIntl } from 'react-intl'

interface InfoNotificationProps {
  isBuyableListing: boolean
  sellingFromHome: boolean
  onEditModalOpen?: () => void
  hasCopiedLink?: boolean
  hasCopiedInfo?: boolean
  handleCopy?: (simpleId: string, info: boolean) => void
  simpleId?: string
  handleClickOnNextStep?: () => void
  isLoading?: boolean
}

export const InfoNotification = ({
  isBuyableListing,
  sellingFromHome,
  onEditModalOpen,
  handleCopy,
  simpleId,
  hasCopiedInfo,
  hasCopiedLink,
  handleClickOnNextStep,
  isLoading,
}: InfoNotificationProps) => {
  const { formatMessage } = useIntl()

  const title = isBuyableListing
    ? formatMessage({ id: 'infoNotification.title.buyableListing' })
    : sellingFromHome
      ? formatMessage({ id: 'infoNotification.title.sellFromHome' })
      : formatMessage({ id: 'infoNotification.title.sellFromStorage' })

  const description = isBuyableListing
    ? formatMessage({ id: 'infoNotification.description.buyableListing' })
    : formatMessage({ id: 'infoNotification.description.preview' })

  const buttonTextForPreview = sellingFromHome
    ? formatMessage({ id: 'infoNotification.buttonText.sellingFromHome' })
    : formatMessage({ id: 'infoNotification.buttonText.sellingFromStorage' })

  return (
    <Box
      backgroundColor={'purple.100'}
      border="1px solid"
      borderRadius="2xl"
      borderColor={'purple.200'}
      textAlign="center"
      pt="6"
      pb={2}
      px="2"
      justifyContent="center"
      alignContent="center"
    >
      <Flex alignItems="center" gap="8px" justifyContent="center">
        {isBuyableListing && <DynamicIcon iconName={DynamicIconName.checkMark} />}
        <Heading fontWeight="medium" fontSize="lg">
          {title}
        </Heading>
      </Flex>
      <Text>{description}</Text>

      {isBuyableListing ? (
        <>
          <Button
            variant="solid"
            colorScheme="purple"
            backgroundColor="purple.500"
            width="100%"
            mt={2}
            mb={4}
            leftIcon={<EditDescriptionIcon />}
            onClick={onEditModalOpen}
          >
            <Text color="blackAlpha.900">{formatMessage({ id: 'word.change' })}</Text>
          </Button>
          {handleCopy && simpleId && (
            <HStack>
              {' '}
              <Button
                leftIcon={
                  hasCopiedLink && !hasCopiedInfo ? (
                    <CheckMarkIcon width={4} />
                  ) : (
                    <ShareLinkIcon width={4} />
                  )
                }
                variant="solid"
                colorScheme="purple"
                backgroundColor="purple.500"
                w="100%"
                onClick={() => handleCopy(simpleId, false)}
              >
                <Text ml={2}> {formatMessage({ id: 'infoNotification.copy.link' })}</Text>
              </Button>
              <Button
                variant="solid"
                colorScheme="purple"
                backgroundColor="purple.500"
                w="100%"
                leftIcon={
                  hasCopiedInfo && !hasCopiedLink ? (
                    <CheckMarkIcon width={4} />
                  ) : (
                    <NoteIcon width={4} />
                  )
                }
                onClick={() => handleCopy(simpleId, true)}
              >
                <Text ml={2}> {formatMessage({ id: 'infoNotification.copy.information' })}</Text>
              </Button>
            </HStack>
          )}
        </>
      ) : (
        handleClickOnNextStep &&
        !isLoading && (
          <Button
            mt={2}
            variant="solid"
            backgroundColor="purple.400"
            w="100%"
            onClick={handleClickOnNextStep}
            data-testid="go-to-published-listing"
          >
            <Text>{buttonTextForPreview}</Text>
            <ArrowRightIcon width={4} ml={2} />
          </Button>
        )
      )}
    </Box>
  )
}
