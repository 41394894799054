import {
  OrderState,
  ProductPriceType,
  ProductTarget,
  ProductType,
  ServiceLevelType,
  ServiceOrderState,
  type StorageItemsForOrderlinesRequest,
  SupportedCities,
} from '@wanda-space/types'
import {
  type OrderLineForNewItems,
  type OrderLineWithFullProductAndDiscount,
  type OrderlineInOrderResponse,
} from 'api-client'
import { PriceWrapper } from 'interfaces'
import * as R from 'ramda'

import { Pricetype } from '@wanda-space/ops-types'
import { isTwoDaysAhead } from './dates'
import { toSupportedCurrency } from './price-utils'
import { productToPrice } from './product-utils'

export const byProductType: (
  type: ProductType
) => (line: OrderLineWithFullProductAndDiscount | OrderlineInOrderResponse) => boolean = (type) => {
  return (line) => line.product.productType === type
}

export const byProductTypes: (
  types: ProductType[]
) => (line: OrderLineWithFullProductAndDiscount) => boolean = (types) => {
  return (line) => types.includes(line.product.productType)
}

export const bySupportedInCity: (
  city: SupportedCities
) => (line: OrderLineWithFullProductAndDiscount) => boolean = (city) => {
  return (line) => line.product.supportedInCities?.includes(city) ?? false
}

export const not: (
  fn: (line: OrderLineWithFullProductAndDiscount) => boolean
) => (line: OrderLineWithFullProductAndDiscount) => boolean = (fn) => {
  return (line) => !fn(line)
}

export const getPriceFromOrderLine: (line: OrderLineWithFullProductAndDiscount) => number = (
  line
) => line.quantity * line.product.price

export const sumPrice: (lines: OrderLineWithFullProductAndDiscount[]) => number = R.pipe(
  R.map(getPriceFromOrderLine),
  R.sum
)

export const groupByPriceType: (
  lines: OrderLineWithFullProductAndDiscount[]
) => Record<ProductPriceType, OrderLineWithFullProductAndDiscount[]> = (lines) => {
  return {
    ONE_TIME: [],
    RECURRING: [],
    ...(R.groupBy((line) => line.product.priceType, lines) as Partial<
      Record<ProductPriceType, OrderLineWithFullProductAndDiscount[]>
    >),
  }
}

export const isFlexAddress: (line: OrderLineWithFullProductAndDiscount) => boolean = ({
  product,
}) =>
  product.productType === ProductType.SERVICE &&
  product.target === ProductTarget.USER &&
  product.priceType === ProductPriceType.RECURRING &&
  product.localizationKey.includes('product.flex.address')

export const hasFlexAddress = (lines: OrderLineWithFullProductAndDiscount[]) =>
  lines.find(
    (line) =>
      line.product.productType === ProductType.USER_FEATURE &&
      line.product.target === ProductTarget.USER &&
      line.product.priceType === ProductPriceType.RECURRING
  )

export const isEditOrCancelOrderAllowed = (
  deliveryDate: string,
  timeslot: { from: string; to: string },
  state?: { orderState?: OrderState; serviceOrderState?: ServiceOrderState }
): boolean => {
  if (
    state?.orderState &&
    [
      OrderState.ORDER_REJECTED,
      OrderState.ORDER_FULFILLED,
      OrderState.SHIPMENT_HANDOVER_COMPLETED,
    ].includes(state?.orderState)
  ) {
    return false
  }

  if (
    state?.serviceOrderState &&
    [
      ServiceOrderState.ORDER_REJECTED,
      ServiceOrderState.ORDER_FULFILLED,
      ServiceOrderState.IN_PROGRESS,
    ].includes(state?.serviceOrderState)
  ) {
    return false
  }
  return isTwoDaysAhead(deliveryDate, timeslot)
}

export const getKeyForServiceLevel = (
  serviceLevelType: ServiceLevelType
): 'carrying' | 'curbside' | 'firstDoor' => {
  const serviceLevelToKey: Record<ServiceLevelType, 'carrying' | 'curbside' | 'firstDoor'> = {
    [ServiceLevelType.CARRYING]: 'carrying',
    [ServiceLevelType.CURBSIDE]: 'curbside',
    [ServiceLevelType.FIRST_DOOR]: 'firstDoor',
  }
  return serviceLevelToKey[serviceLevelType]
}

export const getTaasOrderLineForNewItemsByServiceLevelType = (
  serviceLevelType: ServiceLevelType,
  orderLineForNewItems: OrderLineForNewItems
) => {
  const serviceLevelTypeKey = getKeyForServiceLevel(serviceLevelType)
  return orderLineForNewItems[serviceLevelTypeKey] ?? []
}

export const getServiceLevelByOrderDetails = (serviceLevel: string): ServiceLevelType => {
  const serviceLevelToServiceLevelType: Record<string, ServiceLevelType> = {
    FIRST_DOOR: ServiceLevelType.FIRST_DOOR,
    CARRYING: ServiceLevelType.CARRYING,
    CURBSIDE: ServiceLevelType.CURBSIDE,
  }

  const serviceLevelType = serviceLevelToServiceLevelType[serviceLevel]

  return serviceLevelType
}

export const getOrderlinesWithItem = (orderlines: OrderLineWithFullProductAndDiscount[]) => {
  return orderlines.filter(
    (
      orderline
    ): orderline is OrderLineWithFullProductAndDiscount & {
      item: StorageItemsForOrderlinesRequest
    } => Boolean(orderline.item) && orderline.product.productType === ProductType.STORAGE
  )
}

export const orderlineToPrice = ({
  product,
}: OrderLineWithFullProductAndDiscount | OrderlineInOrderResponse) => productToPrice(product)

export const orderlineToProduct = ({ product }: OrderLineWithFullProductAndDiscount) => product

export const orderlineToDiscountedOrderline = (
  orderline: OrderLineWithFullProductAndDiscount | OrderlineInOrderResponse
): PriceWrapper => {
  return {
    oldPrice: orderline.product.price,
    amount: orderline.product.price - (orderline.discount?.discountAmount ?? 0),
    currency: toSupportedCurrency(orderline.product.currency),
  }
}

export const byProductPriceType: (
  priceType: ProductPriceType
) => (line: OrderLineWithFullProductAndDiscount | OrderlineInOrderResponse) => boolean = (type) => {
  return (line) => line.product.priceType === type
}
