export type Beacon = (event: string, options?: unknown) => void
export type Intercom = (event: string, options?: unknown) => void

export interface WindowWithPlugin extends Window {
  Beacon?: Beacon
  Intercom?: Intercom
}

export type HelpMailFormData = {
  fullName?: string
  email?: string
  subject?: string
  text?: string
}

export const openHelp = () => {
  const windowWithPlugin = window as WindowWithPlugin
  if (windowWithPlugin.Beacon) {
    windowWithPlugin.Beacon('open')
  }

  if (windowWithPlugin.Intercom) {
    windowWithPlugin.Intercom('show')
  }
}

export const openHelpMessageForm = (options?: HelpMailFormData) => {
  openHelp()
  const windowWithPlugin = window as WindowWithPlugin
  if (windowWithPlugin.Beacon) {
    windowWithPlugin.Beacon('navigate', '/ask/')
    windowWithPlugin.Beacon('prefill', {
      name: options?.fullName,
      email: options?.email,
      subject: options?.subject,
      text: options?.text,
    })
  }

  if (windowWithPlugin.Intercom) {
    windowWithPlugin.Intercom('showMessages')
  }
}

export const openHelpChat = () => {
  resetHelp()
  openHelp()
  const windowWithPlugin = window as WindowWithPlugin
  if (windowWithPlugin.Beacon) {
    windowWithPlugin.Beacon('navigate', '/ask/chat/')
  }

  if (windowWithPlugin.Intercom) {
    windowWithPlugin.Intercom('showNewMessage')
  }
}

export const resetHelp = () => {
  const windowWithPlugin = window as WindowWithPlugin
  if (windowWithPlugin.Beacon) {
    windowWithPlugin.Beacon('reset')
  }
}
