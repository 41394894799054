import { Box, Button, Flex, Heading, Spinner, Text, useDisclosure } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { ProductResponseDto, ProductType } from '@wanda-space/types'
import { Stepable } from 'interfaces'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch } from 'reduxStore'

import { selectStorageItems } from 'reduxStore/ducks/serviceFromStorage/selectors'
import {
  ProductSelector,
  ProductWithServiceProducts,
} from 'routes/ServiceFlow/components/ProductSelector'
import { ServiceOrdersBasket } from 'routes/ServiceFlow/components/ServiceOrdersBasket'
import { fetchServiceProvider } from '../../../api-client/lib/routes/serviceProviders'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { useProductsAndCategories } from '../../../hooks/useProductsAndCategories'
import {
  selectSelectedCategory,
  selectServiceOrderLines,
} from '../../../reduxStore/ducks/serviceFromStorage/selectors'
import {
  addServiceOrderLine,
  removeServiceOrderLine,
  updateCommentOnServiceOrderLine,
} from '../../../reduxStore/ducks/serviceFromStorage/serviceFromStorage'
import { add } from '../../../utils'
import {
  isApplicableToAnyService,
  isApplicableToProduct,
  productToPrice,
} from '../../../utils/product-utils'
import { AddServiceModal, AddServiceModalData } from '../components/AddServiceModal'
import { ServiceProviderIntro } from '../components/ServiceProviderIntro'
import { ServiceVendorBackground } from '../components/ServiceVendorBackground'

const SelectServiceProducts = ({ onNextStep }: Stepable) => {
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const [modalData, setModalData] = useState<AddServiceModalData>({ isOpen: false })
  const { isOpen: isBasketOpen, onOpen: openBasket, onClose: closeBasket } = useDisclosure()
  const coupon = useAppSelector((state) => state.servicesFlowNext.coupon)
  const { serviceProducts, isSuccess, isLoading } = useProductsAndCategories({
    couponCode: coupon,
  })
  const category = useAppSelector(selectSelectedCategory)
  const serviceOrderLines = useAppSelector(selectServiceOrderLines)
  const storageItems = useAppSelector(selectStorageItems)

  const { data: serviceProvider } = useQuery(
    ['service-provider', category?.serviceProviderId],
    () => {
      return fetchServiceProvider(category!.serviceProviderId!)
    },
    {
      enabled: !!category?.serviceProviderId,
    }
  )

  const totalServicePrice = useMemo(() => {
    return add(...serviceOrderLines.map(({ product }) => productToPrice(product)))
  }, [serviceOrderLines])

  const productDataItems: ProductWithServiceProducts[] = useMemo(() => {
    const storageProducts = storageItems.map((item) => item.storageProduct as ProductResponseDto)
    if (isSuccess && serviceProducts && category) {
      const relevantServiceProducts = serviceProducts.filter((p) => p.categoryId === category.id)
      return storageProducts
        .filter((p) => p.active)
        .filter((p) => p.productType === ProductType.STORAGE)
        .filter((p) => p.categoryId === category.id)
        .filter((p) => isApplicableToAnyService(relevantServiceProducts, p.id))
        .map((p) => {
          return {
            ...p,
            serviceProducts: relevantServiceProducts.filter((sp) =>
              isApplicableToProduct(sp, p.id)
            ),
            items: storageItems.map((item) => ({
              id: item.id,
              type: item.type,
              name: item.name,
              product: p,
            })),
          }
        })
    }
    return []
  }, [isSuccess, serviceProducts, category, storageItems])

  const handleOnNextStep = () => {
    if (typeof onNextStep === 'function') {
      onNextStep()
    }
  }

  if (isLoading) {
    return <Spinner size="large" />
  }

  const uniqueItems = new Set(
    serviceOrderLines.filter((sol) => !!sol.item).map((sol) => sol.item!.id)
  )

  const storageItemIdStorageProductIndex = Object.fromEntries(
    storageItems.map((item) => [item.id, item.storageProduct])
  )

  return (
    <Box>
      <ServiceVendorBackground />
      <ServiceProviderIntro serviceProvider={serviceProvider} />
      {serviceProvider && category && (
        <AddServiceModal
          onClose={() => setModalData({ isOpen: false })}
          onRemove={(index) => {
            dispatch(removeServiceOrderLine(index))
            setModalData({ isOpen: false })
          }}
          serviceProvider={serviceProvider}
          productCategory={category}
          onAdd={(comment: string) => {
            if (modalData.isOpen) {
              if (modalData.creatingNew) {
                dispatch(
                  addServiceOrderLine({
                    ...modalData.orderLine,
                    comment,
                  })
                )
              } else {
                if (typeof modalData.orderLineIndex === 'number') {
                  dispatch(
                    updateCommentOnServiceOrderLine({
                      comment,
                      index: modalData.orderLineIndex,
                    })
                  )
                }
              }
            }
            setModalData({ isOpen: false })
          }}
          data={modalData}
        />
      )}
      <ServiceOrdersBasket
        isOpen={isBasketOpen}
        onClose={closeBasket}
        onEditClick={(index) => {
          const serviceOrderLine = serviceOrderLines?.[index]
          if (serviceOrderLine) {
            setModalData({
              creatingNew: false,
              orderLineIndex: index,
              isOpen: true,
              orderLine: serviceOrderLine,
            })
          }
        }}
        serviceOrderLines={serviceOrderLines}
        storageItemIdStorageProductIndex={storageItemIdStorageProductIndex}
      />
      <Heading fontSize="2xl" mb={8}>
        {formatMessage({ id: 'service.letsGo' })}
      </Heading>
      <ProductSelector
        onSubItemAdd={(item, serviceProduct) => {
          const { product: storageProduct, ...itemProps } = item
          setModalData({
            isOpen: true,
            creatingNew: true,
            storageProduct,
            orderLine: {
              item: itemProps,
              product: serviceProduct,
              quantity: 1,
            },
          })
        }}
        list={productDataItems}
        expandedProducts={productDataItems.map((p) => p.id)}
        serviceOrderLines={serviceOrderLines}
      />
      {/* )} */}
      <Flex
        backgroundColor="paleBlue.300"
        direction="column"
        p={4}
        rowGap={2}
        borderRadius="3xl"
        mt={4}
      >
        <Text fontWeight="medium">{formatMessage({ id: 'booking.service.title' })}</Text>
        {serviceOrderLines.length > 0 ? (
          <>
            <Flex justifyContent="space-between" alignItems="center">
              <Text>
                {formatMessage(
                  {
                    id: 'booking.service.details',
                  },
                  {
                    serviceCount: serviceOrderLines.length,
                    itemsCount: uniqueItems.size,
                    price: totalServicePrice?.amount.toFixed(2) ?? '',
                  }
                )}
              </Text>
            </Flex>
            <Button
              variant="outline"
              colorScheme="white"
              borderColor="gray.300"
              onClick={() => openBasket()}
              fontWeight="medium"
              size="lg"
            >
              {formatMessage({ id: 'booking.confirmation.order.editOrder' })}
            </Button>
            <Button
              colorScheme="ctaBlack"
              isDisabled={!serviceOrderLines.length}
              data-testid="continue-button"
              size="lg"
              onClick={handleOnNextStep}
              width="100%"
            >
              {formatMessage({ id: 'word.continue' })}
            </Button>
          </>
        ) : (
          <Text>
            {formatMessage({
              id: 'phrase.addServices.before.continue',
            })}
          </Text>
        )}
      </Flex>
    </Box>
  )
}

export { SelectServiceProducts }
