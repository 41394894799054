import { Button, Flex, HStack, Image, Tag, Text } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { DynamicIcon, DynamicIconName } from '@wanda-space/noelle'
import { type ServiceProviderResponseDto } from '@wanda-space/types'
import React from 'react'
import { useIntl } from 'react-intl'

import {
  fetchServiceProviderImage,
  fetchServiceProviderImageIds,
} from '../../../api-client/lib/routes/serviceProviders'

export interface Props {
  startingPrice: number
  title: string
  onClick?: () => void
  icon: DynamicIconName
  serviceProvider: ServiceProviderResponseDto
  isPreviouslySelected: boolean
  'data-testid': string
}

export const ServiceProviderCard = ({
  onClick,
  icon,
  startingPrice,
  serviceProvider,
  title,
  isPreviouslySelected,
  ...rest
}: Props) => {
  const { formatMessage } = useIntl()

  const { data: image } = useQuery(
    ['service-provider-image-first', serviceProvider?.id],
    async () => {
      const fullImageIds = await fetchServiceProviderImageIds(serviceProvider.id)
      const fullImageId = fullImageIds?.[0] ?? null
      if (fullImageId?.split('/').length) {
        const imageId = fullImageId.split('/').pop()!
        const imageBlob = await fetchServiceProviderImage(serviceProvider.id, imageId)
        const dataUrl = URL.createObjectURL(imageBlob)
        return dataUrl
      }
    },
    {
      enabled: !!serviceProvider,
    }
  )

  return (
    <Button
      minHeight="170px"
      maxHeight="170px"
      maxWidth="350px"
      width="350px"
      pt={2}
      pr={2}
      pb={2}
      pl={2}
      border="2px solid"
      borderColor="gray.200"
      borderRadius="12px  "
      justifyContent="left"
      variant="outline"
      onClick={() => onClick?.()}
      height="auto"
      whiteSpace="normal"
      _hover={{
        background: 'gray.50',
      }}
      _active={{
        background: 'gray.50',
      }}
      data-testid={rest['data-testid']}
    >
      <Flex gap={4}>
        <Flex flexShrink="0" borderRadius="xl" width="72px" height="154px" m="0" overflow="hidden">
          {image && <Image src={image} objectFit="cover" objectPosition="center" />}
        </Flex>
        <Flex direction="column" gap={3} mt={1} justifyContent="space-between">
          <Flex alignItems="flex-start" maxWidth="100%" gap={2}>
            <DynamicIcon marginTop={0.5} iconName={icon} height={6} width={6} />
            <Text
              as="h3"
              fontSize="large"
              fontWeight="medium"
              noOfLines={2}
              display="inline-block"
              verticalAlign="middle"
            >
              {title}
            </Text>
          </Flex>
          <Text
            fontSize="medium"
            color="gray.600"
            as="h3"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="normal"
            maxWidth="200px"
            maxHeight="60px"
            minHeight="60px"
            noOfLines={3}
          >
            {formatMessage({ id: `${serviceProvider?.localizationKey}.introduction` })}
          </Text>
          <HStack>
            <Tag
              size="lg"
              borderRadius="lg"
              variant="solid"
              colorScheme="blue"
              color="black"
              backgroundColor="paleBlue.300"
              fontSize="sm"
              fontWeight="medium"
            >
              <HStack>
                <Text>
                  {formatMessage({
                    id: 'word.from',
                  }).toLowerCase()}
                </Text>
                <Text>{startingPrice},-</Text>
              </HStack>
            </Tag>
            {isPreviouslySelected && (
              <Tag
                backgroundColor="purple.700"
                variant="solid"
                size="lg"
                color="white"
                colorScheme="purple"
                textAlign="left"
                fontSize="sm"
                fontWeight="medium"
                borderRadius="lg"
                maxHeight={6}
                py={1}
                px={2}
              >
                <Text>
                  {formatMessage({
                    id: 'phrase.service.serviceStarted',
                  })}
                </Text>
              </Tag>
            )}
          </HStack>
        </Flex>
      </Flex>
    </Button>
  )
}
