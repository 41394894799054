export const useStripeRedirectHandler = () => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const redirectSuccess = urlSearchParams.get('redirect_status')
  const paymentIntentClientSecret = urlSearchParams.get('payment_intent_client_secret')
  const setupIntentClientSecret = urlSearchParams.get('setup_intent_client_secret')
  const paymentIntentId = urlSearchParams.get('payment_intent')
  const setupIntentId = urlSearchParams.get('setup_intent')

  let intentId: string | undefined

  if (setupIntentId) {
    intentId = setupIntentId
  }

  if (paymentIntentId) {
    intentId = paymentIntentId
  }

  const receivedIntentSecrets =
    Boolean(setupIntentClientSecret) || Boolean(paymentIntentClientSecret)
  const receivedIntentIds = Boolean(intentId)

  const hasPaymentSucceded =
    Boolean(redirectSuccess) && (receivedIntentSecrets || receivedIntentIds)

  return { hasPaymentSucceded, intentId }
}
