import { Flex, Text } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { Spinner } from '@wanda-space/noelle'
import { ProductPriceType, ProductType } from '@wanda-space/types'
import { getOrder } from 'api-client/lib/routes/orders'
import { BookingConfirmFooter } from 'components/booking-confirm-page-components/BookingConfirmFooter'
import { BookingConfirmationHeader } from 'components/booking-confirm-page-components/BookingConfirmHeader'
import { OrderlineAddress } from 'components/booking-confirm-page-components/OrderlineAddress'
import { WorthKnowingWrapper } from 'components/booking-confirm-page-components/WorthKnowingWrapper'
import { YouWillBeContacted } from 'components/booking-confirm-page-components/YouWillBeContacted'
import { Routes } from 'consts'
import { useAppSelector } from 'hooks/useAppSelector'
import React from 'react'
import { useIntl } from 'react-intl'
import { Navigate, useSearchParams } from 'react-router-dom'
import { linkFormatter } from 'utils/linkFormatter'
import { byProductType, orderlineToDiscountedOrderline } from 'utils/orderline'
import { add, priceToSupportedCurrency, sanitizeStripeAmount } from 'utils/price-utils'

const BookingConfirmation = () => {
  const { formatMessage } = useIntl()
  const [searchParams] = useSearchParams()
  const orderId = searchParams.get('orderId')
  const countryCode = useAppSelector((state) => state.user.user?.countryCode)

  const { data, isError, isLoading } = useQuery(['orderId', orderId], () => getOrder(orderId!))

  const storageOrderlines = data?.orderLines?.filter(byProductType(ProductType.STORAGE)) ?? []

  const oneTimeOrderlines = (data?.orderLines || []).filter(
    (ol) => ol.product.priceType === ProductPriceType.ONE_TIME
  )

  const totalOneTimeCost = add(...oneTimeOrderlines.map(orderlineToDiscountedOrderline))

  if (isError) {
    return <Navigate to={Routes.ReturnFlow} />
  }

  if (isLoading || !data) {
    return <Spinner />
  }

  return (
    <>
      <BookingConfirmationHeader />
      <Flex mt={3} flexDirection="column">
        <Text size="md" mb={5}>
          {formatMessage(
            { id: 'booking-confirm.order.details.returnFlow' },
            {
              itemCount: (
                <b>
                  {storageOrderlines.length}{' '}
                  {formatMessage(
                    {
                      id: 'word.thing.lowercase',
                    },
                    {
                      count: storageOrderlines.length,
                    }
                  )}
                </b>
              ),
              totalPrice: (
                <b>
                  {sanitizeStripeAmount(totalOneTimeCost.amount)}{' '}
                  {priceToSupportedCurrency(totalOneTimeCost, countryCode)}
                </b>
              ),
              emailAddress: data.orderDetails.contactInfo.email,
            }
          )}
        </Text>
        <WorthKnowingWrapper>
          <OrderlineAddress order={data} />
          <YouWillBeContacted messageId="booking.confirmation.order.worthKnowing.returnFlow.youWillBeContacted" />
        </WorthKnowingWrapper>
        <BookingConfirmFooter
          detailsMessage={formatMessage(
            {
              id: 'booking.confirmation.order.returnFlow.forgotSomething.details',
            },
            {
              termsOfUseLink: (
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  hrefLang={formatMessage({ id: 'word.languageCode' })}
                  href={linkFormatter(formatMessage, { navigation: 'terms' })}
                  style={{ textDecoration: 'underline' }}
                >
                  {formatMessage({ id: 'summary.termsOfUse' })}
                </a>
              ),
            }
          )}
          editOrderBtn={{
            show: true,
            orderId: data.id,
          }}
        />
      </Flex>
    </>
  )
}

export { BookingConfirmation }
