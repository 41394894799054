import { StripeError } from '@stripe/stripe-js'
import { Notification } from '@wanda-space/noelle'
import React from 'react'
import { useIntl } from 'react-intl'

export class UnknownPaymentError extends Error {
  code = 'general'
  constructor() {
    super('Unknown payment error')
    Object.setPrototypeOf(this, UnknownPaymentError.prototype)
    this.name = this.constructor.name
  }
}
export class AuthenticationError extends Error {
  code = 'authentication'
  constructor() {
    super('Unknown payment error')
    Object.setPrototypeOf(this, UnknownPaymentError.prototype)
    this.name = this.constructor.name
  }
}

export class MissingStripeWhenSubmittingError extends Error {
  code = 'general'
  constructor() {
    super('Stripe not loaded before submitting')
    Object.setPrototypeOf(this, MissingStripeWhenSubmittingError.prototype)
  }
}
export class UnsuccessfulSetupIntentError extends Error {
  code = 'general'
  constructor() {
    super('Unsuccessful setup intent error')
    Object.setPrototypeOf(this, UnsuccessfulSetupIntentError.prototype)
    this.name = this.constructor.name
  }
}

export const PaymentError = ({
  error,
  orderPlacementError = false,
  onClose,
  wide,
}: {
  onClose?: () => void
  error: StripeError | UnknownPaymentError | AuthenticationError | undefined
  orderPlacementError?: boolean
  wide?: boolean
}) => {
  const { formatMessage } = useIntl()

  if (!error) {
    return null
  }

  let text: string

  if (error?.code === 'authentication') {
    text = formatMessage({ id: 'payment.authentication.fail' })
  } else if (error?.code && error?.code !== 'general' && error.message) {
    text = error.message
  } else {
    text = formatMessage({ id: 'error.general' })
  }

  return (
    <Notification
      id="error"
      dismissible={!!onClose}
      onClose={onClose}
      orderPlacementError={orderPlacementError}
      text={text}
      type="errors"
      wide={wide}
    />
  )
}
