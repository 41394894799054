import { StorageItemType } from '@wanda-space/types'

import { FormatMessage } from './format'

export const translateItemType = (formatMessage: FormatMessage, itemType: StorageItemType) => {
  if (itemType === StorageItemType.LARGE) {
    return formatMessage({ id: 'item.large' })
  } else if (itemType === StorageItemType.BOX) {
    return formatMessage({ id: 'item.box' })
  }
  return formatMessage({ id: 'item.small' })
}
