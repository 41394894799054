function formatTime(time: string) {
  return `${time.substring(0, 2)}:${time.substring(2, 4)}`
}

export function formatTimeSlot(timeslot: { from: string; to: string } | undefined) {
  if (!timeslot) {
    return ''
  }
  return `${formatTime(timeslot.from)} - ${formatTime(timeslot.to)}`
}

export function timeSlotToString({ from, to }: { from: string; to: string }) {
  return `${from}-${to}`
}

export function timeSlotFromString(timeslot: string) {
  const [from, to] = timeslot.split('-')
  return { from, to }
}
