import { Text } from '@chakra-ui/react'
import { ChangeAddressButton } from 'components/contact-info/ChangeAddressButton'
import { ContactInfoCard } from 'components/contact-info/ContactInfoCard'
import { ContactInfoForm } from 'components/contact-info/ContactInfoForm'
import {
  useAddressRenderStates,
  useContactInfoData,
  useFlexAddress,
} from 'components/contact-info/hooks'
import {
  AddressRenderState,
  ContactInfoData,
  ContactInfoPageSubmitHandlerParams,
  FieldNames,
} from 'components/contact-info/types'
import {
  isAddressComplete,
  isFlexAddressDisabled,
  isOrderAddressSameAsUserAddress,
  showContactCardCheck,
} from 'components/contact-info/utils'
import { useActiveUserLocation } from 'hooks/useActiveUserLocation'
import { useAppSelector } from 'hooks/useAppSelector'
import { useHideFields } from 'hooks/useHideFields'
import type { Stepable } from 'interfaces'
import React from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch } from 'reduxStore'
import {
  addAddons,
  currentUserAddressSelector,
  orderContactPersonSelector,
  removeAddon,
  setContactPerson,
  updateAddress,
} from 'reduxStore/ducks/returnFlow/reducers'
import { patchAddress } from 'reduxStore/ducks/user/user'

export const Address = ({ onNextStep }: Stepable) => {
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const orderAddress = useAppSelector(currentUserAddressSelector)
  const orderContactPerson = useAppSelector(orderContactPersonSelector)
  const { storage, addons } = useAppSelector((state) => state.returnFlow.orderLines)
  const {
    isCheckingFlexAddress,
    isOneTimeFlexOrderlineLoading,
    oneTimeFlexOrderline,
    hasFlexAddressSubscription,
    alreadyAddedOnetimeFlexAddress,
  } = useFlexAddress(storage, addons)
  const { addressRenderState, setAddressRenderState, setInitialRenderState } =
    useAddressRenderStates()
  const { contactInfo, userAddress, accountId, isUserAddressComplete } =
    useContactInfoData(orderAddress)
  const { country } = useActiveUserLocation()
  const isFlexedEnabled = isFlexAddressDisabled(country) === false
  const hideFields = useHideFields()

  if (userAddress) {
    setInitialRenderState(userAddress, orderAddress)
  }

  const onSubmit = async (values: ContactInfoPageSubmitHandlerParams) => {
    const { contactInfo, contactPerson } = values
    dispatch(setContactPerson(contactPerson))
    dispatch(updateAddress(contactInfo))

    if (userAddress && isUserAddressComplete === false) {
      dispatch(patchAddress(contactInfo))
    }

    if (
      userAddress &&
      oneTimeFlexOrderline &&
      isOrderAddressSameAsUserAddress(userAddress, contactInfo) &&
      alreadyAddedOnetimeFlexAddress
    ) {
      dispatch(removeAddon(alreadyAddedOnetimeFlexAddress))
    }

    onNextStep()
  }

  const updateAddressRenderState = (state: AddressRenderState) => {
    if (alreadyAddedOnetimeFlexAddress) {
      dispatch(removeAddon(alreadyAddedOnetimeFlexAddress))
    }
    if (state === AddressRenderState.USER_DEFAULT_ADDRESS && userAddress) {
      dispatch(updateAddress(userAddress as unknown as ContactInfoData))
    } else if (state === AddressRenderState.CHANGE_ORDER_ADDRESS) {
      if (accountId && oneTimeFlexOrderline) {
        dispatch(
          addAddons({
            product: oneTimeFlexOrderline?.orderline.product,
            accountId,
          })
        )
      }
    }

    setAddressRenderState(state)
  }

  const showContactInfoCard = showContactCardCheck(addressRenderState)
  let disabledFields: FieldNames[] = []
  const isUserDefaultAddressIncomplete =
    addressRenderState === AddressRenderState.USER_DEFAULT_ADDRESS_INCOMPLETE

  if (
    isFlexedEnabled &&
    !hasFlexAddressSubscription &&
    (!oneTimeFlexOrderline?.orderline || isUserDefaultAddressIncomplete)
  ) {
    disabledFields = []
    if (contactInfo.firstName !== '') {
      disabledFields.push(FieldNames.FIRST_NAME)
    }

    if (contactInfo.lastName !== '') {
      disabledFields.push(FieldNames.LAST_NAME)
    }

    if (contactInfo.phoneNumber !== '') {
      disabledFields.push(FieldNames.PHONE_NUMBER)
    }

    if (contactInfo.city !== '') {
      disabledFields.push(FieldNames.CITY)
    }

    if (contactInfo.street !== '') {
      disabledFields.push(FieldNames.STREET)
    }

    if (contactInfo.postalCode !== '') {
      disabledFields.push(FieldNames.POSTCODE)
    }
  }

  const showDefaultAddressButton =
    !isUserAddressComplete && addressRenderState === AddressRenderState.CHANGE_ORDER_ADDRESS

  return showContactInfoCard ? (
    <ContactInfoCard
      showContactPerson
      onSubmit={onSubmit}
      defaultContactPerson={orderContactPerson}
      contactInfo={contactInfo}
      updateAddressRenderState={updateAddressRenderState}
      isCheckingFlexAddress={isCheckingFlexAddress || isOneTimeFlexOrderlineLoading}
      flexOneTimeOrderline={oneTimeFlexOrderline?.orderline}
      changeAddressButtonTextId="word.change.deliveryAddress"
    />
  ) : (
    <>
      <ContactInfoForm
        hideFields={hideFields}
        showContactPerson
        contactPerson={orderContactPerson}
        contactInfo={contactInfo}
        onSubmit={onSubmit}
        disableFields={disabledFields}
        flexOneTimeOrderline={oneTimeFlexOrderline?.orderline}
        updateAddressRenderState={
          isUserAddressComplete || showDefaultAddressButton ? updateAddressRenderState : undefined
        }
      />
      {isUserDefaultAddressIncomplete && (
        <ChangeAddressButton
          onClick={() => updateAddressRenderState(AddressRenderState.CHANGE_ORDER_ADDRESS)}
          isCheckingFlexAddress={isCheckingFlexAddress || isOneTimeFlexOrderlineLoading}
          changeAddressButtonTextId="word.change.deliveryAddress"
          flexOneTimeOrderline={oneTimeFlexOrderline?.orderline}
        />
      )}
      {isFlexedEnabled &&
        userAddress &&
        !hasFlexAddressSubscription &&
        isAddressComplete(userAddress) &&
        !oneTimeFlexOrderline?.orderline.product && (
          <Text m={2}>{formatMessage({ id: 'flexAddress.change' })}</Text>
        )}
    </>
  )
}
