import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { MapPinIcon } from '@wanda-space/noelle'
import { useActiveUserLocation } from 'hooks/useActiveUserLocation'
import { useAppSelector } from 'hooks/useAppSelector'
import React from 'react'
import { useIntl } from 'react-intl'
import { useAuth } from '../hooks/useAuth'
import { PostalCodeForm } from './PostalCodeForm'

type Props = {
  onChange?: () => void
}

export function InlinePostalCodeSelector(props: Props) {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { formatMessage } = useIntl()
  const { isAuthenticated } = useAuth()
  const user = useAppSelector((state) => state.user.user)

  const { postalCode } = useActiveUserLocation()

  const onSubmit = () => {
    props.onChange?.()
    onClose()
  }

  return (
    <>
      <Flex alignItems="left">
        <MapPinIcon mr="1" />
        {postalCode}
        {(!isAuthenticated || !user?.address?.postalCode) && (
          <Text color="gray.500">
            &nbsp;(
            <Button variant="link" onClick={onOpen}>
              {formatMessage({ id: 'word.change' })}
            </Button>
            )
          </Text>
        )}
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size="xs">
        <ModalOverlay />
        <ModalContent position="relative" py={5} backgroundColor="purple.300" borderRadius="lg">
          <ModalCloseButton position="absolute" top="-1" right="-1" />
          <ModalBody background="transparent">
            <PostalCodeForm onSubmit={onSubmit} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
