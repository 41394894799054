import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { type ItemResponseDto, StorageItemType } from '@wanda-space/types'
import { ItemPayloadWithProduct } from 'reduxStore/commonMappers'
import { hasItemLimitExceeded } from 'utils/product-utils'

import { SliceNames } from '../../constants'

export interface EditStorageFlowState {
  newItems: ItemPayloadWithProduct[]
  bookedItems: ItemResponseDto[]
  pickerItems: ItemPayloadWithProduct[]
  selectedDeliveryItems: ItemResponseDto[]
  limitOfItemsExceeded: boolean
  shouldUpdateItemsWithTimeslot: boolean
}

const initialState: EditStorageFlowState = {
  newItems: [] as ItemPayloadWithProduct[],
  bookedItems: [] as ItemResponseDto[], // to keep track of initial booked items
  pickerItems: [] as ItemPayloadWithProduct[],
  selectedDeliveryItems: [] as ItemResponseDto[],
  limitOfItemsExceeded: false,
  shouldUpdateItemsWithTimeslot: false,
}
export const editStorageFlowStateSlice = createSlice({
  name: SliceNames.EDIT_STORAGE,
  initialState,
  reducers: {
    setShouldUpdateItemsWithTimeslot: (state, action: PayloadAction<boolean>) => {
      state.shouldUpdateItemsWithTimeslot = action.payload
    },
    setNewItems: (state, action: PayloadAction<ItemPayloadWithProduct[]>) => {
      state.newItems = action.payload
      state.shouldUpdateItemsWithTimeslot = true
    },
    setPickerItems: (state, action: PayloadAction<ItemPayloadWithProduct[]>) => {
      state.pickerItems = action.payload
    },
    setBookedItems: (state, action: PayloadAction<ItemResponseDto[]>) => {
      state.bookedItems = action.payload
    },
    toggleDeliveryItem: (state, action: PayloadAction<ItemResponseDto>) => {
      if (state.selectedDeliveryItems.find((i) => i.id === action.payload.id) !== undefined) {
        state.selectedDeliveryItems = state.selectedDeliveryItems.filter(
          (i) => i.id !== action.payload.id
        )
      } else {
        state.selectedDeliveryItems = [...state.selectedDeliveryItems, action.payload]
      }
      const sizes = state.selectedDeliveryItems.reduce<Record<StorageItemType, number>>(
        (acc, item) => {
          acc[item.type] += 1
          return acc
        },
        {
          SMALL: 0,
          LARGE: 0,
          BOX: 0,
        }
      )
      state.limitOfItemsExceeded = hasItemLimitExceeded(sizes)
    },
    setDeliveryItems: (state, action: PayloadAction<ItemResponseDto[]>) => {
      state.selectedDeliveryItems = action.payload
    },
    clearState: () => {
      return initialState
    },
  },
})

export const {
  setNewItems,
  setBookedItems,
  clearState,
  setPickerItems,
  toggleDeliveryItem,
  setDeliveryItems,
  setShouldUpdateItemsWithTimeslot,
} = editStorageFlowStateSlice.actions

export const editStorageFlow = editStorageFlowStateSlice.reducer
