/** @file These are utilities to parse common data types into the format we want to log */

import { type ListingResponseDto, type ProductCategoryResponseDto } from '@wanda-space/types'
import type { DateAndTime, OrderLineWithFullProductAndDiscount } from 'api-client'
import type { FlattenedDeliveryInfo, PriceWrapper } from 'interfaces'
import type { RootState } from 'reduxStore'
import type { reduxSliceKeys } from 'reduxStore/constants'
import type {
  GA4PurchaseEvent,
  GA4PurchaseEventItem,
  GA4PurchaseEvent_wrapped,
  TrackerDTO,
} from 'tracking/types'
import { v5 as uuidv5 } from 'uuid'

type KnownObj<T = any> = Record<string, T>
/** Helper - returns an object that only includes relevant keys, as provided in `keys` param */
const util_getWhatsRelevant = (obj: KnownObj, keys: Array<keyof KnownObj>): Partial<KnownObj> =>
  Object.fromEntries(Object.entries(obj).filter(([key]) => keys.includes(key)))

/** As we're already using the uuid package, this is an adequate (SHA-1) way to create a hash from UUID. */
const util_makeHashFromUUID = (uuid: string): string => uuidv5(uuid, uuidv5.URL)

/** Little helper to handle optional fields for price, tax etc, which are provided as strings as cents */
const getNumVal = (val: number | string | undefined): number => (val && Number(val) / 100) || 0

const UNKNOWN = 'unknown'

/** Functions that take payload by type, and return what's relevant for logging */
const util_getWhatsRelevantByType = {
  orderLine: (ol: OrderLineWithFullProductAndDiscount) => {
    return {
      item: ol.item && util_getWhatsRelevant(ol.item, ['type', 'name']),
      product: util_getWhatsRelevant(ol.product, ['localizationKey', 'name']),
      quantity: ol.quantity,
    }
  },
  orderLines: (ols: OrderLineWithFullProductAndDiscount[]) => ols.map((ol) => ol.product.name),
  dateAndTimeslot: (dt: DateAndTime) =>
    dt.timeslot && dt.date
      ? {
          date: dt.date,
          timelsot_from: dt.timeslot.from,
          timelsot_to: dt.timeslot.to,
        }
      : {},
  productCategory: (pc: ProductCategoryResponseDto) =>
    util_getWhatsRelevant(pc, ['name', 'serviceProviderId', 'localizationKey', 'id']),
  deliveryInfo: (di: FlattenedDeliveryInfo) => {
    di.postalCode
  },
  priceWrapper: (pw: PriceWrapper) => {
    return {
      amount: pw.amount,
      currency: pw.currency,
    }
  },
}

type undecoratedTrackerDTO = Omit<TrackerDTO, '_country' | '_language' | '_city'>

/**
 * Assures standardized format of tracker DTOs, regardless of whether
 * useMixpanelTracker or redux middleware is triggering given event
 */
const getDecoratedTrackerDTO = (
  dto: undecoratedTrackerDTO,
  uiState: RootState['ui'],
  flow?: TrackerDTO['_flow']
): TrackerDTO => {
  return {
    _country: uiState.country || UNKNOWN,
    _language: uiState.language || UNKNOWN,
    _city: uiState.city || UNKNOWN,
    ...dto,
    _flow: flow || dto._flow || UNKNOWN,
  }
}

/**
 * Puts a {@link GA4PurchaseEvent} object into a standardized {@link GA4PurchaseEvent_wrapped wrapper},
 * so it can be handled by both GA and MixPanel.
 */
const wrapGA4PurchaseEvent = (
  ga4Purchase: GA4PurchaseEvent | Record<'_spaceship_error', string>,
  flow: reduxSliceKeys | 'unknown' | 'listing' | null,
  flowVariant?: 'listingBuy' | 'listingBid'
): GA4PurchaseEvent_wrapped => ({
  _flow: flow || UNKNOWN,
  _flow_variant: flowVariant || null,
  _event_type: 'purchase',
  _purchase: ga4Purchase,
})

/**
 * Generates and returns a {@link GA4PurchaseEvent_wrapped} object based on a ListingDto object
 * The optional `bid` param is used to indicate if the listing was purchased via bidding or not.
 *
 * If `bid` is set to a number greater than 0, it will be treated as the accepted bid amount,
 * and the *item_category2* will be set to `bid` (otherwise to `buy`).
 *
 * Note, this is a a quick fix, and should be refactored at some point to use the same logic as
 * parseOrderLineArraysForPurchaseEvent (based on proper order lines).
 */
const parseListingForPurchaseEvent = (
  listing: ListingResponseDto,
  bid = 0
): GA4PurchaseEvent_wrapped => {
  const { currency, price, id, description, conditionType } = listing

  const price_normalized = (bid > 0 && bid) || getNumVal(price)
  const id_scrambled = util_makeHashFromUUID(id)

  const ga4Purchase: GA4PurchaseEvent = {
    transaction_id: id_scrambled,
    currency,
    value: price_normalized,
    items: [
      {
        price: price_normalized,
        item_id: id_scrambled,
        item_name: description || UNKNOWN,
        item_category: 'listing',
        item_category2: bid ? 'bid' : 'buy',
        item_variant: conditionType || UNKNOWN,
      },
    ],
  }

  return wrapGA4PurchaseEvent(ga4Purchase, 'listing', bid ? 'listingBid' : 'listingBuy')
}

/**
 * Generates and returns a {@link GA4PurchaseEvent} object
 *
 * @param olArrs                  Array of {@link OrderLine} arrays
 * @param transaction_id          Transaction ID
 * @param ignoreStorageOrderLines If true, {@link OrderLine}s with productType STORAGE will be ignored
 */
const parseOrderLineArraysForPurchaseEvent = (
  olArrs: OrderLineWithFullProductAndDiscount[][],
  transaction_id: string,
  ignoreStorageOrderLines = false
): GA4PurchaseEvent => {
  const items: GA4PurchaseEventItem[] = []
  const totals = {
    value: 0,
    price: 0,
    discount: 0,
    tax: 0,
  }
  let currency = ''

  const round2dec = (val: number): number => Math.round(val * 100) / 100

  /**
   * Retrieves / calculates and returns relevant info from an {@link OrderLine} (per item).
   * Also updates totals, which are used to calculate event-level values.
   *
   * It looks like the GA4 documentation & examples provide some contradictory information,
   * but people in forums seem to agree that discount is the difference between value and price:
   *
   * - value: list price (before discount)
   * - price: price after discount
   * - discount: discount amount
   *
   * This functions also handles the case where value is not provided, but discount is.
   * And vice versa, where discount is not provided, but value is.
   */
  const getOrderLineDetailsAndUpdateTotals = (
    ol: OrderLineWithFullProductAndDiscount
  ): GA4PurchaseEventItem => {
    const price = getNumVal(ol.product.price) // mandatory for Product
    let value = getNumVal(ol.product.originalPrice) // NOT mandatory for Product
    let discount = getNumVal(ol.discount?.discountAmount || ol.product?.discount?.amount) // NOT mandatory for OrderLine

    if (!value && discount) {
      // Since originalPrice (value) is not mandatory for Product, we might need to calculate it from price and discount
      value = price + discount
    } else if (!discount && value) {
      // Since discount is not mandatory for OrderLine, we might need to calculate it from price and value
      discount = value - price
    }

    if (!value) value = price // If we still don't have value, we'll use price as value

    const taxRate = getNumVal(ol.product?.taxRate) // NOT mandatory for Product!?
    const quantity = ol.quantity || 1

    totals.value += value * quantity
    totals.price += price * quantity
    totals.discount += discount * quantity
    totals.tax += price * taxRate * quantity

    return {
      quantity,
      discount,
      price,
      item_id: ol.product.id,
      item_name: ol.product.name,
      item_list_name: ol.product.productType, // 'STORAGE'|'TAAS'|'SERVICE'|'USER_FEATURE'|'ADD_ON'|'SHOP'|'SHIPPING'
      item_variant: ol.product.priceType, // 'RECURRING' | 'ONE_TIME'
      //item_category: Could be added, but we don't seem to have a taxonomy for this
      // TODO: ADD CUSTOM ATTRIBUTES?
    }
  }

  for (const olArr of olArrs) {
    for (const ol of olArr) {
      if (!currency) currency = ol.product?.currency?.toUpperCase() || ''
      if (ignoreStorageOrderLines && ol.product.productType === 'STORAGE') continue
      items.push(getOrderLineDetailsAndUpdateTotals(ol))
    }
  }

  // Round all totals to 2 decimals
  totals.tax = round2dec(totals.tax)
  totals.discount = round2dec(totals.discount)
  totals.price = round2dec(totals.price)
  totals.value = round2dec(totals.value)

  return {
    transaction_id,
    currency,
    items,
    ...totals,
  }
}

export {
  KnownObj,
  util_getWhatsRelevantByType,
  parseOrderLineArraysForPurchaseEvent,
  parseListingForPurchaseEvent,
  getDecoratedTrackerDTO,
  util_makeHashFromUUID,
  wrapGA4PurchaseEvent,
}
