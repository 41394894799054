import {
  type PaginatedResponseDto,
  type ProductSubscriptionResponseDto,
  type UsedM2SpaceResponseDto,
} from '@wanda-space/types'

import { request } from '../api-client'

export async function getProductSubscriptions() {
  return request<PaginatedResponseDto<ProductSubscriptionResponseDto>>({
    method: 'GET',
    url: 'product-subscriptions',
  })
}

export async function getUsedSpace() {
  return request<UsedM2SpaceResponseDto>({
    method: 'GET',
    url: 'product-subscriptions/get-m2-used-space',
  })
}

export async function cancelProductSubscription(id: string): Promise<void> {
  return request({
    method: 'DELETE',
    url: `product-subscriptions/cancel/${id}`,
  })
}
