import { useQuery } from '@tanstack/react-query'
import { fetchItems } from 'api-client/lib/routes/items'
import { User } from 'interfaces/user'

export const reactQueryKeyFetchItems = 'fetchItems'

export function useItems(user?: User, options?: { enabled?: boolean }) {
  return useQuery(
    [reactQueryKeyFetchItems, user],
    () => fetchItems({ page: 0, itemsPerPage: 2000 }),
    options
  )
}
