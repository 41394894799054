import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { PostalCodeDto } from '@wanda-space/ops-types'
import { PostalCodeFilters } from '@wanda-space/types'
import { fetchPostalCodes } from 'api-client/lib/routes/postalCodes'

type PostalCodesProps = PostalCodeFilters & {
  enableCondition?: boolean
}

export const usePostalCodes = (
  { country, postalCode, city }: PostalCodesProps = {},
  options?: UseQueryOptions<PostalCodeDto[]>
) => {
  return useQuery<PostalCodeDto[]>({
    queryKey: ['postal-codes', country, postalCode, city],
    queryFn: () => fetchPostalCodes({ country, postalCode, city }),
    enabled: true,
    ...options,
  })
}
