import { captureException } from '@sentry/react'
import { Stripe, loadStripe } from '@stripe/stripe-js'
import { getEnv } from 'env'
import { useAppSelector } from 'hooks/useAppSelector'
import { mapLocaleToShortString } from 'i18n'
import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react'

interface StripeProps {
  children: ReactNode
}

const initialStripeState: StripeStateInitial = {
  loading: false,
  stripe: null,
}

type StripeState = StripeStateSuccess | StripeStateLoading | StripeStateError | StripeStateInitial

export const StripeContext = React.createContext<StripeState>({ loading: false, stripe: null })
export const useStripeContext = () => {
  return useContext(StripeContext)
}

interface StripeStateSuccess {
  error?: never
  stripe: Stripe
  loading: false
}

interface StripeStateLoading {
  error?: never
  stripe: null
  loading: true
}

interface StripeStateInitial {
  error?: never
  stripe: null
  loading: false
}

interface StripeStateError {
  error: unknown
  stripe: null
  loading: false
}

export const StripeProvider = ({ children }: StripeProps) => {
  const [stripe, setStripe] = useState<StripeState>(initialStripeState)
  const locale = useAppSelector((state) => state.user.user?.locale || state.ui.language)
  const mountedRef = useRef(false)

  const setupStripe = async () => {
    try {
      setStripe({ loading: true, stripe: null })
      const stripe = await loadStripe(getEnv('STRIPE_PUBLIC_KEY') || '', {
        locale: mapLocaleToShortString(locale),
      })
      if (!stripe) {
        throw new Error('Missing stripe context')
      }
      if (mountedRef.current) {
        setStripe({
          stripe,
          loading: false,
        })
      }
    } catch (error) {
      if (mountedRef.current) {
        setStripe({
          error,
          stripe: null,
          loading: false,
        })
      }
      captureException(error)
    }
  }

  useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true
    }
    setupStripe()
    return () => {
      mountedRef.current = false // clean up function
    }
  }, [locale])

  return <StripeContext.Provider value={stripe}>{children}</StripeContext.Provider>
}
