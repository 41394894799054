import { format as dateFormat } from 'date-fns'
import noLocale from 'date-fns/locale/nb'
import sveLocale from 'date-fns/locale/sv'
import { type FlattenedDeliveryInfo } from 'interfaces'
import { IntlFormatters } from 'react-intl'

export type FormatMessage = IntlFormatters['formatMessage']

export const getLocale = (language?: string): Locale | undefined => {
  if (language === 'no') {
    return noLocale
  } else if (language === 'sv') {
    return sveLocale
  }
  if (language === 'sv') {
    return sveLocale
  }

  // Undefined locale will yield generic English formatting.
  return undefined
}

/**
 * Wrapper for date-fns format. Uses locale for formatting.
 */
export const format = (date: Date | number, _format: string, language?: string): string => {
  const locale = getLocale(language)
  return dateFormat(date, _format, { locale })
}

export function formatUnixTimestamp(timestamp: number, locale: string) {
  const DateFormat = ' do MMMM yyyy'
  const date = new Date(timestamp * 1000)
  return dateFormat(date, DateFormat, { locale: getLocale(locale) })
}

export function nameToCapitalFirstLetterOnly(name: string) {
  if (typeof name !== 'string') return ''
  const nameString = name.toLowerCase()
  return nameString.replace(/(?:^|\s|-)([a-z0-9øæå]\w{0})/gi, (s) => s.toUpperCase())
}
export type FormatAddressInput = Pick<FlattenedDeliveryInfo, 'street' | 'postalCode' | 'city'>

export function formatAddress({ street, postalCode, city }: FormatAddressInput) {
  return `${street}, ${postalCode} ${city}`
}
