import {
  type OrderResponseWithItems,
  OrderType,
  ServiceOrderState,
  StorageItemType,
} from '@wanda-space/types'
import { IntlShape } from 'react-intl'

export const getStorageOrderDescription = (
  order: OrderResponseWithItems,
  formatMessage: IntlShape['formatMessage']
) => {
  const [smallItemsCount, largeItemsCount, boxItemsCount] = order.storageItems.reduce(
    ([smallItems, largeItems, boxItems], item) => {
      if (item.type === StorageItemType.LARGE) {
        return [smallItems, largeItems + 1, boxItems]
      } else if (item.type === StorageItemType.BOX) {
        return [smallItems, largeItems, boxItems + 1]
      }
      return [smallItems + 1, largeItems, boxItems]
    },
    [0, 0, 0]
  )

  const pickupOrDelivery = order.type === OrderType.DELIVERY ? 'word.returnOf' : 'word.pickupOf'
  let description = `${formatMessage({ id: pickupOrDelivery })}`
  const items = formatMessage(
    { id: 'word.itemsPlural' },
    { length: smallItemsCount + largeItemsCount }
  )

  if (smallItemsCount > 0) {
    description = `${description} ${smallItemsCount} ${formatMessage(
      { id: 'word.small' },
      { length: smallItemsCount }
    )} `
  }
  if (smallItemsCount > 0 && largeItemsCount > 0) {
    description = `${description} ${formatMessage({ id: 'word.and' }).toLowerCase()}`
  }
  if (smallItemsCount > 0 && boxItemsCount > 0) {
    description = `${description} ${formatMessage({ id: 'word.and' }).toLowerCase()}`
  }

  if (boxItemsCount > 0) {
    description = `${description} ${boxItemsCount} ${formatMessage(
      { id: 'word.box' },
      { length: boxItemsCount }
    )}`
  }
  if (boxItemsCount > 0 && largeItemsCount > 0) {
    description = `${description} ${formatMessage({ id: 'word.and' }).toLowerCase()}`
  }

  if (largeItemsCount > 0) {
    description = `${description} ${largeItemsCount} ${formatMessage(
      { id: 'word.big' },
      { length: largeItemsCount }
    )}`
  }

  return smallItemsCount || largeItemsCount ? `${description} ${items}` : `${description}`
}

export const getServiceOrderString = (
  status: string,
  formatMessage: IntlShape['formatMessage']
) => {
  switch (status) {
    case ServiceOrderState.PAYMENT_AUTHORISED:
      return formatMessage({ id: 'serviceOrder.status.ordered' })

    case ServiceOrderState.IN_PROGRESS:
      return formatMessage({ id: 'serviceOrder.status.withPartner' })

    case ServiceOrderState.ORDER_REJECTED:
      return formatMessage({ id: 'serviceOrder.status.orderRejected' })

    case ServiceOrderState.ORDER_FULFILLED:
      return formatMessage({ id: 'serviceOrder.status.orderDone' })
    default:
      return ''
  }
}
