import { SupportedCountries } from '@wanda-space/types'

export const toSupportedCountry = (val: unknown): SupportedCountries | undefined => {
  if (typeof val === 'string') {
    const supportedCountries = Object.values(SupportedCountries)
    const country = val.toUpperCase() as SupportedCountries
    if (supportedCountries.includes(country)) {
      return country
    }
  }

  return
}
