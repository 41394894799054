export const handleApiValidationErrors = (
  { message }: any,
  setFieldError: (field: any, message: string) => void
) => {
  if (message && Array.isArray(message)) {
    message.forEach(({ property, children, constraints }) => {
      if (children && children.length > 0) {
        children.forEach(({ property: childProperty, constraints: childConstraints }: any) => {
          setFieldError(childProperty, Object.values(childConstraints || {}).join(' '))
        })
      } else {
        setFieldError(property, Object.values(constraints).join(' '))
      }
    })
  }
}

export const mapApiValidationErrors = ({ message }: any) => {
  if (message && Array.isArray(message)) {
    return message
      .map(({ children, constraints }) => {
        if (children && children.length > 0) {
          return children
            .map(({ constraints: childConstraints }: any) => {
              return Object.values(childConstraints).join(' ')
            })
            .join(' ')
        }
        return Object.values(constraints).join(' ')
      })
      .join(' ')
  }
  if (typeof message === 'string') {
    return message
  }
  return ''
}
