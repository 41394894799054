import { captureException } from '@sentry/react'
import { useElements } from '@stripe/react-stripe-js'
import type { StripeCardNumberElement, StripeError } from '@stripe/stripe-js'
import { useQuery } from '@tanstack/react-query'
import { createSetupIntent, fetchSubscription, getPaymentMethods } from 'api-client'
import type { CustomerPaymentMethod } from 'api-client'
import {
  UnknownPaymentError,
  UnsuccessfulSetupIntentError,
} from 'components/PaymentOptions/PaymentError'
import {
  AuthenticationError,
  MissingStripeWhenSubmittingError,
} from 'components/PaymentOptions/PaymentError'
import type { UsePayment } from 'components/PaymentOptions/types'
import { useStripeContext } from 'contexts/stripe'
import { useState } from 'react'
import { useAppDispatch } from 'reduxStore'
import { addPaymentMethod } from 'reduxStore/ducks/subscription'

import { ApiError } from 'api-client/lib/api-client'
import { useAppSelector } from './useAppSelector'

export const usePaymentMethods = () => {
  const accountId = useAppSelector((state) => state.user.user?.id)
  const paymentMethodsResult = useQuery(['getPaymentMethods', accountId], () => getPaymentMethods())
  let defaultCustomerPaymentMethod: CustomerPaymentMethod | undefined = undefined

  const { data: paymentMethods = [] } = paymentMethodsResult

  if (paymentMethods.length) {
    const defaultPaymentMethod = paymentMethods.find((paymentMethod) => paymentMethod.default)
    defaultCustomerPaymentMethod = defaultPaymentMethod || paymentMethods[0]
  }

  return {
    defaultCustomerPaymentMethod,
    paymentMethodsResult,
  }
}

export const usePayment: UsePayment = (input) => {
  const { onSuccess, getPaymentIntentConfirmationDetails } = input
  const accountId = useAppSelector((state) => state.user.user?.id)
  const paymentMethodsResult = useQuery(['getPaymentMethods', accountId], () => getPaymentMethods())
  const { stripe } = useStripeContext()
  const elements = useElements()
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [paymentError, setPaymentError] = useState<
    StripeError | UnknownPaymentError | AuthenticationError | undefined
  >()
  const dispatch = useAppDispatch()

  let defaultCustomerPaymentMethod: CustomerPaymentMethod | undefined = undefined

  const { data: paymentMethods = [] } = paymentMethodsResult

  if (paymentMethods.length) {
    const defaultPaymentMethod = paymentMethods.find((paymentMethod) => paymentMethod.default)
    defaultCustomerPaymentMethod = defaultPaymentMethod || paymentMethods[0]
  }

  const handleOneTimePayment = async () => {
    try {
      if (!stripe || !elements) {
        captureException(new MissingStripeWhenSubmittingError())
        return
      }
      if (!getPaymentIntentConfirmationDetails) {
        throw new Error('Missing payment intent get confirmation details function')
      }

      if (!paymentMethods.length) {
        throw new Error('Missing payment method during one time payment')
      }

      if (!defaultCustomerPaymentMethod) {
        throw new Error('Missing default payment method during one time payment')
      }

      setPaymentLoading(true)
      const { clientSecret } = await getPaymentIntentConfirmationDetails()

      if (!clientSecret) {
        throw new UnsuccessfulSetupIntentError()
      }

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: defaultCustomerPaymentMethod.id,
      })

      if (result.error) {
        setPaymentError(new AuthenticationError())
      } else {
        if (onSuccess) {
          await onSuccess(defaultCustomerPaymentMethod.id)
        }
      }
      return result
    } catch (err) {
      captureException(err)
      setPaymentError(new UnknownPaymentError())
    } finally {
      setPaymentLoading(false)
    }
  }

  const addNewPaymentMethod = async (card: StripeCardNumberElement) => {
    try {
      if (!stripe) {
        captureException(new Error('Stripe did not load'))
        return
      }
      const { clientSecret } = await createSetupIntent()

      if (!clientSecret) {
        throw new UnsuccessfulSetupIntentError()
      }

      const { error: confirmCardSetupError, setupIntent: setupIntentResult } =
        await stripe.confirmCardSetup(clientSecret, {
          payment_method: { card },
        })

      if (confirmCardSetupError) {
        throw confirmCardSetupError
      }

      if (setupIntentResult && setupIntentResult.status === 'succeeded') {
        const paymentMethod = setupIntentResult.payment_method
        if (paymentMethod) {
          const paymentMethodId =
            typeof paymentMethod === 'string' ? paymentMethod : paymentMethod.id
          await dispatch(addPaymentMethod(paymentMethodId))
          await paymentMethodsResult.refetch()
          return paymentMethodId
        }
        throw new UnknownPaymentError()
      }
      throw new UnsuccessfulSetupIntentError()
    } catch (error) {
      captureException(error)
      throw error
    }
  }

  return {
    paymentLoading,
    paymentError,
    handleOneTimePayment,
    paymentMethodsResult,
    defaultPaymentMethod: defaultCustomerPaymentMethod,
    addNewPaymentMethod,
    isPaymentReady: Boolean(stripe) && Boolean(elements),
  }
}

export const usePaymentCardFetcher = (enableCondition = true) => {
  const accountId = useAppSelector((state) => state.user.user?.id)

  const paymentMethodsResult = useQuery(
    ['getPaymentCard', accountId],
    async () => {
      const paymentMethods = await getPaymentMethods()
      const defaultPaymentMethod =
        paymentMethods.find((paymentMethod) => paymentMethod.default) ?? paymentMethods[0]

      return {
        paymentMethods,
        cardNumber: defaultPaymentMethod.card?.last4,
      }
    },
    { enabled: enableCondition }
  )

  return paymentMethodsResult
}

export const useSubscription = () => {
  const accountId = useAppSelector((state) => state.user.user?.id)
  const subscriptionResult = useQuery(['subscription', accountId], () => fetchSubscription(), {
    retry: (_, error: ApiError) => {
      if (error && error.status() === 404) {
        return false
      }

      return true
    },
  })

  return subscriptionResult
}
