import { request } from '../api-client'

export enum LoyaltyPartner {
  OBOS = 'OBOS',
  BATE = 'BATE',
}

export interface LoyaltyMembershipDto {
  loyaltyPartner: LoyaltyPartner
  loyaltyMembershipId: string
}
export interface ObosDto {
  dateOfBirth: string
  loyaltyMembershipId: string
}

export async function getMembership(partner: LoyaltyPartner): Promise<LoyaltyMembershipDto> {
  return request({
    method: 'GET',
    url: 'loyalty-membership',
    params: { partner: partner },
  })
}

export async function activateObosMembership(payload: ObosDto): Promise<LoyaltyMembershipDto> {
  return request({
    method: 'POST',
    url: 'loyalty-membership/obos',
    body: payload,
  })
}

export async function activateMembership(
  payload: LoyaltyMembershipDto
): Promise<LoyaltyMembershipDto> {
  return request({
    method: 'POST',
    url: 'loyalty-membership',
    body: payload,
  })
}
