import { useQuery } from '@tanstack/react-query'
import { Spinner } from '@wanda-space/noelle'
import { StorageItemState } from '@wanda-space/types'
import { fetchItem } from 'api-client'
import { Routes } from 'consts'
import { useDocumentTitle } from 'hooks'
import { withRequiredParams } from 'hooks/withRequiredParams'
import React from 'react'
import { useIntl } from 'react-intl'
import { Navigate, useLocation } from 'react-router-dom'

import { OnTheMove } from './OnTheMove/OnTheMove'
import { ServicePending } from './ServicePending/ServicePending'
import { Stored } from './Stored/Stored'
import { WithCustomer } from './WithCustomer/WithCustomer'

const ItemDetails = withRequiredParams(['id'], function ItemDetails({ id }) {
  const { formatMessage } = useIntl()
  const location = useLocation()

  const { isLoading, data } = useQuery(['item', id], () => fetchItem(id))

  useDocumentTitle(
    `${formatMessage({ id: 'word.space' })} - ${formatMessage({ id: 'word.itemDetails' })}`
  )

  if (isLoading) {
    return <Spinner />
  }
  if (data) {
    // @ts-ignore fix ItemResponseDto
    if (data.state === StorageItemState.WITH_CUSTOMER && !data.pendingOrderId) {
      return <WithCustomer item={data} />
    }

    // @ts-ignore fix ItemResponseDto?
    if (data.state === StorageItemState.ON_THE_ROAD || data.pendingOrderDetails) {
      return <OnTheMove item={data} />
    }
    if (data.state === StorageItemState.STORED) {
      return <Stored item={data} />
    }
    // @ts-ignore fix ItemResponseDto
    if (data.pendingServiceOrderIds.length) {
      return <ServicePending item={data} />
    }
  }
  return <Navigate to={location.state?.referrer || Routes.Space} />
})

export { ItemDetails }
