import { getAdvertisementRoute } from 'routes/BuySellFlow/BuySellFlow'
import { returnFlowPaths } from 'routes/ReturnFlow/paths'
import { paths as squareMeterPaths } from 'routes/SquareMeterFlow/paths'
import { paths as storagePaths } from 'routes/Storage/paths'
import { pathTo } from 'utils'

const Home = '/'
const Login = '/login'
const LoginCallback = pathTo(Login, 'callback')
const Register = '/register'
const Space = pathTo('/space')
const MySubscription = pathTo(Space, 'my-subscription')
const MyItemsView = pathTo(Space, 'my-items')
const NoImageItemView = pathTo(Space, 'no-image-view')
const ItemDetails = (id: string) => pathTo(Space, 'item', id)
const CreateItem = (id: string) => pathTo(Space, 'item', 'create', id)

const Settings = pathTo(Space, 'settings')
const EditAddress = pathTo(Settings, 'address')
const ResetPassword = pathTo('/password-reset')
const Market = pathTo('/market')
const ChangePassword = pathTo(Settings, 'password')
const Invoices = pathTo(Settings, 'invoices')
const Orders = pathTo(Settings, 'orders')
const EditServiceOrder = (id: string) => pathTo(Settings, 'service-orders', id)
const Redirect = pathTo('/redirect')
const ReferFriend = '/referral'
const ReferredUserSigup = (country: string, id: string) => pathTo(ReferFriend, country, id)
const Referrals = pathTo(Settings, '/referrals')
const PackagingOrders = pathTo(Settings, 'packaging-orders')
const PurchaseOrders = pathTo(Settings, 'purchase-orders')
const SalesOrders = pathTo(Settings, 'sales-orders')
const ServiceOrders = pathTo(Settings, 'service-orders')
const TransportOrders = pathTo(Settings, 'transport-orders')
const SelectAccounts = pathTo(Settings, '/select-account')

const EditOrderDetails = (id: string) => pathTo(Space, 'edit-order', id)
const EditOrderAddress = (id: string) => pathTo(Space, 'edit-order', id, 'address')
const EditOrderCarrying = (id: string) => pathTo(Space, 'edit-order', id, 'carrying')
const EditOrderTime = (id: string) => pathTo(Space, 'edit-order', id, 'time')
const EditOrderAddPickupItems = (id: string) => pathTo(Space, 'edit-order', id, 'pickup-items')
const EditOrderAddDeliveryItems = (id: string) => pathTo(Space, 'edit-order', id, 'delivery-items')
const EditOrderPacking = (id: string) => pathTo(Space, 'edit-order', id, 'packing')

const Billing = pathTo(Settings, 'billing')
const Shop = pathTo('/packaging')
const ShopSummary = pathTo(Shop, 'summary')
const ShopCheckout = pathTo(Shop, 'checkout')
const ShopCheckoutConfirmation = pathTo(Shop, ShopCheckout, 'checkoutconfirm')
const ShopRequireRegistration = pathTo(Shop, 'register')
const ObosMembership = pathTo(Settings, 'obos')

const SharingInfo = (id: string) => pathTo('/sharing', id)
const Partnerships = pathTo(Settings, 'partnerships')
const Bate = pathTo(Settings, 'bate')
const Storage = pathTo('storage')
const StorageSelectItems = pathTo(Storage, storagePaths.selectItems)
const StorageSelectExistingItems = pathTo(Storage, storagePaths.existingItems)
const StorageSchedule = pathTo(Storage, storagePaths.schedule)
const StorageCarrying = pathTo(Storage, storagePaths.carrying)
const StorageSummary = pathTo(Storage, storagePaths.summary)
const StorageAddress = pathTo(Storage, storagePaths.address)
const SelectFlow = pathTo('/select-flow')
const SelectStorage = pathTo(SelectFlow, '/storage')
const ServicesNext = pathTo('/services-next')
const CollectPostCode = pathTo('/post-code')
const CollectEmail = pathTo('/finish-logging-in')

const Sell = pathTo('/sell')
const Buy = pathTo('/buy')
const ListingNotFound = pathTo('/listing-not-found')
const SellWithPickup = pathTo(Sell, '/pickup')
const ReturnFlow = '/return-flow'
const Bid = pathTo('/bid')
const ViewBid = (listingId: string, bidId: string) => pathTo(Bid, bidId, 'listing', listingId)
const ViewListing = (listingId: string) => pathTo(Buy, getAdvertisementRoute(listingId))
const MyTransfers = pathTo('/my-transfers')

const ReturnFlowSelectItems = pathTo(ReturnFlow, returnFlowPaths.selectItems)
const ReturnFlowSchedule = pathTo(ReturnFlow, returnFlowPaths.schedule)
const ReturnFlowCarrying = pathTo(ReturnFlow, returnFlowPaths.carrying)
const ReturnFlowSummary = pathTo(ReturnFlow, returnFlowPaths.summary)
const ReturnFlowaddress = pathTo(ReturnFlow, returnFlowPaths.address)
const SquareMeterFlow = pathTo('square-meter-flow')
const SquareMeterFlowSchedule = pathTo(SquareMeterFlow, squareMeterPaths.schedule)
const SquareMeterFlowAddress = pathTo(SquareMeterFlow, squareMeterPaths.address)
const SquareMeterSelectAreaSize = pathTo(SquareMeterFlow, squareMeterPaths.selectAreaSize)
const ServiceFromStorage = pathTo('/service-from-storage')

export const Routes = {
  Home,
  Login,
  Register,
  Space,
  MyItemsView,
  NoImageItemView,
  CreateItem,
  ItemDetails,
  Settings,
  EditAddress,
  ResetPassword,
  ChangePassword,
  Billing,
  Invoices,
  ListingNotFound,
  PackagingOrders,
  PurchaseOrders,
  SalesOrders,
  ServiceOrders,
  TransportOrders,
  Orders,
  EditOrderDetails,
  EditServiceOrder,
  EditOrderAddress,
  EditOrderTime,
  EditOrderCarrying,
  EditOrderAddPickupItems,
  EditOrderAddDeliveryItems,
  EditOrderPacking,
  LoginCallback,
  ObosMembership,
  Market,
  SharingInfo,
  Shop,
  ShopCheckout,
  ShopSummary,
  ShopCheckoutConfirmation,
  Partnerships,
  Bate,
  MySubscription,
  Redirect,
  Storage,
  StorageSelectItems,
  StorageSelectExistingItems,
  StorageSchedule,
  StorageCarrying,
  StorageSummary,
  StorageAddress,
  SelectFlow,
  CollectPostCode,
  Sell,
  SellWithPickup,
  Buy,
  ReturnFlow,
  ReturnFlowSelectItems,
  ReturnFlowSchedule,
  ReturnFlowCarrying,
  ReturnFlowSummary,
  ReturnFlowaddress,
  SelectStorage,
  ServicesNext,
  ShopRequireRegistration,
  ReferFriend,
  ReferredUserSigup,
  Referrals,
  SquareMeterFlow,
  SquareMeterFlowSchedule,
  SquareMeterFlowAddress,
  SquareMeterSelectAreaSize,
  Bid,
  ViewBid,
  CollectEmail,
  ViewListing,
  ServiceFromStorage,
  SelectAccounts,
  MyTransfers,
}
