import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  type CreateServiceOrderWithPickupResponseDto,
  type DiscountResponseDto,
  ItemResponseDto,
  type ProductCategoryResponseDto,
  ServiceOrderResponseDto,
} from '@wanda-space/types'
import { type OrderLineWithFullProductAndDiscount } from 'api-client'
import { getAllOrderlinesWithDiscounts } from 'utils'

import { SliceNames } from '../../constants'

export interface ServicesFromStorageFlowState {
  selectedCategory?: ProductCategoryResponseDto
  storageItems: ItemResponseDto[]
  serviceOrderLines: OrderLineWithFullProductAndDiscount[]
  orderSuccessPayload: ServiceOrderResponseDto | null
  coupon?: string
}

const initialState: ServicesFromStorageFlowState = {
  serviceOrderLines: [] as OrderLineWithFullProductAndDiscount[],
  storageItems: [],
  orderSuccessPayload: null,
}

export const serviceFromStorageFlowStateSlice = createSlice({
  name: SliceNames.SERVICE_FROM_STORAGE,
  initialState,
  reducers: {
    setSelectedCategory: (state, action: PayloadAction<ProductCategoryResponseDto>) => {
      if (state.selectedCategory !== action.payload) {
        state.selectedCategory = action.payload
        state.serviceOrderLines = []
      }
    },
    setServiceOrderLines: (state, action: PayloadAction<OrderLineWithFullProductAndDiscount[]>) => {
      state.serviceOrderLines = action.payload
    },
    addServiceOrderLine: (state, action: PayloadAction<OrderLineWithFullProductAndDiscount>) => {
      state.serviceOrderLines.push(action.payload)
    },
    updateCommentOnServiceOrderLine: (
      state,
      action: PayloadAction<{ index: number; comment: string }>
    ) => {
      const { comment, index } = action.payload
      state.serviceOrderLines[index].comment = comment
    },
    removeServiceOrderLine: (state, action: PayloadAction<number>) => {
      state.serviceOrderLines.splice(action.payload, 1)
    },
    setOrderSuccessPayload: (state, action: PayloadAction<ServiceOrderResponseDto>) => {
      state.orderSuccessPayload = action.payload
    },
    resetAll: () => {
      return initialState
    },
    applyDiscounts: (
      state,
      action: PayloadAction<{ coupon?: string; discounts: DiscountResponseDto[] }>
    ) => {
      if (action.payload.coupon) {
        state.coupon = action.payload.coupon
      }
      const { services } = getAllOrderlinesWithDiscounts(
        [],
        [],
        [],
        [],
        [],
        state.serviceOrderLines,
        action.payload.discounts
      )
      state.serviceOrderLines = services
    },
    toggleStorageItems(state, action: PayloadAction<ItemResponseDto[]>) {
      state.storageItems = action.payload
    },
  },
})

export const {
  setSelectedCategory,
  setServiceOrderLines,
  setOrderSuccessPayload,
  resetAll,
  addServiceOrderLine,
  removeServiceOrderLine,
  updateCommentOnServiceOrderLine,
  applyDiscounts,
  toggleStorageItems,
} = serviceFromStorageFlowStateSlice.actions

export const serviceFromStorageFlow = serviceFromStorageFlowStateSlice.reducer
