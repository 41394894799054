import { Flex, Tag, Text } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { DynamicIcon, DynamicIconName } from '@wanda-space/noelle'
import {
  ItemResponseDto,
  OrderType,
  StorageItemState,
  itemHasPendingLogisticOrder,
  itemHasPendingServiceOrder,
} from '@wanda-space/types'
import { getListings } from 'api-client'
import React from 'react'
import { useIntl } from 'react-intl'

interface Props {
  id: string
  state: StorageItemState
  pendingOrderId: string | undefined | null
  pendingOrderType: OrderType | undefined
  pendingServices?: ItemResponseDto['pendingServices']
  sharedWith: {
    email: string
    ownerPays: boolean
  }[]
  isOwner: boolean
}

export const ItemLabel = ({
  state,
  pendingOrderId,
  sharedWith,
  pendingOrderType,
  id,
  pendingServices,
}: Props) => {
  const { formatMessage } = useIntl()
  const { data: listing } = useQuery(['fetchListing', id], () =>
    getListings({ page: 0, itemsPerPage: 100 })
  )

  const withCustomer = state === StorageItemState.WITH_CUSTOMER
  const bookedReturn =
    itemHasPendingLogisticOrder({ pendingOrderId }) && pendingOrderType === OrderType.DELIVERY
  const bookedPickup =
    itemHasPendingLogisticOrder({ pendingOrderId }) && pendingOrderType === OrderType.PICK_UP
  const stored = state === StorageItemState.STORED
  const shared = sharedWith.length > 0
  const selling = listing?.items.some((item) => item.id === id)
  const onCar = state === StorageItemState.ON_THE_ROAD
  const atService = itemHasPendingServiceOrder({ pendingServices })

  return (
    <Flex gap={1}>
      {withCustomer && (
        <ItemTag
          bg={'paleBlue.300'}
          iconName={DynamicIconName.houseCheckmark}
          color={'paleBlue.800'}
          title={formatMessage({ id: 'word.withCustomer' })}
        />
      )}
      {bookedReturn && (
        <ItemTag
          bg="orange.100"
          color="orange.700"
          iconName={DynamicIconName.truckClock}
          title={formatMessage({ id: 'word.return' })}
        />
      )}
      {bookedPickup && (
        <ItemTag
          bg="orange.100"
          color="orange.700"
          iconName={DynamicIconName.truckClock}
          title={formatMessage({ id: 'word.pickup' })}
        />
      )}
      {stored && (
        <ItemTag
          bg="purple.200"
          color="purple.800"
          iconName={DynamicIconName.threeBoxes2}
          title={formatMessage({ id: 'word.stored' })}
        />
      )}
      {shared && (
        <ItemTag
          bg="brown.200"
          color="brown.800"
          iconName={DynamicIconName.shareItem}
          title={formatMessage({ id: 'word.shared' })}
        />
      )}
      {selling && (
        <ItemTag
          bg="brown.200"
          color="brown.800"
          iconName={DynamicIconName.deal}
          title={formatMessage({ id: 'word.selling' })}
        />
      )}
      {onCar && (
        <ItemTag
          bg="green.100"
          color="green.800"
          iconName={DynamicIconName.truckCheckmark}
          title={formatMessage({ id: 'word.onCar' })}
        />
      )}
      {atService && (
        <ItemTag
          bg="brown.200"
          color="brown.800"
          iconName={DynamicIconName.tools}
          title={formatMessage({ id: 'word.atService' })}
        />
      )}
    </Flex>
  )
}

interface ItemTagProps {
  iconName: DynamicIconName
  title: string
  bg: string
  color: string
}

const ItemTag = ({ iconName, title, bg, color }: ItemTagProps) => {
  return (
    <Tag bg={bg ?? 'ctaBlack.500'} variant="solid" minHeight={8} maxHeight={8} borderRadius="3xl">
      <DynamicIcon iconName={iconName ?? DynamicIconName.box} color={color ?? 'white'} />
      <Text ml="1" color={color}>
        {title}
      </Text>
    </Tag>
  )
}
