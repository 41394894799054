export interface PurchasePayloadContactInfoAddress {
  street: string
  city: string
  postalCode: string
  countryCode: string
}

export interface PurchasePayloadContactInfo {
  firstName: string
  lastName: string
  email: string
  mobilePhone: string
  address: PurchasePayloadContactInfoAddress
}

export interface PackagingOrderDetailsInputDto {
  deliveryDate: string
  deliveryTimeSlot: { from: string; to: string }
  contactInfo: PurchasePayloadContactInfo
}

export enum DeliveryTypes {
  NEXT_DAY = 'NEXT_DAY',
  SPECIFIC_DAY = 'SPECIFIC_DAY',
}
