import {
  type CheckValidOrderResponseDto,
  type CreateReferralRequestDto,
  type CreateReferralResponseDto,
  type CreditTransactionBalanceResponseDto,
  type CreditTransactionResponseDto,
  type PaginatedResponseDto,
  type PaginationQueryParams,
} from '@wanda-space/types'

import { request } from '../api-client'

export async function createCreditTransaction(body: CreateReferralRequestDto) {
  return request<CreateReferralResponseDto>({
    method: 'POST',
    url: 'credit-transaction/referral',
    body: body,
  })
}

export async function getCreditTransactions({
  itemsPerPage = 25,
  page = 0,
}: PaginationQueryParams) {
  return request<PaginatedResponseDto<CreditTransactionResponseDto>>({
    method: 'GET',
    url: 'credit-transaction',
    params: { itemsPerPage: itemsPerPage, page: page },
  })
}

export async function getCreditBalance() {
  return request<CreditTransactionBalanceResponseDto>({
    method: 'GET',
    url: 'credit-transaction/balance',
  })
}

export async function checkAtLeastOneFulfilledOrder() {
  return request<CheckValidOrderResponseDto>({
    method: 'GET',
    url: 'credit-transaction/check-valid-order',
  })
}
