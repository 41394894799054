import { AspectRatio, Flex, Grid, Spinner } from '@chakra-ui/react'
import { Uploader } from 'components/UploadImage'
import React from 'react'

import { useQuery } from '@tanstack/react-query'
import { getImageDetails } from '../../../api-client'
import { RemovableImage } from '../Image/ItemImage'

type Props = {
  itemId: string
  onSetImage: (img: string) => void
  onRemoveImage: (img: string | undefined) => void
  createMode?: boolean
  canRemove?: boolean
}

export const ImageGrid = ({
  itemId,
  onSetImage,
  onRemoveImage,
  createMode,
  canRemove = true,
}: Props) => {
  const itemImagesQuery = useQuery(['fetchItemImages', itemId], () => getImageDetails(itemId))

  if (itemImagesQuery.isInitialLoading) {
    return (
      <Flex height="100%" width="100%" justifyContent="center" alignItems="center">
        <Spinner />
      </Flex>
    )
  }

  const allItemImageUrls = itemImagesQuery.data?.items.map((itemImage) => itemImage.url) ?? []

  const count = itemImagesQuery.data?.count ?? 0
  const canAddMoreImages = count <= 3

  return (
    <div>
      {count === 0 ? (
        <>
          {createMode && canAddMoreImages && (
            <Uploader
              onSetImage={(img: string) => {
                onSetImage(img)
              }}
            />
          )}
        </>
      ) : (
        <Grid templateColumns="repeat(auto-fill, minmax(40%, 1fr))" gap={1}>
          {itemId &&
            itemImagesQuery.data?.items.map((itemImage) => (
              <RemovableImage
                key={itemImage.id}
                imageId={itemImage.id}
                src={itemImage.url}
                imageUrls={allItemImageUrls}
                itemId={itemId}
                onRemoveImage={onRemoveImage}
                canRemove={canRemove}
              />
            ))}
          {createMode && canAddMoreImages && (
            <AspectRatio ratio={1}>
              <Uploader
                onSetImage={(img: string) => {
                  onSetImage(img)
                }}
              />
            </AspectRatio>
          )}
        </Grid>
      )}
    </div>
  )
}
