import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getOrders } from 'api-client/lib/routes/orders'
import { ReactQueryKey } from 'consts/ReactQueryKeys'

import { useAppSelector } from './useAppSelector'
import { useAuth } from './useAuth'

export const useOrders = (page = 0, itemsPerPage = 1000) => {
  const { isAuthenticated } = useAuth()
  const accountId = useAppSelector((state) => state.user.user?.id)
  return useQuery([ReactQueryKey.FETCH_ORDERS, accountId], () => getOrders(page, itemsPerPage), {
    enabled: !!accountId && isAuthenticated,
    refetchOnMount: false,
  })
}

export const useOrdersInvalidator = () => {
  const queryClient = useQueryClient()
  return () =>
    queryClient.invalidateQueries({
      queryKey: [ReactQueryKey.FETCH_ORDERS],
    })
}
