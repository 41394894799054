import { Box, Button, Flex } from '@chakra-ui/react'
import { Alignment, Fit, Layout, useRive } from '@rive-app/react-canvas'
import { CloseIcon, DynamicIconName, MenuIcon } from '@wanda-space/noelle'
import { Routes } from 'consts'
import { AnimatePresence, motion } from 'framer-motion'
import { useAppSelector } from 'hooks/useAppSelector'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

// @ts-ignore file ext not recognized and complains, suggestions are welcome
import wandaLogo from '/assets/animations/wandaLogo.riv'

import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { useWindowSize } from 'react-use'

import { useAuth } from 'hooks/useAuth'
import { UserBadgeButton } from '../components/UserBadgeButton/UserBadgeButton'
import { NavMenuItem } from './NavMenuItem'

interface Props {
  onToggleShowMenu: () => void
  isMenuOpen: boolean
}

const AuthenticatedNav = ({ onToggleShowMenu, isMenuOpen }: Props) => {
  const { data: featureFlags } = useFeatureFlags()
  const user = useAppSelector((state) => state.user.user)
  const { formatMessage } = useIntl()
  const { width: browserWidth } = useWindowSize()
  const { rive, RiveComponent } = useRive({
    src: wandaLogo,
    autoplay: false,
    layout: new Layout({
      fit: Fit.Fill,
      alignment: Alignment.Center,
    }),
  })

  const displayTopNav = browserWidth >= 800

  useEffect(() => {
    if (isMenuOpen) {
      window.scrollTo(0, 0)
    }

    if (rive) {
      const animation = isMenuOpen ? 'Opening' : 'Closing'
      rive.play(animation)
    }
  }, [isMenuOpen])

  const motionProps = {
    initial: { opacity: 0.5 },
    animate: { opacity: 1 },
    exit: { opacity: 0.5 },
    transition: { duration: 0.2, ease: 'easeIn' },
  }

  return (
    <Flex alignItems="center" width="100%" justifyContent="space-between">
      <Link title={formatMessage({ id: 'space.goToSpace' })} to={Routes.Space}>
        <Box height="42px" width="78px">
          <RiveComponent />
        </Box>
      </Link>
      <Flex gap="19px">
        {displayTopNav && (
          <Flex alignItems="center">
            <NavMenuItem
              key="space"
              text={formatMessage({ id: 'navigation.mySpace.title' })}
              iconName={DynamicIconName.threeBoxes}
              path={Routes.Space}
              borderRadius
              // end: workaround, to deal with inconsistent nav hierarchy - see https://linear.app/wanda/issue/AST-514/show-what-page-you-are-on-in-the-menu-on-desktop#comment-e7e5a379
              end={true}
            />
            {featureFlags?.ENABLE_SPACESHIP_FLOW_PACKAGING_IN_MENUS && (
              <NavMenuItem
                key="shop"
                text={formatMessage({ id: 'navigation.shop.title' })}
                iconName={DynamicIconName.box}
                path={Routes.Shop}
                borderRadius
              />
            )}
            <NavMenuItem
              key="orders"
              text={formatMessage({ id: 'navigation.myOrders.title' })}
              iconName={DynamicIconName.movingTruck}
              path={Routes.Orders}
              borderRadius
            />
            {featureFlags?.ENABLE_SPACESHIP_FLOW_BUY && (
              <NavMenuItem
                key="market"
                text={formatMessage({ id: 'navigation.market.title' })}
                iconName={DynamicIconName.sellWithWanda}
                href="https://wanda.space/market"
                borderRadius
              />
            )}
          </Flex>
        )}
        <Flex alignItems="center" zIndex="10002">
          {!displayTopNav && (
            <AnimatePresence initial={false} custom={isMenuOpen} exitBeforeEnter>
              {isMenuOpen && (
                <motion.div {...motionProps} key="open">
                  <Button
                    p="2"
                    data-testid="menu-button-close"
                    rightIcon={<CloseIcon color="white" h={4} w={4} />}
                    size="small"
                    colorScheme="ctaBlack"
                    aria-label="menu-close"
                    onClick={onToggleShowMenu}
                    width="90px"
                    mr={2}
                  >
                    {formatMessage({ id: 'word.close' })}
                  </Button>
                </motion.div>
              )}
              {!isMenuOpen && (
                <motion.div {...motionProps} key="closed">
                  <Button
                    p="2"
                    data-testid="menu-button-open"
                    rightIcon={<MenuIcon h={4} w={4} />}
                    size="small"
                    backgroundColor="white"
                    aria-label="menu-open"
                    onClick={onToggleShowMenu}
                    width="90px"
                    mr={2}
                  >
                    {formatMessage({ id: 'word.menu' })}
                  </Button>
                </motion.div>
              )}
            </AnimatePresence>
          )}
          {user?.address && (
            <UserBadgeButton firstName={user.address.firstName} lastName={user.address.lastName} />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export { AuthenticatedNav }
