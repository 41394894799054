import { Modal, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import { WarningModal } from '@wanda-space/noelle'
import { always } from 'ramda'
import React from 'react'
import { useIntl } from 'react-intl'

interface Props {
  title: string
  description: string | React.ReactNode
  isOpen: boolean
  onClose?: () => void
  handleClickYes: () => void
  handleClickNo?: () => void
  yesButtonText?: string
  noButtonText?: string
}

export const WarningModalContent = ({
  isOpen,
  onClose,
  description,
  handleClickYes,
  handleClickNo,
  title,
  yesButtonText,
  noButtonText,
}: Props) => {
  const { formatMessage } = useIntl()
  return (
    <Modal isOpen={isOpen} onClose={always(undefined)}>
      <ModalOverlay />
      <ModalContent width="auto" display="inline-block">
        <WarningModal
          title={title}
          content={<Text>{description}</Text>}
          closeIconPresent={!!onClose}
          yesButtonText={yesButtonText ?? formatMessage({ id: 'word.yes' })}
          noButtonText={noButtonText ?? formatMessage({ id: 'word.no' })}
          onClickYes={handleClickYes}
          onClickNo={handleClickNo ?? onClose}
          onClose={onClose}
        />
      </ModalContent>
    </Modal>
  )
}
