import { Box, Container, Flex, IconButton } from '@chakra-ui/react'
import { Button, Text } from '@chakra-ui/react'
import { CloseIcon, Spinner } from '@wanda-space/noelle'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useRef, useEffect, ReactNode } from 'react'
import { createPortal } from 'react-dom'
import { Layer } from 'styles'
import { onClickOutside } from 'utils'

interface Props {
  show: boolean
  onClose: () => void
  children: ReactNode | string
  closeButtonTitle?: string
  closeButtonVariant?: string
  closeButtonDisabled?: boolean
  closeButtonLoading?: boolean
  hideCloseIcon?: boolean
  title?: ReactNode | string
  bgColor?: string
  loading?: boolean
  'data-testid': string
  titlePosition?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit'
}

const Modal = ({
  children,
  onClose,
  closeButtonTitle,
  closeButtonVariant = 'primary',
  closeButtonDisabled,
  closeButtonLoading,
  hideCloseIcon,
  title,
  bgColor,
  loading,
  'data-testid': dataId,
  titlePosition = 'center',
}: Props) => {
  const ref = useRef(null)

  useEffect(() => onClickOutside(ref), [])

  return (
    <Flex
      data-testid={dataId}
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      backgroundColor="rgba(0, 0, 0, 0.7)"
      alignItems="center"
      justifyContent="center"
      padding="8"
      z-index="100"
    >
      <Container borderRadius="8" position="relative" bgColor={bgColor} ref={ref}>
        {!hideCloseIcon && (
          <IconButton
            position="absolute"
            top="2"
            right="2"
            aria-label="close button"
            icon={<CloseIcon w="4" h="4" />}
            onClick={onClose}
            size="sm"
          />
        )}
        {!loading && title && (
          <Text alignItems={titlePosition} fontSize="lg" as="h4" mb={3} fontWeight="medium">
            {title}
          </Text>
        )}
        <Box flex="1" overflowY="auto" width="100%">
          {loading ? <Spinner /> : children}
        </Box>
        {!loading && closeButtonTitle && (
          <Button
            data-testid="modal-close-button"
            isDisabled={closeButtonDisabled}
            isLoading={closeButtonLoading}
            onClick={onClose}
            position="sticky"
            colorScheme={closeButtonVariant}
            width="100%"
          >
            {closeButtonTitle}
          </Button>
        )}
      </Container>
    </Flex>
  )
}

const AnimatedModal = ({ show, ...rest }: Props) => {
  return createPortal(
    <AnimatePresence>
      {show && (
        <motion.div
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          initial={{ opacity: 0, position: 'relative', zIndex: Layer.Modal }}
          key="modal"
          transition={{ duration: 0.5 }}
        >
          <Modal show={show} {...rest} />
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  )
}

export { AnimatedModal }
