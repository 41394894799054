import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { captureException } from '@sentry/react'
import {
  BID_FLOW_STATE,
  EDIT_STORAGE_FLOW_STATE,
  PACKAGING_FLOW_STATE,
  RETURN_FLOW_STATE,
  SELL_FLOW_STATE,
  SELL_WITH_PICKUP_STATE,
  SERVICE_FLOW_NEXT_STATE,
  SERVICE_FROM_STORAGE,
  SQUARE_METER_FLOW_STATE,
  STORAGE_FLOW_STATE,
  UI_STATE,
} from 'consts'
/* eslint-disable local-rules/no-use-dispatch*/
import { useDispatch } from 'react-redux'

import {
  bidFlow,
  editStorageFlow,
  item,
  packagingFlow,
  returnFlow,
  sell,
  sellWithPickupFlow,
  serviceFromStorageFlow,
  servicesFlowNext,
  squareMeterFlow,
  storageFlow,
  subscription,
  ui,
  user,
} from './ducks'
import { initialState } from './ducks/storage/storageFlow'
import { loggerMiddleware } from './middleware/logger'

const reducer = combineReducers({
  item,
  subscription,
  ui,
  user,
  storageFlow,
  sellFlow: sell,
  sellWithPickupFlow,
  editStorageFlow,
  returnFlow,
  servicesFlowNext,
  packagingFlow,
  squareMeterFlow,
  bidFlow,
  serviceFromStorageFlow,
})

const preloadedState: any = {}

const rehydrateState = (key: string) => {
  try {
    const persistedState = sessionStorage.getItem(key)
    if (persistedState) {
      return JSON.parse(persistedState)
    }
    return null
  } catch (error) {
    captureException(error)
  }
}

const storageFlowState = rehydrateState(STORAGE_FLOW_STATE)
const servicesNextFlowState = rehydrateState(SERVICE_FLOW_NEXT_STATE)
const editStorageFlowState = rehydrateState(EDIT_STORAGE_FLOW_STATE)
const SellFromStorageFlowState = rehydrateState(SELL_FLOW_STATE)
const returnFlowState = rehydrateState(RETURN_FLOW_STATE)
const sellWithPickupFlowState = rehydrateState(SELL_WITH_PICKUP_STATE)
const packagingFlowState = rehydrateState(PACKAGING_FLOW_STATE)
const squareMeterFlowState = rehydrateState(SQUARE_METER_FLOW_STATE)
const bidFlowState = rehydrateState(BID_FLOW_STATE)
const serviceFromStorageFlowState = rehydrateState(SERVICE_FROM_STORAGE)
const uiState = rehydrateState(UI_STATE)

if (storageFlowState) {
  preloadedState.storageFlow = { ...initialState, ...storageFlowState }
}

if (editStorageFlowState) {
  preloadedState.editStorageFlow = editStorageFlowState
}

if (SellFromStorageFlowState) {
  preloadedState.sellFlow = SellFromStorageFlowState
}

if (returnFlowState) {
  preloadedState.returnFlow = returnFlowState
}

if (sellWithPickupFlowState) {
  preloadedState.sellWithPickupFlow = sellWithPickupFlowState
}

if (servicesNextFlowState) {
  preloadedState.servicesFlowNext = servicesNextFlowState
}

if (packagingFlowState) {
  preloadedState.packagingFlow = packagingFlowState
}

if (squareMeterFlowState) {
  preloadedState.squareMeterFlow = squareMeterFlowState
}

if (bidFlowState) {
  preloadedState.bidFlow = bidFlowState
}

if (serviceFromStorageFlowState) {
  preloadedState.serviceFromStorageFlow = serviceFromStorageFlowState
}

if (uiState) {
  preloadedState.ui = uiState
}

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(loggerMiddleware),
  preloadedState,
})

store.subscribe(() => {
  sessionStorage.setItem(STORAGE_FLOW_STATE, JSON.stringify(store.getState().storageFlow))
  sessionStorage.setItem(SERVICE_FLOW_NEXT_STATE, JSON.stringify(store.getState().servicesFlowNext))
  sessionStorage.setItem(EDIT_STORAGE_FLOW_STATE, JSON.stringify(store.getState().editStorageFlow))
  sessionStorage.setItem(RETURN_FLOW_STATE, JSON.stringify(store.getState().returnFlow))
  sessionStorage.setItem(PACKAGING_FLOW_STATE, JSON.stringify(store.getState().packagingFlow))
  sessionStorage.setItem(SQUARE_METER_FLOW_STATE, JSON.stringify(store.getState().squareMeterFlow))
  sessionStorage.setItem(
    SERVICE_FROM_STORAGE,
    JSON.stringify(store.getState().serviceFromStorageFlow)
  )
  // prevent session storage limit to be reached due to large images
  sessionStorage.setItem(
    SELL_FLOW_STATE,
    JSON.stringify({
      ...store.getState().sellFlow,
      images: [],
    })
  )
  sessionStorage.setItem(
    SELL_WITH_PICKUP_STATE,
    JSON.stringify({ ...store.getState().sellWithPickupFlow, images: [] })
  )
  sessionStorage.setItem(BID_FLOW_STATE, JSON.stringify(store.getState().bidFlow))
  sessionStorage.setItem(UI_STATE, JSON.stringify(store.getState().ui))
})
export { store }

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch // Export a hook that can be reused to resolve types
