import { useQuery } from '@tanstack/react-query'
import { mergeRight } from 'ramda'

import { getTranslations } from '../api-client/lib/routes/translations'
import { mapLocaleToShortString, messages } from '../i18n'
import { useAppSelector } from './useAppSelector'

export const useTranslations = () => {
  const locale = useAppSelector((state) => state.ui.language)
  const languageShortString = mapLocaleToShortString(locale)
  const { data, isSuccess } = useQuery(
    ['translations', locale],
    () => getTranslations(languageShortString),
    {
      enabled: !!locale,
      suspense: true,
    }
  )

  if (isSuccess && data) {
    return mergeRight(messages[locale], data)
  }

  return messages[locale]
}
