import { GeneralError } from '@wanda-space/noelle'
import { FeatureFlagKey } from 'api-client/lib/routes/feature-flags'
import { InlinePostalCodeSelector } from 'components/InlinePostalCodeSelector'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import React from 'react'
import { useIntl } from 'react-intl'
import { Outlet } from 'react-router-dom'

interface RequireFeatureFlagProps {
  featureFlag: FeatureFlagKey
}

export const RequireFeatureFlag = ({ featureFlag }: RequireFeatureFlagProps) => {
  const { data: featureFlags } = useFeatureFlags({ suspense: true })
  const { formatMessage } = useIntl()

  if (!featureFlags?.[featureFlag]) {
    return (
      <>
        <GeneralError
          text={formatMessage({
            id: 'route.error.missing-feature-flag',
            defaultMessage: 'It looks like this feature is not available in your region',
          })}
          buttonText="Go back"
        />
        <InlinePostalCodeSelector />
      </>
    )
  }

  return <Outlet />
}
