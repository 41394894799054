import { Button, Image } from '@chakra-ui/react'
import type { ButtonProps } from '@chakra-ui/react'
import GoogleSVG from '@wanda-space/noelle/illustrations/google.svg'
import React from 'react'
import { useIntl } from 'react-intl'

const GoogleButton = (props: ButtonProps) => {
  const { formatMessage } = useIntl()
  return (
    <Button
      data-testid="google-login"
      w="100%"
      size="lg"
      p="7"
      variant="outline"
      colorScheme="gray"
      {...props}
    >
      <Image alt="Google logo" w="5" src={GoogleSVG} mr="3" />
      {formatMessage({ id: 'word.google' })}
    </Button>
  )
}

export { GoogleButton }
