import { Routes, WANDA_LAST_ROUTE } from 'consts'
import { useAuth } from 'hooks/useAuth'
import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { Props } from './types'

const PrivateRoute = ({ redirect = Routes.Login }: Props) => {
  const location = useLocation()
  const search = location.search
  const { isAuthenticated, isAuthenticating } = useAuth()
  const to = redirect + search

  if (!(isAuthenticated || isAuthenticating)) {
    localStorage.setItem(WANDA_LAST_ROUTE, location.pathname)
    return <Navigate to={to} replace />
  }
  return <Outlet />
}

export { PrivateRoute }
