import { Box, Button, Container, Text } from '@chakra-ui/react'
import { SupportedCurrencies } from '@wanda-space/types'
import { Uploader } from 'components/ImageGallery/Uploader'
import { Form, Formik } from 'formik'
import React from 'react'
import { useIntl } from 'react-intl'
import { CommonBannerDetails } from 'routes/BuySellFlow/common/Banner'

interface ForYourInformationProps {
  onOpen?: () => void
  sellerAmount: number
  feePercent: number
  feeFlat: number
  currency: SupportedCurrencies
  uploadImages?: ({ images }: { images: string[] }) => Promise<void>
}

export const ForYourInformationBox = ({
  onOpen,
  sellerAmount,
  feePercent,
  feeFlat,
  currency,
  uploadImages,
}: ForYourInformationProps) => {
  const { formatMessage } = useIntl()
  return (
    <Box border="1px solid" maxW={['100%', 'xs']} borderRadius="2xl" borderColor="gray.200">
      <Box
        width="100%"
        backgroundColor="gray.50"
        borderTopRadius="2xl"
        borderBottom="1px solid"
        borderColor="gray.200"
      >
        <Container py={2}>
          <Text fontSize="lg" fontWeight="medium">
            {formatMessage({ id: 'info.buy.sell.info.for.seller' })}
          </Text>
        </Container>
      </Box>

      <CommonBannerDetails
        amount={sellerAmount}
        feePercent={feePercent}
        feeFlat={feeFlat}
        currency={currency}
      />

      <Container>
        {uploadImages && (
          <Formik initialValues={{ images: [] }} onSubmit={uploadImages}>
            {({ setFieldValue, submitForm }) => (
              <Form>
                <Uploader
                  onSetImages={(images) => {
                    if (images.length > 0) {
                      setFieldValue('images', images)
                      setTimeout(() => {
                        submitForm()
                      }, 0)
                    }
                  }}
                />
              </Form>
            )}
          </Formik>
        )}
        {onOpen && (
          <Button variant="ghost" colorScheme="gray" width="100%" my={2} onClick={onOpen}>
            <Text fontWeight="medium">
              {formatMessage({ id: 'info.buy.sell.delete.sales.page' })}
            </Text>
          </Button>
        )}
      </Container>
    </Box>
  )
}
