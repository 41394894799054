import { FlowStep, NextCallback } from 'interfaces/flows'
import React, { useEffect, useState } from 'react'
import { Navigate, matchPath, useLocation, useNavigate } from 'react-router-dom'

import { useAuth } from './useAuth'

export const useSteps = (
  steps: FlowStep[],
  fullRoutePath: string,
  options = { useMatchPath: false }
) => {
  const [furthestStepReached, setFurthestStepReached] = useState(0)
  const { isAuthenticated } = useAuth()
  const location = useLocation()
  const { pathname, search } = location
  const navigate = useNavigate()
  const currentPath = pathname.split(fullRoutePath).pop() || pathname
  const currentStepIndex = steps.findIndex(({ path }) =>
    options.useMatchPath ? !!matchPath(`/${path}`, `/${currentPath}`) : path === currentPath
  )
  const currentStep = steps[currentStepIndex >= 0 ? currentStepIndex : 0]
  const nextStep = steps[currentStepIndex + 1]
  const previousStep = steps[currentStepIndex - 1]
  const isFirstStep = currentStepIndex === 0
  const isLastStep = currentStepIndex === steps.length - 1
  const progress = ((currentStepIndex + 1) / steps.length) * 100

  useEffect(() => {
    if (currentStepIndex > furthestStepReached) {
      setFurthestStepReached(currentStepIndex)
    }
  }, [currentStep])

  const hasStepGuard = isAuthenticated === false && currentStep.requireLogin
  const shouldFallBack = currentStep.fallback?.() && previousStep

  const StepGuard = hasStepGuard ? () => <Navigate replace to={steps[0].path + search} /> : null

  const FallBackNavigate = shouldFallBack
    ? () => <Navigate replace to={previousStep.path + search} />
    : null

  const onNextStep: NextCallback = (params) => {
    if (nextStep) {
      let newQueryParams = search
      let replace = false
      let pathname = nextStep.path

      if (params) {
        if (params.queryParams) {
          const searchParams = new URLSearchParams(search)
          for (const queryParamKey in params.queryParams) {
            searchParams.append(queryParamKey, params.queryParams[queryParamKey])
          }
          newQueryParams = searchParams.toString()
        }

        if (params.replace) {
          replace = params.replace
        }

        if (params.nextPath) {
          pathname = params.nextPath
        }
      }
      navigate({ pathname, search: newQueryParams }, { replace })
    }
  }

  if (currentStep.title) {
    document.title = `WANDA : ${currentStep.title}`
  }

  return {
    currentStep,
    progress,
    nextStep,
    currentStepIndex,
    previousStep,
    isLastStep,
    isFirstStep,
    furthestStepReached,
    onNextStep,
    StepGuard,
    FallBackNavigate,
  }
}
