import { StorageItemType } from '@wanda-space/types'
import { OrderLinePayload } from 'api-client'

import { ItemPayloadWithProduct } from '../reduxStore/commonMappers'

export enum ItemsFilter {
  LARGE = 'LARGE',
  SMALL = 'SMALL',
  ALL = 'ALL',
  BOX = 'BOX',
}

export interface PaymentCommitment {
  startDate: string
  endDate: string
  orderId: string
}

export type ThresholdMap = Record<keyof typeof StorageItemType, boolean>

export interface PickerItemsAndOrderLines {
  pickerItems: ItemPayloadWithProduct[]
  orderLines: OrderLinePayload[]
}
