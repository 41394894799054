import { BulletPoint } from '@wanda-space/noelle'
import { format } from 'date-fns'
import { SquareMeterFlowState } from 'reduxStore/ducks/squareMeter/types'
import { FormatMessage, formatAddress } from 'utils'
import { formatTimeSlot } from 'utils/timeslot-utils'

const introduction = 'introduction'
const selectAreaSize = 'select-area-size'
const schedule = 'schedule'
const address = 'address'
const register = 'register'
const summary = 'summary'
const payment = 'payment'
const confirmation = 'confirmation'
const addons = 'addons'
const disclaimer = 'disclaimer'
const itemMix = 'item-mix'

export const paths = {
  selectAreaSize,
  schedule,
  summary,
  address,
  register,
  payment,
  introduction,
  confirmation,
  addons,
  disclaimer,
  itemMix,
}

export const bulletPoints = (
  formatMessage: FormatMessage,
  data: SquareMeterFlowState,
  locale: Locale | undefined
): BulletPoint[] => {
  return [
    {
      summaryText: data.orderLines.squareMeterProduct
        ? `${formatMessage({
            id: `${data.orderLines.squareMeterProduct.product.localizationKey}.name`,
          })} ${formatMessage({ id: 'word.storing' })}`
        : undefined,
      inProgressText: formatMessage({ id: 'nav.storing.default' }),
      link: paths.selectAreaSize,
    },
    {
      summaryText: data.dateAndTime.date
        ? formatMessage(
            { id: 'editOrderDetails.pickupTime' },
            {
              date: `${format(new Date(data.dateAndTime.date), 'EEEE do MMM', {
                locale,
              })}, ${formatTimeSlot(data.dateAndTime.timeslot)})
              `,
            }
          )
        : undefined,
      inProgressText: formatMessage({ id: 'nav.timeAndDate.default' }),
      link: paths.schedule,
    },
    {
      summaryText: data.address.street ? formatAddress(data.address) : undefined,
      inProgressText: formatMessage({ id: 'word.address' }),
      link: paths.address,
    },
    {
      summaryText:
        data.orderLines.addons.length > 0
          ? data.orderLines.addons
              .map(({ product }) => formatMessage({ id: product.localizationKey }))
              .join(', ')
          : undefined,
      inProgressText: formatMessage({ id: 'word.additionalServices' }),
      link: paths.addons,
    },
    {
      inProgressText: formatMessage({ id: 'word.summary' }),
      link: paths.summary,
    },
    {
      inProgressText: formatMessage({ id: 'word.payment' }),
      link: paths.payment,
    },
  ]
}
