import { Box, useMediaQuery } from '@chakra-ui/react'
import React from 'react'

export const ServiceVendorBackground = () => {
  const [isMobile] = useMediaQuery('(max-width: 580px)')
  const sharedProps = {
    zIndex: -1,
    backgroundColor: 'paleBlue.200',
    margin: 'auto',
  }

  if (isMobile) {
    return (
      <Box left="0" height="325px" width="100vw" top="0" {...sharedProps} position="absolute" />
    )
  }

  return (
    <Box
      top="0"
      left="20px"
      height="350px"
      width="calc(100vw - 40px)"
      borderRadius="20px"
      position="absolute"
      {...sharedProps}
    />
  )
}
