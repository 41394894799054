import { Box, Flex, Skeleton, Text, Tooltip } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { InfoIcon, Spinner } from '@wanda-space/noelle'
import { ApiError } from 'api-client/lib/api-client'
import { getCreditBalance } from 'api-client/lib/routes/referral'
import { useAppSelector } from 'hooks/useAppSelector'
import React from 'react'
import { useIntl } from 'react-intl'
import { sanitizeAmount } from 'utils'

type props = {
  totalDiscount?: number
  totalTransactionPrice: number
  setWandaCredit?: (credit: number) => void
}

export const CreditsDiscounts = ({
  setWandaCredit,
  totalDiscount,
  totalTransactionPrice,
}: props) => {
  const user = useAppSelector((state) => state.user.user)
  const { data: creditBalance, isInitialLoading } = useQuery(
    ['creditBalance', user],
    async () => {
      const creditBalanceResponse = await getCreditBalance()
      const wandaCredits = sanitizeAmount(creditBalanceResponse.balance)
      if (setWandaCredit) {
        setWandaCredit(wandaCredits)
      }
      return wandaCredits
    },
    {
      enabled: !!user,
      retry: (_, error: ApiError) => {
        if (error && error.status() === 404) {
          return false
        }

        return true
      },
    }
  )

  const { formatMessage } = useIntl()

  return (
    <>
      <Flex justifyContent="space-between">
        <Flex>
          <Text>{formatMessage({ id: 'summary.box.credit.content.credit' })}</Text>
          <Tooltip
            backgroundColor="gray.100"
            color="black.800"
            hasArrow
            label={
              <Box>
                <Text fontWeight="medium">
                  {formatMessage({ id: 'wanda.credit.tooltip.title' })}
                </Text>
                <Text fontWeight="light">
                  {formatMessage({ id: 'wanda.credit.tooltip.description' })}
                </Text>
              </Box>
            }
          >
            <Flex>
              <InfoIcon height={18} />
            </Flex>
          </Tooltip>
        </Flex>
        {isInitialLoading ? (
          <Skeleton opacity=".2" height="5" width="8" />
        ) : creditBalance ? (
          <Text>-{Math.min(totalTransactionPrice, creditBalance)},-</Text>
        ) : (
          <Text>0.00</Text>
        )}
      </Flex>
      <Flex justifyContent="space-between">
        <Flex>
          <Text>{formatMessage({ id: 'word.discount' })}</Text>
        </Flex>
        {isInitialLoading ? (
          <Skeleton opacity=".2" height="5" width="8" />
        ) : totalDiscount ? (
          <Text>-{totalDiscount?.toFixed(2)},-</Text>
        ) : (
          <Text>0.00</Text>
        )}
      </Flex>
    </>
  )
}
