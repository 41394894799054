import React from 'react'
import { useParams } from 'react-router-dom'

export function withRequiredParams<T, P = Record<string, unknown>>(
  params: (keyof T)[],
  WrappedComponent: React.ComponentType<{ [key in keyof T]: string } & P>
): React.ComponentType<P> {
  const WrappedWithRequireParams = (props: P) => {
    const routeParams = useParams()
    const missingParam = params.find((key) => !routeParams[key as string])

    if (missingParam) {
      throw new Error(`Missing route param ${missingParam.toString()}`)
    }

    return (
      <WrappedComponent {...(routeParams as unknown as { [key in keyof T]: string })} {...props} />
    )
  }

  return WrappedWithRequireParams
}
