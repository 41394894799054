import { ItemResponseDto } from '@wanda-space/types'
import { RootState } from 'reduxStore'

import { ListingForPickup, ListingForStorage } from './sellFlow'

export function getSelectedItemsFromStorageToSell(state: RootState): ItemResponseDto[] {
  return state.sellFlow.storageItems
}

export const getListingFromStorageState = (state: RootState): ListingForStorage => state.sellFlow
export const orderContactPersonSelector = (state: RootState) =>
  state.sellWithPickupFlow.contactPerson

export const getListingWithPickupFromState = (state: RootState): ListingForPickup =>
  state.sellWithPickupFlow
