import {
  type TimeslotAvailabilityDto,
  type TimeslotAvailabilityInputDto,
} from '@wanda-space/ops-types'

import { request } from '../api-client'
import { opsBaseUrl } from '../config'
import { Category, ServiceLevelsOrderlines, ServiceLevelsOrderlinesResponse } from '../types'

export async function getAvailableOpsTimeslotsByCount(body: TimeslotAvailabilityInputDto) {
  return (
    await request<{ dates: Record<string, TimeslotAvailabilityDto[]> }>({
      baseUrl: opsBaseUrl,
      skipAuth: true,
      method: 'POST',
      url: '/v1/timeslots/availability/',
      body,
    })
  ).dates
}

export function getCategories() {
  return request<Category[]>({
    method: 'GET',
    url: 'taas/categories',
  })
}

export function fetchTaasOrderLines(payLoad: ServiceLevelsOrderlines) {
  return request<ServiceLevelsOrderlinesResponse>({
    method: 'POST',
    url: 'taas/servicelevels-orderlines',
    body: payLoad,
  })
}

export function fetchTaasOrderLinesByPriceGroups(payload: ServiceLevelsOrderlines) {
  return request<ServiceLevelsOrderlinesResponse>({
    method: 'POST',
    url: 'taas/price-group-servicelevels-orderlines',
    body: payload,
  })
}
