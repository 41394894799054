import { type ItemResponseDto } from '@wanda-space/types'
import { ItemsFilter, PriceWrapper } from 'interfaces'
import { type RootState } from 'reduxStore/index'
import { add } from 'utils/price-utils'
import { hasStorageProduct, productToPrice } from 'utils/product-utils'

export function getFilteredItems(filter: { state: 'STORED' | 'WITH_CUSTOMER'; type: ItemsFilter }) {
  return (state: { item: RootState['item'] }): ItemResponseDto[] => {
    const filtered =
      filter.state === 'STORED' ? state.item.storedItems : state.item.itemsWithCustomer
    if (filter.type === ItemsFilter.ALL) {
      return Object.values(filtered)
    }
    // @ts-ignore deal with enums
    return filtered.filter((item) => item.type === filter.type)
  }
}

export function getSelectedExtraItemsStoragePrice(state: RootState): PriceWrapper {
  const selectedExtraItems = state.item.selectedAddOnItems
    .filter(hasStorageProduct)
    .map((i) => productToPrice(i.storageProduct))
  return add(...selectedExtraItems)
}

export function getNumberOfItemsAvailableForDelivery(state: { item: RootState['item'] }) {
  // @ts-ignore deal with pendingOrderId in ItemResponseDto
  return state.item.storedItems.filter((item) => !item.pendingOrderId).length
}

export function getNumberOfItemsAvailableForPickup(state: { item: RootState['item'] }) {
  // @ts-ignore deal with pendingOrderId in ItemResponseDto
  return state.item.itemsWithCustomer.filter((item) => !item.pendingOrderId).length
}

export function checkForPendingOrderId(state: { item: RootState['item'] }) {
  // @ts-ignore deal with pendingOrderId in ItemResponseDto
  return state.item.itemsWithCustomer.filter((item) => item.pendingOrderId)
}

export function getItemsById(ids: string[]) {
  return (state: { item: RootState['item'] }): ItemResponseDto[] => {
    return state.item.itemsWithCustomer.filter(({ id }) => ids.includes(id))
  }
}

export function getStoredItemsByIds(ids: string[]) {
  return (state: { item: RootState['item'] }): ItemResponseDto[] => {
    return state.item.storedItems.filter(({ id }) => ids.includes(id))
  }
}
export function getItemsWithCustomer() {
  return (state: { item: RootState['item'] }): ItemResponseDto[] => {
    return state.item.itemsWithCustomer
  }
}
export function getItemsAvailableForPickup() {
  return (state: { item: RootState['item'] }): ItemResponseDto[] => {
    // @ts-ignore deal with pendingOrderId in ItemResponseDto
    return state.item.itemsWithCustomer.filter((item) => item.pendingOrderId === undefined)
  }
}
export function getItemsAvailableForDelivery() {
  return (state: { item: RootState['item'] }): ItemResponseDto[] => {
    // @ts-ignore deal with pendingOrderId in ItemResponseDto
    return state.item.storedItems.filter((item) => item.pendingOrderId === undefined)
  }
}
