import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { SupportedCountries } from '@wanda-space/noelle'
import { SupportedCities } from '@wanda-space/types'
import { CURRENCIES_BY_COUNTRY } from 'consts/Currency'
import {
  DEFAULT_CITY,
  DEFAULT_COUNTRY,
  DEFAULT_LANGUAGE,
  DEFAULT_POSTAL_CODE,
  Locale,
  mapStringToLocale,
} from 'i18n'
import { PriceWrapper } from 'interfaces'
import { RootState } from 'reduxStore'

import { PostalCodeDto } from '@wanda-space/ops-types'
import { getSupportedCurrencyForCountryCode } from 'utils'
import { fetchUser as fetchUserRequest } from '../../api-client/lib/routes/user'
import { SliceNames } from '../constants'

type State = {
  language: Locale
  country: SupportedCountries
  city: SupportedCities
  postalCode: string
  isSummaryView: boolean
  isMainNavFixed: boolean
  estimatedTimeslotCost: PriceWrapper
}
const initialState: State = {
  language: DEFAULT_LANGUAGE,
  country: DEFAULT_COUNTRY,
  city: DEFAULT_CITY,
  postalCode: DEFAULT_POSTAL_CODE,
  isSummaryView: false,
  isMainNavFixed: true,
  estimatedTimeslotCost: {
    amount: 0,
    currency: getSupportedCurrencyForCountryCode(DEFAULT_COUNTRY),
  },
}

export const fetchUser = createAsyncThunk('user/fetchUser', fetchUserRequest)

export const uiSlice = createSlice({
  name: SliceNames.UI,
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = mapStringToLocale(action.payload)
    },
    setPostalCode: (
      state,
      action: PayloadAction<{
        postalCode: string
        city: SupportedCities
        country: SupportedCountries
      }>
    ) => {
      state.postalCode = action.payload.postalCode
      state.city = action.payload.city
      state.country = action.payload.country
    },
    setMainNavFixed: (state, action: PayloadAction<boolean>) => {
      state.isMainNavFixed = action.payload
    },
    setEstimatedTimeslotCost: (state, { payload }: PayloadAction<PriceWrapper>) => {
      state.estimatedTimeslotCost = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.country = action.payload.countryCode ?? DEFAULT_COUNTRY
      state.city = action.payload.city ?? DEFAULT_CITY
      state.language = action.payload.locale
      state.postalCode = action.payload.address?.postalCode ?? state.postalCode
    })
  },
})

export const currencySelector = (state: RootState) => CURRENCIES_BY_COUNTRY[state.ui.country]
export const { setLanguage, setMainNavFixed, setEstimatedTimeslotCost, setPostalCode } =
  uiSlice.actions
export const ui = uiSlice.reducer
