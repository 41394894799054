import { Box, Container, ContainerProps, Flex, Heading, Text } from '@chakra-ui/react'
import { BackButton } from '@wanda-space/noelle'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { InlinePostalCodeSelector } from './InlinePostalCodeSelector'

type Props = React.PropsWithChildren<{
  title?: string
  description?: ReactNode
  onBack?: JSX.Element | (() => void)
  indicator?: JSX.Element
  containerProps?: ContainerProps
  indicatorWrapperProps?: ContainerProps
  enableFixedIndicator?: boolean
  enablePostalCodeSelector?: boolean
  onPostalCodeChange?: () => void
}>

const BookingHeader = ({
  title,
  description,
  onBack,
  children,
  indicator,
  containerProps,
  indicatorWrapperProps,
  enableFixedIndicator = false,
  enablePostalCodeSelector = true,
  onPostalCodeChange,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [subMenuWidth, setSubMenuWidth] = useState('initial')
  const [isIndicatorFixed, setIsIndicatorFixed] = useState(false)
  const location = useLocation()

  const subMenuWidthSetter = () => {
    if (containerRef.current) {
      const { width, paddingLeft } = getComputedStyle(containerRef.current)
      setSubMenuWidth(`${parseInt(width) - parseInt(paddingLeft) * 2}px`)
    }
  }

  const toggleIndicatorFixedState = () => {
    if (window.scrollY > 60) {
      if (isIndicatorFixed === false) {
        subMenuWidthSetter()
        setIsIndicatorFixed(true)
      }
    } else {
      setIsIndicatorFixed(false)
    }
  }

  useEffect(() => {
    if (enableFixedIndicator) {
      window.addEventListener('scroll', toggleIndicatorFixedState, false)
    }

    subMenuWidthSetter()
    window.addEventListener('resize', subMenuWidthSetter, false)

    return () => {
      window.removeEventListener('resize', subMenuWidthSetter, false)
      if (enableFixedIndicator) {
        window.removeEventListener('resize', toggleIndicatorFixedState, false)
      }
    }
  }, [])

  useEffect(() => {
    subMenuWidthSetter()
  }, [location.pathname])

  let topOffset = 0
  let contentTopOffset = 3

  if (isIndicatorFixed) {
    contentTopOffset = 20
  } else if (onBack || indicator) {
    topOffset = 5
  }
  if (!onBack && !indicator && !title && !description) {
    return null
  }

  return (
    <Container paddingTop="0" ref={containerRef} {...containerProps}>
      <div>
        <Flex
          bgGradient="linear(to-b, white 50%, transparent 100%)"
          position={isIndicatorFixed ? 'fixed' : 'initial'}
          top={topOffset}
          zIndex={isIndicatorFixed ? 99 : 0}
          width={subMenuWidth}
          alignItems="center"
          justifyContent="space-between"
          pb={0}
          pt="2"
          {...indicatorWrapperProps}
        >
          {indicator}
        </Flex>
        {enablePostalCodeSelector ? (
          <Box py={2}>
            <InlinePostalCodeSelector onChange={onPostalCodeChange} />
          </Box>
        ) : null}
        <Box mt={contentTopOffset}>
          {onBack && typeof onBack === 'function' ? (
            <Flex h={10} alignItems="center">
              <BackButton
                backTo=""
                linkProps={{
                  onClick: onBack,
                }}
              />
            </Flex>
          ) : (
            onBack
          )}
        </Box>
        {title && (
          <Heading variant="h3" fontWeight="normal">
            {title}
          </Heading>
        )}
        {description && <Text>{description}</Text>}
      </div>
      <Box flexShrink={3} alignSelf="flex-end">
        {children}
      </Box>
    </Container>
  )
}

export { BookingHeader }
