import { firstName, lastName, phoneNumber } from 'utils/validators'
import * as Yup from 'yup'

import { FormatMessage } from '../../../utils'

const schema = (formatMessage: FormatMessage) =>
  Yup.object().shape({
    firstName: firstName(formatMessage),
    lastName: lastName(formatMessage),
    phoneNumber: phoneNumber(formatMessage).required(formatMessage({ id: 'validation.required' })),
  })

export { schema }
