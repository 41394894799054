import { BulletPoint } from '@wanda-space/noelle'
import { FlowStep } from 'interfaces'

export const sortCurrentStepToLast = (bulletPoints: BulletPoint[], currentStep: FlowStep) => {
  return [...bulletPoints].sort((pointFirst, pointSecond) => {
    if (currentStep.path === pointFirst.link) {
      return 1
    } else if (currentStep.path === pointSecond.link) {
      return -1
    }
    return 0
  })
}
