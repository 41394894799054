import { Box, IconButton, Text } from '@chakra-ui/react'
import { ChevronRightIcon, DynamicIcon, DynamicIconName, InfoBox } from '@wanda-space/noelle'
import { InfoBoxProps } from '@wanda-space/noelle/dist/components/InfoBox'
import React from 'react'

export interface ServiceInfoBoxProps extends Omit<InfoBoxProps, 'leftIcon' | 'title'> {
  disabled?: boolean
  disableHover?: boolean
  leftIconName?: DynamicIconName
  title: string
}

export const ServiceInfoBox = ({
  title,
  description,
  disabled = false,
  disableHover = false,
  leftIconName,
  ...rest
}: ServiceInfoBoxProps) => {
  return (
    <Box
      w="100%"
      backgroundColor="white"
      borderStyle="solid"
      borderWidth={2}
      borderRadius="xl"
      {...(disabled
        ? {
            backgroundColor: 'gray.100',
            cursor: 'not-allowed',
          }
        : null)}
      _hover={
        !disableHover && !disabled
          ? {
              backgroundColor: 'gray.50',
              cursor: 'pointer',
            }
          : undefined
      }
    >
      <InfoBox
        backgroundColor="inherit"
        border="none"
        leftIcon={
          <DynamicIcon
            iconName={leftIconName || DynamicIconName.deal}
            {...(disabled ? { color: 'gray.600' } : null)}
          />
        }
        rightButton={
          rest.rightButton || (
            <IconButton
              borderRadius="100%"
              backgroundColor="black"
              aria-label="arrow"
              size="sm"
              isDisabled={disabled}
              _hover={disabled || disableHover ? { backgroundColor: 'black' } : undefined}
              icon={<ChevronRightIcon height={6} width={6} color="white" />}
            />
          )
        }
        title={
          <Box maxW="80%">
            <Text {...(disabled ? { color: 'gray.600' } : null)} fontWeight="medium">
              {title}
            </Text>
          </Box>
        }
        description={
          typeof description === 'string' ? (
            <Box maxW="80%">
              <Text color="gray.600">{description}</Text>
            </Box>
          ) : (
            description
          )
        }
        {...rest}
      />
    </Box>
  )
}
