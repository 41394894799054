import { Box, Flex, Text } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { Spinner } from '@wanda-space/noelle'
import { type ServiceOrderResponseDto } from '@wanda-space/types'
import { BookingConfirmationHeader } from 'components/booking-confirm-page-components/BookingConfirmHeader'
import { YouWillBeContacted } from 'components/booking-confirm-page-components/YouWillBeContacted'
import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { add, orderlineToDiscountedOrderline } from 'utils'

import { useAppSelector } from 'hooks/useAppSelector'
import { linkFormatter } from 'utils/linkFormatter'
import { fetchServiceOrder } from '../../../api-client/lib/routes/serviceOrders'
import { BookingConfirmFooter } from '../../../components/booking-confirm-page-components/BookingConfirmFooter'
import { WorthKnowingWrapper } from '../../../components/booking-confirm-page-components/WorthKnowingWrapper'
import { Routes } from '../../../consts'
import { sanitizeStripeAmount } from '../../../utils/price-utils'

export type ConfirmationDetails = {
  serviceItemsCount: number
  serviceTotalPrice: string
}

const BookingConfirmation = () => {
  const { formatMessage } = useIntl()
  const [confirmationDetails, setConfirmationDetails] = useState<ConfirmationDetails | null>(null)
  const [orderNotFound, setOrderNotFound] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const serviceOrderId = searchParams.get('orderIds')
  const navigate = useNavigate()
  const { country, language: locale } = useAppSelector((state) => state.ui)
  const user = useAppSelector((state) => state.user.user)

  const serviceOrderResult = useQuery({
    queryKey: ['serviceOrderId', serviceOrderId],
    queryFn: () => fetchServiceOrder(serviceOrderId!),
    enabled: !!serviceOrderId,
  })

  const serviceOrder: ServiceOrderResponseDto | null = useMemo(() => {
    if (serviceOrderResult?.isSuccess && serviceOrderResult.data) {
      return serviceOrderResult.data
    }
    return null
  }, [serviceOrderResult])

  useEffect(() => {
    if (serviceOrder) {
      const totalServiceCosts = add(...serviceOrder.orderLines.map(orderlineToDiscountedOrderline))

      const uniqueServicedItemSet = new Set(serviceOrder.orderLines.map((ol) => ol.item?.id))

      setConfirmationDetails({
        serviceItemsCount: uniqueServicedItemSet.size,
        serviceTotalPrice: sanitizeStripeAmount(totalServiceCosts.amount).toString(),
      })
    }
  }, [serviceOrder])

  useEffect(() => {
    if (serviceOrderResult.isError) {
      setOrderNotFound(true)
    }
  }, [serviceOrderResult])

  if (orderNotFound) {
    navigate(Routes.ServiceFromStorage)
  }

  if (!confirmationDetails) {
    return <Spinner />
  }

  return (
    <>
      <BookingConfirmationHeader />
      <Flex mt={3} flexDirection="column">
        <Text size="md" mb={5}>
          {formatMessage(
            { id: 'booking-confirm.order.details.serviceFromStorage' },
            {
              serviceCount: (
                <b>
                  {confirmationDetails.serviceItemsCount}{' '}
                  {formatMessage(
                    {
                      id: 'word.thing.lowercase',
                    },
                    { count: confirmationDetails.serviceItemsCount }
                  )}
                </b>
              ),
              servicePrice: <b>{confirmationDetails.serviceTotalPrice}</b>,
              email: user?.email,
            }
          )}
        </Text>
        <WorthKnowingWrapper>
          <Box />
          <YouWillBeContacted messageId="booking.confirmation.order.worthKnowing.serviceFromStorageFlow.youWillBeContacted" />
        </WorthKnowingWrapper>
        <BookingConfirmFooter
          detailsMessage={formatMessage(
            {
              id: 'booking.confirmation.order.serviceFlow.forgotSomething.details',
            },
            {
              termsOfUseLink: (
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={linkFormatter(formatMessage, {
                    locale,
                    navigation: 'terms',
                    countryCode: country,
                  })}
                  style={{ textDecoration: 'underline' }}
                >
                  {formatMessage({ id: 'summary.termsOfUse' })}
                </a>
              ),
            }
          )}
        />
      </Flex>
    </>
  )
}

export { BookingConfirmation }
