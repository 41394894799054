import { Button, Flex, Spinner, Text, useToken } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { Elements } from '@stripe/react-stripe-js'
import { StripeElementsOptions } from '@stripe/stripe-js'
import { useWandaToast } from '@wanda-space/noelle'
import { SupportedCountries } from '@wanda-space/types'
import { useStripeContext } from 'contexts/stripe'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { usePayment } from 'hooks/usePayments'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { getCurrencyForCountryCode } from 'utils'
import { serializeError } from 'utils/errors'

import { InvoiceBox } from './InvoiceBox'
import { PaymentCardBox } from './PaymentCardBox'
import { PaymentDetails } from './PaymentDetails'
import { PaymentError } from './PaymentError'
import { PaymentContentProps, PaymentType, PaymentWrapperProps } from './types'

const defaultStripeOptions: StripeElementsOptions = {
  mode: 'setup',
  currency: getCurrencyForCountryCode(SupportedCountries.NO),
  setup_future_usage: 'off_session',
}

export const PaymentWrapper = (props: PaymentWrapperProps) => {
  const stripeContext = useStripeContext()
  const featureFlags = useFeatureFlags()
  const [ctaBlack900, red500, blue500] = useToken('colors', ['ctaBlack.900', 'red.500', 'blue.500'])
  const [fontFamily] = useToken('fonts', ['body'])
  const [fontSizeSm] = useToken('fontSizes', ['sm'])
  const [space7, space2] = useToken('space', ['7', '2'])

  const options = props.options ?? defaultStripeOptions

  if (stripeContext.loading || featureFlags.isInitialLoading) {
    return <Spinner />
  }

  return (
    <Elements
      stripe={stripeContext.stripe}
      options={{
        ...options,
        appearance: {
          variables: {
            colorPrimary: blue500,
            colorDanger: red500,
            fontFamily,
            fontSizeSm,
            spacingGridRow: space7,
          },
          rules: {
            '.Label': {
              color: ctaBlack900,
              textTransform: 'uppercase',
              marginBottom: space2,
            },
            '.Input': {
              fontWeight: '500',
            },
          },
        },
      }}
    >
      {featureFlags.data?.ENABLE_PAYMENT_ELEMENTS ? (
        <PaymentDetails {...props} options={options} />
      ) : (
        <PaymentContent {...props} />
      )}
    </Elements>
  )
}

export const PaymentContent = ({
  onSuccess,
  buttonLoading,
  disableButton,
  disableOfferInvoiceOption = false,
  getPaymentIntentConfirmationDetails,
  paymentButtonLabel,
}: PaymentContentProps) => {
  const [selectedPaymentType, setselectedPaymentType] = useState<PaymentType | undefined>(
    disableOfferInvoiceOption ? PaymentType.PAYMENT_METHOD : undefined
  )
  const [isPlacingOrder, setIsPlacingOrder] = useState(false)
  const useHandleOnTimePayment = typeof getPaymentIntentConfirmationDetails !== 'undefined'
  const toast = useWandaToast()

  const {
    paymentError,
    paymentLoading,
    handleOneTimePayment,
    paymentMethodsResult,
    addNewPaymentMethod,
    defaultPaymentMethod,
    isPaymentReady,
  } = usePayment({
    onSuccess,
    getPaymentIntentConfirmationDetails,
  })

  const handlePlaceOrder = async () => {
    try {
      setIsPlacingOrder(true)
      if (!selectedPaymentType) {
        throw new Error('Payment Type is not selected')
      }

      if (selectedPaymentType === PaymentType.INVOICE) {
        return await onSuccess()
      }

      if (!defaultPaymentMethod) {
        throw new Error('Missing default payment method during card payment')
      }

      if (useHandleOnTimePayment) {
        return await handleOneTimePayment()
      }

      return await onSuccess(defaultPaymentMethod.id)
    } catch (error: unknown) {
      toast({
        variant: 'subtle',
        status: 'error',
        description: formatMessage({ id: serializeError(error).localizationKey }),
      })
      captureException(error)
    } finally {
      setIsPlacingOrder(false)
    }
  }

  const getPaymentButtonLabelBySelectedPaymentType = () => {
    return selectedPaymentType === PaymentType.INVOICE
      ? formatMessage({ id: 'word.pay.invoice' })
      : formatMessage({ id: 'word.pay.card' })
  }

  const { formatMessage } = useIntl()

  const emptyPaymentMethods =
    paymentMethodsResult.isSuccess && paymentMethodsResult.data.length === 0

  const noCardPresent = selectedPaymentType === PaymentType.PAYMENT_METHOD && emptyPaymentMethods

  const offerInvoiceOption = !disableOfferInvoiceOption && emptyPaymentMethods

  if (!selectedPaymentType && paymentMethodsResult.isSuccess && !emptyPaymentMethods) {
    setselectedPaymentType(PaymentType.PAYMENT_METHOD)
  }

  return (
    <>
      {paymentMethodsResult.isLoading ? (
        <Spinner />
      ) : (
        <>
          <Text m="2" fontSize={'lg'} fontWeight={'medium'}>
            {formatMessage({ id: 'payment.information.header' })}
          </Text>
          <PaymentCardBox
            defaultPaymentMethod={defaultPaymentMethod}
            selected={selectedPaymentType}
            onSelect={(value) => setselectedPaymentType(value)}
            roundCorners={!offerInvoiceOption}
            handleNewCard={addNewPaymentMethod}
            selectable={offerInvoiceOption}
          />
          {offerInvoiceOption && (
            <InvoiceBox
              selected={selectedPaymentType}
              onSelect={(value) => setselectedPaymentType(value)}
            />
          )}

          <Flex mt="5" display="block">
            <PaymentError error={paymentError} />
            <Button
              data-testid="continue-button"
              backgroundColor={'purple.400'}
              opacity={!selectedPaymentType || disableButton || noCardPresent ? '40%' : '100%'}
              isDisabled={!selectedPaymentType || disableButton || noCardPresent || !isPaymentReady}
              size="lg"
              onClick={handlePlaceOrder}
              width="100%"
              isLoading={isPlacingOrder || buttonLoading || paymentLoading}
            >
              {paymentButtonLabel
                ? paymentButtonLabel
                : getPaymentButtonLabelBySelectedPaymentType()}
            </Button>
            <Text my="4" textAlign="center" color="gray.500">
              {formatMessage({ id: 'safe.payment.with.stripe' })}
            </Text>
          </Flex>
        </>
      )}
    </>
  )
}
