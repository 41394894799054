import { Flex, Heading, Image } from '@chakra-ui/react'
import spaceshipVan from '@wanda-space/noelle/illustrations/spaceshipVanBlack.svg'
import React from 'react'
import { useIntl } from 'react-intl'

export const BookingConfirmationHeader = () => {
  const { formatMessage } = useIntl()
  return (
    <>
      <Flex justifyContent="center" mb={5} data-testid="booking-confirmation-header">
        <Flex
          height={136}
          width="100%"
          backgroundColor="purple.100"
          borderRadius="3xl"
          justifyContent="center"
          alignItems="center"
        >
          <Image alt="spaceship van" src={spaceshipVan} />
        </Flex>
      </Flex>
      <Heading as="h1" size="2xl" fontWeight="normal">
        {formatMessage({ id: 'booking.confirmation.thanks' })}
      </Heading>
    </>
  )
}
