import { useAppSelector } from './useAppSelector'

export function useActiveUserLocation() {
  const country = useAppSelector((state) => state.ui.country)
  const serviceArea = useAppSelector((state) => state.ui.city)
  const postalCode = useAppSelector((state) => state.ui.postalCode)

  return {
    country,
    serviceArea,
    postalCode,
  }
}
