import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import {
  AccountId,
  type DiscountResponseDto,
  type ItemResponseDto,
  type PlacedOrderResponseDto,
  type ServiceLevelType,
} from '@wanda-space/types'
import type { DateAndTime, OrderLineWithFullProductAndDiscount, Product } from 'api-client'
import { ContactPerson } from 'components/contact-info/types'
import type { FlattenedDeliveryInfo, PriceWrapper } from 'interfaces'
import type { RootState } from 'reduxStore'
import { mapItemToStorageOrderLine, mapUserProductToOrderLine } from 'reduxStore/commonMappers'
import type { ItemPayloadWithProduct } from 'reduxStore/commonMappers'
import { getAllOrderlinesWithDiscounts } from 'utils/product-utils'

import { DEFAULT_COUNTRY } from 'i18n'
import { getSupportedCurrencyForCountryCode } from 'utils'
import { SliceNames } from '../../constants'
import type { StorageFlowState } from './types'

const itemPayloadAdapter = createEntityAdapter<ItemPayloadWithProduct>()
export const initialState: StorageFlowState = {
  dateAndTime: {},
  newItems: itemPayloadAdapter.getInitialState(),
  existingItems: [],
  address: {} as FlattenedDeliveryInfo,
  orderLines: {
    storage: [],
    addons: [],
    taas: [],
    packing: [],
    timeslot: [],
  },
  estimatedDeliveryCost: {
    amount: 0,
    currency: getSupportedCurrencyForCountryCode(DEFAULT_COUNTRY),
  },
  coupon: undefined,
  orderSuccessPayload: null,
}
export const storageFlowStateSlice = createSlice({
  name: SliceNames.STORAGE,
  initialState,
  reducers: {
    selectDateAndTime: (state, action: PayloadAction<DateAndTime>) => {
      state.dateAndTime = action.payload
    },
    setItems: (state, action: PayloadAction<ItemPayloadWithProduct[]>) => {
      itemPayloadAdapter.removeAll(state.newItems)
      itemPayloadAdapter.addMany(state.newItems, action.payload)
      state.orderLines.storage = [...action.payload, ...state.existingItems].map(
        mapItemToStorageOrderLine
      )
    },
    submitAddress: (state, action: PayloadAction<FlattenedDeliveryInfo>) => {
      state.address = action.payload
    },
    setServiceLevel: (state, action: PayloadAction<{ serviceLevelType: ServiceLevelType }>) => {
      state.serviceLevel = action.payload.serviceLevelType
    },
    applyDiscounts: (
      state,
      action: PayloadAction<{ coupon?: string; discounts: DiscountResponseDto[] }>
    ) => {
      if (action.payload.coupon) {
        state.coupon = action.payload.coupon
      }
      const { packing, taas, storage, timeslot, addons } = getAllOrderlinesWithDiscounts(
        state.orderLines.addons,
        state.orderLines.packing,
        state.orderLines.storage,
        state.orderLines.taas,
        state.orderLines.timeslot,
        [],
        action.payload.discounts
      )
      state.orderLines = { packing, taas, storage, timeslot, addons }
    },
    setExistingItems: (state, { payload }: PayloadAction<ItemResponseDto[]>) => {
      state.existingItems = payload
      state.orderLines.storage = [
        ...payload,
        ...(Object.values(state.newItems.entities).filter(
          (item) => !!item
        ) as ItemPayloadWithProduct[]),
      ].map(mapItemToStorageOrderLine)
    },
    resetAll: () => {
      return initialState
    },
    setPacking: (state, action: PayloadAction<OrderLineWithFullProductAndDiscount[]>) => {
      state.orderLines.packing = action.payload
    },
    addAddons: (state, action: PayloadAction<{ product: Product; accountId: AccountId }>) => {
      state.orderLines.addons = [
        ...state.orderLines.addons,
        mapUserProductToOrderLine(action.payload.product, action.payload.accountId),
      ]
    },
    updateAddons: (state, action: PayloadAction<{ productId: string; accountId: AccountId }>) => {
      const index = state.orderLines.addons.findIndex(
        (addon) => addon.product.id === action.payload.productId
      )

      state.orderLines.addons[index].accountId = action.payload.accountId
    },
    removeAddon: (state, action: PayloadAction<{ productId: string }>) => {
      const index = state.orderLines.addons.findIndex(
        (addon) => addon.product.id === action.payload.productId
      )

      if (index >= 0) {
        state.orderLines.addons.splice(index, 1)
      }
    },
    setTaasOrderlines: (state, action: PayloadAction<OrderLineWithFullProductAndDiscount[]>) => {
      state.orderLines.taas = action.payload
    },
    setOrderSuccessPayload: (state, action: PayloadAction<PlacedOrderResponseDto>) => {
      state.orderSuccessPayload = action.payload
    },
    setContactPerson: (state, { payload }: PayloadAction<ContactPerson>) => {
      state.contactPerson = payload
    },
    setEstimatedDeliveryCost: (state, { payload }: PayloadAction<PriceWrapper>) => {
      state.estimatedDeliveryCost = payload
    },
    setTimeslotOrderlines: (
      state,
      action: PayloadAction<OrderLineWithFullProductAndDiscount[]>
    ) => {
      state.orderLines.timeslot = action.payload
    },
  },
})

export const {
  setItems,
  setServiceLevel,
  submitAddress,
  selectDateAndTime,
  resetAll,
  applyDiscounts,
  setExistingItems,
  addAddons,
  setTaasOrderlines,
  setPacking,
  updateAddons,
  removeAddon,
  setEstimatedDeliveryCost,
  setOrderSuccessPayload,
  setContactPerson,
  setTimeslotOrderlines,
} = storageFlowStateSlice.actions

export const itemPayloadSelectors = itemPayloadAdapter.getSelectors<RootState>(
  (state) => state.storageFlow.newItems
)

export const orderContactPersonSelector = (state: RootState) => state.storageFlow.contactPerson

export const storageFlow = storageFlowStateSlice.reducer
