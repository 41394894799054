import { Button, Flex, Text } from '@chakra-ui/react'
import { CheckMarkIcon } from '@wanda-space/noelle'
import React from 'react'
import { useIntl } from 'react-intl'

import { PaymentType } from './types'

interface Props {
  selected: PaymentType | undefined
  onSelect: (value: PaymentType) => void
}

export function InvoiceBox({ selected, onSelect }: Props) {
  const invoiceSelected = selected === PaymentType.INVOICE
  const { formatMessage } = useIntl()
  return (
    <Button
      p="6"
      border="1px solid"
      borderColor={`${invoiceSelected ? 'purple.600' : 'gray.200'}`}
      borderTopRadius="0"
      borderBottomRadius="12"
      justifyContent="left"
      variant="outline"
      onClick={() => onSelect(PaymentType.INVOICE)}
      height="auto"
      width="100%"
      display={'block'}
      whiteSpace="normal"
      data-testid="invoice-payment-method"
      backgroundColor={`${invoiceSelected ? 'purple.50' : 'none'}`}
      _hover={{
        background: invoiceSelected ? 'purple.100' : 'gray.50',
      }}
      _selected={{
        background: invoiceSelected ? 'purple.200' : 'gray.50',
      }}
    >
      <Flex width="100%" justifyItems="flex-start">
        <Flex flexDirection="column" width="100%">
          <Flex justifyContent="space-between" alignItems="center" mb={2}>
            <Text fontSize="xl" fontWeight="500">
              {formatMessage({ id: 'invoice.header' })}
            </Text>

            <CheckMarkIcon
              onClick={() => onSelect(PaymentType.INVOICE)}
              textAlign="end"
              color={invoiceSelected ? 'purple.700' : 'gray.200'}
              width="6"
              height="6"
            />
          </Flex>
        </Flex>
      </Flex>
      <Text fontSize="lg">{formatMessage({ id: 'invoice.description' })}</Text>
    </Button>
  )
}
