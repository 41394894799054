import {
  Box,
  Center,
  Container,
  Divider,
  Flex,
  HStack,
  Heading,
  List,
  ListIcon,
  ListItem,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  DealIcon,
  DynamicIcon,
  DynamicIconName,
  FileShareIcon,
  MedalIcon,
  MovingTruckIcon,
  WandaTooltip,
} from '@wanda-space/noelle'
import { SupportedCurrencies } from '@wanda-space/types'
import React from 'react'
import { useIntl } from 'react-intl'

const publishedPageWorthToKnow = [
  {
    icon: FileShareIcon,
    id: 'info.buy.worth.to.know.view.link',
  },
  {
    icon: MedalIcon,
    id: 'info.buy.worth.to.know.first.come.first',
  },
  {
    icon: MovingTruckIcon,
    id: 'info.buy.worth.to.know.bought.same.area',
  },
  {
    icon: DealIcon,
    id: 'info.buy.worth.to.know.when.sold',
  },
]

export const ServiceFeeDetails = ({
  feePercent,
  amount,
  feeFlat,
  currency,
}: {
  feePercent: number
  feeFlat: number
  amount: number
  currency: SupportedCurrencies
}) => {
  const { formatMessage, formatNumber } = useIntl()
  return (
    <Box>
      <Text fontWeight="medium" fontSize="sm">
        {formatMessage({ id: 'phrase.you.will.receive' })}
      </Text>
      <Flex justifyContent="space-between">
        <Text fontSize="sm">{formatNumber(amount)},-</Text>
        <Flex alignItems="flex-start">
          {feePercent ? (
            <Text fontSize="sm" color="gray.600" mr="1">
              {formatMessage(
                { id: 'phrase.service.fee.percent' },
                { feePercent, feeFlat, currency }
              )}
            </Text>
          ) : null}
          {feeFlat ? (
            <Text fontSize="sm" color="gray.600" mr="1">
              {formatMessage({ id: 'phrase.service.fee.flat' }, { feePercent, feeFlat, currency })}
            </Text>
          ) : null}
          {feePercent || feeFlat ? (
            <Text fontSize="sm" color="gray.600">
              {formatMessage({ id: 'word.fee' })}
            </Text>
          ) : null}

          <WandaTooltip
            content={formatMessage(
              { id: 'phrase.service.fee.tooltip' },
              { feePercent, feeFlat, currency }
            )}
            iconButtonProps={{
              ml: 1,
              as: 'div',
              height: 'fit-content',
              'aria-label': 'service-fee-tooltip-button',
              icon: (
                <DynamicIcon
                  iconName={DynamicIconName.info}
                  color="gray.600"
                  width={4}
                  height={4}
                  mt="-1.5"
                />
              ),
            }}
          />
        </Flex>
      </Flex>
    </Box>
  )
}

export const CommonBannerDetails = ({
  feePercent,
  feeFlat,
  amount,
  currency,
}: {
  feePercent: number
  feeFlat: number
  amount: number
  currency: SupportedCurrencies
}) => {
  const { formatMessage } = useIntl()

  return (
    <>
      <Container>
        <ServiceFeeDetails
          feeFlat={feeFlat}
          feePercent={feePercent}
          amount={amount}
          currency={currency}
        />
      </Container>
      <Divider width="100%" />
      <Container>
        <Heading fontSize="md" my={4} as="h3">
          {formatMessage({ id: 'phrase.worthKnowing' })}
        </Heading>
        <List spacing={3}>
          {publishedPageWorthToKnow.map(({ id, icon }) => (
            <ListItem key={id} my={4}>
              <ListIcon boxSize={6} as={icon} />
              {formatMessage({ id })}
            </ListItem>
          ))}
        </List>
      </Container>
    </>
  )
}
