import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  AccountId,
  type DiscountResponseDto,
  ItemMixTypes,
  type PlacedOrderResponseDto,
  ServiceLevelType,
} from '@wanda-space/types'
import type { DateAndTime, OrderLineWithFullProductAndDiscount, Product } from 'api-client'
import { type ContactPerson } from 'components/contact-info/types'
import { type FlattenedDeliveryInfo } from 'interfaces'
import type { RootState } from 'reduxStore'
import { mapUserProductToOrderLine } from 'reduxStore/commonMappers'
import { discountMapper, getAllOrderlinesWithDiscounts } from 'utils'

import { SliceNames } from '../../constants'
import type { SquareMeterFlowState } from './types'

export const initialState: SquareMeterFlowState = {
  dateAndTime: {},
  address: {} as FlattenedDeliveryInfo,
  orderLines: {
    squareMeterProduct: null,
    timeslot: [],
    addons: [],
  },
  serviceLevel: ServiceLevelType.CARRYING,
  orderSuccessPayload: null,
}

export const squareMeterFlowStateSlice = createSlice({
  name: SliceNames.M2_PPA,
  initialState,
  reducers: {
    selectDateAndTime: (state, action: PayloadAction<DateAndTime>) => {
      state.dateAndTime = action.payload
    },

    setAddress: (state, action: PayloadAction<FlattenedDeliveryInfo>) => {
      state.address = action.payload
    },

    resetAll: () => {
      return initialState
    },

    setSquareMeterProduct: (state, action: PayloadAction<OrderLineWithFullProductAndDiscount>) => {
      state.orderLines.squareMeterProduct = action.payload
    },

    setNextLevelTier: (state, action: PayloadAction<Product | undefined>) => {
      state.nextLevelTier = action.payload
    },

    setOrderSuccessPayload: (state, action: PayloadAction<PlacedOrderResponseDto>) => {
      state.orderSuccessPayload = action.payload
    },

    setTimeslotOrderlines: (
      state,
      action: PayloadAction<OrderLineWithFullProductAndDiscount[]>
    ) => {
      state.orderLines.timeslot = action.payload
    },

    addAddons: (state, action: PayloadAction<{ product: Product; accountId: AccountId }>) => {
      state.orderLines.addons = [
        ...state.orderLines.addons,
        mapUserProductToOrderLine(action.payload.product, action.payload.accountId),
      ]
    },

    applyDiscounts: (
      state,
      action: PayloadAction<{ coupon?: string; discounts: DiscountResponseDto[] }>
    ) => {
      if (action.payload.coupon) {
        state.coupon = action.payload.coupon
      }
      const { addons, timeslot } = getAllOrderlinesWithDiscounts(
        state.orderLines.addons,
        [],
        [],
        [],
        state.orderLines.timeslot,
        [],
        action.payload.discounts
      )
      state.orderLines.addons = addons
      state.orderLines.timeslot = timeslot
      state.orderLines.squareMeterProduct = state.orderLines.squareMeterProduct
        ? discountMapper([state.orderLines.squareMeterProduct], action.payload.discounts)[0]
        : null
    },

    setContactPerson: (state, { payload }: PayloadAction<ContactPerson>) => {
      state.contactPerson = payload
    },

    removeAddon: (state, action: PayloadAction<{ productId: string }>) => {
      const index = state.orderLines.addons.findIndex(
        (addon) => addon.product.id === action.payload.productId
      )

      state.orderLines.addons.splice(index, 1)
    },

    setItemMix: (state, action: PayloadAction<ItemMixTypes>) => {
      state.itemMix = action.payload
    },
  },
})

export const {
  setAddress,
  selectDateAndTime,
  resetAll,
  setOrderSuccessPayload,
  setTimeslotOrderlines,
  setSquareMeterProduct,
  setNextLevelTier,
  setContactPerson,
  applyDiscounts,
  addAddons,
  removeAddon,
  setItemMix,
} = squareMeterFlowStateSlice.actions

export const orderContactPersonSelector = (state: RootState) => state.squareMeterFlow.contactPerson
export const itemMixSelector = (state: RootState) => state.squareMeterFlow.itemMix

export const squareMeterFlow = squareMeterFlowStateSlice.reducer
