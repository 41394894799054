import { replace } from 'ramda'

export const downloadFile = (listingName: string, blob: Blob, uniqueId?: number | string) => {
  const imageURL = URL.createObjectURL(blob)
  const anchor = document.createElement('a')
  anchor.href = imageURL
  anchor.download = replace(/\s/g, '-', listingName) + (uniqueId ? uniqueId.toString() : '')
  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)
  URL.revokeObjectURL(imageURL)
}
