/**
 * @file Custom hook that provides route to relevant initial page for storage flow, for a given user
 */

import { type OrderResponseWithItems, OrderState } from '@wanda-space/types'
import { type OrderlineInOrderResponse } from 'api-client'
import { Routes } from 'consts'
import { useAppSelector } from 'hooks/useAppSelector'
import { useAuth } from 'hooks/useAuth'
import { hasOrderlineSquareMeterOrderline } from 'routes/SquareMeterFlow/helper'

import { useItems } from './useItems'
import { useM2Subscriptions } from './useM2Subscriptions'
import { useOrders } from './useOrders'

export const storageKeys: { [key: string]: string } = {
  PER_ITEM: 'PER_ITEM',
  SELECT: 'SELECT',
  M2: 'M2',
} as const

/** The routes that are relevant to use for storage type selection */
export const StorageFlowRoutes: { [key: keyof typeof storageKeys]: string } = {
  /** Route for "per item" storage */
  [storageKeys.PER_ITEM]: Routes.Storage,
  /** Route for selecting storage type */
  [storageKeys.SELECT]: Routes.SelectStorage,
  /** Route for "m2" storage */
  [storageKeys.M2]: Routes.SquareMeterSelectAreaSize,
} as const

/** The id's (used for GTM), corresponding with the routes that are relevant to use for storage type selection */
export const StorageFlowIds: { [key: keyof typeof storageKeys]: string } = {
  /** Id for "per item" storage */
  [storageKeys.PER_ITEM]: 'storage-per-item',
  /** Id for selecting storage type */
  [storageKeys.SELECT]: 'storage-select',
  /** Route for "m2" storage */
  [storageKeys.M2]: 'storage-per-m2',
} as const

// convenience method
const getFlowInfo = (key: keyof typeof storageKeys) => ({
  flowRoute: StorageFlowRoutes[key],
  flowId: StorageFlowIds[key],
})

// Use these later, when BE has decorated user accordingly
/*
const isUsingM2Storage = (user: User | null): boolean =>
  user?.features?.includes('m2-storage') || false

const isUsingPerItemStorage = (user: User | null): boolean =>
  user?.features?.includes('per-item-storage') || false
*/

export function useStorageFlowRouteSelector() {
  const { isAuthenticated, isAuthenticating } = useAuth()
  const user = useAppSelector((state) => state.user.user)

  let defaultValue: {
    flowRoute: string
    flowId: string
    meterSquareOrder?: OrderResponseWithItems
    meterSquareProduct?: OrderlineInOrderResponse['product']
    perItemStorageOrder?: OrderResponseWithItems[]
  } = {
    flowRoute: '',
    flowId: '',
  }

  const { data: orders, isInitialLoading: isOrdersLoading } = useOrders()

  if (orders) {
    const activeOrders = orders.filter((order) => order.state === OrderState.PAYMENT_AUTHORISED)
    const meterSquareOrder = activeOrders.find((order) => {
      return hasOrderlineSquareMeterOrderline(order.orderLines)
    })

    if (meterSquareOrder) {
      const orderline = hasOrderlineSquareMeterOrderline(meterSquareOrder.orderLines)
      if (orderline) {
        defaultValue = { ...defaultValue, meterSquareOrder, meterSquareProduct: orderline.product }
      }
    } else {
      defaultValue = { ...defaultValue, perItemStorageOrder: activeOrders }
    }
  }

  // Temporary way to figure out whether user already uses m2 ................................................\
  const productSubscriptions = useM2Subscriptions()
  const usesM2 = productSubscriptions.hasSquareMeterSubscriptions

  // ........................................................................................................./

  // Temporary way to figure out whether user already uses per item storage ..................................\
  const { data: existingItemsOnUser, isInitialLoading: isUserLoading } = useItems(user, {
    enabled: isAuthenticated,
  })
  // ........................................................................................................./

  if (
    isOrdersLoading ||
    productSubscriptions.isInitialLoading ||
    isUserLoading ||
    isAuthenticating
  ) {
    return defaultValue
  }

  const usesPerItem = isAuthenticated && !usesM2 && !!existingItemsOnUser?.length

  // User is not logged in - let user decide
  if (!isAuthenticated || !user) {
    return { ...defaultValue, ...getFlowInfo(storageKeys.SELECT) }
  }

  // User is logged in - check if already using m2 storage
  // if (isUsingM2Storage(user)) return getArr(storageKeys.M2) // <- Use this approach later, when BE has decorated user accordingly
  if (usesM2 || defaultValue.meterSquareOrder) {
    const flowInfo = getFlowInfo(storageKeys.M2)
    return { ...defaultValue, ...flowInfo }
  }

  // User is logged in - not using m2 storage already - check if user already uses per item storage
  //if (isUsingPerItemStorage(user)) return getArr(storageKeys.PER_ITEM) // <- Use this approach later, when BE has decorated user accordingly
  if (
    usesPerItem ||
    (defaultValue.perItemStorageOrder && defaultValue.perItemStorageOrder.length > 0)
  ) {
    return { ...defaultValue, ...getFlowInfo(storageKeys.PER_ITEM) }
  }

  // User is logged in - not using m2 storage already - not using per item storage already - let user decide
  return { ...defaultValue, ...getFlowInfo(storageKeys.SELECT) }
}
