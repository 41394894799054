import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { CheckIcon, ChevronRightIcon, CouponIcon, useWandaToast } from '@wanda-space/noelle'
import { DiscountResponseDto } from '@wanda-space/types'
import { ApiError } from 'api-client/lib/api-client'
import { fetchDiscounts } from 'api-client/lib/routes/discount'
import {
  LoyaltyMembershipDto,
  LoyaltyPartner,
  activateObosMembership,
  getMembership,
} from 'api-client/lib/routes/loyalty'
import { Field, FieldProps, Form, Formik } from 'formik'
import { useAppSelector } from 'hooks/useAppSelector'
import React from 'react'
import { useIntl } from 'react-intl'

interface ObosMembershipFieldProps {
  padding: number
  smallGap: number
  onMembershipActivation: (discounts: DiscountResponseDto[]) => void
  coupon: string | undefined
}

interface ObosMembershipFieldValues {
  obosNumber: string
  birthdate: string
}

export const ObosMembershipField = ({
  padding,
  smallGap,
  onMembershipActivation,
  coupon,
}: ObosMembershipFieldProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { formatMessage } = useIntl()
  const handleSetObos = async (num: string) => {
    localStorage.setItem('obosNo', num)
  }
  const accountId = useAppSelector((state) => state.user.user?.id)
  const country = useAppSelector((state) => state.ui.country)
  const city = useAppSelector((state) => state.ui.city)
  const wandaToast = useWandaToast()
  const checkObosMembership = useQuery([accountId, 'obos-membership'], async () => {
    try {
      const data = await getMembership(LoyaltyPartner.OBOS)
      return data
    } catch (error) {
      captureException(error)
    }
  })
  const productDiscounts = useQuery({
    queryKey: ['products-with-discount', country, city, coupon],
    queryFn: async () => {
      if (city) {
        const discounts = await fetchDiscounts({
          countryCode: country,
          city: city,
          code: coupon || undefined,
        })
        return discounts
      }
      return []
    },
    enabled: !!city && !!country,
  })
  const activateObosMembershipMutation = useMutation(activateObosMembership, {
    onError: (error) => {
      captureException(error)
      wandaToast({
        title: formatMessage({
          id: 'error.failed-to-activate-obos-membership',
        }),
        description: formatMessage({
          id: (error as ApiError)?.localizationKey() ?? 'general.error',
        }),
        status: 'error',
      })
    },
    onSuccess: async (data: LoyaltyMembershipDto) => {
      handleSetObos(data.loyaltyMembershipId)
      const result = await productDiscounts.refetch()
      onMembershipActivation(result.data ?? [])
      await checkObosMembership.refetch()
    },
  })

  const isLoyaltyActive = Boolean(checkObosMembership.data?.loyaltyMembershipId)

  return (
    <>
      {!isLoyaltyActive ? (
        <>
          <Flex
            as={Button}
            justifyContent="space-between"
            onClick={onOpen}
            color="gray.600"
            p={padding}
            outline="1px solid"
            borderRadius="md"
            outlineColor="gray.200"
            outlineOffset={0}
            bg="transparent"
            _active={{ bg: 'transparent' }}
            _hover={{ bg: 'transparent' }}
          >
            {formatMessage({ id: 'payment.register.obos.membership' })}
            <ChevronRightIcon color="gray.600" />
          </Flex>

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <>
                <ModalHeader>
                  {formatMessage({ id: 'payment.register.obos.membership' })}

                  <ModalCloseButton bg="purple.200" p={smallGap} borderRadius="100%" />
                </ModalHeader>
                <Formik
                  initialValues={{
                    obosNumber: '',
                    birthdate: '',
                  }}
                  onSubmit={({ birthdate, obosNumber }) =>
                    activateObosMembershipMutation.mutateAsync({
                      dateOfBirth: birthdate,
                      loyaltyMembershipId: obosNumber,
                    })
                  }
                >
                  {({ isSubmitting, values }) => (
                    <Form>
                      <ModalBody>
                        <Field name="birthdate">
                          {({ field, form }: FieldProps<string, ObosMembershipFieldValues>) => (
                            <FormControl mb={2}>
                              <FormLabel fontWeight={'medium'} mb={2}>
                                {formatMessage({
                                  id: 'partnership.obos.birthdate.label',
                                })}
                              </FormLabel>
                              <Input
                                value={field.value}
                                type="date"
                                name="birthdate"
                                onChange={form.handleChange}
                              />
                            </FormControl>
                          )}
                        </Field>
                        <Field name="obosNumber">
                          {({ field, form }: FieldProps<string, ObosMembershipFieldValues>) => (
                            <FormControl
                              isInvalid={Boolean(activateObosMembershipMutation.error)}
                              mb={2}
                            >
                              <FormLabel mb={2} fontWeight={'medium'} ml={1}>
                                {formatMessage({ id: 'word.obosMemberNo' })}
                              </FormLabel>
                              <Input
                                name="obosNumber"
                                value={field.value}
                                onChange={form.handleChange}
                                placeholder={formatMessage({
                                  id: 'word.obosMemberNo',
                                })}
                              />
                              <FormErrorMessage>
                                {formatMessage({
                                  id: 'word.obos.format.error',
                                })}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          type="submit"
                          isLoading={isSubmitting}
                          loadingText={formatMessage({
                            id: 'word.partnership.verifying',
                          })}
                          mt={4}
                          size="lg"
                          data-testid="save-button"
                          colorScheme="ctaBlack"
                          isDisabled={!(values.obosNumber || values.birthdate)}
                        >
                          {formatMessage({ id: 'word.save' })}
                        </Button>
                      </ModalFooter>
                    </Form>
                  )}
                </Formik>
              </>
            </ModalContent>
          </Modal>
        </>
      ) : (
        <Flex
          outline="1px solid"
          borderRadius="md"
          outlineColor="gray.200"
          justifyContent="space-between"
          alignItems="center"
          px={4}
          py={2}
          cursor="not-allowed"
        >
          <Flex alignItems="center">
            <CouponIcon mr={smallGap} />
            <Text fontSize="large">{formatMessage({ id: 'word.obosMembership' })}</Text>
          </Flex>
          <Box py={1} px={2} borderRadius="md" bg="green.200">
            <CheckIcon color="white" />
          </Box>
        </Flex>
      )}
    </>
  )
}
