import { Spinner } from '@wanda-space/noelle'
import { type ItemResponseDto, StorageItemState } from '@wanda-space/types'
import { ItemForm } from 'components/Item'
import { Routes } from 'consts'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { useImageHook } from '../hooks'

interface Props {
  item: ItemResponseDto
}

const WithCustomer = ({ item }: Props) => {
  const { isLoading, isError, isSuccess, addImage, deleteImage } = useImageHook()
  const location = useLocation()

  if (!item || item.state !== StorageItemState.WITH_CUSTOMER) {
    return <Navigate to={location.state?.referrer || Routes.Space} />
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      {item && (
        <ItemForm
          isError={isError}
          isSuccess={isSuccess}
          addImage={addImage}
          deleteImage={deleteImage}
          item={item}
        />
      )}
    </>
  )
}

export { WithCustomer }
