import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { WandaSpinner } from '@wanda-space/noelle'
import React from 'react'
import { useIntl } from 'react-intl'

export const AccountMergingLoadingModal: React.FC = () => {
  const { onClose } = useDisclosure()
  const { formatMessage } = useIntl()

  return (
    <Modal isCentered size="sm" isOpen={true} onClose={onClose}>
      <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="5px" />
      <ModalContent borderRadius="12">
        <ModalBody textAlign="center" py="5">
          <Box>
            <WandaSpinner color="gray.400" w="14" />
          </Box>
          <Text mt="2" color="gray.600">
            {formatMessage({ id: 'users.merge.loading' })}
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
