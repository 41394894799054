import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { type PlaceShopOrderWithOrderlinesResponseDto } from '@wanda-space/types'
import { type OrderLineWithFullProductAndDiscount } from 'api-client'

import { SliceNames } from '../../constants'

interface PackagingOrderAddress {
  street: string
  postalCode: string
  city: string
  phoneNumber: string
}

export interface PackagingOrderDetails extends PackagingOrderAddress {
  firstName: string
  lastName: string
  timeSlot?: { from: string; to: string }
  selectedDate?: string
}

export interface PackagingFlowState {
  orderLines: {
    packaging: OrderLineWithFullProductAndDiscount[]
  }
  orderDetails: PackagingOrderDetails
  orderSuccessPayload: PlaceShopOrderWithOrderlinesResponseDto | null
}

const initialState: PackagingFlowState = {
  orderLines: {
    packaging: [],
  },
  orderDetails: {
    street: '',
    postalCode: '',
    city: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    timeSlot: undefined,
    selectedDate: undefined,
  },
  orderSuccessPayload: null,
}

export const packagingFlowStateSlice = createSlice({
  name: SliceNames.PACKAGING,
  initialState,
  reducers: {
    setPackagingOrderLines: (
      state,
      action: PayloadAction<OrderLineWithFullProductAndDiscount[]>
    ) => {
      state.orderLines.packaging = action.payload
    },
    setOrderDetails: (state, action: PayloadAction<PackagingOrderDetails>) => {
      state.orderDetails = action.payload
    },
    setOrderSuccessPayload: (
      state,
      action: PayloadAction<PlaceShopOrderWithOrderlinesResponseDto>
    ) => {
      state.orderSuccessPayload = action.payload
    },
    resetAll: () => {
      return initialState
    },
  },
})

export const { setPackagingOrderLines, setOrderSuccessPayload, resetAll, setOrderDetails } =
  packagingFlowStateSlice.actions

export const packagingFlow = packagingFlowStateSlice.reducer
