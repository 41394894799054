import { Button, Flex, Text } from '@chakra-ui/react'
import { ChatIcon } from '@wanda-space/noelle'
import React from 'react'
import { useIntl } from 'react-intl'
import { openHelpChat } from 'utils/help'

export const HelpFooter = () => {
  const { formatMessage } = useIntl()
  return (
    <Flex direction="column" alignItems="center" mt="12">
      <ChatIcon width="5" />
      <Text fontWeight="500">{formatMessage({ id: 'word.help' })}</Text>
      <Button
        variant="link"
        display="inline-block"
        onClick={openHelpChat}
        color="ctaBlack.500"
        textDecoration="underline"
      >
        {formatMessage({ id: 'word.chat' })}
      </Button>
    </Flex>
  )
}
