import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import type { ButtonProps, IconProps } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { useQueryClient } from '@tanstack/react-query'
import { TrashIcon, useWandaToast } from '@wanda-space/noelle'
import { deleteItem } from 'api-client'
import { reactQueryKeyFetchItems } from 'hooks/useItems'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import type { To } from 'react-router-dom'

interface DeleteItemProps {
  itemId: string
  itemName: string
  deleteWith?: 'button' | 'icon'
  buttonProps?: ButtonProps
  iconProps?: IconProps
  onDeleteNavigateTo?: number | To
}

export const DeleteItem = ({
  itemId,
  itemName,
  deleteWith = 'icon',
  buttonProps,
  onDeleteNavigateTo,
}: DeleteItemProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { formatMessage } = useIntl()
  const [isDeleting, setIsDeleting] = useState(false)
  const navigate = useNavigate()

  const queryClient = useQueryClient()
  const toast = useWandaToast({
    durationInMilliSecond: 9000,
    position: 'bottom',
  })

  const deleteThisItem = async () => {
    try {
      setIsDeleting(true)
      await deleteItem(itemId)
      await queryClient.invalidateQueries({
        queryKey: [reactQueryKeyFetchItems],
      })
      onClose()
      if (onDeleteNavigateTo) {
        navigate(onDeleteNavigateTo as To)
      }
      toast({
        status: 'success',
        title: formatMessage({ id: 'item.delete.toast.success.title' }),
        description: formatMessage({ id: 'item.delete.toast.description.success' }, { itemName }),
        variant: 'subtle',
      })
    } catch (error) {
      toast({
        status: 'error',
        title: formatMessage({ id: 'item.delete.toast.error.title' }),
        description: formatMessage({ id: 'phrase.something.went.wrong' }),
        variant: 'subtle',
      })
      captureException(error)
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <>
      {deleteWith === 'button' ? (
        <Button onClick={onOpen} size="lg" colorScheme="red" {...buttonProps}>
          {formatMessage({ id: 'word.delete' })}
        </Button>
      ) : (
        <TrashIcon
          onClick={(e) => {
            e.stopPropagation()
            onOpen()
          }}
          color="red.600"
          w={4}
          h={4}
        />
      )}
      <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody mt="3">
            <Text>{formatMessage({ id: 'confirm.delete.storage.item' })}</Text>
            <Text>
              <strong>Item: </strong>
              {itemName}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button isLoading={isDeleting} colorScheme="red" px="5" mr={3} onClick={deleteThisItem}>
              {formatMessage({ id: 'word.delete' })}
            </Button>
            <Button onClick={onClose} variant="ghost">
              {formatMessage({ id: 'word.cancel' })}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
