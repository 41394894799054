import { Container, Flex, Spinner } from '@chakra-ui/react'
import { BackButton } from '@wanda-space/noelle'
import { FlowStepRoutes } from 'components/FlowStepRoutes'
import { Routes as routes } from 'consts'
import { useAppSelector } from 'hooks/useAppSelector'
import { useSteps } from 'hooks/useCurrentStep'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { FlowStep } from 'interfaces'
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ListingForStorage } from 'reduxStore/ducks/sell/sellFlow'
import { sellFromStorage, simpleId } from 'routes/BuySellFlow/BuySellFlow'
import { CreateListing } from 'routes/BuySellFlow/Sell/Storage/CreateListingForStorage'
import { ListingPublished } from 'routes/BuySellFlow/common/ListingPublished'
import { joinString, pathTo } from 'utils'

import { SellIntroduction } from '../../common/Introduction'
import { ListingCreateConfirmForStorage } from './ListingCreateConfirmForStorage'
import { SelectItems } from './SelectItemsForStorage'
import { SellingType } from './SellingType'

const isListingInformationComplete = (listing: ListingForStorage) =>
  listing.name && listing.price && listing.storageItems.length

export const getPublishedListingRoute = (id: string) =>
  joinString([sellFromStorage.listingPublished, id], '/')

export const getSellItemLink = (id: string) =>
  joinString([pathTo(routes.Sell, sellFromStorage.createListing), `?itemId=${id}`])

export const selectItemsRoute = pathTo(routes.Sell, sellFromStorage.selectItems)

export const SellingFlow = (): JSX.Element => {
  const navigate = useNavigate()
  const listingState = useAppSelector((state) => state.sellFlow)
  const [searchParams] = useSearchParams()
  const { data: featureFlags, isLoading: isFeatureFlagsLoading } = useFeatureFlags()

  const steps: FlowStep[] = [
    ...(featureFlags?.ENABLE_FLOW_INTRODUCTION_STEP
      ? [
          {
            element: SellIntroduction,
            path: sellFromStorage.introduction,
          },
        ]
      : []),
    {
      element: SellingType,
      path: sellFromStorage.selectSellType,
    },
    {
      requireLogin: true,
      element: SelectItems,
      path: sellFromStorage.selectItems,
    },
    {
      element: CreateListing,
      path: sellFromStorage.createListing,
      requireLogin: true,
      fallback: () =>
        Boolean(listingState.storageItems.length || searchParams.getAll('itemId').length),
    },
    {
      element: ListingCreateConfirmForStorage,
      path: sellFromStorage.listingPreview,
      requireLogin: true,
      fallback: () => !isListingInformationComplete(listingState),
    },
    {
      element: ListingPublished,
      path: getPublishedListingRoute(simpleId),
      requireLogin: true,
    },
  ]

  const { isLastStep, isFirstStep, onNextStep, currentStep, nextStep } = useSteps(
    steps,
    `${routes.Sell}/`,
    {
      useMatchPath: true,
    }
  )

  const onBack = !isLastStep && !isFirstStep ? () => navigate(-1) : undefined

  const isListingPage = [
    sellFromStorage.listingPreview,
    getPublishedListingRoute(simpleId),
  ].includes(currentStep.path)

  if (isFeatureFlagsLoading) {
    return <Spinner />
  }

  return (
    <Flex display="flex" flexDirection="column">
      <Container mx={isListingPage ? 0 : undefined} py={0} alignSelf="flex-start" mb={4}>
        {onBack && (
          <BackButton
            backTo=""
            linkProps={{
              onClick: (e) => {
                e.preventDefault()
                onBack()
              },
            }}
          />
        )}
      </Container>
      <FlowStepRoutes
        steps={steps}
        currentStep={currentStep}
        onNextStep={onNextStep}
        flowRootPath={routes.Sell}
        nextStep={nextStep}
      />
    </Flex>
  )
}
