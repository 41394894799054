import {
  type DiscountResponseDto,
  type ItemResponseDto,
  OrderContext,
  type OrderResponseWithItems,
  ProductPriceType,
  type ProductResponseDto,
  ProductTarget,
  ProductType,
  StorageItemType,
  SupportedCurrencies,
  UserFeatures,
} from '@wanda-space/types'
import {
  type CategoryRange,
  type OrderLineForNewItems,
  type OrderLineWithFullProductAndDiscount,
  type OrderlineInOrderResponse,
  type Product,
  type ProductCollection,
} from 'api-client'
import { groupBy, indexBy } from 'ramda'
import {
  add,
  getSupportedCurrencyFromProductResponseDto,
  sanitizeAmount,
  toSupportedCurrency,
} from 'utils'

import { type PriceWrapper } from '../interfaces'
import { FormatMessage } from './format'

const THRESHOLDS = {
  SMALL: 40,
  LARGE: 7,
}

export function hasStorageProduct(
  item: Pick<ItemResponseDto, 'storageProduct'>
): item is ItemResponseDto & { storageProduct: Product } {
  return !!item.storageProduct
}

export function getProductLocalizedName(
  product: Product | OrderlineInOrderResponse['product'],
  formatMessage: FormatMessage
) {
  if (product.metadata?.m2Size) {
    return `${product.metadata?.m2Size}m²`
  }

  return formatMessage({ id: `${product.localizationKey}.name` })
}

export function productToPriceForPickerItem(product: Pick<Product, 'price' | 'currency'>) {
  return {
    amount: sanitizeAmount(product.price),
    currency: product.currency,
  }
}

export function productToPriceString(product: Product) {
  const price = productToPrice(product)
  return `${price.amount} ${price.currency}`
}

export function productToPrice(
  product: Pick<Product, 'price' | 'currency' | 'originalPrice'>
): PriceWrapper {
  const amount = sanitizeAmount(product.price)
  const originalAmount = sanitizeAmount(product.originalPrice ?? 0)
  const oldPrice = originalAmount > 0 ? originalAmount : undefined
  return {
    amount,
    currency: toSupportedCurrency(product.currency),
    oldPrice,
  }
}

export function hasItemLimitExceeded(currentItemCounts: Record<StorageItemType, number>) {
  return (
    currentItemCounts.BOX + currentItemCounts.SMALL > THRESHOLDS.SMALL ||
    currentItemCounts.LARGE > THRESHOLDS.LARGE
  )
}

export function getTotalDiscountedPriceForProducts(products: Product[]): PriceWrapper['amount'] {
  const dicountedProductPrices: PriceWrapper[] = products.map((product) => ({
    amount: product.discount?.amount ?? 0,
    currency: toSupportedCurrency(product.currency),
  }))

  return sanitizeAmount(add(...dicountedProductPrices).amount)
}

export const getProductsWithDiscount = (
  products: ProductResponseDto[],
  discounts: DiscountResponseDto[]
): Product[] => {
  return products.map((product) => {
    return mapProductToDiscountedProduct(product, discounts)
  })
}

export const mapProductToDiscountedProduct = (
  product: ProductResponseDto,
  discounts: DiscountResponseDto[]
): Product => {
  const discountProduct = discounts.find((d) => {
    return d.productId === product.id
  })

  const discountedPrice = mapToDiscountedPrice(
    product,
    discounts,
    getSupportedCurrencyFromProductResponseDto(product)
  )

  return {
    ...product,
    price: discountedPrice.price,
    originalPrice: product.price,
    discount: discountProduct,
  }
}

export const discountMapper = (
  orderlines: OrderLineWithFullProductAndDiscount[],
  discounts: DiscountResponseDto[]
) => {
  const lol = orderlines.map((i) => {
    const d = discounts.find((l) => l.productId === i.product.id)

    let price = i.product.price
    let discount = i.product.discount

    if (d && !discount) {
      price = i.product.price - d.amount
      discount = d
    } else if (
      d &&
      i.product.discount &&
      i.product.originalPrice &&
      i.product.discount?.amount < d.amount
    ) {
      price = i.product.originalPrice - d.amount
      discount = d
    }

    return { ...i, product: { ...i.product, price: price, discount: discount } }
  })
  return lol
}

export const getAllOrderlinesWithDiscounts = (
  addons: OrderLineWithFullProductAndDiscount[],
  packing: OrderLineWithFullProductAndDiscount[],
  storage: OrderLineWithFullProductAndDiscount[],
  taas: OrderLineWithFullProductAndDiscount[],
  timeslot: OrderLineWithFullProductAndDiscount[],
  services: OrderLineWithFullProductAndDiscount[],
  discounts: DiscountResponseDto[]
): {
  addons: OrderLineWithFullProductAndDiscount[]
  packing: OrderLineWithFullProductAndDiscount[]
  storage: OrderLineWithFullProductAndDiscount[]
  taas: OrderLineWithFullProductAndDiscount[]
  timeslot: OrderLineWithFullProductAndDiscount[]
  services: OrderLineWithFullProductAndDiscount[]
} => {
  const newAddons = addons.length > 0 ? discountMapper(addons, discounts) : []
  const newPacking = packing.length > 0 ? discountMapper(packing, discounts) : []
  const newStorage = storage.length > 0 ? discountMapper(storage, discounts) : []
  const newTaas = taas.length > 0 ? discountMapper(taas, discounts) : []
  const newTimeslot = timeslot.length > 0 ? discountMapper(timeslot, discounts) : []
  const newServices = services.length > 0 ? discountMapper(services, discounts) : []

  return {
    addons: newAddons,
    packing: newPacking,
    storage: newStorage,
    taas: newTaas,
    timeslot: newTimeslot,
    services: newServices,
  }
}

const mapToDiscountedPrice = (
  product: Product | OrderResponseWithItems['orderLines'][0]['product'],
  discounts: DiscountResponseDto[],
  currency: SupportedCurrencies
): { price: number; currency: SupportedCurrencies } => {
  const discountProduct = discounts.find((d) => {
    return d.productId === product.id
  })

  const discountedPrice = product.price - (discountProduct ? discountProduct?.amount : 0)

  const amount = Math.max(discountedPrice, 0)

  return {
    price: amount,
    currency: currency,
  }
}

export const getOrderlinesWithDiscount = (
  orderLine: OrderLineForNewItems,
  discounts: DiscountResponseDto[],
  currency: SupportedCurrencies
) => {
  const discountedCarrying = orderLine.carrying.map(({ product }) =>
    mapToDiscountedPrice(product, discounts, currency)
  )

  const carryingTotalCost = add(
    ...discountedCarrying.map(({ price }) => productToPrice({ price, currency }))
  )

  const discountedCurbside = orderLine.curbside.map(({ product }) =>
    mapToDiscountedPrice(product, discounts, currency)
  )

  const curbSideTotalCost = add(
    ...discountedCurbside.map(({ price }) => productToPrice({ price, currency }))
  )

  const discountedFirstDoor = orderLine.firstDoor.map(({ product }) =>
    mapToDiscountedPrice(product, discounts, currency)
  )

  const firstDoorTotalCost = discountedFirstDoor.length
    ? add(...discountedFirstDoor.map(({ price }) => productToPrice({ price, currency })))
    : undefined

  return {
    carryingTotalCost,
    curbSideTotalCost,
    firstDoorTotalCost,
  }
}

export function thresholdMetMap(
  currentItemCounts: Record<StorageItemType, number>,
  categoryRange?: CategoryRange[]
) {
  const THRESHOLD = {
    BOX: 40,
    SMALL: 40,
    LARGE: 7,
  }

  if (categoryRange) {
    categoryRange.forEach((c) => {
      THRESHOLD[c.type] = c.from - 1
    })
  }

  const hasSmallhresholdMet = currentItemCounts.BOX + currentItemCounts.SMALL >= THRESHOLD.SMALL

  return {
    BOX: hasSmallhresholdMet,
    SMALL: hasSmallhresholdMet,
    LARGE: currentItemCounts.LARGE >= THRESHOLD.LARGE,
  }
}

export const isApplicableToProduct = (sourceProduct: Product, productId: string): boolean => {
  if (Array.isArray(sourceProduct.applicableToProducts)) {
    return sourceProduct.applicableToProducts.includes(productId)
  }
  return true
}

export const isApplicableToAnyService = (sourceProducts: Product[], productId: string): boolean => {
  return sourceProducts.some((product) => isApplicableToProduct(product, productId))
}

export const getProductCollection = (
  products: ProductResponseDto[],
  metadataByType?: Partial<Record<ProductType, Record<string, string | number | boolean>>>
): ProductCollection => {
  const filteredProducts = products.filter(({ productType, metadata }: Product) => {
    const filter = metadataByType?.[productType]
    if (filter === undefined) {
      return true
    }
    return Object.keys(filter).some((metaKey) => {
      if (typeof filter[metaKey] === 'boolean') {
        return !!metadata[metaKey] === filter[metaKey]
      }
      // This equation seems to be intentional, e.g. 2 equals "2"
      return metadata[metaKey] === filter[metaKey]
    })
  })
  const { USER, ITEM, ORDER } = ProductTarget
  const { STORAGE, SERVICE, ADD_ON, SHIPPING, SHOP } = ProductType
  const productsById: Record<string, Product> = indexBy(({ id }) => id, filteredProducts)
  const userProducts = filteredProducts.filter(({ target }) => target === USER)
  const itemProducts = filteredProducts.filter(({ target }) => target === ITEM)
  const storageProducts = filteredProducts.filter(({ productType }) => productType === STORAGE)
  const serviceProducts = filteredProducts.filter(({ productType }) => productType === SERVICE)
  const addOnProducts = filteredProducts.filter(({ productType }) => productType === ADD_ON)
  const timeslotsProducts = indexBy(
    ({ id }) => id,
    filteredProducts.filter(({ productType, target }) => productType === ADD_ON && target === ORDER)
  )
  const packagingProducts = filteredProducts.filter(
    ({ productType, target }) => productType === SHOP && target === ORDER
  )
  const shippingProducts = filteredProducts.filter(
    ({ productType, target }) => productType === SHIPPING && target === ORDER
  )

  const baseStorageProductByItemType = indexBy(
    ({ metadata }: Product) => metadata.itemType as StorageItemType,
    storageProducts.filter(({ metadata }) => metadata.baseStorageProduct)
  )
  const productsByCategoryId = groupBy(({ categoryId }) => categoryId ?? 'null', filteredProducts)
  const storageProductsByCategoryId = groupBy(
    ({ categoryId }) => categoryId ?? 'null',
    storageProducts
  )

  return {
    products,
    userProducts,
    itemProducts,
    storageProducts,
    serviceProducts,
    baseStorageProductByItemType,
    addOnProducts,
    timeslotsProducts,
    storageProductsByCategoryId,
    productsByCategoryId,
    productsById,
    packagingProducts,
    shippingProducts,
  }
}

export const isOrderlineForRecurringProduct = (
  orderline: OrderLineWithFullProductAndDiscount | OrderlineInOrderResponse
) => {
  return orderline.product.priceType === ProductPriceType.RECURRING
}

export const isOrderlineForOnetimeProduct = (
  orderline: OrderLineWithFullProductAndDiscount | OrderlineInOrderResponse
) => {
  return orderline.product.priceType === ProductPriceType.ONE_TIME
}

export const productToDiscount = (product: Product): PriceWrapper | undefined => {
  if (product.discount) {
    return {
      amount: sanitizeAmount(product.discount.amount),
      currency: toSupportedCurrency(product.currency),
    }
  }
}

export const flexAddressProductFinder = (product: Product) =>
  product.priceType === ProductPriceType.RECURRING &&
  product.metadata.userFeatureKey === UserFeatures.FLEX_ADDRESS

export const insuranceProductFinder = (product: Product) =>
  product.priceType === ProductPriceType.RECURRING &&
  product.metadata.userFeatureKey === UserFeatures.INSURANCE
