import {
  type LookupImageByIdDto,
  type PaginatedResponseDto,
  type PaginationQueryParams,
  type ServiceProviderResponseDto,
  type UploadedImageIdsResponseDto,
} from '@wanda-space/types'

import { request, requestBlob } from '../api-client'

export async function fetchServiceProvider(id: string): Promise<ServiceProviderResponseDto> {
  return request<ServiceProviderResponseDto>({
    method: 'GET',
    url: `service-providers/${id}`,
  })
}

export async function fetchServiceProviders(params?: PaginationQueryParams) {
  return request<PaginatedResponseDto<ServiceProviderResponseDto>>({
    method: 'GET',
    url: 'service-providers',
    params,
  })
}

export async function fetchServiceProviderImageIds(id: string) {
  return request<UploadedImageIdsResponseDto>({
    method: 'GET',
    url: `service-providers/${id}/images`,
  })
}

export function fetchServiceProviderImage(
  serviceProviderId: LookupImageByIdDto['id'],
  imageId: LookupImageByIdDto['imageId']
) {
  return requestBlob({
    method: 'GET',
    url: `service-providers/${serviceProviderId}/image/${imageId}`,
  })
}
