import { Button, HStack, IconButton, Image } from '@chakra-ui/react'
import { DynamicIconName, SellWithWandaIcon, UserSettingIcon } from '@wanda-space/noelle'
import logo from '@wanda-space/noelle/illustrations/logo.svg'
import { Routes, WANDA_LAST_ROUTE } from 'consts'
import { useDesktopQuery } from 'hooks'
import React from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { linkFormatter } from 'utils/linkFormatter'
import { NavMenuItem } from '../AuthenticatedNav/NavMenuItem'

const UnAutheticatedNav = () => {
  const { formatMessage } = useIntl()
  const isDesktop = useDesktopQuery()

  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  const handleLogin = () => {
    localStorage.setItem(WANDA_LAST_ROUTE, pathname + search)
    navigate(Routes.Login + search)
  }

  return (
    <>
      <a href={linkFormatter(formatMessage)}>
        <Image alt="wanda.space" height={[8, 10]} src={logo} />
      </a>

      {pathname.includes('login') ? (
        <Link to={Routes.Register}>{formatMessage({ id: 'word.register' })}</Link>
      ) : (
        <HStack>
          {isDesktop ? (
            <>
              <NavMenuItem
                text={formatMessage({ id: 'navigation.market.title' })}
                iconName={DynamicIconName.sellWithWanda}
                href="https://wanda.space/market"
                borderRadius
              />
              <Button
                _hover={{ bg: 'purple.200' }}
                variant="ghost"
                key="login"
                leftIcon={<UserSettingIcon color="purple.700" />}
                onClick={handleLogin}
              >
                {formatMessage({ id: 'word.login' })}
              </Button>
            </>
          ) : (
            <>
              <IconButton
                as="a"
                href="https://wanda.space/market"
                variant="ghost"
                aria-label="market"
                _hover={{ bg: 'purple.200' }}
                icon={<SellWithWandaIcon color="purple.700" />}
                color={'purple.700'}
                fontStyle="normal"
              />
              <IconButton
                aria-label="login"
                colorScheme="ctaBlack"
                fontWeight="medium"
                color={'purple.700'}
                icon={<UserSettingIcon color="purple.700" />}
                variant="ghost"
                onClick={handleLogin}
                textDecoration="underline"
              />
            </>
          )}
        </HStack>
      )}
    </>
  )
}

export { UnAutheticatedNav }
