import { SupportedCountries } from '@wanda-space/noelle'
import {
  type ConditionType,
  type ConfirmPaymentIntent,
  type CreateListingBidBodyDto,
  type CreateListingRequestBodyDto,
  type CreateListingWithPickupRequestDto,
  type CreateListingWithPickupResponseDto,
  type ItemResponseDto,
  type ListingBidResponseDto,
  type ListingBideWithListingAndBidderDetailsResponseDto,
  type ListingOpsState,
  type ListingProductCategoriesParamDto,
  type ListingProductCategoriesResponseDto,
  type ListingResponseDto,
  type ListingStorageItemResponseDto,
  type LookupBidByIdForListingParamDto,
  type OrderByDirection,
  type PaginatedResponseDto,
  type PaginationQueryParams,
  type SetupIntentResponseDto,
  type UpdateListingBidStatusBodyDto,
  UpdateListingStatusDto,
} from '@wanda-space/types'
import { Locale } from 'i18n'

import { ApiParams, request, requestBlob } from '../api-client'
import { ListingServiceFees } from '../types'

export type ListingDtoChunk = PaginatedResponseDto<ListingResponseDto>

export async function createListingForStorage(
  data: CreateListingRequestBodyDto
): Promise<ListingResponseDto> {
  return request({
    method: 'POST',
    url: 'listing',
    body: { ...data },
  })
}

export async function getListings(params: PaginationQueryParams): Promise<ListingDtoChunk> {
  return request({
    method: 'GET',
    url: 'listing',
    params: params,
  })
}

export const postListingImage = async ({
  listingId,
  file,
}: {
  listingId: string
  file: string
}): Promise<{ imageId: string }> => {
  const form = new FormData()

  const img = await fetch(file)
  const imgBlob = await img.blob()
  form.append('file', imgBlob)

  return request({
    method: 'POST',
    url: `listing/${listingId}/image`,
    body: form,
  })
}

export async function postListingPrimaryImage(listingId: string, imageId: string): Promise<void> {
  return request({
    method: 'POST',
    url: `listing/${listingId}/image/${imageId}/primary`,
    body: {},
  })
}

export async function getListingById(id: string): Promise<ListingResponseDto> {
  return request<ListingResponseDto>({
    method: 'GET',
    url: `listing/${id}`,
  }).then((listing) => listing)
}

export async function fetchAllSellableListings(params: {
  itemsPerPage: string
  page: string
  filters: {
    city?: string
    country: string
    conditionType?: string[]
    categoryIds?: string[]
    opsState?: ListingOpsState
  }
  orderBy: string
  orderDir: OrderByDirection
}): Promise<ListingDtoChunk> {
  const queryParams = {
    itemsPerPage: params.itemsPerPage,
    page: params.page,
    orderBy: params.orderBy,
    orderDir: params.orderDir,
    filters: {
      city: params.filters.city,
      country: params.filters.country,
      conditionType: params.filters.conditionType?.map((condition) => condition),
      categoryIds: params.filters.categoryIds,
      opsState: params.filters.opsState,
    },
  }

  return request({
    method: 'GET',
    url: 'listing/all-sellable-listings',
    params: queryParams,
  })
}

export function getListingBySimpleId(simpleId: string): Promise<ListingResponseDto> {
  return request<ListingResponseDto>({
    method: 'GET',
    url: `listing/${simpleId}/simple-id`,
  })
    .then((listing) => listing)
    .catch((err) => {
      return Promise.reject(err.body.status)
    })
}

/**
 * @deprecated use confirmListingPurchase instead
 */
export async function buyListing(id: string): Promise<void> {
  return request({
    method: 'POST',
    url: `listing/${id}/buy/storage`,
    body: {},
  })
}

export async function confirmListingPurchase(id: string, params: ConfirmPaymentIntent) {
  return request<ListingResponseDto>({
    method: 'POST',
    url: `listing/${id}/payment-intent/confirm`,
    body: params,
  })
}

export async function getListingImages(id: string): Promise<string[]> {
  return request({
    method: 'GET',
    url: `listing/${id}/images`,
  })
}

export async function downloadListingImages(id: string): Promise<Blob> {
  return requestBlob({
    method: 'GET',
    url: `listing/${id}/images/download`,
  })
}

export async function getListingImageById(listingId: string, imageId: string): Promise<Blob> {
  return requestBlob({
    method: 'GET',
    url: `listing/${listingId}/image/${imageId}`,
  })
}

export async function fetchListingItems(id: string) {
  return request<ListingStorageItemResponseDto>({
    method: 'GET',
    url: `listing/${id}/items`,
  })
}

export async function getListingItemImageById(
  listingId: string,
  itemId: string,
  imageId: string
): Promise<Blob> {
  return requestBlob({
    method: 'GET',
    url: `listing/${listingId}/item/${itemId}/image/${imageId}`,
  })
}

export async function fetchListingSoldToUser(
  params: PaginationQueryParams
): Promise<ListingDtoChunk> {
  return request({
    method: 'GET',
    url: 'listing/bought',
    params,
  })
}

export async function fetchListingConfig(params: { countryCode: SupportedCountries }) {
  return request<ListingServiceFees>({
    method: 'GET',
    url: 'listing/config',
    params,
  })
}

export async function fetchSellableItemsForListing(params: PaginationQueryParams) {
  return request<PaginatedResponseDto<ItemResponseDto>>({
    method: 'GET',
    url: 'listing/sellable-stored-items',
    params,
  })
}

export async function updateListingStatus(listingId: string, params: UpdateListingStatusDto) {
  return request({
    method: 'PUT',
    url: `listing/${listingId}/status`,
    body: params,
  })
}

export async function createListingPaymentIntent(listingId: string) {
  return request<SetupIntentResponseDto>({
    method: 'POST',
    url: `listing/${listingId}/payment-intent`,
    body: {},
  })
}

export async function createListingWithPickup({
  listing,
  order,
  showOwnerName,
}: CreateListingWithPickupRequestDto) {
  return request<CreateListingWithPickupResponseDto>({
    method: 'POST',
    url: 'listing/with-pickup',
    body: {
      listing,
      order,
      showOwnerName,
    },
  })
}

export async function updateListingName(name: string, listingId: string) {
  return request<ListingResponseDto>({
    method: 'PUT',
    url: `listing/${listingId}/name`,
    body: { name: name },
  })
}

export async function updateListingPrice(price: number, listingId: string) {
  return request<ListingResponseDto>({
    method: 'PUT',
    url: `listing/${listingId}/price`,
    body: { price: price },
  })
}

export async function updateListingDescription(description: string, listingId: string) {
  return request<ListingResponseDto>({
    method: 'PUT',
    url: `listing/${listingId}/description`,
    body: { description: description },
  })
}

export async function updateListingItemImage(listingId: string, itemId: string, file: string) {
  const form = new FormData()

  const img = await fetch(file)
  const imgBlob = await img.blob()
  form.append('file', imgBlob)

  return await request<{ imageId: string }>({
    method: 'PUT',
    url: `listing/${listingId}/item/${itemId}/image`,
    body: form,
  })
}
export async function generateListingDescription(params: {
  title: string
  tone: string
  file: string
  locale: Locale
}) {
  const form = new FormData()

  console.log(params.file)
  if (params.file) {
    const img = await fetch(params.file)
    const imgBlob = await img.blob()
    form.append('data', imgBlob)
  }
  form.append('tone', params.tone)
  form.append('title', params.title)
  form.append('locale', params.locale)

  return await request<{ description: string }>({
    method: 'POST',
    url: 'listing/generate-description',
    body: form,
  })
}

export async function deleteListingImage(listingId: string, imageId: string) {
  return request({
    method: 'DELETE',
    url: `listing/${listingId}/image/${imageId}`,
  })
}

export async function updateListingDimension(
  listingId: string,
  dimensionType: string,
  value?: number | string | null
) {
  return request({
    method: 'PUT',
    url: `listing/${listingId}/dimensions/${dimensionType}`,
    body: { value },
  })
}

export async function updateListingConditionType(listingId: string, conditionType: ConditionType) {
  return request({
    method: 'PUT',
    url: `listing/${listingId}/condition-type`,
    body: { conditionType },
  })
}

export async function updateListingExtraDescriptiveInfo(
  listingId: string,
  extraDescriptiveInfo: ListingResponseDto['extraDescriptiveInfo']
) {
  return request({
    method: 'PUT',
    url: `listing/${listingId}/extra-descriptive-info`,
    body: { extraDescriptiveInfo },
  })
}

export async function createListingBid(listingId: string, data: CreateListingBidBodyDto) {
  return request<ListingBidResponseDto>({
    method: 'POST',
    url: `listing/${listingId}/bid`,
    body: { amount: data.amount },
  })
}

export async function getBidsForListing(listingId: string) {
  return request<PaginatedResponseDto<ListingBidResponseDto>>({
    method: 'GET',
    url: `listing/${listingId}/bid`,
  })
}

export async function getBidByIdForListing(listingId: string, bidId: string) {
  return request<ListingBideWithListingAndBidderDetailsResponseDto>({
    method: 'GET',
    url: `listing/${listingId}/bid/${bidId}`,
  })
}

export async function updateBidStatus(
  { id, bidId }: LookupBidByIdForListingParamDto,
  update: UpdateListingBidStatusBodyDto
) {
  return request<ListingBidResponseDto>({
    method: 'PUT',
    url: `listing/${id}/bid/${bidId}/status`,
    body: update,
  })
}

export async function getAllBidsForUser(params: PaginationQueryParams = {}) {
  return request<PaginatedResponseDto<ListingBideWithListingAndBidderDetailsResponseDto>>({
    method: 'GET',
    url: 'listing/bid',
    params: {
      page: params.page?.toString(),
      itemsPerPage: params.itemsPerPage?.toString(),
    },
  })
}

export async function getListingProductCategories(
  params: ListingProductCategoriesParamDto,
  queryParams: ApiParams
) {
  return request<ListingProductCategoriesResponseDto[]>({
    method: 'GET',
    url: `listing/category/${params.country}`,
    params: queryParams,
  })
}
