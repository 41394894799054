import { FieldNames } from 'components/contact-info/types'
import { getEnv } from 'env'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useAuth } from './useAuth'

export const useHideFields = () => {
  const { isAuthenticated } = useAuth()
  const [searchParams] = useSearchParams()
  const passwordless = searchParams.has('passwordless')

  return useMemo(() => {
    if (isAuthenticated) {
      return [FieldNames.EMAIL]
    } else {
      return getEnv('APP_ENVIRONMENT') === 'prd'
        ? [FieldNames.PHONE_NUMBER]
        : passwordless
          ? [FieldNames.PHONE_NUMBER]
          : [FieldNames.EMAIL]
    }
  }, [isAuthenticated])
}
