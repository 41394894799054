import { Flex, type IconProps, Text } from '@chakra-ui/react'
import { DynamicIcon, type DynamicIconName } from '@wanda-space/noelle'
import React from 'react'
import { NavLink } from 'react-router-dom'

export type NavMenuItemProps = {
  text: string
  iconName: DynamicIconName
  iconProps?: Omit<IconProps, 'orientation'>
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  borderRadius?: boolean
} & (
  | {
      path: string
      /** Default false. @see {@link https://reactrouter.com/en/main/components/nav-link#end docs} */
      end?: boolean
    }
  | { href: string }
)

export const NavMenuItem = ({
  text,
  iconName,
  onClick,
  borderRadius,
  iconProps,
  ...props
}: NavMenuItemProps) => {
  if ('href' in props) {
    return (
      <Flex
        as="a"
        href={props.href}
        onClick={onClick}
        gap="14px"
        _hover={{ bg: 'purple.200' }}
        px="3"
        py="2"
        mx="1"
        borderRadius={borderRadius ? 'xl' : 0}
        fontWeight="normal"
        _activeLink={{ bg: 'purple.300' }}
      >
        <>
          <DynamicIcon iconName={iconName} color={'purple.700'} h={6} w={6} {...iconProps} />
          <Text>{text}</Text>
        </>
      </Flex>
    )
  }

  if ('path' in props) {
    return (
      <Flex
        as={NavLink}
        to={props.path}
        onClick={onClick}
        gap="14px"
        _hover={{ bg: 'purple.200' }}
        px="3"
        py="2"
        mx="1"
        borderRadius={borderRadius ? 'xl' : 0}
        fontWeight="normal"
        _activeLink={{ bg: 'purple.300' }}
        end={props.end ?? false}
      >
        {({ isActive }: { isActive: boolean }) => (
          <>
            <DynamicIcon
              iconName={iconName}
              color={isActive ? 'purple.800' : 'purple.700'}
              h={6}
              w={6}
              {...iconProps}
            />
            <Text>{text}</Text>
          </>
        )}
      </Flex>
    )
  }
  throw new Error('Invalid props pass either path or href')
}
