import { BulletPoint } from '@wanda-space/noelle'
import { ServiceLevelType } from '@wanda-space/types'
import { format } from 'date-fns'
import { ReturnFlowState } from 'reduxStore/ducks/returnFlow/types'
import { FormatMessage, formatAddress } from 'utils'
import { formatTimeSlot } from 'utils/timeslot-utils'

export const returnFlowPaths = {
  selectItems: 'select-items',
  schedule: 'schedule',
  carrying: 'carrying',
  summary: 'summary',
  confirm: 'confirm',
  address: 'address',
}

export const bulletPoints = (
  formatMessage: FormatMessage,
  data: ReturnFlowState,
  furthestStepReached: number,
  locale: Locale | undefined
): BulletPoint[] => [
  {
    summaryText:
      data.orderLines.storage.length > 0
        ? formatMessage({ id: 'nav.return' }, { itemcount: data.orderLines.storage.length })
        : undefined,
    inProgressText: formatMessage({ id: 'returnFlow.step.selectItems' }),
    link: returnFlowPaths.selectItems,
  },
  {
    summaryText: data.dateAndTime.date
      ? formatMessage(
          { id: 'editOrderDetails.deliveryTime' },
          {
            date: `${format(new Date(data.dateAndTime.date), 'EEEE do MMM', {
              locale,
            })}, ${formatTimeSlot(data.dateAndTime.timeslot)})
              `,
          }
        )
      : undefined,
    inProgressText: formatMessage({ id: 'nav.timeAndDate.default' }),
    link: returnFlowPaths.schedule,
  },
  {
    summaryText:
      data.address.street && data.address.postalCode && data.address.city
        ? //@ts-ignore idk why this is not working
          formatAddress(data.address)
        : undefined,
    inProgressText: formatMessage({ id: 'word.address' }),
    link: returnFlowPaths.address,
  },
  {
    summaryText:
      furthestStepReached > 2
        ? data.serviceLevelType === ServiceLevelType.CURBSIDE
          ? formatMessage({ id: 'returnFlow.curbside.title' })
          : formatMessage({ id: 'returnFlow.carrying.title' })
        : undefined,
    inProgressText: `${formatMessage({ id: 'word.carrying' })}?`,
    link: returnFlowPaths.carrying,
  },
  {
    inProgressText: formatMessage({ id: 'word.payment' }),
    link: returnFlowPaths.summary,
  },
]
