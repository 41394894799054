import {
  Box,
  Button,
  Divider,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import {
  ConditionType,
  ListingOpsState,
  type ListingResponseDto,
  SupportedCities,
} from '@wanda-space/types'
import { PriceWrapper } from 'interfaces'
import React from 'react'
import { useIntl } from 'react-intl'
import { formatServiceArea } from 'utils/serviceArea'

import { BuyIntroduction } from '../common/Introduction'

interface BuyBoxProps {
  listingPrice: number
  serviceFee?: number
  locale?: Locale
  availableInServiceArea: SupportedCities
  curbsideFee: number
  storagePrice: PriceWrapper
  handleBuy?: () => void
  handleBid?: () => void
  conditionType?: ConditionType
  dimensionLength?: ListingResponseDto['dimensionLength']
  dimensionHeight?: ListingResponseDto['dimensionHeight']
  dimensionWidth?: ListingResponseDto['dimensionWidth']
  dimensionFreeText?: ListingResponseDto['dimensionFreeText']
  extraDescriptiveInfo?: ListingResponseDto['extraDescriptiveInfo']
  category?: (string | undefined)[]
  opsState?: ListingOpsState
}

export const BuyInformationBox = ({
  listingPrice,
  serviceFee,
  availableInServiceArea,
  curbsideFee,
  storagePrice,
  handleBuy,
  handleBid,
  conditionType,
  dimensionLength,
  dimensionHeight,
  dimensionWidth,
  dimensionFreeText,
  extraDescriptiveInfo,
  category,
  opsState,
}: BuyBoxProps) => {
  const { formatMessage, formatNumber } = useIntl()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const bigMargin = 6
  const smallMargin = 4
  const xSmallMargin = 2

  const hasAnyDimensions = dimensionLength !== 0 && dimensionHeight !== 0 && dimensionWidth !== 0

  const dimensions = `${dimensionLength ?? '-'} x ${dimensionWidth ?? '-'} x ${
    dimensionHeight ?? '-'
  }`

  const hasAnyExtraDescriptiveInfo =
    extraDescriptiveInfo?.brand ||
    extraDescriptiveInfo?.model ||
    extraDescriptiveInfo?.year ||
    extraDescriptiveInfo?.colour ||
    extraDescriptiveInfo?.material

  const isItemsStored = opsState === ListingOpsState.ITEMS_STORED

  return (
    <>
      <Box
        backgroundColor="brown.200"
        border="1px solid"
        borderColor="blackAlpha.200"
        p={bigMargin}
        fontSize="lg"
        borderRadius="2xl"
        width="100%"
        mb={bigMargin}
      >
        {opsState && !isItemsStored && (
          <VStack
            alignItems="center"
            bgColor="brown.50"
            px={bigMargin}
            py={smallMargin}
            borderRadius="2xl"
            mb={smallMargin}
          >
            <Text fontWeight="medium" color="neutralGray.800">
              {formatMessage({ id: 'buy.coming.soon.for.sale' })}
            </Text>
            <Text color="neutralGray.800" fontSize="sm" textAlign="center">
              {formatMessage({ id: 'buy.sell.items.waiting.pickup' })}
            </Text>
          </VStack>
        )}
        <Grid templateColumns="repeat(3, 1fr)" color="neutralGray.800" gap={xSmallMargin}>
          <GridItem mb={smallMargin} id="price">
            <Text fontSize="sm" color="neutralGray.700" casing="uppercase">
              {formatMessage({ id: 'info.buy.sell.price' })}
            </Text>
            <Text as="h2" fontWeight="medium" color="purple.900" fontSize="4xl">
              {formatNumber(listingPrice + (serviceFee ?? 0))},-
            </Text>
          </GridItem>
          <Box />
          <GridItem mb={smallMargin} id="deliveryPrice" alignSelf="flex-end">
            <Text>{formatMessage({ id: 'delivery.from' }, { price: curbsideFee })},-</Text>
          </GridItem>

          <GridItem id="city">
            <Text fontSize="sm" color="neutralGray.700" casing="uppercase">
              {formatMessage({ id: 'info.buy.sell.city' })}
            </Text>
            <Text as="h2" fontWeight="medium" fontSize="medium">
              {formatServiceArea(availableInServiceArea)}
            </Text>
          </GridItem>
          <Divider borderColor="neutralGray.400" justifySelf="center" orientation="vertical" />
          <GridItem>
            <Text fontSize="sm" color="neutralGray.700" casing="uppercase">
              {formatMessage({ id: 'word.category' })}
            </Text>
            {category?.map((c) => {
              return (
                <Text key={c} as="h2" fontWeight="medium" fontSize="medium">
                  {formatMessage({ id: `${c}.name` })}
                </Text>
              )
            })}
          </GridItem>
          <GridItem>
            <Text fontSize="sm" color="neutralGray.700" casing="uppercase">
              {formatMessage({ id: 'word.dimensions' })} ({formatMessage({ id: 'word.lwh' })}){':'}
            </Text>
            {hasAnyDimensions && (
              <Text as="h2" fontWeight="medium" fontSize="medium">
                {dimensions}
                {formatMessage({ id: 'word.cm' })}
              </Text>
            )}
            {dimensionFreeText && (
              <Text as="h2" fontWeight="medium" fontSize="medium">
                {dimensionFreeText}
              </Text>
            )}
            {!hasAnyDimensions && !dimensionFreeText && (
              <Text as="h2" fontWeight="medium" fontSize="medium">
                -
              </Text>
            )}
          </GridItem>
          <Divider orientation="vertical" borderColor="neutralGray.400" justifySelf="center" />
          <GridItem>
            <Text fontSize="sm" color="neutralGray.700" casing="uppercase">
              {formatMessage({ id: 'word.condition' })}
            </Text>
            {conditionType && (
              <Text as="h2" fontWeight="medium" fontSize="medium">
                {formatMessage({ id: `${conditionType.toLowerCase()}.title` })}
              </Text>
            )}
          </GridItem>

          {hasAnyExtraDescriptiveInfo && (
            <GridItem>
              <Text fontSize="sm" color="neutralGray.700" casing="uppercase">
                {formatMessage({ id: 'word.details' })}
              </Text>
              {extraDescriptiveInfo?.brand && (
                <Text>
                  {formatMessage({ id: 'word.brand' })} {': '}
                  {extraDescriptiveInfo?.brand}
                </Text>
              )}
              {extraDescriptiveInfo?.model && (
                <Text>
                  {formatMessage({ id: 'word.model' })} {': '}
                  {extraDescriptiveInfo?.model}
                </Text>
              )}
              {extraDescriptiveInfo?.year && (
                <Text>
                  {formatMessage({ id: 'word.year' })} {': '}
                  {extraDescriptiveInfo?.year}
                </Text>
              )}
              {extraDescriptiveInfo?.colour && (
                <Text>
                  {formatMessage({ id: 'word.colour' })} {': '}
                  {extraDescriptiveInfo?.colour}
                </Text>
              )}
              {extraDescriptiveInfo?.material && (
                <Text>
                  {formatMessage({ id: 'word.material' })}
                  {': '}
                  {extraDescriptiveInfo?.material}
                </Text>
              )}
            </GridItem>
          )}
        </Grid>

        <VStack marginBottom={6}>
          <Button
            mt={smallMargin}
            type="button"
            variant="solid"
            colorScheme="purple"
            size="lg"
            w="100%"
            borderRadius={8}
            data-testid="btn-buy-listing"
            isDisabled={!handleBuy || !isItemsStored}
            onClick={handleBuy}
          >
            {formatMessage({ id: 'word.buy' })}
          </Button>
          <Button
            type="button"
            variant="outline"
            backgroundColor="white"
            colorScheme="purple"
            size="lg"
            w="100%"
            mb={smallMargin}
            borderRadius={8}
            data-testid="btn-bid-listing"
            isDisabled={!handleBid || !isItemsStored}
            onClick={handleBid}
          >
            <Text color="black" as="span">
              {formatMessage({ id: 'word.bid' })}
            </Text>
          </Button>
        </VStack>
        <Box textAlign="center">
          <Button
            color="neutralGray.800"
            textDecoration="underline"
            variant="link"
            onClick={onOpen}
          >
            {formatMessage({ id: 'buy.see.how.it.works' })}
          </Button>
        </Box>
        <Modal onClose={onClose} size="full" isOpen={isOpen}>
          <ModalContent>
            <ModalCloseButton top="5" right="5" />
            <ModalBody padding="3">
              <BuyIntroduction onNextStep={onClose} />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
      <Text fontWeight="medium">{formatMessage({ id: 'buy.free.to.wait' })}</Text>
      <Text fontSize="md" color="neutralGray.800" mb={smallMargin}>
        {formatMessage(
          { id: 'buy.store.with.wanda' },
          {
            storagePrice: storagePrice.amount,
            bold: (value) => (
              <Text as="span" fontWeight="medium">
                {value}
              </Text>
            ),
          }
        )}
      </Text>
    </>
  )
}
