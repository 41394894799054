import { Flex, IconButton, Image as ChakraImage } from '@chakra-ui/react'
import { Spinner, StarIcon, TrashIcon } from '@wanda-space/noelle'
import emptyStateGreen from '@wanda-space/noelle/illustrations/empty-image-green.svg'
import React from 'react'

interface Props {
  imageUrl: string
  onRemoveImage?: (img: string, index: number) => void
  spinnerSize?: 'small' | 'big'
  onChangeMainImg?: (img: string, index: number) => void
  isMainImg?: boolean
  isLoading?: boolean
  index: number
}

const Image = ({
  imageUrl,
  onRemoveImage,
  onChangeMainImg,
  isLoading,
  isMainImg,
  index,
}: Props) => {
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {imageUrl ? (
            <Flex>
              <ChakraImage borderRadius="2xl" alt="" src={imageUrl} />
              {onRemoveImage && (
                <IconButton
                  aria-label="Remove picture"
                  onClick={() => onRemoveImage(imageUrl, index)}
                  icon={<TrashIcon />}
                  position="absolute"
                  borderRadius="100%"
                  bottom={2}
                  right={2}
                />
              )}

              {onChangeMainImg &&
                (isMainImg ? (
                  <IconButton
                    onClick={() => onChangeMainImg(imageUrl, index)}
                    position="absolute"
                    top={2}
                    right={2}
                    borderRadius="100%"
                    icon={<StarIcon color="orange.200" _hover={{ color: 'yellow.300' }} />}
                    aria-label={'favorite picture'}
                    border="1px solid"
                    borderColor="orange.200"
                    backgroundColor="white"
                    _hover={{ borderColor: 'transparent' }}
                  />
                ) : (
                  <IconButton
                    aria-label="Set main picture"
                    position="absolute"
                    top={2}
                    right={2}
                    icon={<StarIcon color="gray.200" _hover={{ color: 'gray.500' }} />}
                    borderRadius="100%"
                    border="1px solid"
                    borderColor="gray.200"
                    backgroundColor="white"
                    _hover={{ borderColor: 'transparent' }}
                    onClick={() => onChangeMainImg(imageUrl, index)}
                  />
                ))}
            </Flex>
          ) : (
            <ChakraImage h={36} alt="empty image" w="100%" src={emptyStateGreen} />
          )}
        </>
      )}
    </>
  )
}

export { Image }
