import { useQuery } from '@tanstack/react-query'
import { getFeatureFlags } from 'api-client/lib/routes/feature-flags'

import { useAppSelector } from './useAppSelector'

export const useFeatureFlags = (options?: { suspense?: boolean }) => {
  const { country, city } = useAppSelector((state) => state.ui)
  const accountId = useAppSelector((state) => state.user.user?.id)
  return useQuery({
    queryKey: ['feature-flags', accountId, country, city],
    queryFn: () => getFeatureFlags({ countryCode: country, city }),
    keepPreviousData: true,
    ...options,
  })
}
