import { Flex, Heading } from '@chakra-ui/react'
import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'

interface PropTypes {
  children: (ReactElement | undefined)[]
}
export const WorthKnowingWrapper = ({ children }: PropTypes) => {
  const { formatMessage } = useIntl()
  return (
    <>
      <Heading as="h2" size="lg" fontWeight="normal">
        {formatMessage({ id: 'phrase.worthKnowing' })}
      </Heading>
      <Flex flexDirection="column" gap={3} mt={5} mb={5} ml={1}>
        {children}
      </Flex>
    </>
  )
}
