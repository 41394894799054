import { EntityState, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {
  ConditionType,
  type CreateListingRequestBodyDto,
  type DiscountResponseDto,
  type ItemResponseDto,
  type PlacedOrderResponseDto,
  ServiceLevelType,
  SupportedCurrencies,
} from '@wanda-space/types'
import type { DateAndTime, OrderLineWithFullProductAndDiscount } from 'api-client'
import { type ContactPerson } from 'components/contact-info/types'
import type { FlattenedDeliveryInfo, PriceWrapper } from 'interfaces'
import type { RootState } from 'reduxStore'
import { mapItemToStorageOrderLine } from 'reduxStore/commonMappers'
import type { ItemPayloadWithProduct } from 'reduxStore/commonMappers'
import { getAllOrderlinesWithDiscounts, getSupportedCurrencyFromProductResponseDto } from 'utils'

import { SliceNames } from '../../constants'

// SHARED

export const getDefaultExtraDescriptiveInfo = () => ({
  brand: '',
  model: '',
  material: '',
  year: '',
  colour: '',
  comment: '',
})

export type CommonListingInformation = Omit<
  CreateListingRequestBodyDto,
  'extraDescriptiveInfo' | 'storageItems' | 'currency' | 'price'
> & {
  coupon?: string
  images: string[]
  extraDescriptiveInfo: {
    colour: string
    brand: string
    model: string
    material: string
    year: string
    comment: string
  }
}

type ListingFormPrice = {
  amount: PriceWrapper['amount'] | undefined
  currency: SupportedCurrencies
}

export type ListingFormValues = CommonListingInformation & {
  price: ListingFormPrice
}

// PICKUP

const itemPayloadAdapter = createEntityAdapter<ItemPayloadWithProduct>()

export interface ListingForPickup extends CommonListingInformation {
  price: PriceWrapper
  storageItems: EntityState<ItemPayloadWithProduct>
  dateAndTime: DateAndTime
  orderLines: {
    storage: OrderLineWithFullProductAndDiscount[]
    taas: OrderLineWithFullProductAndDiscount[]
    packing: OrderLineWithFullProductAndDiscount[]
    timeslot: OrderLineWithFullProductAndDiscount[]
  }
  address: FlattenedDeliveryInfo
  contactPerson?: ContactPerson
  serviceLevel?: ServiceLevelType
  orderSuccessPayload: PlacedOrderResponseDto | null
}

export const sellWithPickupInitialState: ListingForPickup = {
  name: '',
  description: '',
  price: {
    amount: 0,
    currency: SupportedCurrencies.NOK,
  },
  images: [],
  storageItems: itemPayloadAdapter.getInitialState(),
  dateAndTime: {},
  address: {} as FlattenedDeliveryInfo,
  orderLines: { storage: [], taas: [], packing: [], timeslot: [] },
  showOwnerName: true,
  conditionType: ConditionType.USED,
  dimensionLength: undefined,
  dimensionHeight: undefined,
  dimensionWidth: undefined,
  dimensionFreeText: '',
  extraDescriptiveInfo: getDefaultExtraDescriptiveInfo(),
  orderSuccessPayload: null,
}

const sellWithPickupStateSlice = createSlice({
  name: SliceNames.SELL_WITH_PICKUP,
  initialState: sellWithPickupInitialState,
  reducers: {
    updateListingForPickupFormValues(state, action: PayloadAction<ListingFormValues>) {
      Object.assign(state, action.payload)
    },
    setItems: (state, action: PayloadAction<ItemPayloadWithProduct[]>) => {
      itemPayloadAdapter.removeAll(state.storageItems)
      itemPayloadAdapter.addMany(state.storageItems, action.payload)
      state.orderLines.storage = [...action.payload].map(mapItemToStorageOrderLine)
    },
    resetAll() {
      return sellWithPickupInitialState
    },
    setAddress(state, action: PayloadAction<FlattenedDeliveryInfo>) {
      state.address = action.payload
    },
    setContactPerson(state, action: PayloadAction<ContactPerson>) {
      state.contactPerson = action.payload
    },
    selectDateAndTime(state, action: PayloadAction<DateAndTime>) {
      state.dateAndTime = action.payload
    },
    setServiceLevel(state, action: PayloadAction<{ serviceLevelType: ServiceLevelType }>) {
      state.serviceLevel = action.payload.serviceLevelType
    },
    setTaasOrderLines(state, action: PayloadAction<OrderLineWithFullProductAndDiscount[]>) {
      state.orderLines.taas = action.payload
    },
    setTimeslotOrderlines: (
      state,
      action: PayloadAction<OrderLineWithFullProductAndDiscount[]>
    ) => {
      state.orderLines.timeslot = action.payload
    },
    applyDiscounts: (
      state,
      action: PayloadAction<{ coupon?: string; discounts: DiscountResponseDto[] }>
    ) => {
      if (action.payload.coupon) {
        state.coupon = action.payload.coupon
      }

      const { packing, taas, storage, timeslot } = getAllOrderlinesWithDiscounts(
        [],
        state.orderLines.packing,
        state.orderLines.storage,
        state.orderLines.taas,
        state.orderLines.timeslot,
        [],
        action.payload.discounts
      )
      state.orderLines = { packing, taas, storage, timeslot }
    },

    setOrderSuccessPayload: (state, action: PayloadAction<PlacedOrderResponseDto>) => {
      state.orderSuccessPayload = action.payload
    },
  },
})

// STORAGE
export interface ListingForStorage extends CommonListingInformation {
  price: PriceWrapper
  storageItems: ItemResponseDto[]
}

const initialState: ListingForStorage = {
  name: '',
  description: '',
  price: {
    amount: 0,
    currency: SupportedCurrencies.NOK,
  },
  images: [],
  storageItems: [],
  showOwnerName: true,
  conditionType: ConditionType.USED,
  dimensionLength: undefined,
  dimensionHeight: undefined,
  dimensionWidth: undefined,
  dimensionFreeText: '',
  extraDescriptiveInfo: getDefaultExtraDescriptiveInfo(),
}

const SellFromStorageFlowStateSlice = createSlice({
  name: SliceNames.SELL,
  initialState,
  reducers: {
    updateListingFormValues(state, action: PayloadAction<ListingFormValues>) {
      Object.assign(state, action.payload)
    },
    toggleStorageItems(state, action: PayloadAction<ItemResponseDto[]>) {
      state.storageItems = action.payload
    },
    resetAll() {
      return initialState
    },
  },
})

// EXPORTS

export const itemPayloadSelectors = itemPayloadAdapter.getSelectors<RootState>(
  (state) => state.sellWithPickupFlow.storageItems
)

export const orderAddressSelectors = (state: RootState) => state.sellWithPickupFlow.address

export const {
  setItems,
  updateListingForPickupFormValues,
  selectDateAndTime,
  setAddress,
  setContactPerson,
  setServiceLevel,
  setTaasOrderLines,
  resetAll: resetAllSellWithPickupState,
  setTimeslotOrderlines,
  applyDiscounts: applyDiscountsSellWithPickup,
  setOrderSuccessPayload,
} = sellWithPickupStateSlice.actions

export const { updateListingFormValues, toggleStorageItems, resetAll } =
  SellFromStorageFlowStateSlice.actions
export const sell = SellFromStorageFlowStateSlice.reducer
export const sellWithPickupFlow = sellWithPickupStateSlice.reducer
