import { Stepable } from 'interfaces'
import React from 'react'
import { useIntl } from 'react-intl'
import { FlowIntroduction } from 'routes/Common/FlowIntroduction'

const emojis = ['🪑', '🥁', '🚲', '🛋️', '🛝']
const SellIntroduction = ({ onNextStep }: Stepable) => {
  const { formatMessage } = useIntl()
  return (
    <FlowIntroduction
      pageType="sell"
      stepNumber={4}
      onNextStep={onNextStep}
      pageTitleKey="phrase.sell.with.wanda"
      nextBtnDataTestId="sell-go-to-select-selling-type"
      titleKey="sell.introduction.sellWithWanda"
      subtitleKey="sell.introduction.subtitle"
      emojis={emojis}
      readMoreLink={formatMessage({ id: 'intropage.sell.readMoreLink' })}
    />
  )
}

const BuyIntroduction = ({ onNextStep }: Stepable) => {
  const { formatMessage } = useIntl()
  return (
    <FlowIntroduction
      pageType="buy"
      stepNumber={5}
      containerWidth="100%"
      onNextStep={onNextStep}
      pageTitleKey="buySellFlow.step.advertisement"
      titleKey="buy.introduction.sellWithWanda"
      subtitleKey="buy.introduction.subtitle"
      emojis={emojis}
      readMoreLink={formatMessage({ id: 'intropage.buy.readMoreLink' })}
    />
  )
}

export { SellIntroduction, BuyIntroduction }
