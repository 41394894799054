import { Box, Button, Container, Grid, GridItem, Text, Wrap } from '@chakra-ui/react'
import { SelectCard, Spinner } from '@wanda-space/noelle'
import { OrderLinePayload } from 'api-client/lib/types'
import { useProductsAndCategories } from 'hooks/useProductsAndCategories'
import React from 'react'
import { useIntl } from 'react-intl'
import { sanitizeAmount } from 'utils/price-utils'

interface Props {
  onSubmit: () => void
  isPacking: boolean
  onPackingChange: (packing: boolean) => void
  isLoading: boolean
  packingPrice: string
  packingOrderlines: OrderLinePayload[]
  buttonText: string
}

export const SelectPacking = ({
  onSubmit,
  isPacking,
  onPackingChange,
  isLoading,
  packingPrice,
  packingOrderlines,
  buttonText,
}: Props) => {
  const products = useProductsAndCategories()

  const { formatMessage } = useIntl()

  const getOrderLinePrice = (itemProductId: string) => {
    const product = products?.addOnProducts?.find((j) => j.id === itemProductId)

    if (product) return sanitizeAmount(product.price)
    else return 0
  }

  const OrderLineOverview = () => (
    <Grid
      templateRows="repeat(1, 1fr)"
      templateColumns="repeat(4, 1fr)"
      gap={1}
      key={Math.random()}
    >
      {packingOrderlines?.map((i) => (
        <React.Fragment key={i.productId}>
          <GridItem key={i.item?.name || ' '} colSpan={3}>
            <Text> {i.item?.name}</Text>
          </GridItem>
          <GridItem rowSpan={2} colSpan={1} textAlign="right" mb="4">
            {`${getOrderLinePrice(i.productId)},-`}
          </GridItem>
          <GridItem colSpan={3} mb="4">
            <Text size="sm" opacity="0.65">
              {formatMessage({ id: 'booking.packing.orderline.description' })}
            </Text>
          </GridItem>
        </React.Fragment>
      ))}
    </Grid>
  )

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && (
        <Box width="100%">
          <Wrap mb={6}>
            <SelectCard
              key="you-pack"
              title={formatMessage({ id: 'booking.packing.you.pack.title' })}
              description={formatMessage({
                id: 'booking.packing.you.pack.description',
              })}
              active={!isPacking}
              onClick={() => onPackingChange(false)}
              badge={'0,-'}
              data-testid="select-you-pack"
              showCheckMark
            />
          </Wrap>
          <Wrap mb={6}>
            <SelectCard
              key="we-pack"
              title={formatMessage({ id: 'booking.packing.we.pack.title' })}
              description={formatMessage({ id: 'booking.packing.we.pack.description' })}
              active={isPacking}
              onClick={() => onPackingChange(true)}
              badge={packingPrice}
              data-testid="select-we-pack"
              expandable
              closeBtnText={formatMessage({ id: 'word.close' })}
              openBtnText={formatMessage({ id: 'word.show.details' })}
              expandableContent={
                <Container margin="unset" padding="unset" width={'100%'}>
                  <OrderLineOverview />
                </Container>
              }
              showCheckMark
            />
          </Wrap>
          <Button
            width="100%"
            onClick={onSubmit}
            isLoading={isLoading}
            size="lg"
            variant="solid"
            colorScheme="ctaBlack"
            data-testid="continue-button"
          >
            {buttonText}
          </Button>
        </Box>
      )}
    </>
  )
}
